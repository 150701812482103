import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { Input } from "../../../components/common/input/index";
import { useStyle } from "./edu-ques.style";
import Icon from "@material-ui/core/Icon";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";
import { useState } from "react";

type CriteriaProps = {
  questionLabel: string;
  data: any;
  index: number;
  change: any;
  furChange: any;
  errorEdu?: any;
  error?: any;
  onDelete: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const Question: React.FC<CriteriaProps> = ({
  questionLabel,
  data,
  index,
  change,
  furChange,
  errorEdu,
  error,
  onDelete,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <DeleteBox
        index={index}
        id={data.edu_uuid}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text subHeading>{questionLabel}</Text>
        {selectedGlobalDate === currentGlobalDate && (
          <Icon
            style={{ cursor: "pointer", color: "#757575" }}
            onClick={() => setOpen(true)}
          >
            delete
          </Icon>
        )}
      </Box>
      <div className={classes.inputContainer}>
        <Input
          value={data.eduQuestion}
          onChange={(e: any) => change(e.target.value, index, "eduQuestion")}
          error={errorEdu}
          disabled={selectedGlobalDate !== currentGlobalDate}
          maxlength="200"
        />
      </div>
      <Box display="flex" alignItems="center">
        <Checkbox
          color="default"
          onChange={() => furChange(!data.details, index, "details")}
          checked={data.details}
          style={{ marginLeft: "-13px" }}
          disabled={selectedGlobalDate !== currentGlobalDate}
        />
        <Text paragraph>Further details required?</Text>
      </Box>
      {data.details ? (
        <>
          <Box display="flex" alignItems="center">
            <Checkbox
              color="default"
              onChange={() =>
                furChange(
                  !data.require_supporting_doc,
                  index,
                  "require_supporting_doc"
                )
              }
              checked={data.require_supporting_doc}
              style={{ marginLeft: "-13px" }}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
            <Text paragraph>File upload required?</Text>
          </Box>
          <div className={classes.inputContainer}>
            <Input
              label="Further details required *"
              value={data.furtherDetails}
              onChange={(e: any) =>
                furChange(e.target.value, index, "furtherDetails")
              }
              error={error.includes(`${index}`)}
              disabled={selectedGlobalDate !== currentGlobalDate}
              maxlength="200"
            />
          </div>
        </>
      ) : null}
    </Grid>
  );
};

export default Question;
