import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    fontFamily: `"LFT Etica", sans-serif`,
    borderRadius: "5px",
    maxWidth: "94%",
    minWidth: "94%",
    maxHeight: "300px",
    minHeight: "300px",
    padding: "10px 25px ",
    "&:focus": {
      outline: "none",
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
    fontSize: "16px",
    background: theme.palette.common.white,
  },
  rootError: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
    borderRadius: "5px",
    maxWidth: "94%",
    minWidth: "94%",
    maxHeight: "300px",
    minHeight: "300px",
    padding: "10px 25px",
    "&:focus": {
      outline: "none",
    },
    fontFamily: `"LFT Etica", sans-serif`,
    background: theme.palette.common.white,
  },
  disabled: {
    color: theme.palette.grey[400],
  },
  input: {
    padding: 0,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
}));
