/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button } from "../../../../../components/common/button/index";
import { useStyle } from "./student-siblings-details.styled";
import Grid from "@material-ui/core/Grid";
import { InputRadioButton } from "../../../../../components/common/input-radio-button-two";
import { Box } from "@material-ui/core";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import { Loader } from "../../../../../components/common/loader";
import request from "../../../../../utils/authUtil";
import StudentSiblingCard from "../../../../parent/application/student-details/student-sibling-card/student-input-card";
import { ConfirmBox } from "../../../../../components/common/confirm-box";
import { AdminButton } from "../../../../../components/common/admin-button";

type Props = {
  stdAppId: string;
  isDisabled: boolean;
  open: boolean;
  setOpen: any;
  setFormDisabled: any;
  errorEdit: string;
  setErrorEdit: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const DEFAULT_SIBLING_DATA = {
  name_of_student: "",
  year_of_student: 0,
};

const getSiblingsData = async (stdAppId: string): Promise<any> => {
  const res = await request({
    url: `student-application/student-sibling-details/?uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

const postSiblingsData = async ({ stdAppId, localData }: any): Promise<any> => {
  const res = await request({
    url: `student-application/student-sibling-details/?uuid=${stdAppId}`,
    method: "put",
    data: localData,
  });
  return res;
};

const StudentSiblingsDetails = (props: Props) => {
  const styles = useStyle();
  const {
    stdAppId,
    isDisabled,
    open,
    setOpen,
    setFormDisabled,
    selectedGlobalDate,
    currentGlobalDate,
  } = props;
  const [localData, setLocalData] = useState<any>([]);
  const [prevLocalData, setPrevLocalData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [checkYes, setCheckYes] = useState<string>("");
  const [prevCheckYes, setPrevCheckYes] = useState<string>("");
  const [error, setError] = useState<any>({});
  const [isSiblingDataError, setIsSiblingDataError] = useState<boolean>(false);

  const handleChangeRadio = (name: string, index: number) => {
    if (name === "Yes") {
      setCheckYes("Yes");
      setIsSiblingDataError(false);
      if (localData.length === 0) {
        setLocalData([DEFAULT_SIBLING_DATA]);
        setError({
          0: true,
        });
      }
    } else if (name === "No") {
      if (localData?.length > 0) {
        setCheckYes("Yes");
        setIsSiblingDataError(true);
      } else {
        setCheckYes("No");
        setIsSiblingDataError(false);
      }
    }
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getSiblingsError,
    data,
    refetch,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["stdsibling", stdAppId],
    () => getSiblingsData(stdAppId),
    {
      select: (res) => res.data,
    }
  );

  const mutation: UseMutationResult<any, Error, any> = useMutation(
    async ({ stdAppId, localData }) =>
      postSiblingsData({ stdAppId, localData }),
    {
      onSuccess: (res) => {
        setLoader(false);
      },
      onError: (e) => {},
    }
  );

  useEffect(() => {
    if (data && data?.results?.length !== 0) {
      if (
        data.results.year_of_student !== null &&
        data.results.name_of_student !== ""
      ) {
        setLocalData([...data?.results]);
        setPrevLocalData([...data?.results]);
        setCheckYes("Yes");
        setPrevCheckYes("Yes");
      } else {
        setCheckYes("No");
        setPrevCheckYes("No");
      }
    } else {
      setCheckYes("No");
      setPrevCheckYes("No");
    }
  }, [data]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  const setErrorOnSubmit = () => {
    let hasErrors =
      typeof error === "object"
        ? Object.values(error).some((item) => {
            return item;
          })
        : true;
    if (!hasErrors) {
      setLoader(true);
      mutation.mutate({ stdAppId, localData });
      setFormDisabled(true);
    }
  };

  const saveSiblingsData = () => {
    if (checkYes === "Yes") {
      setErrorOnSubmit();
    } else {
      if (checkYes === "No" && localData?.results?.length > 0) {
        setCheckYes("Yes");
        setIsSiblingDataError(false);
      } else {
        setFormDisabled(true);
      }
    }
  };

  const addNewSiblingData = async () => {
    setLoader(true);
    setLocalData((curr: any) => [...curr, DEFAULT_SIBLING_DATA]);
    setLoader(false);
  };

  const cancelSiblingsDataChanges = () => {
    // setFormDisabled(true);
    // refetch();
  };

  const cancelEdit = async () => {
    setFormDisabled(true);
    setLocalData(prevLocalData);
    refetch();
    setCheckYes(prevCheckYes);
  };

  return (
    <div className={styles.bodyContainer}>
      {isError ? (
        <Box>
          <h4>Error fetching this data .....</h4>
        </Box>
      ) : (
        <Grid container justifyContent="center">
          <Grid container direction="column">
            {isSiblingDataError ? (
              <p style={{ color: "#d22929" }}>
                Please remove all siblings if the student does not have any
              </p>
            ) : null}
            <Box>
              <InputRadioButton
                label="Does your student/child have any siblings in the school *"
                placeHolderOne="No"
                placeHolderTwo="Yes"
                onChange={handleChangeRadio}
                valueOne="No"
                valueTwo="Yes"
                index={0}
                answer={checkYes}
                isCheckString
                isDisabled={isDisabled}
              />
            </Box>
            {checkYes === "Yes" ? (
              <>
                {localData?.map((state: any, index: number) => {
                  return (
                    <StudentSiblingCard
                      isDisabled={isDisabled}
                      stdAppId={stdAppId}
                      key={index}
                      siblingInfoGetter={localData}
                      siblingInfoSetter={setLocalData}
                      setCheckToNo={() => setCheckYes("No")}
                      indexOfSibling={index}
                      hasErrorSetter={setError}
                      noSiblingDataError={() => setIsSiblingDataError(false)}
                    />
                  );
                })}
                {!isDisabled ? (
                  <div className={styles.buttonWidth}>
                    <Button
                      light
                      label="Add Sibling"
                      squared
                      endIcon="add"
                      onClick={addNewSiblingData}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
          </Grid>
          {localStorage.getItem("employee_type") === "Super Admin" &&
          currentGlobalDate === selectedGlobalDate ? (
            <Box mt="20px" mb="30px" display="flex" alignItems="center">
              <Box width="150px" mt="40px">
                <AdminButton
                  label={!isDisabled ? "Save" : "Edit Details"}
                  onClick={
                    !isDisabled
                      ? () => {
                          setOpen(true);
                        }
                      : () => setFormDisabled(false)
                  }
                />
              </Box>
              {!isDisabled && (
                <Box ml="10px" width="150px" mt="40px">
                  <AdminButton label="Cancel" light onClick={cancelEdit} />
                </Box>
              )}
            </Box>
          ) : null}
        </Grid>
      )}
      <Loader open={loader} />
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={saveSiblingsData}
        message="Are you sure you want to save changes to the siblings data ?"
        handleCancel={cancelSiblingsDataChanges}
      />
    </div>
  );
};
export default StudentSiblingsDetails;
