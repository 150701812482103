import requestv2 from "../../../../utils/authUtilv2";

export const getMotherTongueAndEthnicity = async (uuid: any) => {
    const motherTongueList = await requestv2({
        url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${uuid}`,
        method: "get",
    });
    return motherTongueList;
};

export const getMotherTongueAndEthnicityList = async () => {
    const motherTongueList = await requestv2({
        url: `school-settings/mother-tongue-list/`,
        method: "get",
    });
    const ethnicityList = await requestv2({
        url: `school-settings/ethnicity-cultural-background-list/`,
        method: "get",
    });
    return { motherTongueList, ethnicityList }
};

export const postMotherTongueAndEthnicityData = async (uuid: any, motherTongueData: any) => {
    const res2 = await requestv2({
        url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${uuid}`,
        method: "put",
        data: motherTongueData
    })
    return res2;
}