import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    fontFamily: `"LFT Etica", sans-serif`,
  },
  bgGray: {
    background: theme.palette.background.default,
  },
  bgWhite: {
    background: theme.palette.common.white,
  },
  header: {
    fontSize: "15px",
    fontWeight: 600,
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#2B2B33",
  },
  label: {
    marginBottom: 6.5,
    fontFamily:    `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  inputRoot: {
    width: "100%",
    fontFamily:    `"LFT Etica", sans-serif`,
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    overflow: "auto",
    outline: "none",
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
  },
  iconStyle: {
    color: theme.palette.grey[300],
    cursor: "pointer",
    marginTop:"8px"
  },
  inputStyle: {
    padding: "15px",
    border: "none",
    background: "none",
    outline: "none",
  },
  boxStyle: {
    background: "#F9F8F7",
    width: "91%",
    padding: "1.5%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
  },
  viewContainer: {
    background: theme.palette.grey[200],
  },
  viewBold: {
    fontWeight: 600,
    fontSize: "14px",
    marginRight: "10px",
  },
  viewNormal: {
    fontWeight: 400,
    fontSize: "14px",
    marginRight: "10px",
  },
  beforeEdit: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "8px",
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.grey[300],
  },
  textOne: {
    fontSize: "14px",
    color: theme.palette.common.black,
    fontWeight: 600,
    textDecoration: "none",
    marginRight: "10px",
  },
  dropContainer: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 12,
    zIndex: 1000,
    position: "absolute",
    top: "60px",
  },
  itemBorder: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[200],
  },
  displayNone: {
    display: "none",
  },
  select: {
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "10px",
    cursor:"pointer"
  },
  root2:{
    "&:hover": {
      cursor: "pointer",  
      backgroundColor: "#f9f8f7"
    },
  },
  firstlastbtn: {
    textDecoration: "none",
    backgroundColor: "white",
    color: theme.palette.text.primary,
    padding: "6px 8px",
    display: "inline-block",
    border: "1px solid",
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
    },
    "&:active": {
      backgroundColor: "white",
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)",
    },
  }
}));
