import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "../start-selection.style";
import { AdminButton } from "../../../../../components/common/admin-button/index";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { TextFieldCustom } from "../text-field-component/index";
import LotteryDataTable from "./lottery-table";
import request from "../../../../../utils/authUtil";
import { Loader } from "../../../../../components/common/loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ConfirmBox } from "../../../../../components/common/confirm-box";
import { useQuery, UseQueryResult } from "react-query";

const LotterySelection = () => {
  const classes = useStyle();
  const history = useHistory();
  const schoolId = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [openFinal, setOpenFinal] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [criterias, setCriterias] = useState<any>();
  const [totalSeats, setTotalSeats] = useState("");
  const [availableSeats, setAvailableSeats] = useState("");
  const [availableCandidates, setAvailableCandidates] = useState("");
  const [totalSelected, setTotalSelected] = useState<number>();
  const [currentCriteria, setCurrentCriteria] = useState<any>(null);
  const [lotteryList, setLotteryList] = useState<any>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [countOverflow, setCountOverflow] = useState<boolean>(false);
  const [helper, setHelper] = useState<any>();
  const [searchText, setSearchText] = useState<string>("");
  const [manualLottery, setManualLottery] = useState<boolean>(false);
  const [disableComplete, setDisableComplete] = useState<boolean>(true);
  const { data: tableData, isFetching }: UseQueryResult<any, Error> = useQuery<
    any,
    Error
  >(["offers", { searchText }], () => getLotteryList(), {
    select: (lottery) => lottery.data.results,
  });

  useEffect(() => {
    if (!isFetching) {
      setLoader(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (
      // parseInt(totalSeats) === totalSelected &&
      parseInt(availableSeats) === 0
    ) {
      setDisableComplete(false);
    } else {
      setDisableComplete(true);
    }
  }, [totalSeats, totalSelected, availableSeats]);

  useEffect(() => {
    if (tableData) {
      if (Object.keys(tableData).length !== 0) {
        setTotalSeats(tableData.total_places);
        setAvailableSeats(tableData.places_available);
        setCountOverflow(tableData.places_available === 0 ? true : false);
        setAvailableCandidates(tableData.no_of_candidates);
        setTotalSelected(tableData.total_selected);
        setCriterias(tableData.criteria_list);
        setCurrentCriteria(
          tableData.criteria_list.filter(
            (item: any) =>
              item.criteria_uuid === tableData.current_criteria_uuid
          )[0]
        );
        const tab = tableData.application_list.map((item: any) => {
          return {
            ...item,
            id: item.ID,
          };
        });
        setLotteryList(tab);
      }
      getCustomSettings();
    }
  }, [tableData]);

  const getLotteryList = async (): Promise<any> => {
    setLoader(true);
    const res = await request({
      url: `/school-settings/offers-lottery-application-list/?school_uuid=${schoolId}&search_text=${searchText}`,
      method: "get",
    });
    return res;
  };

  const getCustomSettings = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      setManualLottery(res.data.results.is_manual_lottery);
      setLoader(false);
    });
  };

  const downloadFile = () => {
    setLoader(true);
    request({
      url: `/school-settings/offers-lottery-numbers-csv/?school_uuid=${schoolId}&criteria_uuid=${currentCriteria.criteria_uuid}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      window.open(res.data.results, "_parent");
    });
  };

  const postCall = () => {
    setLoader(true);
    const data = {
      ID: helper.ID,
      application_status: helper.application_status,
      criteria_uuid: currentCriteria.criteria_uuid,
    };
    request({
      url: `/school-settings/update-offers-application/?school_uuid=${schoolId}`,
      method: "put",
      data: data,
    }).then((res) => {
      setLoader(false);
      setAvailableSeats(res.data.results.places_available);
      setCountOverflow(res.data.results.places_available === 0 ? true : false);
      setAvailableCandidates(res.data.results.no_of_candidates);
      setTotalSelected(res.data.results.total_selected);
      const tab = res.data.results.application_list.map((item: any) => {
        return {
          ...item,
          id: item.ID,
        };
      });
      setLotteryList(tab);
    });
  };
  const changeStatus = (application: any) => {
    setHelper(application);
    setOpenConfirm(true);
  };

  // const onComplete = () => {
  //   setLoader(true);
  //   const appList: any = [];
  //   let count = 0;
  //   let ready = true;
  //   tableData.map((data: any) => {
  //     if (data.application_status === "Selected") {
  //       count++;
  //       appList.push(data.ID);
  //     }
  //   });
  //   if (count > parseInt(availableSeats)) {
  //     ready = false;
  //   }
  //   if (ready) {
  //     let nextCriteria: any = "";
  //     if (criterias.indexOf(currentCriteria) + 1 < criterias.length) {
  //       nextCriteria = criterias[criterias.indexOf(currentCriteria) + 1];
  //     }

  //     const dt = {
  //       criteria_uuid: currentCriteria.criteria_uuid,
  //       is_special: currentCriteria.is_special,
  //       places_available: availableSeats.toString(),
  //       no_of_candidates: availableCandidates.toString(),
  //       next_criteria_uuid:
  //         nextCriteria !== "" ? nextCriteria.criteria_uuid : "",
  //       application_list: appList,
  //     };

  //     request({
  //       url: `/school-settings/update-offers-lottery-application/?school_uuid=${schoolId}`,
  //       method: "post",
  //       data: dt,
  //     }).then((res) => {
  //       setLoader(false);
  //       history.push("/admin/offers");
  //     });
  //   } else {
  //     setLoader(false);
  //     setShowAlert(true);
  //     setTimeout(() => {
  //       setShowAlert(false);
  //     }, 5000);
  //   }
  // };

  // const single = (it: any) => {
  //   const tmp = tableData.map((element: any) => {
  //     if (element.ID === it) {
  //       return {
  //         ...element,
  //         application_status:
  //           element.application_status === "Selected"
  //             ? "In-Review"
  //             : "Selected",
  //       };
  //     } else {
  //       return { ...element };
  //     }
  //   });
  //   setTableData(tmp);
  // };

  // const selectChecked = () => {
  //   const selected = tableData.map((data: any) => {
  //     const selectedId = selectedApplications.find((id: any) => id === data.ID);
  //     if (selectedId) {
  //       return {
  //         ...data,
  //         application_status: "Selected",
  //       };
  //     } else {
  //       return {
  //         ...data,
  //       };
  //     }
  //   });
  //   setTableData(selected);
  //   setSelectedApplications([]);
  // };

  const onComplete = () => {
    setLoader(true);
    let nextCriteria: any = "";
    if (criterias.indexOf(currentCriteria) + 1 < criterias.length) {
      nextCriteria = criterias[criterias.indexOf(currentCriteria) + 1];
    }
    const remaining = lotteryList
      .filter((data: any): string | undefined => {
        if (data.application_status === "In-Review") {
          return data.id;
        }
      })
      .map((item: any) => item.id);
    const data = {
      criteria_uuid: currentCriteria.criteria_uuid,
      next_criteria_uuid: nextCriteria !== "" ? nextCriteria.criteria_uuid : "",
      application_list: remaining,
    };
    request({
      url: `/school-settings/update-lottery-process/?school_uuid=${schoolId}`,
      method: "put",
      data: data,
    }).then((res) => {
      setLoader(false);
      history.push("/admin/offers");
    });
  };

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  const runDigitizedLottery = () => {
    setLoader(true);
    const list = lotteryList
      .filter((data: any): string | undefined => {
        if (data.application_status === "In-Review") {
          return data.id;
        }
      })
      .map((item: any) => item.id);
    const data = {
      application_no_list: list,
      criteria_uuid: currentCriteria.criteria_uuid,
      no_of_seats: availableSeats,
    };
    request({
      url: `/school-settings/select-lottery-automatically/?school_uuid=${schoolId}`,
      method: "post",
      data: data,
    }).then((res) => {
      setLoader(false);
      setAvailableSeats(res.data.results.places_available);
      setCountOverflow(res.data.results.places_available === 0 ? true : false);
      setAvailableCandidates(res.data.results.no_of_candidates);
      setTotalSelected(res.data.results.total_selected);
      const tab = res.data.results.application_list.map((item: any) => {
        return {
          ...item,
          id: item.ID,
        };
      });
      setLotteryList(tab);
      setDisableComplete(false);
    });
  }

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div className={classes.subContainer}>
        <Box marginBottom="40px">
          <Box textAlign="center">
            <p className={classes.textOne}>Lottery Screen</p>
          </Box>
        </Box>
        {showAlert ? (
          <Dialog
            open={true}
            onClose={() => setShowAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Selected Applications is more than Available Seats"}
            </DialogTitle>
            <DialogActions className={classes.dialogActions}>
              <div style={{ width: "100px" }}>
                <AdminButton
                  onClick={() => () => setShowAlert(false)}
                  label="Okay"
                />
              </div>
            </DialogActions>
          </Dialog>
        ) : null}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <TextFieldCustom placeholder="Total Seats:" value={totalSeats} />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total Selected"
              value={totalSelected?.toString()}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total Seats Available For"
              value={`${
                currentCriteria?.criteria_name || ""
              }: ${availableSeats}`}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Candidates available for "
              value={`${
                currentCriteria?.criteria_name || ""
              }: ${availableCandidates}`}
            />
          </Box>
          {manualLottery ? <Box width="200px">
              <AdminButton
                label="Export CSV"
                startIcon="download_icon"
                light
                onClick={downloadFile}
              />
            </Box>:
            <Box width="200px">
              <AdminButton
                label="Run Lottery Selection"
                onClick={runDigitizedLottery}
              />
            </Box>
          }
        </Box>
        <Box
          display="flex"
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            style={{ borderBottom: "1px solid #000000", float: "right" }}
            width="250px"
          >
            <input
              onChange={(e: any) => searchTextChange(e.target.value)}
              type="text"
              placeholder="Find an applicant"
              className={classes.inputStyle}
            />

            <Icon className={classes.iconStyle}>search</Icon>
          </Box>
        </Box>
        {/* <Box display="flex" justifyContent="end" alignItems="center">
          <Box maxWidth="250px">
            <AdminButton
              onClick={selectChecked}
              label="Mark as Selected"
              padding="10px"
              disabled={selectedApplications.length === 0}
            />
          </Box>
        </Box> */}

        <div style={{ marginTop: 40 }}>
          {lotteryList && (
            <LotteryDataTable
              data={lotteryList}
              // onCheck={saveSelectedList}
              singleSelect={changeStatus}
              disableEdit={countOverflow}
              manual={manualLottery}
              // sa={selectedApplications}
            />
          )}
        </div>
        <Box marginTop="40px" width="250px">
          <AdminButton
            label="Complete lottery process"
            onClick={() => setOpenFinal(true)}
            disabled={disableComplete}
          />
        </Box>
        <ConfirmBox
          message="Complete lottery process?"
          onClick={onComplete}
          open={openFinal}
          setOpen={setOpenFinal}
        />
        <ConfirmBox
          message={`Do you want to change the status of Lottery Number ${helper?.lottery_number} ?`}
          onClick={postCall}
          open={openConfirm}
          setOpen={setOpenConfirm}
        />
      </div>
      <Loader open={loader} />
    </Grid>
  );
};

export default LotterySelection;
