import { Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { Loader } from "../../../../components/common/loader";
import request from "../../../../utils/authUtil";
import request3 from "../../../../utils/authUtilv3";
import { useStyle } from "./student-details.style";
import { ViewDoc } from "../../../../components/common/view-doc/index";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";
import { Button } from "../../../../components/common/button";
import DocumentsModal from "./DocumentsModal";
import { useParams } from "react-router";
import { ConfirmBox } from "../../../../components/common/confirm-box";
// import { AdminButton } from "../../../../components/common/admin-button";

type Props = {
  applicationID?: any;
  UIType?: string;
};

interface ParamsType {
  applicationId: string;
}

interface documentData {
  documentId: string,
  documentType: string,
}

const Documents: React.FC<Props> = ({ applicationID, UIType }) => {
  const classes = useStyle();
  const schoolId = localStorage.getItem("school-id");
  const params: ParamsType = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [openDoc, setOpenDoc] = useState<boolean>(false);
  const [parentId, setParentId] = useState("");
  // const [downloadAllUrls, setDownloadAllUrls] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([
    {
      By: "",
      Created: "",
      Document: "",
      Name: "",
      Type: "",
      document_uuid: "",
      url: "",
    },
  ]);

  const [currDocument, setCurrDocument] = useState<documentData>({
  documentId: "",
  documentType: "",
  });

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDocuments = async (): Promise<any> => {
    setLoader(true);
    const res = await request({
      url: `/school-settings/parent-student-documents-details/?school_uuid=${schoolId}&student_app_uuid=${applicationID}`,
      method: "get",
    });
    if (res?.data?.status_code === 1) {
      setParentId(res?.data?.results?.parent_uuid);
      setDocuments(res?.data?.results?.All_Documents_Details);
      const urls: any = [];
      const temp: any = [];
      if (Object.keys(res?.data?.results.All_Documents_Details).length !== 0) {
        res.data.results.All_Documents_Details.forEach((item: any) => {
          urls.push(item.document_presingne_url);
          temp.push({
            By: item.By,
            Created: item.last_modified,
            Document: item.Document,
            Name: item.Name,
            Type: item.Type,
            document_uuid: item.document_uuid,
            url: item.document_presingne_url,
          });
          return temp;
        });
      }
      // setDownloadAllUrls(urls);
      setDocuments(temp);
      setLoader(false);
    }
  };

  const columns = [
    {
      id: "Created",
      label: "Created",
      minWidth: 100,
    },
    {
      id: "By",
      label: "By",
      minWidth: 100,
    },
    {
      id: "Type",
      label: "Type",
      minWidth: 100,
    },
    {
      id: "Document",
      label: "Document",
      minWidth: 100,
    },
    {
      id: "Name",
      label: " Name",
      minWidth: 100,
    },
    {
      id: "View",
      label: "  ",
      minWidth: 100,
      align: "right",
    },
    {
      id: "Delete",
      label: "  ",
      minwidth: 100,
      align: "right",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [local, setLocal] = useState({
    doc_name: "",
    url: "",
  });
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onOpenDoc = (name: string, url: string) => {
    setOpenDoc(true);
    setLocal({ ...local, doc_name: name, url: url });
  };

  const modalHandler = (open: boolean, isSaved: boolean) => {
    setIsModalOpen(open);
    if (isSaved) {
      getDocuments();
    }
  };

  const deleteBtnHandler = (item: any) => {
    setCurrDocument((state:documentData) => ({
      documentId:item?.document_uuid,
      documentType:item?.Document,}))


    setIsDeleteDocumentModalOpen(true);
  };

  const deleteSelectedDocumentFn = async () => {
    const res = await request3({
      url: `school-settings/delete-application-document/?student_app_uuid=${params?.applicationId}`,
      method: "delete",
      data: { 
        document_uuid : currDocument?.documentId,
        document_type : currDocument?.documentType,
       },
    });
    if(res?.data?.status_code === 1){
      await getDocuments();
    }
  };

  // const allDownloadHandler = () => {
  //   if (downloadAllUrls.length > 0) {
  //     downloadAllUrls.forEach((url: any, index: any) => {
  //       window.open(url, url);
  //     })
  //   }
  // }

  return (
    <Grid container direction="column" justifyContent="flex-start">
      {/* add button */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {localStorage.getItem("employee_type") === "Super Admin" && (
          <div
            style={{
              maxWidth: "10%",
              marginBottom: "10px",
              marginRight: "3rem",
            }}
          >
            <Button
              label="ADD"
              padding="0.4rem 2rem"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        )}
        {/* <div
          style={{
            maxWidth: "200px",
            marginBottom: "10px",
            marginRight: "3rem",
          }}
        >
          <AdminButton
              startIcon="download_icon"
              // className={classes.adminButton}
              label="Download All"
              onClick={allDownloadHandler}
            />
        </div> */}
      </div>
      {/* Modal component renders start */}
      <DocumentsModal
        isModalOpen={isModalOpen}
        modalHandler={modalHandler}
        applicationID={applicationID}
        parentID={parentId}
      />
      <div>
        <Loader open={loader} />
      </div>
      <div>
        <Paper
          style={{
            overflow: "hidden",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      className={classes.root}
                      key={`${column.id}${index}`}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {documents &&
                  documents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any, index: number) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={`${item.document_uuid}${index}`}
                          tabIndex={-1}
                        >
                          <>
                            <TableCell className={classes.root}>
                              {item.Created ? item.Created : "-"}
                            </TableCell>
                            <TableCell className={classes.root}>
                              {item.By ? item.By : "-"}
                            </TableCell>
                            <TableCell className={classes.root}>
                              {item.Type ? item.Type : "-"}
                            </TableCell>
                            <TableCell className={classes.root}>
                              {item.Document ? item.Document : "-"}
                            </TableCell>
                            <TableCell className={classes.root}>
                              {item.Name ? item.Name : "-"}
                            </TableCell>
                            <TableCell className={classes.root}>
                              <span
                                onClick={() => onOpenDoc(item.Name, item.url)}
                                style={{
                                  width: 80,
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  style={{
                                    color: "#757575",
                                    marginRight: 10,
                                  }}
                                >
                                  remove_red_eye_icon
                                </Icon>
                                VIEW
                              </span>
                            </TableCell>
                            <TableCell className={classes.root}>
                              <span
                                onClick={() =>{
                                  deleteBtnHandler(item)
                                }
                                }
                                style={{
                                  width: "auto",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Icon
                                  style={{
                                    color: "#757575",
                                    marginRight: 10,
                                  }}
                                >
                                  delete
                                </Icon>
                              </span>
                            </TableCell>
                          </>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25, 50, 100]}
            component="div"
            count={documents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ViewDoc
          openDoc={openDoc}
          setOpen={setOpenDoc}
          docName={local.doc_name}
          url={local.url}
        />
      </div>
      <ConfirmBox
        message="Are you sure you want to delete this document?"
        arg={false}
        open={isDeleteDocumentModalOpen}
        setOpen={setIsDeleteDocumentModalOpen}
        onClick={deleteSelectedDocumentFn}
      />
    </Grid>
  );
};

export default Documents;
