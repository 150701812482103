import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    padding: "14px 18px",
  },
  logoImage: {
    width: 50,
    height: 65,
  },
  card: {
    marginTop: 82,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "50%",
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%"
    },
  },
  buttonWidth: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "43%"
    },
  }
}));
