import { Box } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { Loader } from "../../../components/common/loader";
import request from "../../../utils/authUtil";
import requestv3 from "../../../utils/authUtilv3";
import { useStyle } from "./dashboard.style";
// import { localeData } from "moment";
import { useHistory } from "react-router-dom";
import { AdminButton } from "../../../components/common/admin-button";

const Dashboard = () => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [localData, setLocalData] = useState<any>();
  const [admissionReportData, setAdmissionReportData] = useState<any>([]);
  const schoolId = localStorage.getItem("school-id");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const history = useHistory();
  const getDashboarData = () => {
    const selectedGlobal = selectedGlobalDate || "";
    setLoader(true);
    request({
      url: `/school-settings/school-dashboard/?school_uuid=${schoolId}&year_uuid=${selectedGlobal}`,
      method: "get",
    }).then((res) => {
      setLocalData(res?.data?.results);
      setLoader(false);
    });
  };

  const getAdmissionNoticeDetails = async()=>{
    setLoader(true);
    const selectedGlobal = selectedGlobalDate || "";
    const res = await requestv3({
      url: `school-settings/admission-notice-report-details/?application_uuid=${schoolId}&year_uuid=${selectedGlobal}`,
      method: "get",
    })
    setAdmissionReportData(res?.data?.results);
    setLoader(false);
  }

  useEffect(() => {
    getDashboarData();
    getAdmissionNoticeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGlobalDate]);

  return (
    <Box className={classes.root}>
      <Loader open={loader} />
      <Box className={classes.container}>
        <Box className={classes.heading}>Dashboard</Box>
        <Box marginTop="22px" padding="20px" className={classes.content}>
          <Box fontSize="18px">Offers and acceptances</Box>
          <Box
            marginTop="25px"
            display="flex"
            justifyContent="flex-start"
            width="80%"
          >
            <Box width="30%" padding="7px" borderLeft="1px solid #949494">
              <Box fontSize="16px" fontWeight={600}>
                Selection
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Intake</Box>
                <Box fontWeight={600}>{localData?.total_intake}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Applicants</Box>
                <Box fontWeight={600}>
                  {localData?.total_applications_count}
                </Box>
              </Box>
              {/* <Box className={classes.displayFlex} width="70%">
                <Box>Selected for offer</Box>
                <Box fontWeight={600}>
                  {localData?.total_application_selected_count}
                </Box>
              </Box> */}
              {/* <Box className={classes.displayFlex} width="70%">
                <Box>Selection remaining</Box>
                <Box fontWeight={600}>
                  {localData?.selection_remaining_count}
                </Box> */}
              <Box className={classes.displayFlex} width="70%">
                <Box>Late applications</Box>
                <Box fontWeight={600}>{localData?.late_application_count}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Applications pending</Box>
                <Box fontWeight={600}>
                  {localData?.applications_pending_count}
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Applications reviewed</Box>
                <Box fontWeight={600}>
                  {localData?.applications_reviewed_count}
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Enrolment pending</Box>
                <Box fontWeight={600}>{localData?.enrolment_pending_count}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Enrolment reviewed</Box>
                <Box fontWeight={600}>
                  {localData?.enrolment_reviewed_count}
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Total places available</Box>
                <Box fontWeight={600}>{localData?.total_places_available}</Box>
              </Box>
            </Box>
            <Box width="30%" padding="7px" borderLeft="1px solid #949494">
              <Box fontSize="16px" fontWeight={600}>
                Offer
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Offered</Box>
                <Box fontWeight={600}>{localData?.total_offered_count}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Accepted</Box>
                <Box fontWeight={600}>
                  {localData?.total_offered_accepted_count}
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Declined</Box>
                <Box fontWeight={600}>{localData?.total_declined_count}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Withdrawals</Box>
                <Box fontWeight={600}>{localData?.withdrawals}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Acceptances remaining</Box>
                <Box fontWeight={600}>
                  {localData?.acceptances_remaining_count}
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Cancelled</Box>
                <Box fontWeight={600}>{localData?.total_cancelled_count}</Box>
              </Box>
            </Box>
            <Box width="40%" padding="7px" borderLeft="1px solid #949494">
              <Box fontSize="16px" fontWeight={600}>
                School admission notice report
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Number of applications received</Box>
                <Box fontWeight={600}>{admissionReportData?.total_applications_count}</Box>
              </Box><Box className={classes.displayFlex} width="70%">
                <Box>Intake</Box>
                <Box fontWeight={600}>{admissionReportData?.total_intake}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Original number of applicants placed on waiting list</Box>
                <Box fontWeight={600}>{admissionReportData?.original_count_of_wait_list}</Box>
              </Box>
              <Box className={classes.displayFlex} width="70%">
                <Box>Final number of applicants placed on waiting list </Box>
                <Box fontWeight={600}>{admissionReportData?.total_count_of_wait_list}</Box>
              </Box>
              <Box className={classes.displayFlex} width="100%" flexDirection="column" gridGap="8px">
                <Box fontWeight="600" mb="8px">Number of offers sent by criteria</Box>
                <Box width="100%" display="flex" flexDirection="column" gridGap="12px">
                {
                  // eslint-disable-next-line array-callback-return
                  admissionReportData?.criterias_selection_count_list?.map((item:any,index:number)=>{
                    for(let key in item){
                    return<Box display="flex" justifyContent="space-between" key={index} width="70%">
                      <Box>{key}</Box>
                      <Box fontWeight={600}>{item[key]}</Box>
                    </Box>
                    }
                  })
                }
                </Box>
              </Box>
              <Box className={classes.displayFlex} width="100%" flexDirection="column" gridGap="8px">
                <Box fontWeight="600" mb="8px">Number of offers Accepted by Criteria</Box>
                <Box width="100%" display="flex" flexDirection="column" gridGap="12px">
                {
                  // eslint-disable-next-line array-callback-return
                  admissionReportData?.criterias_offer_accepted_count_list?.map((item:any,index:number)=>{
                    for(let key in item){
                    return<Box display="flex" justifyContent="space-between" key={index} width="70%">
                      <Box>{key}</Box>
                      <Box fontWeight={600}>{item[key]}</Box>
                    </Box>
                    }
                  })
                }
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            marginTop="80px"
            marginBottom="10px"
            width="190px"
            marginLeft="auto"
          >
            <AdminButton
              label="Manage your offers"
              startIcon="edit"
              onClick={() => history.push(`/admin/offers`)}
            />
          </Box>
        </Box>
        {/* <Box marginTop="14px" padding="20px" className={classes.content}>
          <Box marginBottom="20px">About your selection</Box>
          {localData?.application_qualify_under_criteria?.map(
            (item: any, index: any) => (
              <Box marginTop="10px" key={index}>
                {item.count} applicants qualify under {item.criteria_name}.
              </Box>
            )
          )}
        </Box> */}
      </Box>
    </Box>
  );
};

export default Dashboard;
