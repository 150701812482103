import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "calc(100% - 288px)",
    fontFamily: `"LFT Etica", sans-serif`,
  },
  container: {
    marginRight: "54px",
    marginBottom: "50px",
  },
  content: {
    backgroundColor: "#F9F8F7",
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: "16px",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
    fontFamily: `"LFT Etica", sans-serif`,
  },

  displayFlex: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "13px",
    fontSize: "16px",
    fontFamily: `"LFT Etica", sans-serif`,
  },
}));
