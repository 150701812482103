import request from "../../../utils/authUtil";

interface educationData {
  edu_uuid: string;
  eduQuestion: string;
  details: boolean;
  furtherDetails: string;
  furtherDetails_uuid: string;
  require_supporting_doc: any;
}

interface postEducationData {
  id: string;
  education: educationData[];
}

export const getEducation = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-education-question-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postEducation = async ({
  id,
  education,
}: postEducationData): Promise<any> => {
  const data = education.map((item: any) => {
    return {
      education_question_uuid: item.edu_uuid || "",
      question: item.eduQuestion,
      further_detail_required: item.details,
      require_supporting_doc: item.require_supporting_doc,
      follow_up: item.details
        ? [
            {
              education_question_follow_up_uuid: item.furtherDetails_uuid || "",
              follow_up_question: item.furtherDetails,
            },
          ]
        : [],
    };
  });
  const res = await request({
    url: `school-settings/application-education-question-details/?application_uuid=${id}`,
    method: "put",
    data,
  });
  return res;
};
