import requestv2 from "../../../../utils/authUtilv2";

export const updatePdf = async (
    student_app_uuid: string
  ): Promise<any> => {
    const res = await requestv2({
      url: `school-settings/regenerate_student_enrolment_pdf/?stu_app_uuid=${student_app_uuid}`,
      method: "put",
    });
    return res;
  };    