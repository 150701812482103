import MaterialButton from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { useStyle } from "./secondary-button.style";
import cn from "classnames";

type SecondaryButtonProps = {
  label?: string;
  light?: Boolean;
  onClick?: any;
  disabled?: boolean | undefined;
  className?: string;
  startIcon?: string;
  iconSize?: string;
  type?: string;
};

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  label,
  light = false,
  onClick,
  disabled,
  className,
  startIcon,
  iconSize,
  type,
}) => {
  const classes = useStyle();
  return (
    <MaterialButton
      className={cn(
        classes.root,
        light ? classes.secondaryLight : classes.secondaryMain,
        className
      )}
      onClick={onClick}
      disabled={disabled}
      startIcon={
        startIcon && (
          <Icon
            style={{ fontSize: `${iconSize}` }}
            className={light ? classes.deleteIcon : ""}
          >
            {startIcon}
          </Icon>
        )
      }
      type={type ? "submit" : undefined}
    >
      {label}
    </MaterialButton>
  );
};
