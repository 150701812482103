import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { useStyle } from "./select-dropdown.style";
import cn from "classnames";
import Icon from "@material-ui/core/Icon";
import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";

import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

type SelectDropDownProps = {
  name?: string;
  label?: string;
  value?: any;
  placeholder?: string;
  onChange?: any;
  item: any;
  error?: boolean;
  errorMessage?: string;
  isReligion?: boolean;
  disabled?: boolean;
};

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      padding: "12px 12px",
      "&:focus": {
        borderRadius: 4,
        backgroundColor: theme.palette.common.white,
      },
    },
  })
)(InputBase);

export const SelectDropDown: React.FC<SelectDropDownProps> = ({
  name,
  label,
  value,
  placeholder,
  onChange,
  item,
  error = false,
  errorMessage = "Required",
  isReligion = false,
  disabled = false,
}) => {
  const classes = useStyle();
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel className={error ? classes.labelError : classes.label}>
        {label}
      </InputLabel>
      <FormControl variant="filled" fullWidth={true}>
        <NativeSelect
          IconComponent={() => (
            <Icon className={classes.iconCustom}>keyboard_arrow_down_icon</Icon>
          )}
          name={name}
          className={cn(classes.root, error ? classes.error : "")}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          input={<BootstrapInput />}
        >
          {item?.map((itemlist: any, index: number) => (
            <option value={itemlist.value} key={index}>
              {itemlist.name}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
