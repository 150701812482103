import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    minWidth: "43.93px",
    height: "43.93px",
    padding: "0px",
    textTransform: "none",
    borderRadius: "50%",
    justifyContent: "center",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  bgYellow: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
