import { useState, useEffect } from "react";
import { Loader } from "../../../components/common/loader";
import { useStyle } from "./criteria-tab.style";
import { getInstruction, putInstruction } from "./criteria.api";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import { AdminButton } from "../../../components/common/admin-button";

type Props = {
  applicationID: string;
};
export const Instruction: React.FC<Props> = ({ applicationID }) => {
  const [loader, setLoader] = useState(false);
  const [instructionText, setInstructionText] = useState("");
  const classes = useStyle();

  useEffect(() => {
    setLoader(true);
    getInstruction(applicationID).then((res) => {
      if (res.data.status_code === 1) {
        setInstructionText(res.data.results.instruction);
      }
    });
    setLoader(false);
  }, []);

  const onSaveClick = () => {
    setLoader(true);
    putInstruction(applicationID, instructionText).then((res) => {
      if (res.data.status_code === 1) {
        setLoader(false);
      }
    });
  };
  return (
    <>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <InputLabel className={classes.label}>Criteria Instruction</InputLabel>
        <TextareaAutosize
          value={instructionText}
          onChange={(e) => setInstructionText(e.target.value)}
          maxRows={4}
          minRows={4}
          className={classes.inputRoot}
          style={{ resize: "none", fontSize: "16px" }}
        />
        <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
          <AdminButton
            label="Save"
            onClick={onSaveClick}
          />
        </div>
      </Grid>
    </>
  );
};
