import { useState, useEffect } from "react";
import { useStyle } from "./info.style";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import cn from "classnames";
import request from "../../../utils/authUtil";
import { useQuery, UseQueryResult } from "react-query";

type InfoProps = {};

const goToUrl = (url: string) => {
  window.open(url);
};

export const Info: React.FC<InfoProps> = () => {
  const classes = useStyle();
  const [description, setDescription] = useState<any>();
  const [urlLinks, setUrlLinks] = useState({
    admission_notice: "",
    admission_policy_url: "",
    anti_bullying_url: "",
    assessment_testing: "",
    code_Of_behaviour_url: "",
    data_protection_url: "",
    // acceptable_use_policy: "",
  });
  let infoItems = [
    {
      infoTitle: "Our Admission Policy",
      url:
        urlLinks.admission_policy_url.includes("https://") ||
        urlLinks.admission_policy_url.includes("http://")
          ? urlLinks.admission_policy_url
          : `https://${urlLinks.admission_policy_url}`,
    },
    {
      infoTitle: "Our Anti-Bullying Policy",
      url:
        urlLinks.anti_bullying_url.includes("https://") ||
        urlLinks.anti_bullying_url.includes("http://")
          ? urlLinks.anti_bullying_url
          : `https://${urlLinks.anti_bullying_url}`,
    },
    {
      infoTitle: "Our Data Protection Policy",
      url:
        urlLinks.data_protection_url.includes("https://") ||
        urlLinks.data_protection_url.includes("http://")
          ? urlLinks.data_protection_url
          : `https://${urlLinks.data_protection_url}`,
    },
    {
      infoTitle: "Our Code of Behaviour",
      url:
        urlLinks.code_Of_behaviour_url.includes("https://") ||
        urlLinks.code_Of_behaviour_url.includes("http://")
          ? urlLinks.code_Of_behaviour_url
          : `https://${urlLinks.code_Of_behaviour_url}`,
    },
    {
      infoTitle: "Our Admissions Notice",
      url:
        urlLinks.admission_notice.includes("https://") ||
        urlLinks.admission_notice.includes("http://")
          ? urlLinks.admission_notice
          : `https://${urlLinks.admission_notice}`,
    },
    // {
    //   infoTitle: "Our Acceptable Use Policy",
    //   url:
    //     urlLinks.acceptable_use_policy.includes("https://") ||
    //     urlLinks.acceptable_use_policy.includes("http://")
    //       ? urlLinks.acceptable_use_policy
    //       : `https://${urlLinks.acceptable_use_policy}`,
    // },
  ];

  const school_uuid = localStorage.getItem("school-id");

  const getUrlLinks = async (): Promise<any> => {
    if (school_uuid) {
      const response = await request({
        url: `student-application/student-related-urls-links/?school_uuid=${school_uuid}`,
        method: "get",
      });
      return response;
    }
  };
  const { data }: UseQueryResult<any, Error> = useQuery<any, Error>(
    "urlLinks",
    () => getUrlLinks(),
    {
      select: (urlLinks) => urlLinks.data,
    }
  );

  const getDescription = async () => {
    await request({
      url: `student-application/student-application-static-data/`,
      method: "get",
    }).then((res) => {
      setDescription(res?.data?.results?.application_description);
    });
  };

  useEffect(() => {
    if (data) {
      if (data.status_code === 1) {
        const urlLinks = data.results;
        setUrlLinks({
          ...urlLinks,
          admission_notice: urlLinks.admission_notice
            ? urlLinks.admission_notice
            : "",
          admission_policy_url: urlLinks.admission_policy_url
            ? urlLinks.admission_policy_url
            : "",
          anti_bullying_url: urlLinks.anti_bullying_url
            ? urlLinks.anti_bullying_url
            : "",
          assessment_testing: urlLinks.assessment_testing
            ? urlLinks.assessment_testing
            : "",
          code_Of_behaviour_url: urlLinks.code_Of_behaviour_url
            ? urlLinks.code_Of_behaviour_url
            : "",
          data_protection_url: urlLinks.data_protection_url
            ? urlLinks.data_protection_url
            : "",
          acceptable_use_policy: urlLinks.acceptable_use_policy
            ? urlLinks.acceptable_use_policy
            : "",
        });
      }
    }
  }, [data]);

  useEffect(() => {
    getDescription();
  }, []);
  return (
    <Box className={cn(classes.root)} display="block" fontWeight={400}>
      <p className={classes.title}>Important information</p>
      <div className={classes.description}>{description}</div>
      {infoItems.map((item, key) => (
        <Box display="flex" alignItems="center" mb="17px" key={key}>
          <button className={classes.btnLink} onClick={() => goToUrl(item.url)}>
            <Box display="flex" alignItems="center">
              <u>{item.infoTitle}</u>
              <Icon style={{ fontSize: "15px" }}>call_made_icon</Icon>
            </Box>
          </button>
        </Box>
      ))}
    </Box>
  );
};
