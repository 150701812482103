import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
  },
  iconlabel: {
    color: theme.palette.grey[300],
    marginRight: 2,
    fontSize: 18,
  },
  icon: {
    color: theme.palette.grey[300],
    marginRight: "10px",
    fontSize: "18px",
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.grey[300],
  },
  textOne: {
    fontSize: "14px",
    color: theme.palette.common.black,
    fontWeight: 600,
    textDecoration: "none",
  },
  dropContainer: {
    backgroundColor: theme.palette.common.white,
    width: "180px",
    top: 60,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 12,
    paddingLeft: 15,
    paddingRight: 15,
    cursor: "pointer",
    zIndex:1000,
  },
  itemBorder: {
    borderBottom: "1px solid",
    color: theme.palette.grey[200],
  },
  displayNone: {
    display: "none",
  },
}));
