import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { useStyle } from "./edu-ques.style";
import request from "../../../utils/authUtil";
import { AdminButton } from "../../../components/common/admin-button/index";
import { Input } from "../../../components/common/input";
import { Loader } from "../../../components/common/loader/index";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";
import Checkbox from "@material-ui/core/Checkbox";
type Props = {
  applicationID?: string;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const Languages: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [error, setError] = useState<Number[]>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [idPre, setId] = useState<string>("");
  const [indexPre, setIndex] = useState<number>(0);
  const [list, setList] = useState<any>([
    { enrolment_language_uuid: "", language_name: "" },
  ]);
  const [allowLanguage, setAllowLanguage] = useState<boolean>(false);
  const [languageDescription, setLanguageDescription] = useState<string>("");
  useEffect(() => {
    setLoader(true);
    request({
      url: `school-settings/school-enrolment-languages/?application_uuid=${applicationID}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            response.data.results.language.map((language: any) => {
              temp.push({
                enrolment_language_uuid: language.enrolment_language_uuid || "",
                language_name: language.language_name,
              });
            });
            setLanguageDescription(response.data.results.language_description);
            setList(temp);
            setAllowLanguage(response.data.results.allow_language);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  }, []);

  const addLanguage = () => {
    switchReady(false);
    setList([...list, { enrolment_language_uuid: "", language_name: "" }]);
  };

  const onChange = (value: any, ind: any) => {
    switchReady(false);
    const temp = [...list];
    const tempErr = [...error];
    temp[ind].language_name = value;
    setList(temp);
    if (value.length == 0) {
      if (!tempErr.includes(ind)) {
        tempErr.push(ind);
      }
    } else if (tempErr.includes(ind)) {
      const val = tempErr.indexOf(ind);
      if (ind > -1) {
        tempErr.splice(val, 1);
      }
    }
    setError(tempErr);
  };

  const deleteHandler = (id: string, index: number) => {
    setOpen(true);
    setId(id);
    setIndex(index);
  };

  const onSaveClick = () => {
    const temp = [...error];
    try {
      list.map((item: any, index: any) => {
        if (item.language_name.length == 0) {
          if (!temp.includes(index)) {
            temp.push(index);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        let lang = list.map((item: any, index: any) => {
          return {
            enrolment_language_uuid: item.enrolment_language_uuid,
            order: index + 1,
            language_name: item.language_name,
          };
        });
        let data = {
          language: lang,
          allow_language: allowLanguage,
          language_description : languageDescription,
        };
        request({
          url: `school-settings/school-enrolment-languages/?application_uuid=${applicationID}`,
          method: "put",
          data: data,
        }).then((res) => {
          const temp: any = [];
          setLoader(false);
          res.data.results.language.map((language: any) => {
            temp.push({
              enrolment_language_uuid: language.enrolment_language_uuid || "",
              language_name: language.language_name,
            });
          });
          switchReady(true);
          setList(temp);
          setAllowLanguage(res.data.results.allow_language);
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteLanguage = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/school-enrolment-languages/`,
        method: "delete",
        data: {
          enrolment_language_uuid: id,
        },
      }).then(() => {
        const temp = [...list];
        temp.splice(index, 1);
        setList(temp);
        setError([]);
        setLoader(false);
      });
    } else {
      const temp = [...list];
      temp.splice(index, 1);
      setList(temp);
      setError([]);
    }
  };

  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.langContainer}>
          <Box display="flex" alignItems="center" mb={1}>
            <Checkbox
              disabled={selectedGlobalDate !== currentGlobalDate}
              color="default"
              style={{ marginLeft: "-13px" }}
              onChange={(e) => {
                setAllowLanguage(!allowLanguage);
                switchReady(false);
              }}
              checked={allowLanguage}
            />
            <p className={classes.text}>Include Language selection question</p>
          </Box>
          <Box>
            <Input
            type="text"
            value={languageDescription}
            onChange={(e)=>setLanguageDescription(e.target.value)}
            label="Language description"
            />
          </Box>
          {list.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                key={index}
              >
                <Input
                  label={`Language ${index + 1}`}
                  type="text"
                  value={item.language_name}
                  onChange={(e: { target: { value: any } }) =>
                    onChange(e.target.value, index)
                  }
                  error={error.includes(index)}
                  disabled={selectedGlobalDate !== currentGlobalDate}
                />
                {selectedGlobalDate === currentGlobalDate && (
                  <Icon
                    style={{ cursor: "pointer", color: "#757575", marginTop: "25px" }}
                    onClick={() =>
                      deleteHandler(item.enrolment_language_uuid, index)
                    }
                  >
                    delete
                  </Icon>
                )}
              </Box>
            );
          })}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <>
            <div style={{ width: "300px", marginTop: "20px" }}>
              <AdminButton
                label="Add further Languages"
                endIcon="add"
                light
                onClick={addLanguage}
              />
            </div>
            <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
              <AdminButton label="Save" onClick={onSaveClick} />
            </div>
          </>
        )}
        <DeleteBox
          index={indexPre}
          id={idPre}
          onClick={() => {
            deleteLanguage(idPre, indexPre);
          }}
          open={open}
          setOpen={setOpen}
        />
      </Grid>
    </div>
  );
};

export default Languages;
