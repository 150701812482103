import { Box, Checkbox, InputLabel } from "@material-ui/core";
import cn from "classnames";
import { useEffect, useState } from "react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import "react-quill/dist/quill.snow.css";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import { Loader } from "../../../components/common/loader/index";
import { Text } from "../../../components/common/text";
import request from "../../../utils/authUtil";
import Editor from "./editor";
import { useStyle } from "./templates.style";

type Props = {
  templateID: string;
  error?: any;
  onClose: any;
  refetch: any;
  currentGlobalDate: any;
  selectedGlobalDate: any;
};

interface templateData {
  template_code: string;
  template_code_text: string;
  email_subject: string;
  email_body: string;
  is_active: boolean;
  is_editable: boolean;
}

interface PostTemplate {
  template: templateData;
}

const EditTemplate: React.FC<Props> = ({
  templateID: templateID,
  onClose,
  refetch,
  currentGlobalDate,
  selectedGlobalDate,
}) => {
  const classes = useStyle();
  const [template, setTemplate] = useState<templateData>({
    template_code: "",
    template_code_text: "",
    email_subject: "",
    email_body: "",
    is_active: true,
    is_editable: true,
  });

  const [error, setError] = useState("");
  const [errorCheck, setErrorCheck] = useState<string[]>([]);
  const [errorSubmit, setErrorSubmit] = useState<Boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [emailBody, setEmailBody] = useState("");
  const [placeholderValues, setPlaceholderValues] = useState<any>();

  const getTemplate = async (id: string): Promise<any> => {
    if (!id) return {};

    const res = await request({
      url: `school-communications/email-templates-details/?uuid=${id}`,
      method: "get",
    });
    return res;
  };

  const postTemplate = async ({ template }: PostTemplate): Promise<any> => {
    setLoader(true);
    const meth = templateID ? "put" : "post";

    var data: any = {};
    data = {
      template_code: template.template_code,
      template_code_text: template.template_code_text,
      email_subject: template.email_subject,
      email_body: emailBody,
      is_active: template.is_active,
      is_editable: template.is_editable,
    };

    if (templateID) {
      data.uuid = templateID;
    }

    const res = await request({
      url: `school-communications/email-templates-details/`,
      method: meth,
      data: data,
    });
    if (res) {
      setLoader(false);
    }
    return res;
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getTemplateError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["template1", templateID],
    () => getTemplate(templateID),
    {
      select: (template) => template.data,
    }
  );

  const mutation: UseMutationResult<any, Error, PostTemplate> = useMutation(
    async ({ template }) => postTemplate({ template })
  );

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setTemplate({
          ...template,
          template_code: data.results.template_code,
          template_code_text: data.results.template_code_text,
          email_subject: data?.results.email_subject,
          email_body: data.results.email_body,
          is_active: data.results.is_active,
          is_editable: data.results.is_editable,
        });
        setEmailBody(data.results.email_body);
      }
    }
  }, [data]);

  useEffect(() => {
    request({
      url: `school-communications/email-tags-details/`,
      method: "get",
    }).then((res) => {
      const values: any = [];
      res.data.results.map((item: any) =>
        values.push({ name: item.tag_name, value: `{{${item.tag_text}}}` })
      );
      setPlaceholderValues(values);
    });
  }, []);

  const onSaveClick = async () => {
    setErrorSubmit(true);
    if (errorCheck.length == 0) {
      mutation.mutate({ template });
    }
    await refetch();
    onClose();
  };

  const handleChange = (field: string) => {
    setEmailBody(field);
  };

  const LengthCheck = (field: string, errorName: string) => {
    const tempErrorArray = [...errorCheck];
    if (field.length === 0) {
      tempErrorArray.push(errorName);
      setErrorCheck(tempErrorArray);
    } else {
      const index = tempErrorArray.indexOf(errorName);
      if (index > -1) {
        tempErrorArray.splice(index, 1);
        setErrorCheck(tempErrorArray);
      }
    }
  };

  const onCancelClick = () => {
    onClose();
  };

  return (
    <div>
      <div>
        <Loader open={isLoading} />
      </div>
      {/* post loader */}
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getTemplateError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          <Input
            label="Template Code"
            type="text"
            value={template.template_code}
            onChange={(e: any) => {
              setTemplate({ ...template, template_code: e.target.value });
              LengthCheck(e.target.value, "codeError");
            }}
            error={errorSubmit ? errorCheck.includes("codeError") : false}
            disabled
          />
          <Input
            label="Template Description"
            value={template.template_code_text}
            type="text"
            onChange={(e: any) => {
              setTemplate({ ...template, template_code_text: e.target.value });
              LengthCheck(e.target.value, "codeTextError");
            }}
            error={errorSubmit ? errorCheck.includes("codeTextError") : false}
          />
          <Input
            label="Email Subject"
            value={template.email_subject}
            type="text"
            onChange={(e: any) => {
              setTemplate({ ...template, email_subject: e.target.value });
              LengthCheck(e.target.value, "subjectError");
            }}
            error={errorSubmit ? errorCheck.includes("subjectError") : false}
          />
          <InputLabel
            className={cn(classes.label, error ? classes.labelError : null)}
          >
            Email Body
          </InputLabel>
          {placeholderValues ? (
            <Editor
              value={emailBody}
              handleChange={handleChange}
              dropdownValues={placeholderValues}
            />
          ) : null}

          <Box display="flex" alignItems="center">
            <Checkbox
              color="default"
              onChange={(e: any) => {
                setTemplate({ ...template, is_active: !template.is_active });
              }}
              checked={template.is_active}
            />
            <Text paragraph>Is active</Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              color="default"
              onChange={(e: any) => {
                setTemplate({
                  ...template,
                  is_editable: !template.is_editable,
                });
              }}
              checked={template.is_editable}
            />
            <Text paragraph>Is editable</Text>
          </Box>
          {selectedGlobalDate === currentGlobalDate && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box style={{ width: 100, marginTop: 40 }} mr="40px" mb="40px">
                <AdminButton light label="Cancel" onClick={onCancelClick} />
              </Box>
              <Box style={{ width: 100, marginTop: 40 }} mb="40px">
                <AdminButton label="Save" onClick={onSaveClick} />
              </Box>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default EditTemplate;
