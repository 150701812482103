import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  criteriaContainer: {
    width: "100%",
    marginTop: 30,
  },
  inputContainer: {
    width: "100%",
    marginTop: 20,
  },
  specContainer: {
    width: "60%",
    marginTop: 30,
  },
  tab: {
    marginRight: 50,
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    width: 200
  },
  tabButton: {
    display: "block",
    backgroundColor: "inherit",
    color: theme.palette.common.black,
    padding: "12px 22px",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#2B2B33",
  },
  questionTab: {
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: 6,
    borderColor: theme.palette.grey[200],
    boxShadow: "3px 6px 8px #E6E4E3",
    backgroundColor: theme.palette.primary.light,
    margin: '20px 8px',
    padding: '7px',
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(251, 173, 22, 0.4)",
    },
  },
  questionText: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#2B2B33",
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
  },
  popUpScreen: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 1300,
  },
  popUpContainer: {
    minWidth: "45vw",
    minHeight: "25vh",
    padding: 20,
    backgroundColor: theme.palette.grey[200]
  },
  optionsDiv: {
    height: "35vh",
    overflow: 'auto',
  },
  options: {
    backgroundColor: theme.palette.primary.light,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: theme.palette.grey[300],
    padding: 5,
    // margin: 5,
    fontSize: "16px",
    color: "#2B2B33",
  },
  formPopup: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "3px solid #f1f1f1",
    borderRadius: 10,
    zIndex: 9,
  },
  typeBtn: {
    cursor: "pointer",
    fontSize: 16,
    textTransform: "none",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily:   `"LFT Etica", sans-serif`,
    borderRadius:4,
    padding:"6px 12px 6px 8px",
    width:"100%"
  },
  openPopup: {
    display: "block",
  },
  closePopup: {
    display: "none",
  },
  closeBtn: {
    position: 'absolute',
    right: 20,
    top: 20,
    width: 30,
    height: 30,
    backgroundColor: "#222",
    color: '#fff',
    fontSize: 25,
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },
  label: {
    marginBottom: 6.5,
    fontFamily:   `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  inputRoot: {
    width: "100%",
    fontFamily:   `"LFT Etica", sans-serif`,
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    overflow: "auto",
    outline: "none",
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
  },
}));
