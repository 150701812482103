import { useState } from "react";
import { Input } from "../../../../components/common/input/index";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { DeleteBox } from "../../../../components/common/delete-dialogbox/index";

type EducationOfferProps = {
  data: any;
  index: number;
  onChange: any;
  error?: any;
  onDelete?: any;
  schoolOffer: boolean | string;
};

const EduOfferItem: React.FC<EducationOfferProps> = ({
  data,
  index,
  onChange,
  error,
  onDelete,
  schoolOffer
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      {schoolOffer ? (
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <Input
              label="Please give details"
              onChange={(e: any) => onChange(e.target.value, index)}
              value={data.another_school_details}
              error={error}
            />
          </Box>
          {index !== 0 ? (
            <Box>
            <Icon
              style={{
                cursor: "pointer",
                marginTop: "-30px",
                color: "#757575",
              }}
              onClick={() => setOpen(true)}
            >
              delete
            </Icon>
            </Box>
          ) : null}
        </Box>
      ) : null}

      <DeleteBox
        index={index}
        id={data.school_offer_uuid}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
    </Box>
  );
};

export default EduOfferItem;
