import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  //  part2
  textOne: {
    fontSize: "14px",
    fontWeight: 600
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  addStudentContainer: {
    width: "100%",
  }
}));
