/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./student-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import { Box } from "@material-ui/core";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import { Loader } from "../../../../components/common/loader/index";
import request from "../../../../utils/authUtil";
import StudentSiblingCard from "./student-sibling-card/student-input-card";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const DEFAULT_SIBLING_DATA = {
  name_of_student: "",
  year_of_student: 0,
}

const getSiblingsData = async (stdAppId: string): Promise<any> => {
  const res = await request({
    url: `student-application/student-sibling-details/?uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

const postSiblingsData = async ({ stdAppId, localData }: any): Promise<any> => {
  const res = await request({
    url: `student-application/student-sibling-details/?uuid=${stdAppId}`,
    method: "put",
    data: localData,
  });
  return res;
};

const StudentSibling = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [localData, setLocalData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [checkYes, setCheckYes] = useState<string>("");
  const [error, setError] = useState<any>({});
  const [isSiblingDataError, setIsSiblingDataError] = useState<boolean>(false);


  const handleChangeRadio = (name: string, index: number) => {
    if (name === "Yes") {
      setCheckYes("Yes");
      setIsSiblingDataError(false)
      if(localData.length === 0){
      setLocalData([
        DEFAULT_SIBLING_DATA
      ]);
      setError({
        0:true,
      })
    }
    } else if (name === "No") {
      if(localData?.length > 0){
        setCheckYes("Yes");
        setIsSiblingDataError(true);
      }
      else{
        setCheckYes("No");
        setIsSiblingDataError(false);
      }
    }
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getSiblingsError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["stdsibling", stdAppId],
    () => getSiblingsData(stdAppId),
    {
      select: (res) => res.data,
    }
  );

  const mutation: UseMutationResult<any, Error, any> = useMutation(
    async ({ stdAppId, localData }) =>
      postSiblingsData({ stdAppId, localData }),
    {
      onSuccess: (res) => {
        setLoader(false);
        nextPage();
      },
      onError: (e) => {},
    }
  );

  useEffect(() => {
    if (data && data?.results?.length !== 0) {
      if (
        data.results.year_of_student !== null &&
        data.results.name_of_student !== ""
      ) {
        setLocalData([...data?.results])
        setCheckYes("Yes");
      } else {
        setCheckYes("No");
      }
    }
    else{
      setCheckYes("No");
    }
  }, [data]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);


  const setErrorOnSubmit = () => {
    let hasErrors = typeof error === 'object' ? Object.values(error).some((item)=>{return item}) : true
    if (!hasErrors) {
      setLoader(true);
      let trimmedData = localData.map((item: any) => {
        return {
          ...item,
          name_of_student: item["name_of_student"].trim(),
        };
      });
      mutation.mutate({ stdAppId, localData:trimmedData });
    }
  };

  const saveSiblingsData = () => {
    if(checkYes === "Yes"){
      setErrorOnSubmit();
    }
    else{
    if(checkYes === "No" && localData?.results?.length > 0){
      setIsSiblingDataError(true)
      setCheckYes("Yes");
    }
      else nextPage()
    }
  };

  const addNewSiblingData = async() => {
    setLoader(true)
    setLocalData((curr:any)=>[...curr, DEFAULT_SIBLING_DATA])
    setLoader(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">

          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={1} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Student/Child details"
                description="Next: Guardian details"
              />
            </div>
            {isError ? (
              <div>
                <p>Error while Fetching data.....</p>
                <p>{getSiblingsError}</p>
              </div>
            ) : null}
            {isSuccess ? (
              <Grid container direction="column">
                { isSiblingDataError ?
                  <p style={{color:"#d22929"}}>Please remove all siblings if the student does not have any</p>
                  :
                  null
                }
                <Box>
                  <InputRadioButton
                    label="Does your student/child have any siblings in the school *"
                    placeHolderOne="No"
                    placeHolderTwo="Yes"
                    onChange={handleChangeRadio}
                    valueOne="No"
                    valueTwo="Yes"
                    index={0}
                    answer={checkYes}
                    isCheckString
                  />
                </Box>
                {checkYes === "Yes" ? (
                  <>
                  {localData?.map((state:any,index:number)=>{
                    return(
                      <StudentSiblingCard
                        setCheckToNo={()=>setCheckYes("No")}
                        stdAppId={stdAppId}
                        key={index}
                        siblingInfoGetter={localData}
                        siblingInfoSetter={setLocalData}
                        indexOfSibling={index}
                        hasErrorSetter={setError}
                        noSiblingDataError={()=>setIsSiblingDataError(false)}
                      />
                    )
                  })
                  }
                  <div className={styles.buttonWidth}>
                  <Button light label="Add Sibling" squared endIcon="add" onClick={addNewSiblingData}/>
                  </div>
                  </>
                  )
                  :null
                }
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={saveSiblingsData}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
      <Loader open={loader} />
      </div>
    </div>
  );
};

export default StudentSibling;
