import { useEffect, useState } from "react";
import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import { Button } from "../../../components/common/button/index";
import { Input } from "../../../components/common/input/index";
import Header from "../header/index";
import { useStyle } from "./profile.style";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import request, { ParentUser } from "../../../utils/authUtil";
import { useQuery, UseQueryResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import { CheckNetwork } from "../../../utils/helper";

type Props = {};

const Profile = (props: Props) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const id = ParentUser.uuid;
  const [error, setError] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  // const [validError, setValidError] = useState({
  //   firstNameError: false,
  //   lastNameError: false,
  // });

  // Fetch Profile Data  Functionality
  const getProfileData = async (): Promise<any> => {
    if (id) {
      const response = await request({
        url: `/parent/parent-profile-page/?uuid=${id}`,
        method: "get",
      });
      return response;
    }
  };

  const { data, isLoading }: UseQueryResult<any, Error> = useQuery<any, Error>(
    "profileData",
    () => getProfileData(),
    {
      select: (profileData) => profileData.data,
    }
  );

  useEffect(() => {
    if (data) {
      if (data.status_code === 1) {
        const pD = data.results;
        setProfileData({
          first_name: pD.first_name,
          last_name: pD.last_name,
          email: pD.email,
          phone: pD.mobile_number,
        });
        setDisplayName(pD.first_name);
      } else if (data.status_code === -1) {
        setError(data.status_message);
      }
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  // Update Profile  Data Functionality
  // const validate = () => {
  //   return {
  //     firstNameError: profileData.first_name.length === 0,
  //     lastNameError: profileData.last_name.length === 0,
  //   };
  // };
  // const onSubmit = () => {
  //   const err = validate();
  //   setValidError(err);
  //   if (err.firstNameError === false && err.lastNameError === false) {
  //     setLoader(true);
  //     request({
  //       url: `/parent/parent-profile-page/?uuid=${id}`,
  //       method: "put",
  //       data: {
  //         first_name: profileData.first_name,
  //         last_name: profileData.last_name,
  //       },
  //     }).then((response) => {
  //       setLoader(false);
  //       if (response.data.status_code === 1) {
  //         if (response.data.results) {
  //           setProfileData(response.data.results);
  //           setDisplayName(response.data.results.first_name);
  //         }
  //       } else if (response.data.status_code === -1) {
  //         setLoader(false);
  //         setError(response.data.status_message);
  //       }
  //       setLoader(false);
  //     });
  //   }
  // };

  return (
    <div className={styles.container}>
      <CheckNetwork />
      <Header />
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title={`Hi ${displayName}`}
                description="Here you can edit your account info"
              />
            </div>
            {/* error msg */}
            <p
              style={{
                margin: 10,
                color: "#EB5757",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {error}
            </p>
            <Grid container direction="column">
              <Input
                label="Your first name"
                type="text"
                value={profileData.first_name}
                disabled={true}
              />
              <Input
                label="Your last name"
                type="text"
                value={profileData.last_name}
                disabled={true}
              />
              <Input
                label="Your email"
                type="email"
                disabled={true}
                value={profileData.email}                
              />
              <Input
                label="Phone"
                type="text"
                disabled={true}
                value={profileData.phone}
              />
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Link
                    to="/parent/dashboard"
                    style={{ textDecoration: "none" }}
                  >
                    <Button label="Back" light startIcon="navigate_before" />
                  </Link>
                </div>
                <div className={styles.buttonWidth}>
                  <Link
                    to="/parent/edit-profile"
                    style={{ textDecoration: "none" }}
                  >
                    <Button label="Edit" />
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Box className={styles.footerContainer}>
          <Footer center />
        </Box>

        <Loader open={loader} />
      </div>
    </div>
  );
};

export default Profile;
