import InputLabel from "@material-ui/core/InputLabel";
import React, { useState, useRef, useEffect } from "react";
import { useStyle } from "./input-date.style";
import cn from "classnames";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";

type InputProps = {
  label?: string;
  value?: any;
  error?: boolean;
  errorMessage?: string | null;
  onChange?: any;
  disabled?: any;
  maxDate?: any;
  minDate?: any;
};

export const InputDatePicker: React.FC<InputProps> = ({
  label,
  value,
  error = false,
  errorMessage = "Required",
  onChange,
  disabled = false,
  maxDate,
  minDate,
}) => {
  const classes = useStyle();
  const ref: any = useRef();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setOpen(false);
    });
  }, []);
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : null)}
      >
        {label}
      </InputLabel>
      <div ref={ref}>
        <div>
          <Box position="relative">
            <div onClick={() => (!disabled ? setOpen(true) : null)}>
              <DatePicker
                selected={value}
                placeholderText="--/--/----"
                className={cn(
                  classes.root,
                  error ? classes.error : "",
                  "react-datepicker__input-container",
                  !disabled ? classes.fontColor : classes.disabledFontColor
                )}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                dateFormat="dd/MM/yyyy"
                disabled={disabled}
                endDate={new Date()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                open={open}
              />
            </div>
            <Icon
              onClick={() => (!disabled ? setOpen(!open) : null)}
              className={
                !disabled
                  ? classes.calender_icon
                  : classes.disabled_calender_icon
              }
            >
              calendar_today_icon
            </Icon>
          </Box>
        </div>
      </div>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
