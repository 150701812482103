
import { useStyle } from "./text-field.style";
import cn from "classnames";

type CustomInputProps = {
  placeholder?: string | null;
  value?: string | null;
};

export const TextFieldCustom: React.FC<CustomInputProps> = ({
  placeholder,
  value,
}) => {
  const classes = useStyle();

  return (
    <div className={cn(classes.root)}>
      {placeholder}
      <div style={{ fontWeight: 600,marginTop:"3px" }}>{value}</div>
    </div>
  );
};
