import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    padding: "13px 12px",
    border: "1px solid",
    cursor: "pointer",
    borderColor: theme.palette.grey[200],
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: `"LFT Etica", sans-serif`,
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
    whiteSpace: "pre-wrap",
  },
  input: {
    fontFamily: `"LFT Etica", sans-serif`,
    display: "none",
  },
  uploadIcon: {
    color: theme.palette.grey[300],
  },
  completeIcon: {
    color: theme.palette.success.main,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  belowLabel: {
    color: theme.palette.grey[400],
    marginTop: 7,
    fontSize: 11,
  },
  deleteIcon: {
    color: "#757575",
    cursor: "pointer",
  },
}));
