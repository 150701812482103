import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./quill-toolbar";
import "react-quill/dist/quill.snow.css";
import "./editor.css";

export const Editor = ({ value, handleChange, dropdownValues }) => {

  return (
    <div className="text-editor">
      <EditorToolbar dropdownValues={dropdownValues}/>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
