import requestv2 from "../../../../utils/authUtilv2";

export const getDocument = async (
  studentAppId: string,
  schoolId: string | null
): Promise<any> => {
  const res = await requestv2({
    url: `student-application/student-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}&is_application_form=false`,
    method: "get",
  });
  return res;
};

export const postDocument = async (
  studentAppId: string,
  schoolId: string | null,
  data: any
): Promise<any> => {
  const res = await requestv2({
    url: `student-application/student-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}&is_application_form=${false}`,
    method: "put",
    data: data,
  });
  return res;
};
