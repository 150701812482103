/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { useStyle } from "./student-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { SelectDropDown } from "../../../../components/common/select-dropdown/index";
import { getCountry } from "../guardian-details/guardians.api";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Box } from "@material-ui/core";
import { county } from "../county";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import { Loader } from "../../../../components/common/loader/index";
import request from "../../../../utils/authUtil";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const getStudentAddressData = async (stdAppId: string): Promise<any> => {
  const res = await request({
    url: `student-application/student-application-address/?student_app_uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

const postStudentAddressData = async ({
  stdAppId,
  localData,
}: any): Promise<any> => {
  const res = await request({
    url: `student-application/student-application-address/?student_app_uuid=${stdAppId}`,
    method: "put",
    data: localData,
  });
  return res;
};

const StudentAddress = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [localData, setLocalData] = useState({
    address_line_one: "",
    address_line_two: "",
    town: "",
    city: "",
    country: "IE",
    eir_code: "",
    is_current: true,
    is_permanent: true,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [country, setCountry] = useState([
    { name: "-- Select Country --", value: "" },
  ]);

  const [error, setError] = useState<any>({
    address_line_one: false,
    town: false,
    city: false,
    eir_code: false,
  });
  const {
    isLoading,
    isError,
    isSuccess,
    error: getStdAddressError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["stdaddress", stdAppId],
    () => getStudentAddressData(stdAppId),
    {
      select: (res) => res.data,
    }
  );

  const mutation: UseMutationResult<any, Error, any> = useMutation(
    async ({ stdAppId, localData }) =>
      postStudentAddressData({ stdAppId, localData }),
    {
      onSuccess: (res) => {
        setLoader(false);
        nextPage();
      },
      onError: (e) => {},
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    getCountry().then((res) => {
      setCountry([...country, ...res.data.results]);
    });
  }, []);

  useEffect(() => {
    if (data && Object.keys(data?.results).length !== 0) {
      setLocalData({
        ...localData,
        address_line_one: data.results.address_line_one,
        address_line_two: data.results.address_line_two,
        town: data.results.town,
        city: data.results.city,
        country: data.results.country,
        eir_code: data.results.eir_code,
      });
    }
  }, [data]);

  const validate = () => {
    return {
      address_line_one: localData.address_line_one.length === 0,
      town: localData.town.length === 0,
      city: localData.city === "" && localData.country === 'IE',
      eir_code: localData.eir_code.length === 0,
    };
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "address_line_one":
        setLocalData({
          ...localData,
          address_line_one: event.target.value,
        });
        setError({
          ...error,
          address_line_one: event.target.value.length === 0,
        });
        break;
      case "address_line_two":
        setLocalData({
          ...localData,
          address_line_two: event.target.value,
        });
        break;
      case "town":
        setLocalData({
          ...localData,
          town: event.target.value,
        });
        setError({
          ...error,
          town: event.target.value.length === 0,
        });
        break;
      case "city":
        setLocalData({
          ...localData,
          city: event.target.value,
        });
        setError({
          ...error,
          city: localData.country === 'IE' && event.target.value === "",
        });
        break;
      case "country":
        setLocalData({
          ...localData,
          country: event.target.value,
        });
        setError({
          ...error,
          country: event.target.value === "",
        });
        if(event.target.value === 'IE') {
          setError({
            ...error,
            city: localData.country === 'IE' && event.target.value === "",
          });
        }
        break;
      case "eir_code":
        setLocalData({
          ...localData,
          eir_code: event.target.value,
        });
        setError({
          ...error,
          eir_code: event.target.value.length === 0,
        });
        break;
    }
  };

  const setErrorOnSubmit = () => {
    const err = validate();
    setError(err);
    if (
      err.address_line_one === false &&
      err.town === false &&
      err.city === false &&
      err.eir_code === false
    ) {
      setLoader(true);
      mutation.mutate({ stdAppId, localData });
    }
  };
  const saveStudentAddressData = () => {
    setErrorOnSubmit();
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={1} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Student/Child details"
                description="Next: Guardian details"
              />
            </div>
            {isError ? (
              <div>
                <p>Error while Fetching data.....</p>
                <p>{getStdAddressError}</p>
              </div>
            ) : null}
            {isSuccess ? (
              <Grid container direction="column">
                <Input
                  label="Address line 1 *"
                  value={localData.address_line_one}
                  onChange={(e) => onValueChange(e, "address_line_one")}
                  error={error.address_line_one}
                />
                <Input
                  label="Address line 2"
                  value={localData.address_line_two}
                  onChange={(e) => onValueChange(e, "address_line_two")}
                />
                <Input
                  label="Town/City *"
                  value={localData.town}
                  onChange={(e) => onValueChange(e, "town")}
                  error={error.town}
                />
                <SelectDropDown
                  label={localData.country === 'IE' ? "County *" : "County"}
                  item={county}
                  value={localData.city}
                  onChange={(e: any) => onValueChange(e, "city")}
                  error={error.city}
                />
                <SelectDropDown
                  label="Country *"
                  item={country}
                  value={localData.country}
                  onChange={(e: any) => onValueChange(e, "country")}
                />
                <Input
                  label="Eircode *"
                  value={localData.eir_code}
                  onChange={(e) => onValueChange(e, "eir_code")}
                  error={error.eir_code}
                />
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={saveStudentAddressData}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default StudentAddress;
