import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React from "react";

type Props = {
  globalDateItem: any;
  selectedGlobalDate: any;
  handleChangeGlobaldate: any;
};

const GlobalYearDropDown: React.FC<Props> = ({
  globalDateItem,
  selectedGlobalDate,
  handleChangeGlobaldate,
}) => {
  return (
    <>
      <div style={{ paddingRight: 50 }}>
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedGlobalDate}
            onChange={handleChangeGlobaldate}
            style={{ width: "120px" }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              getContentAnchorEl: null,
            }}
          >
            {globalDateItem.map((item: any) => (
              <MenuItem key={item.year_uuid} value={item.year_uuid}>
                {item.year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};
export default GlobalYearDropDown;
