import { useStyle } from "./profile-image.style";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
type Props = {
  src?: string;
  label?: string;
};

const ProfileImage = ({
  src,
  label,
}: Props) => {
  const classes = useStyle();
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel
        className={cn(classes.label)}
      >
        {label}
      </InputLabel>
      <Grid
        className={cn(classes.root)}
        container
        direction="row"
        justifyContent="space-between"
      >
        <img src={src} alt="Im" height="100%" width="100%" />
      </Grid>
    </div>
  );
};

export default ProfileImage;
