import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    boxShadow: "0px 2px 8px 3px rgba(0, 0, 0, 0.05)",
    borderRadius: 12,
  },
  head: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "12px 12px 0 0",
    padding: "21px 18px 25px 16px",
  },
  content: {
    backgroundColor: theme.palette.common.white,
    borderRadius: "0 0 12px 12px",
    padding: "21px 18px 25px 16px",
    paddingTop: 20,
  },
  text: {
    marginLeft: 20,
    fontSize: 18,
    fontWeight: "bold",
  },
  smallText: {
    fontSize: 12,
    color: theme.palette.grey[300],
    fontWeight: "normal",
  },
  completeIcon: {
    color: theme.palette.success.main,
  },
  textButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.grey[300],
    cursor: "pointer",
  },
  nameContainer: {
    width: "70%",
  },
  buttonContainer: {
    width: "30%",
  },
  buttonWidth: {
    width: "130px",
    marginTop: "20px",
    marginLeft: "54%",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginLeft: "70%",
      width: "210px",
      [theme.breakpoints.down("sm")]: {
        width: "210px",
        marginTop: "20px",
        marginLeft: "70%",
      },
    },
  },
  tickIcon: {
    display: "flex",
    alignItems: "center",
    minWidth: "43px",
    maxWidth: "43px",
    height: "43px",
    textTransform: "none",
    borderRadius: "50%",
    justifyContent: "center",
    backgroundColor: "#E7F3E6",
  },
  acceptOfferButtons: {
    width: "100%",
    marginBottom: "40px",
    display: "flex",
    justifyContent: "space-around",
  },
  declineButton: {
    width: "100%",
    textTransform: "none",
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: `'LFT Etica', sans-serif`,
    borderRadius: 50,
  },
}));
