import { useState, useEffect } from "react";
import { InputRadioButton } from "../../../../../../components/common/input-radio-button-two";
import { Box } from "@material-ui/core";
import {
  getASDApplicationDetails,
  postASDApplicationData,
} from "../../../../../parent/application/asd-confirmation-page/asd-confirmation-page.api";
import { AdminButton } from "../../../../../../components/common/admin-button";
import { ConfirmBox } from "../../../../../../components/common/confirm-box";

type Props = {
  applicationID?: any;
  formDisable?: boolean;
  setFormDisable?: any;
  setErrorEdit?: any;
};

const ASDDetails = (props: Props) => {
  const { applicationID, formDisable, setFormDisable, setErrorEdit } = props;
  const schoolId = localStorage.getItem("school-id");
  const [isASDapplication, setIsASDapplication] = useState(false);
  const [enrolInSpecialClass, setEnrolInSpecialClass] = useState(false);
  const [open, setOpen] = useState(false);

  const saveData = async () => {
    let data = {
      is_asd_application: isASDapplication,
      is_special_classes_only: enrolInSpecialClass,
    };

    let res = await postASDApplicationData(applicationID, schoolId, data);
    if (res.data.status_code === 1) {
      setFormDisable(true);
      setErrorEdit("");
      setOpen(false);
    }
  };

  const cancelEdit = () => {
    setFormDisable(true);
    setErrorEdit("");
  };

  const handleRadioChange = (bool: boolean, type: string) => {
    if (type === "enrolInSpecialClass") {
      setEnrolInSpecialClass(bool);
    } else if (type === "isASDapplication") {
      setIsASDapplication(bool);
    }
  };

  useEffect(() => {
    getASDApplicationDetails(applicationID, schoolId).then((res) => {
      if (res?.data?.status_code === 1) {
        setIsASDapplication(res?.data?.results?.is_asd_application);
        setEnrolInSpecialClass(res?.data?.results?.is_special_classes_only);
      }
    });
  }, []);
  return (
    <div>
      <Box>
        <InputRadioButton
          label="Does your child come under ASD?"
          placeHolderOne="Yes"
          placeHolderTwo="No"
          answer={isASDapplication}
          onChange={(val: boolean) => handleRadioChange(val, "isASDapplication")}
          index={0}
          valueOne={true}
          valueTwo={false}
          isCheckString
          isDisabled={formDisable}
        />
        <InputRadioButton
          label="Enrol student for special class?"
          placeHolderOne="Yes"
          placeHolderTwo="No"
          answer={enrolInSpecialClass}
          onChange={(val: boolean) => handleRadioChange(val, "enrolInSpecialClass")}
          index={0}
          valueOne={true}
          valueTwo={false}
          isCheckString
          isDisabled={formDisable}
        />
      </Box>
      {localStorage.getItem("employee_type") === "Super Admin" ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={saveData}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </div>
  );
};

export default ASDDetails;
