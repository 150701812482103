import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    fontSize: "14px",
    lineHeight: "22px",
    letterSpacing: "0em",
    paddingTop: "10px",
    paddingBottom: "10px",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  title: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "25px",
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
    whiteSpace: "pre-wrap",
    marginBottom: "20px",
  },
  btnLink: {
    padding: "0px",
    border: "none",
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: "13px",
    fontWeight: 600,
    color: "#1A1A1A",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
}));
