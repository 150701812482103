import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { Box, Icon } from "@material-ui/core";
import { AdminButton } from "../../../../components/common/admin-button/index";
import { useStyle } from "../start-selection/selection-table/selection-table.style";

type Props = {
  data: any;
  onWithdraw: any;
};

const WithdrawTable: React.FC<Props> = ({ data, onWithdraw }) => {
  const classes = useStyle();
  const columns: GridColumns = [
    {
      field: "ID",
      headerName: "ID",
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Student_Name",
      headerName: "Student",
      minWidth: 280,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "application_status",
      headerName: "Offer Status",
      minWidth: 280,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: " ",
      headerName: "Withdraw Application",
      minWidth: 280,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Box>
            <AdminButton
              label="Withdraw application"
              disabled={params.row.application_status === "Withdrawn"}
              onClick={() => onWithdraw(params.row.ID)}
            />
          </Box>
        );
      },
      headerAlign: "center",
      align: "center",
    },
    {
      field: "view",
      headerName: "View Application",
      minWidth: 280,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <span
            onClick={() =>
              window.open(`/admin/applications/${params.row.student_app_uuid}`)
            }
            style={{
              width: 80,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              style={{
                color: "#757575",
                marginRight: 10,
              }}
            >
              remove_red_eye_icon
            </Icon>
            VIEW
          </span>
        );
      },
      headerAlign: "center",
      align: "center",
    },
  ];

  const rows = data;

  return (
    <DataGrid
      autoHeight={true}
      className={classes.root}
      rows={rows}
      columns={columns}
      disableSelectionOnClick={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      hideFooterPagination={true}
      hideFooter={true}
    />
  );
};

export default WithdrawTable;
