import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./change-password.style";
import { Footer } from "../../../components/common/footer/index";
import { Input } from "../../../components/common/input/index";
import request, { ParentUser } from "../../../utils/authUtil";
import Header from "../header/index";
import { Loader } from "../../../components/common/loader/index";
import { Link } from "react-router-dom";
import { Welcome } from "../../../components/common/welcome/index";
import { CheckNetwork } from "../../../utils/helper";

type Props = {};

const ChangePassword = (props: Props) => {
  const classes = useStyle();
  let id = ParentUser.uuid;
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [passMatched, setPassMatched] = useState<boolean>(true);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [success, setSuccess] = useState<string>();
  const [data, setData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const matchPasssword = () => {
    if (
      data.new_password.length !== 0 &&
      data.new_password !== data.confirm_password
    ) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (data.new_password.length === 0) {
      if (!temp.includes("confirm_password")) {
        temp.push("confirm_password");
      }
    } else if (data.new_password.length !== 0) {
      if (temp.includes("confirm_password")) {
        const val = temp.indexOf("confirm_password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };

  const test = () => {
    if (
      data.confirm_password.length !== 0 &&
      data.new_password !== data.confirm_password
    ) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (data.new_password.length === 0) {
      if (!temp.includes("new_password")) {
        temp.push("new_password");
      }
    } else if (data.new_password.length !== 0) {
      if (temp.includes("new_password")) {
        const val = temp.indexOf("new_password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };

  const requiredFieldCheck = () => {
    const temp = [];
    if (data.current_password.length === 0) temp.push("current_password");
    if (data.new_password.length === 0) temp.push("new_password");
    if (data.confirm_password.length === 0) temp.push("confirm_password");
    setRequiredFields([...temp]);
    if (temp.length === 0) {
      apiCall();
    }
  };

  const apiCall = () => {
    if (
      id &&
      data.new_password &&
      data.confirm_password &&
      data.new_password === data.confirm_password
    ) {
      setLoader(true);
      request({
        url: "/auth/reset-password/",
        method: "post",
        data: {
          user_uuid: id,
          current_password: data.current_password,
          new_password: data.new_password,
          confirm_new_password: data.confirm_password,
        },
      })
        .then((response) => {
          setLoader(false);
          if (response.data.status_code === 1) {
            setData({
              confirm_password: "",
              current_password: "",
              new_password: "",
            });
            setSuccess("Your password changed successfully");
            setError("");
          } else if (response.data.status_code === -1) {
            setLoader(false);
            setError(response.data.status_message);
            setSuccess("");
          }
          setLoader(false);
        });
    }
  };
  const onSubmit = async () => {
    await requiredFieldCheck();
  };
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
       <CheckNetwork />
      <Header />
      <Grid container justifyContent="center">
        <div className={classes.card}>
          <Loader open={loader} />
          <div style={{ marginBottom: 34 }}>
            <Welcome
                title={`Change Password`}
                description="Here you can change your password"
              />
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          {/* success msg*/}
          <p style={{
              margin: 10,
              color: "#90eb28",
              display: "flex",
              justifyContent: "center",
            }}>{success}</p>

          <Grid container direction="column">
            <Input
              label="Current password"
              type="password"
              value={data.current_password}
              onChange={(e: any) =>
                setData({ ...data, current_password: e.target.value })
              }
              error={requiredFields.includes("current_password")}
            />
            <Input
              label="New password"
              type="password"
              onBlur={test}
              value={data.new_password}
              onChange={(e: any) =>
                setData({ ...data, new_password: e.target.value })
              }
              error={requiredFields.includes("new_password")}
            />
            <Input
              label="Confirm new password"
              type="password"
              value={data.confirm_password}
              onBlur={matchPasssword}
              onChange={(e: any) =>
                setData({ ...data, confirm_password: e.target.value })
              }
              error={
                !passMatched || requiredFields.includes("confirm_password")
              }
              errorMessage={
                !passMatched ? "Doesn't match with Password" : "Required"
              }
            />
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={classes.buttonWidth}>
              <Link to="/parent/dashboard" style={{ textDecoration: "none" }}>
                
                <Button label="Back" light startIcon="navigate_before" />
              </Link>
            </div>
            <div className={classes.buttonWidth}>
              <Button label="Submit" onClick={onSubmit} />
            </div>
          </Grid>
        </div>
      </Grid>
      <Grid container>
        <div style={{ width: "100vw" }}>
          <Footer center />
        </div>
      </Grid>
      <Loader open={loader} />
    </Grid>
  );
};

export default ChangePassword;
