import { useState, useEffect } from "react";
import { useStyle } from "./criteria-tab.style";
import { Box } from "@material-ui/core";
import { Text } from "../../../components/common/text/index";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import { Input } from "../../../components/common/input/index";
import { AdminButton } from "../../../components/common/admin-button/index";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { createCriteria, deleteCriteria } from "./criteria.api";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";
import { useHistory } from "react-router-dom";

type CriteriaProps = {
  index: number;
  applicationID: string;
  criteriaLabel: string;
  criteria_uuid: string;
  criteria_question: string;
  order: number;
  setCriteria: any;
};

const CreateCriteria: React.FC<CriteriaProps> = ({
  index,
  applicationID,
  criteriaLabel,
  criteria_uuid,
  criteria_question,
  order,
  setCriteria,
}) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [inputEmpty, setInputEmpty] = useState<boolean>(false);
  const history = useHistory();

  const saveCriteriaHandler = () => {
    if (inputValue === "") {
      setInputEmpty(true);
    } else {
      createCriteria({ id: applicationID, question: inputValue, order }).then(
        (response) => {
          if (response.data.results) {
            setCriteria(response.data.results);
          }
        }
      );
    }
  };

  const deleteCriteriaHandler = () => {
    deleteCriteria({ id: applicationID, criteria_uuid }).then((response) => {
      if (response.data.results) {
        setCriteria(response.data.results);
      }
    });
  };

  const onInputChange = (value: string) => {
    if (value !== "") {
      setInputEmpty(false);
    }
    setInputValue(value);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      style={{ marginBottom: 20 }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Text subHeading>{criteriaLabel}</Text>
        <Icon
          style={{ cursor: "pointer", color: "#757575" }}
          onClick={() => setOpen(true)}
        >
          delete
        </Icon>
      </Box>
      {criteria_question === "" && (
        <div>
          <div className={classes.inputContainer}>
            <Input
              value={inputValue}
              onChange={(e: any) => onInputChange(e.target.value)}
              error={inputEmpty}
              //   disabled={selectedGlobalDate !== currentGlobalDate}
              maxlength="200"
            />
          </div>
          <div style={{ width: 100 }}>
            <AdminButton label="Save" onClick={saveCriteriaHandler} />
          </div>
        </div>
      )}
      {criteria_question !== "" && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.questionTab}
          onClick={() =>
            history.push(`/admin/settings/criteria/${criteria_uuid}`)
          }
        >
          <div className={classes.questionText}>{criteria_question}</div>

          <ArrowForwardIosIcon
            fontSize="small"
            style={{
              color: "black",
            }}
          />
        </Grid>
      )}
      <DeleteBox
        index={index}
        id={criteria_uuid}
        onClick={deleteCriteriaHandler}
        open={open}
        setOpen={setOpen}
      />
    </Grid>
  );
};

export default CreateCriteria;
