import request from "../../../../utils/authUtil";

export const getUserParentDetails = async (userId: string) => {
  const res = await request({
    url: `student-application/guardian-details/?parent_uuid=${userId}`,
    method: "get",
  });
  return res;
};

export const getAllGuardianDetails = async (stdAppId: any) => {
  const res = await request({
    url: `student-application/guardian-details/?student_app_uuid=${stdAppId}&guardian_uuid=`,
    method: "get",
  });
  return res;
};

export const createGuardian = async (stdAppId: string, guardian: any) => {
  const data = {
    uuid: guardian.uuid,
    guardian_first_name: guardian.guardian_first_name,
    guardian_last_name: guardian.guardian_last_name,
    guardian_email: guardian.guardian_email,
    guardian_phone: parseInt(guardian.guardian_phone),
    guardian_relation: guardian.guardian_relation,
    guardian_phone_verified: guardian.guardian_phone_verified,
    guardian_email_verified: guardian.guardian_email_verified,
    guardian_type: guardian.guardian_type,
    mother_maiden_name: guardian.mother_maiden_name,
  };
  const res = await request({
    url: `student-application/guardian-details/?student_app_uuid=${stdAppId}`,
    method: "put",
    data,
  });
  return res;
};



export const getGuardianAddress = async (guardianId: string) => {
  const res = await request({
    url: `student-application/guardian-address-details/?guardian_uuid=${guardianId}`,
    method: "get",
  });
  return res;
}

export const postGuardianAddress = async (guardianId: string, guardianAddress: any) => {
  const data = {
    address_line_one: guardianAddress.address_line_one,
    address_line_two: guardianAddress.address_line_two,
    town: guardianAddress.town,
    city: guardianAddress.city,
    country: guardianAddress.country,
    eir_code: guardianAddress.eir_code,
    is_current: guardianAddress.is_current,
    is_permanent: guardianAddress.is_permanent
  }
  const res = await request({
    url: `student-application/guardian-address-details/?guardian_uuid=${guardianId}`,
    method: "put",
    data,
  });
  return res;
}

export const getStudentAddressData = async (stdAppId: string): Promise<any> => {
  const res = await request({
    url: `student-application/student-application-address/?student_app_uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

export const getCountry = async () => {
  const res = await request({
    url: `student-application/country-details/`,
    method: "get",
  });
  return res;
}
