import MaterialButton from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { useStyle } from "./addbtn.style";
import cn from "classnames";

type AddButtonProps = {
  onClick?: any;
  className?: string;
  iconName?: string;
  iconSize?: string;
  isDisabled?: boolean;
};

export const AddButton: React.FC<AddButtonProps> = ({
  onClick,
  className,
  iconName,
  iconSize,
  isDisabled=false,
}) => {
  const classes = useStyle();
  return (
    <MaterialButton
      className={cn(classes.root, className, classes.bgYellow)}
      onClick={onClick}
      disabled={isDisabled}
    >
      <Icon
        style={{
          fontSize: `${iconSize}`,
        }}
      >
        {iconName}
      </Icon>
    </MaterialButton>
  );
};
