import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "./text-area.style";
import cn from "classnames";
import * as React from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
type InputProps = {
  label?: string;
  value?: any;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string | null;
  onChange?: any;
  disabled?: any;
};

export const TextArea: React.FC<InputProps> = ({
  label,
  value,
  placeholder,
  error = false,
  errorMessage = "Required",
  onChange,
  disabled = false,
}) => {
  const classes = useStyle();
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : null)}
      >
        {label}
      </InputLabel>
      <TextareaAutosize
        maxRows={30}
        minRows={30}
        style={{ resize: "none", fontSize: "16px", }}
        className={cn(
          error ? (classes.rootError) : classes.root,
          disabled && classes.disabled
        )}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
