import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from "react";
import { Loader } from "../../../../components/common/loader";
import request from "../../../../utils/authUtil";
import requestV2 from "../../../../utils/authUtilv2";
import { useStyle } from "./student-details.style";
import { Button } from "../../../../components/common/button";
import ApplicationTrialModal from "./ApplicationTrialModal";
import { AdminButton } from "../../../../components/common/admin-button";

import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";
type Props = {
  applicationID?: any;
};

const ApplicationTrial: React.FC<Props> = ({ applicationID }) => {
  const columns = [
    { id: "Created", label: "Created", minWidth: 100 },
    { id: "User", label: "User", minWidth: 100 },
    {
      id: "Stage",
      label: "Stage",
      minWidth: 100,
    },
    {
      id: "Status",
      label: "Status",
      minWidth: 100,
    },
    {
      id: "Notes",
      label: " Notes",
      minWidth: 100,
    },
  ];

  //modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [trailData, setTrailData] = useState<any>([]);
  const classes = useStyle();
  const getApplicationTrail = async (): Promise<any> => {
    setLoader(true);
    await request({
      url: `school-settings/application-trail/?student_app_uuid=${applicationID}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setTrailData(res.data.results);
        setLoader(false);
      } else if (res.data.status_code === -1) {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getApplicationTrail();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadAppTrail = () => {
    setLoader(true);
    requestV2({
      url: `school-settings/download-application-trail-pdf/?student_app_uuid=${applicationID}`,
      method: "get",
    }).then((response) => {
      if (response.data.results) {
        window.open(response.data.results);
        setLoader(false);
      }
    });
  };

  return (
    <Grid container direction="column" justifyContent="flex-start">
      {/* add Application Trail button */}
      <div
        style={{
          textAlign: "right",
          margin: "-15px 25px 10px 0",
          width: "150px",
        }}
      >
        <AdminButton
          startIcon="download_icon"
          label="Download"
          onClick={downloadAppTrail}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            maxWidth: "10%",
            marginBottom: "-10px",
            marginRight: "3rem",
            marginTop: "-45px",
          }}
        >
          <Button
            label="ADD"
            padding="0.4rem 2rem"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      {/* Modal component renders start */}
      <ApplicationTrialModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        applicationID={applicationID}
        setTrailData={setTrailData}
      />
      <div>
        {/* *Table Code Start* */}
        <Loader open={loader} />
        <>
          <Paper
            style={{ overflow: "hidden", marginTop: "20px", width: "100%" }}
          >
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        className={classes.root}
                        key={column.id}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trailData &&
                    trailData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item: any) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <>
                              <TableCell className={classes.root}>
                                {item.Created}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.User}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Stage}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Status}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Notes}
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 15, 25, 50, 100]}
              component="div"
              count={trailData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </>
        {/* *Table Code End* */}
      </div>
    </Grid>
  );
};

export default ApplicationTrial;
