/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./guardian-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { GuardianRadio } from "./guardian-info-radio/index";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Loader } from "../../../../components/common/loader/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId?: string;
};

const getAllGuardianDetails = async (stdAppId: any) => {
  const res = await request({
    url: `student-application/guardian-details/?student_app_uuid=${stdAppId}&guardian_uuid=`,
    method: "get",
  });
  return res;
};

const getCorrespondenceData = async (stdAppId: any): Promise<any> => {
  const res = await request({
    url: `student-application/guardian-communication-prefrence/?student_app_uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

const postCorrespondenceData = async ({
  stdAppId,
  data,
}: any): Promise<any> => {
  const res = await request({
    url: `student-application/guardian-communication-prefrence/?student_app_uuid=${stdAppId}`,
    method: "put",
    data,
  });
  return res;
};

const GuardianCorrespondence = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [check, setCheck] = useState<any>({
    one: "",
    two: "",
  });
  const [error, setError] = useState({ guardian_empty: false });
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState({
    email_communication: "",
    post_communication: "",
  });
  const [twoGuardians, setTwoGuardians] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true)
    getAllGuardianDetails(stdAppId).then((res) => {
      setLoader(false)
      if (res.data.results.length === 2) {
        setTwoGuardians(true);
      }
      if (res.data.results.length === 1) {
        setData({
          email_communication: "GUARDIAN_ONE",
          post_communication: "GUARDIAN_ONE"
        });
        setCheck({
          one: "GUARDIAN_ONE_1",
          two: "GUARDIAN_ONE_2"
        });
      }
    });
    setLoader(true)
    getCorrespondenceData(stdAppId).then((res) => {
      if (res.data.status_code === 1) {
        setLoader(false)
        setData(res.data.results);
        setCheck({
          ...check,
          one: res.data.results.email_communication.concat("_1"),
          two: res.data.results.post_communication.concat("_2"),
        });
      }
    });
  }, []);

  const Guardian = [
    {
      label: "Please indicate preference to receive school texts",
      radioHolderOne: "GUARDIAN_ONE_1",
      radioHolderTwo: "GUARDIAN_TWO_1",
      radioHolderThree: "GUARDIAN_BOTH_1",
      radioPlaceHolderOne: "Guardian 1",
      radioPlaceHolderTwo: "Guardian 2",
      radioPlaceHolderThree: "Both",
    },
    {
      label: "Preffered correspondence address",
      radioHolderOne: "GUARDIAN_ONE_2",
      radioHolderTwo: "GUARDIAN_TWO_2",
      radioHolderThree: "GUARDIAN_BOTH_2",
      radioPlaceHolderOne: "Guardian 1",
      radioPlaceHolderTwo: "Guardian 2",
      radioPlaceHolderThree: "Both",
    },
  ];

  const handleChangeRadio = (name: string, index: number) => {
    const val = helper(name);
    if (index === 0) {
      setData({
        ...data,
        email_communication: val || "",
      });
      setCheck({
        ...check,
        one: name,
      });
    }
    if (index === 1) {
      setData({
        ...data,
        post_communication: val || "",
      });
      setCheck({
        ...check,
        two: name,
      });
    }
  };

  const helper = (val: string) => {
    if (val === "GUARDIAN_ONE_1") return "GUARDIAN_ONE";
    if (val === "GUARDIAN_TWO_1") return "GUARDIAN_TWO";
    if (val === "GUARDIAN_BOTH_1") return "GUARDIAN_BOTH";
    if (val === "GUARDIAN_ONE_2") return "GUARDIAN_ONE";
    if (val === "GUARDIAN_TWO_2") return "GUARDIAN_TWO";
    if (val === "GUARDIAN_BOTH_2") return "GUARDIAN_BOTH";
  };

  const validate = () => {
    return {
      guardian_empty:
        data.post_communication === "" || data.email_communication === "",
    };
  };

  const onSubmit = () => {
    const err = validate();
    setError(err);
    if (err.guardian_empty === false) {
      setLoader(true);
      postCorrespondenceData({ stdAppId, data })
        .then((res) => {
          if (res.data.status_code === 1) {
            setData(res.data.results);
            nextPage();
            setLoader(false);
          } else {
            setLoader(false);
          }
        });
    } else {
      setErrorMessage("Please check both fields");
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>arrow_back</Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={2} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Guardian details"
                description="Next: Confirm details"
              />
            </div>

            <Grid container direction="column">
              <Box mb={5}>
                <Box mb="25px">
                  <b>Correspondence preferences</b>
                </Box>
                {Guardian.map((item, index) => (
                  <GuardianRadio
                    label={item.label}
                    radioHolderOne={item.radioHolderOne}
                    radioHolderTwo={item.radioHolderTwo}
                    radioHolderThree={item.radioHolderThree}
                    onChange={handleChangeRadio}
                    check={index === 0 ? check.one : check.two}
                    index={index}
                    radioPlaceHolderOne={item.radioPlaceHolderOne}
                    radioPlaceHolderTwo={item.radioPlaceHolderTwo}
                    radioPlaceHolderThree={item.radioPlaceHolderThree}
                    twoGuardian={twoGuardians}
                  />
                ))}
              </Box>
              {error ? (
                <Box className={styles.errorMessage}>
                  <p>{errorMessage}</p>
                </Box>
              ) : null}
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button
                    label="Next"
                    light
                    endIcon="navigate_next"
                    onClick={onSubmit}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default GuardianCorrespondence;
