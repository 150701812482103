import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "./input-radio.style";
import Radio from "@material-ui/core/Radio";
import { Box } from "@material-ui/core";
import cn from "classnames";
type InputRadioProps = {
  label?: string;
  radioHolderOne?: string;
  onChange?: any;
  check?: boolean;
  error?: boolean;
  errorMessage?: string;
};

export const InputRadio: React.FC<InputRadioProps> = ({
  label,
  radioHolderOne,
  check,
  onChange,
  error = false,
  errorMessage = "Required",
}) => {
  const classes = useStyle();

  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel className={cn(error ? classes.labelError : classes.label)}>
        {label}
      </InputLabel>
      {/* one radio */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={cn(
          classes.root,
          check === true ? classes.borderGreen : "",
          error ? classes.error : ""
        )}
      >
        <p>{radioHolderOne}</p>
        <Radio
          className={check === true ? classes.checked : classes.checkedGrey}
          checked={check}
          onClick={() => onChange(check)}
          color="default"
          disableRipple
        />
      </Box>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
