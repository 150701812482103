import { DataGrid } from "@material-ui/data-grid";
import { Box, Icon } from "@material-ui/core";
import { useStyle } from "./selection-table.style";
import { useState, useEffect } from "react";

type Props = {
  data: any;
  // onCheck?: any;
};

const DataTable: React.FC<Props> = ({ data }) => {
  const classes = useStyle();
  const columns = [
    {
      field: "ID",
      headerName: "ID",
      minWidth: 100,
      sortable: false,
    },
    {
      field: "student_name",
      headerName: "Student",
      minWidth: 280,
      sortable: false,
    },
    {
      field: "criteria",
      headerName: "Criteria",
      minWidth: 280,
      sortable: false,
    },
    {
      field: "application_status",
      headerName: "Offer Status",
      minWidth: 280,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Box
            className={
              params.row.application_status === "Selected"
                ? classes.app_status
                : ""
            }
          >
            {params.row.application_status}
          </Box>
        );
      },
    },
    {
      field: "view",
      headerName: "View Application",
      minWidth: 280,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <span
            onClick={() =>
              window.open(`/admin/applications/${params.row.student_app_uuid}`)
            }
            style={{
              width: 80,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              style={{
                color: "#757575",
                marginRight: 10,
              }}
            >
              remove_red_eye_icon
            </Icon>
            VIEW
          </span>
        );
      },
    },
  ];

  const rows = data;
  const [selectedId, setSelectedId] = useState<number[]>([]);
  useEffect(() => {
    setSelectedId(data.map((dt: any) => dt.id));
  }, [data]);

  return (
    <DataGrid
      autoHeight={true}
      className={classes.root}
      rows={rows}
      columns={columns}
      checkboxSelection
      disableSelectionOnClick={true}
      disableColumnFilter={true}
      disableColumnMenu={true}
      // onSelectionModelChange={(model: any) => onCheck(model)}
      hideFooterPagination={true}
      selectionModel={selectedId}
    />
  );
};

export default DataTable;
