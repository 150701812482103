import { useStyle } from "../start-selection/selection-dropdown-component/selection-drop.style";
import { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import cn from "classnames";

type Props = {
  options: any;
  onClick?: any;
  criteria?: string;
};
const ManualDropDown: React.FC<Props> = ({ options, onClick, criteria }) => {
  const classes = useStyle();
  const ref: any = useRef();
  const [isDrop, setIsDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setIsDrop(false);
    });
  }, []);

  const isDropdown = () => {
    setIsDrop(!isDrop);
  };

  return (
    <Box display="flex" position="relative">
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          fontWeight={600}
          onClick={isDropdown}
        >
          {/* <Box> */}
          <Box
            width="180px"
            className={classes.select}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <p className={classes.textOne}>{criteria}</p>
            <Icon className={classes.arrowIcon}>
              {isDrop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
            </Icon>
          </Box>
        </Box>
        <Box className={isDrop ? classes.dropContainer : classes.displayNone}>
          <div>
            <Box className={cn(classes.root, classes.itemBorder)}>
              <Box
                padding="10px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  onClick("ALL", "");
                  isDropdown();
                }}
              >
                <div>ALL</div>
              </Box>
            </Box>
            {options.map((criteria: any, index: number) => (
              <Box
                className={cn(
                  classes.root,
                  index !== options.length - 1 ? classes.itemBorder : ""
                )}
                onClick={() => {
                  onClick(criteria.criteria_name, criteria.criteria_uuid);
                  isDropdown();
                }}
                style={{ cursor: "pointer" }}
              >
                <Box
                  padding="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div>
                    {criteria.criteria_name} {criteria.is_special && `*`}
                  </div>
                </Box>
              </Box>
            ))}
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default ManualDropDown;
