import requestv2 from "../../../../utils/authUtilv2";
import request from "../../../../utils/authUtil";
import { Type } from "../../../../utils/document-types";

export const getDocument = async (
  studentAppId: string,
  schoolId: string | null
): Promise<any> => {
  const res = await requestv2({
    url: `student-application/student-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}&is_application_form=true`,
    method: "get",
  });
  return res;
};

export const postDocument = async (
  studentAppId: string,
  schoolId: string | null,
  data: any
): Promise<any> => {
  const res = await requestv2({
    url: `student-application/student-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}&is_application_form=true`,
    method: "put",
    data: data,
  });
  return res;
};

export const getParentSignature = async (parentID: string) => {
  const result = await request({
    url: `/auth/parent-documents-upload-credentials?user_uuid=${parentID}&document_type=${Type.signature}`,
    method: "get",
  });
  return result;
};

export const getDate = async () => {
  const result = await request({
    url: `auth/application-server-date/`,
    method: "get",
  });
  return result;
}
