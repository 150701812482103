import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 12,
    padding: "21px 18px 25px 16px",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  nameContainer: {
    width: "70%"
  },
  buttonContainer: {
    width: "30%"
  },
  buttonWidth:{
    [theme.breakpoints.up("xs")]: {
      marginTop: "20px",
      marginLeft: "65%"  , 
      width: "30%",
      [theme.breakpoints.down("xs")]: {
        width: "30%",
        marginTop: "20px",
        marginLeft: "65%"
      },
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
      marginLeft: "68%",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "30%",
        marginTop: "20px",
        marginLeft: "68%"
      },
    },
    [theme.breakpoints.up("md")]: {
      // margin: "40px 0px ",
      width: "30%",
      marginTop: "20px",
      marginLeft: "68%",
      [theme.breakpoints.down("md")]: {
        width: "30%",
        marginTop: "20px",
        marginLeft: "68%"
      },
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "20px", 
      marginLeft: "68%",
      width: "30%",
      [theme.breakpoints.down("md")]: {
        width: "30%",
      },
    },
    
  },
  text: {
    marginLeft: 20,
    fontSize: 18,
    fontWeight: "bold",
  }
}));
