import { useStyle } from "./selection-drop.style";
import { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import cn from "classnames";

type Props = {
  options: any;
  checkboxClick?: any;
};
const SelectionDropDown: React.FC<Props> = ({ options, checkboxClick }) => {
  const classes = useStyle();
  const ref: any = useRef();
  const [isDrop, setIsDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setIsDrop(false);
    });
  }, []);

  const isDropdown = () => {
    setIsDrop(!isDrop);
  };

  const [crit, setCrit] = useState<string>("SELECT");
  const setCritDropdown = (crit: any) => {
    crit.is_special ? setCrit(`${crit.criteria_name} ${crit.is_special && "*"}`) : setCrit(crit.criteria_name);
  };

  const isDisable = (criteria: any, index: number) => {
    if (criteria.status === "NOT_STARTED") {
      if (index === 0) {
        return false;
      } else {
        return true;
      }
    } else if (criteria.status === "DONE") {
      return true;
    }
  };

  const optionClick = (criteria: any, index: number) => {
    if (isDisable(criteria, index)) {
      isDropdown();
    } else {
      checkboxClick(criteria);
      isDropdown();
      setCritDropdown(criteria);
    }
  };

  return (
    <Box display="flex" position="relative">
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          fontWeight={600}
          onClick={isDropdown}
        >
          <Box
            width="180px"
            className={classes.select}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <p className={classes.textOne}>{crit}</p>
            <Icon className={classes.arrowIcon}>
              {isDrop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
            </Icon>
          </Box>
        </Box>
        <Box className={isDrop ? classes.dropContainer : classes.displayNone}>
          <div>
            {options.map((criteria: any, index: number) => (
              <Box
                className={cn(
                  classes.root,
                  index !== options.length - 1 ? classes.itemBorder : "",
                  isDisable(criteria, index) === true ? "" : classes.activeBg
                )}
                onClick={() => optionClick(criteria, index)}
              >
                <Box
                  padding="10px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    className={cn(
                      isDisable(criteria, index) === true
                        ? classes.disableStyle
                        : classes.activeStyle
                    )}
                  >
                    {criteria.criteria_name} {criteria.is_special && `*`}
                  </div>
                  {criteria.status === "DONE" ? (
                    <Icon className={classes.checkIcon}>check_circle_icon</Icon>
                  ) : null}
                </Box>
              </Box>
            ))}
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default SelectionDropDown;
