import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import { useStyle } from "./consents-tab.style";
import Consent from "./consent";
import request from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader/index";
import { ConfirmBox } from "../../../components/common/confirm-box";

type Props = {
  applicationID: string;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface ConsentType {
  term: string;
  cob: string;
  abp: string;
  abs: string;
  pp: string;
  at: string;
  // aup: string;
  dupc: string;
}

interface PostConsentType {
  id: string;
  consents: ConsentType;
}

const ConsentsTab: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [show, setShow] = useState<string>("term");
  const [active, setActive] = useState<string>("term");
  const [nextTab, setNextTab] = useState<string>("");
  const [saved, setSaved] = useState<boolean>(true);
  const default_state = {
    term: "",
    cob: "",
    abp: "",
    abs: "",
    pp: "",
    at: "",
    // aup: "",
    dupc: "",
  };
  const defaultErrState = {
    term_err: false,
    cob_err: false,
    abp_err: false,
    abs_err: false,
    pp_err: false,
    at_err: false,
    // aup_err: false,
    dupc_err: false,
  };
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [validError, setValidError] = useState(defaultErrState);
  const [consents, setConsents] = useState<ConsentType>(default_state);
  const [loader, setLoader] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(1);

  const getConsents = async (id: string): Promise<any> => {
    const res = await request({
      url: `school-settings/application-consent-details/?application_uuid=${id}`,
      method: "get",
    });
    return res;
  };

  const postConsents = async ({
    id,
    consents,
  }: PostConsentType): Promise<any> => {
    setLoader(true);
    const res = await request({
      url: `/school-settings/application-consent-details/?application_uuid=${id}`,
      method: "put",
      data: {
        terms_consent: consents.term,
        code_of_behavior_consent: consents.cob,
        anti_bullying_parent_consent: consents.abp,
        anti_bullying_student_consent: consents.abs,
        picture_permission_consent: consents.pp,
        assessment_testing_consent: consents.at,
        // acceptable_use_policy: consents.aup,
        document_upload_page_consent: consents.dupc,
      },
    });
    if (res) {
      setLoader(false);
      readyToSwitch(true);
    }
    return res;
  };

  const readyToSwitch = (val: boolean) => {
    setSaved(val);
    ready(val);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    refetch,
    error: getConsentsError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["consent", applicationID, selectedGlobalDate],
    () => getConsents(applicationID),
    {
      select: (consent) => consent.data,
    }
  );

  const mutation: UseMutationResult<any, Error, PostConsentType> = useMutation(
    async ({ id, consents }) => postConsents({ id, consents })
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setConsents({
          ...consents,
          term: data?.results?.terms_consent || "",
          cob: data?.results?.code_of_behavior_consent || "",
          abp: data?.results?.anti_bullying_parent_consent || "",
          abs: data?.results?.anti_bullying_student_consent || "",
          pp: data?.results?.picture_permission_consent || "",
          at: data?.results?.assessment_testing_consent || "",
          // aup: data?.results?.acceptable_use_policy || "",
          dupc: data?.results?.document_upload_page_consent || "",
        });
      } else {
        setConsents(default_state);
      }
    }
  }, [data, refresh]);

  const handleTabChange = (value: string) => {
    setNextTab(value);
    if (saved) {
      setRefresh(refresh + 1);
      refetch();
      setShow(value);
      setActive(value);
    } else {
      setOpenConfirm(true);
    }
  };

  const changeTab = () => {
    setRefresh(refresh - 1);
    refetch();
    setShow(nextTab);
    setActive(nextTab);
    setSaved(true);
    ready(true);
  };

  const resetError = () => {
    setValidError(defaultErrState);
  };

  const validate = () => {
    return {
      term_err: consents.term ? consents.term.length === 0 : true,
      cob_err: consents.cob ? consents.cob.length === 0 : true,
      abp_err: consents.abp ? consents.abp.length === 0 : true,
      abs_err: consents.abs ? consents.abs.length === 0 : true,
      pp_err: consents.pp ? consents.pp.length === 0 : true,
      at_err: consents.at ? consents.at.length === 0 : true,
      // aup_err: consents.aup ? consents.aup.length === 0 : true,
      dupc_err: consents.dupc ? consents.dupc.length === 0 : true,
    };
  };
  const onSaveClick = (event: string) => {
    const err = validate();
    setValidError(err);
    if (event.length !== 0) {
      mutation.mutate({ id: applicationID, consents });
    }
  };
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getConsentsError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <>
          <div style={{ margin: "30px 0" }}>
            <div style={{ width: "45vw" }}>
              {show === "term" ? (
                <Consent
                  id="term"
                  label="Terms"
                  value={consents.term}
                  onChange={(e) => {
                    setConsents({ ...consents, term: e.target.value });
                    setValidError({
                      ...validError,
                      term_err: e.target.value.length === 0,
                    });
                    readyToSwitch(false);
                  }}
                  save={onSaveClick}
                  error={validError.term_err}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              ) : null}
              {show === "cob" ? (
                <Consent
                  id="cob"
                  label="Code of Behaviour"
                  value={consents.cob}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, cob: e.target.value });
                    setValidError({
                      ...validError,
                      cob_err: e.target.value.length === 0,
                    });
                  }}
                  save={(e: any) => onSaveClick(consents.cob)}
                  error={validError.cob_err}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              ) : null}
              {show === "abp" ? (
                <Consent
                  id="abp"
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  label="Anti-Bullying Parent"
                  value={consents.abp}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, abp: e.target.value });
                    setValidError({
                      ...validError,
                      abp_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.abp_err}
                  save={onSaveClick}
                />
              ) : null}
              {show === "abs" ? (
                <Consent
                  id="abs"
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  label="Anti-Bullying Student"
                  value={consents.abs}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, abs: e.target.value });
                    setValidError({
                      ...validError,
                      abs_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.abs_err}
                  save={onSaveClick}
                />
              ) : null}
              {show === "pp" ? (
                <Consent
                  id="pp"
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  label="Picture Permissions"
                  value={consents.pp}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, pp: e.target.value });
                    setValidError({
                      ...validError,
                      pp_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.pp_err}
                  save={onSaveClick}
                />
              ) : null}
              {show === "at" ? (
                <Consent
                  id="at"
                  label="Assesment Testing"
                  value={consents.at}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, at: e.target.value });
                    setValidError({
                      ...validError,
                      at_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.at_err}
                  save={onSaveClick}
                />
              ) : null}
              {/* {show === "aup" ? (
                <Consent
                  id="aup"
                  label="Acceptable Use Policy"
                  value={consents.aup}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, aup: e.target.value });
                    setValidError({
                      ...validError,
                      aup_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.aup_err}
                  save={onSaveClick}
                />
              ) : null} */}
              {show === "dupc" ? (
                <Consent
                  id="dupc"
                  label="Document Upload Page Consent"
                  value={consents.dupc}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                  onChange={(e) => {
                    readyToSwitch(false);
                    setConsents({ ...consents, dupc: e.target.value });
                    setValidError({
                      ...validError,
                      dupc_err: e.target.value.length === 0,
                    });
                  }}
                  error={validError.dupc_err}
                  save={onSaveClick}
                />
              ) : null}
            </div>
          </div>
          <div>
            <div className={classes.tab}>
              <button
                className={cn(
                  classes.tabButton,
                  active === "term" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("term");
                  resetError();
                }}
              >
                Terms
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "cob" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("cob");
                  resetError();
                }}
              >
                Code Of Behaviour
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "abp" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("abp");
                  resetError();
                }}
              >
                Anti-Bullying Parent
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "abs" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("abs");
                  resetError();
                }}
              >
                Anti-Bullying Student
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "pp" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("pp");
                  resetError();
                }}
              >
                Picture Permissions
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "at" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("at");
                  resetError();
                }}
              >
                Assesment Testing
              </button>
              {/* <button
                className={cn(
                  classes.tabButton,
                  active === "aup" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("aup");
                  resetError();
                }}
              >
                Acceptable Use Policy
              </button> */}
              <button
                className={cn(
                  classes.tabButton,
                  active === "dupc" ? classes.tabActive : null
                )}
                onClick={() => {
                  handleTabChange("dupc");
                  resetError();
                }}
              >
                Document Upload Page Consent
              </button>
            </div>
          </div>
          <ConfirmBox
            message={`You have unsaved data. Do you want to continue without saving?`}
            onClick={changeTab}
            open={openConfirm}
            setOpen={setOpenConfirm}
          />
        </>
      ) : null}
    </Grid>
  );
};

export default ConsentsTab;
