import { useEffect, useState } from "react";
import { useStyle } from "./student-details.style";
import { Box, Tabs, Tab } from "@material-ui/core";
import StudentDetails from "./student-details";
import Documents from "./documents";
import Communication from "./comunication";
import ApplicationTrial from "./application-trail";
import GuardianDetails from "./guardian-details";
import MedInfoView from "../../enrollment/single-enrollment/med-info-view";
import EduHistView from "../../enrollment/single-enrollment/edu-hist-view";
import CriteriaDetails from "./criteriaDetails";
import Consents from "./consents";
import Icon from "@material-ui/core/Icon";
import { useHistory, useParams } from "react-router-dom";
import request from "../../../../utils/authUtil";
import requestv2 from "../../../../utils/authUtilv2";
import { AdminButton } from "../../../../components/common/admin-button/index";
import { Loader } from "../../../../components/common/loader";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import Checkbox from "@material-ui/core/Checkbox";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import ASDInfo from "./asd-info";

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyle();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.container}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const appReviewOptions = [
  { name: "--Not Reviewed--", value: "" },
  { name: "Reviewed", value: "REVIEWED" },
  { name: "Pending", value: "PENDING" },
];

const StudentEditInfo: React.FC<Props> = () => {
  const classes = useStyle();
  const [value, setValue] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [openOne, setOpenOne] = useState<boolean>(false);
  const [isLateApplication, setIsLateApplication] = useState<boolean>(false);
  const [showAcceptOffer, setShowAcceptOffer] = useState<boolean>(false);
  const [considerInSelection, setConsiderInSelection] =
    useState<boolean>(false);
  const history = useHistory();
  const school_uuid = localStorage.getItem("school-id");
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const { applicationId } = useParams<{ applicationId: string }>();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [studData, setStudData] = useState<any>({});
  const [appStatus, setAppStatus] = useState<string>("");
  const [revPopup, setRevPopup] = useState<boolean>(false);
  const [currentAppReviewStatus, setCurrentAppReviewStatus] = useState<string>(
    appReviewOptions[0].value
  );
  const [updatedAppReviewStatus, setUpdatedAppReviewStatus] =
    useState<string>("");

  const getApplication = async (): Promise<any> => {
    await request({
      url: `/school-settings/school-application/?school_uuid=${school_uuid}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    }).then((res) => {
      getRelDate(res.data?.results[0].application_uuid);
    });
  };

  const getRelDate = async (id: string): Promise<any> => {
    await request({
      url: `school-settings/application-dates-details/?application_uuid=${id}`,
      method: "get",
    }).then((res) => {
      const completedByDate = new Date(res.data.results.completed_by_date);
      const currentDate = new Date();
      const va = completedByDate.getTime() < currentDate.getTime();
      setShowAcceptOffer(va);
    });
  };

  const acceptOffer = () => {
    setLoader(true);
    request({
      url: `school-settings/application-offer-accept/`,
      method: "put",
      data: {
        student_app_uuid: applicationId,
        application_status: "ACCEPT_OFFER",
      },
    }).then((res) => {
      getStudentData();
      setLoader(false);
    });
  };

  const getLateApplication = async (): Promise<any> => {
    setLoader(true);
    await request({
      url: `school-settings/student-application/?school_uuid=${school_uuid}&student_app_uuid=${applicationId}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data.status_code === 1) {
        setIsLateApplication(
          res.data.results.Student_Details.is_late_application
        );
        setConsiderInSelection(
          res.data.results.Student_Details.is_consider_in_selection
        );
      } else if (res.data.status_code === -1) {
        setLoader(false);
      }
    });
  };

  const selectLateApplication = async () => {
    setConsiderInSelection(!considerInSelection);
    setLoader(true);
    await request({
      url: `/school-settings/application-consider-selection/`,
      method: "put",
      data: {
        student_app_uuid: applicationId,
        is_consider_in_selection: !considerInSelection,
      },
    }).then((res) => {
      setLoader(false);
    });
  };

  const getStudentData = async (): Promise<any> => {
    await request({
      url: `school-settings/student-application/?school_uuid=${school_uuid}&student_app_uuid=${applicationId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setStudData(res.data.results.Student_Details);
        setAppStatus(res.data.results.Student_Details.application_status);
        setCurrentAppReviewStatus(
          res.data.results.Student_Details.application_reviewed_status
        );
      }
    });
  };

  const downloadPdf = () => {
    setLoader(true);
    request({
      url: `school-settings/student-application-pdf/?student_app_uuid=${applicationId}`,
      method: "get",
    }).then((response) => {
      if (response.data.results) {
        window.open(response.data.results.document_presingne_url, "_parent");
        setLoader(false);
      }
    });
  };

  const markAsReviewed = () => {
    setLoader(true);
    requestv2({
      url: `school-settings/mark-student-application-reviewed/?stu_app_uuid=${applicationId}`,
      method: "put",
      data: {
        application_reviewed_status: updatedAppReviewStatus,
      },
    }).then((res) => {
      if (res.data.status_code === 1) {
        setCurrentAppReviewStatus(updatedAppReviewStatus);
        setLoader(false);
      }
    });
  };

  const onChange = (e: any) => {
    if (e.target.value !== "") {
      setRevPopup(true);
      setUpdatedAppReviewStatus(e.target.value);
    }
  };

  useEffect(() => {
    getStudentData();
    getLateApplication();
    getApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box marginRight="40px">
      <Loader open={loader} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          background: "#F9F8F7",
          padding: "16px",
          height: "235px",
        }}
      >
        <Box>
          <Icon
            style={{ cursor: "pointer", paddingTop: "12px" }}
            onClick={() => {
              history.push("/admin/applications");
            }}
          >
            arrow_back
          </Icon>
          <p>
            {studData.first_name} {studData.last_name}
          </p>
          <p>
            Application ID : <b>{studData?.application_id}</b>
          </p>
          <p>
            Application Status : <b>{appStatus}</b>
          </p>
          {showAcceptOffer &&
            localStorage.getItem("employee_type") === "Super Admin" &&
            currentGlobalDate === selectedGlobalDate &&
            appStatus === "Offered" && (
              <p>
                <AdminButton
                  label="Accept Offer"
                  onClick={() => setOpenOne(true)}
                />
              </p>
            )}
          <Box
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              width: "210px",
            }}
          >
            <p>Application Review Status</p>
            <SelectDropDown
              value={currentAppReviewStatus}
              onChange={onChange}
              item={appReviewOptions}
            />
          </Box>
        </Box>
        <Box>
          <div style={{ width: "200px" }}>
            <AdminButton
              startIcon="download_icon"
              label="Download Pdf"
              onClick={downloadPdf}
            />
          </div>
          <p>
            Late Application :<b>{isLateApplication ? "Yes" : "No"}</b>
          </p>
          {studData.waiting_list_number && (
            <p>
              Waiting List Number :<b>{studData.waiting_list_number}</b>
            </p>
          )}
          {studData.waiting_list_position && (
            <p>
              Waiting List Position :<b>{studData.waiting_list_position}</b>
            </p>
          )}
          {isLateApplication && (
            <Box display="flex" alignItems="center">
              <p>Consider for Selection</p>
              <Checkbox
                color="default"
                disabled={
                  selectedGlobalDate !== currentGlobalDate ||
                  localStorage.getItem("employee_type") !== "Super Admin"
                }
                onChange={selectLateApplication}
                checked={considerInSelection}
              />
            </Box>
          )}
        </Box>
      </Box>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="desktop"
          aria-label="scrollable auto tabs example"
          style={{ marginLeft: "-40px", marginRight: "-40px" }}
        >
          <Tab
            className={classes.tab}
            label="Student Information"
            {...a11yProps(0)}
          />
          <Tab className={classes.tab} label="Guardian" {...a11yProps(1)} />
          <Tab className={classes.tab} label="Criteria" {...a11yProps(2)} />
          <Tab
          className={classes.tab}
          label="ASD Info"
          {...a11yProps(3)}
          />
          <Tab
            className={classes.tab}
            label="Educational Details"
            {...a11yProps(4)}
          />
          <Tab
            className={classes.tab}
            label="Medical Details"
            {...a11yProps(5)}
          />
          <Tab className={classes.tab} label="Consents" {...a11yProps(6)} />
          <Tab
            className={classes.tab}
            label="Communications"
            {...a11yProps(7)}
          />
          <Tab className={classes.tab} label="Documents" {...a11yProps(8)} />
          <Tab
            className={classes.tab}
            label="Application Trail"
            {...a11yProps(9)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <StudentDetails
            applicationID={applicationId}
            UIType="application"
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GuardianDetails
            applicationID={applicationId}
            UIType="application"
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CriteriaDetails
            applicationID={applicationId}
            UIType="application"
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ASDInfo applicationID={applicationId}/>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <EduHistView
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <MedInfoView
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Consents applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Communication applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <Documents applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <ApplicationTrial applicationID={applicationId} />
        </TabPanel>
      </div>
      <ConfirmBox
        open={openOne}
        setOpen={setOpenOne}
        onClick={acceptOffer}
        arg={true}
        message={`Are you sure you want to accept the offer?`}
      />
      <ConfirmBox
        open={revPopup}
        setOpen={setRevPopup}
        onClick={markAsReviewed}
        arg={true}
        message={`Do you want to mark the application as ${updatedAppReviewStatus.toLowerCase()} ?`}
      />
    </Box>
  );
};

export default StudentEditInfo;
