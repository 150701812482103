import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useStyle } from "../application/application.style";
import Header from "../header/index";
import { Footer } from "../../../components/common/footer/index";
import { useHistory } from "react-router-dom";
import Permissions from "./permissions/index";
import MedInfoEnrollment from "./med-info/index";
import MedicalHistory from "./med-history/index";
import FileUpload from "./file-upload/index";
import EducationNeeds from "./edu-needs";
import EducationOffer from "./edu-needs-offer";
import AntiBullying from "./anti-bullying";
import ConfirmEnrollment from "./confirm/index";
import ThankyouEnrol from "./thankyou-enroll/index";
import { CheckNetwork } from "../../../utils/helper";
import { LanguagePreference } from "./language-preference";
import { SubjectChoice } from "./subject-choice";
import PrevSchoolConsent from "./prev-school-consent";
import request from "../../../utils/authUtil";
import { EmergencyContactEnrollment } from "./emergency-contact-enrollment";
import MotherTongueEthnicity from "./mother-tongue-ethnicity";
import requestv2 from "../../../utils/authUtilv2";
import DocumentInfo from "./document-upload";
import StudentBirthCertificate from "../application/student-details/student-birth-certificate";
import PhotoUpload from "./photo-upload";

type EnrollmentProps = {};

const Enrollment = (props: EnrollmentProps) => {
  const classes = useStyle();
  let { appId } = useParams<{ appId: string }>();
  const schoolID = localStorage.getItem("school-id");
  const history = useHistory();
  const [active, setActive] = useState<string>("conf");
  const [showPrevSchoolConsent, setShowPrevSchoolConsent] =
    useState<boolean>(false);
  const [showEmergencyContact, setShowEmergencyContact] =
    useState<boolean>(false);
  const [showMotherTongueEthnicity, setShowMotherTongueEthnicity] =
    useState<boolean>(false);
  const stuAppId = localStorage.getItem("currentStudentAppID");
  const [showDoc, setShowDoc] = useState<boolean>(false);
  const [languagesDescription, setLanguagesDescription] = useState<string>("");
  const [ subjectsDescription, setSubjectsDescription] = useState<string>("");
  const [ showBirthCertificatePage, setShowBirthCertificatePage ] = useState<boolean>(false);
  const [ showPreviousSchoolReport, setShowPreviousSchoolReport ] = useState<boolean>(false);
  const [showPhotoUpload, setShowPhotoUpload] = useState<boolean>(false);

  const [nextHelp, setNextHelp] = useState({
    eduo: "lp",
    lp: "sc",
    sc: "abp",
  });
  const [prevHelp, setPrevHelp] = useState({
    lp: "eduo",
    sc: "lp",
    abp: "sc",
  });
  const getCustomSettings = () => {
    // setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setShowBirthCertificatePage(
        res.data.results.is_birth_certificate &&
        !res.data.results.birth_certificate_in_application
      );
      setShowPreviousSchoolReport(
        res.data.results.is_previous_school_report &&
        !res.data.results.previous_school_report_in_application
      );
      setShowPhotoUpload(
        res.data.results.is_enrol_photo_optional &&
        !res.data.results.photo_in_application
      )

      setLanguagesDescription(res?.data?.results?.language_description)
      setSubjectsDescription(res?.data?.results?.subject_description)
      setShowMotherTongueEthnicity(
        res?.data?.results?.is_show_mother_tongue_ethnicity &&
        !res?.data?.results?.mother_tongue_ethnicity_in_application
      );
      setShowPrevSchoolConsent(
        res.data.results.contact_previous_school &&
        !res.data.results.contact_previous_school_in_application
      );
      setShowEmergencyContact(
        res.data.results.is_show_emergency_contact &&
        !res.data.results.show_emergency_contact_in_application
      );

      if (res.data.results.allow_language && res.data.results.allow_subjects) {
        setNextHelp({
          eduo: "lp",
          lp: "sc",
          sc: "abp",
        });
        setPrevHelp({
          lp: "eduo",
          sc: "lp",
          abp: "sc",
        });
      }
      if (!res.data.results.allow_language && res.data.results.allow_subjects) {
        setNextHelp({
          eduo: "sc",
          lp: "",
          sc: "abp",
        });
        setPrevHelp({
          lp: "",
          sc: "eduo",
          abp: "sc",
        });
      }
      if (res.data.results.allow_language && !res.data.results.allow_subjects) {
        setNextHelp({
          eduo: "lp",
          lp: "abp",
          sc: "",
        });
        setPrevHelp({
          lp: "eduo",
          sc: "",
          abp: "lp",
        });
      }
      if (
        !res.data.results.allow_language &&
        !res.data.results.allow_subjects
      ) {
        setNextHelp({
          eduo: "abp",
          lp: "",
          sc: "",
        });
        setPrevHelp({
          lp: "",
          sc: "",
          abp: "eduo",
        });
      }
      // setLoader(false);
    });
  };

  useEffect(() => {
    requestv2({
      url: `student-application/check-form-type/?school_uuid=${schoolID}&stu_app_uuid=${stuAppId}&type_of_form=Enrolment`,
      method: "get",
    }).then((res) => {
      setShowDoc(res.data.results);
      // setShowDoc(true)
    });
  }, []);

  useEffect(() => {
    getCustomSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <CheckNetwork />
      <Header />
      {active === "conf" ? (
        <ConfirmEnrollment
          prevPage={() => history.push("/parent/enrolment")}
          nextPage={() => {
            showMotherTongueEthnicity ? setActive("mteth") :
              showBirthCertificatePage ? setActive("sbc") :
              showPhotoUpload ? setActive("photou") :
                showPreviousSchoolReport ? setActive("fileu") :
                  showDoc ? setActive("doc") : setActive("perm")
          }
          }
          stdAppId={appId}
        />
      ) : null}
      {showMotherTongueEthnicity && active === "mteth" ? (
        <MotherTongueEthnicity
          prevPage={() => setActive("conf")}
          nextPage={() => {
            showBirthCertificatePage ? setActive("sbc") :
            showPhotoUpload ? setActive("photou") :
              showPreviousSchoolReport ? setActive("fileu") :
                showDoc ? setActive("doc") : setActive("perm")
          }
          }
          stdAppId={appId}
        />
      ) : null}
      {showBirthCertificatePage && active === "sbc" ? (
        <StudentBirthCertificate
          prevPage={() => { showMotherTongueEthnicity ? setActive("mteth") : setActive("conf") }}
          nextPage={() => {
            showPhotoUpload ? setActive("photou") :
            showPreviousSchoolReport ? setActive("fileu") :
              showDoc ? setActive("doc") :
                setActive("perm")
          }}
          stdAppId={appId}
        />
      )
        :
        null
      }
      {showPhotoUpload && active === "photou" &&
        <PhotoUpload
        prevPage={() => {
          showBirthCertificatePage ? setActive("sbc") :
            showMotherTongueEthnicity ? setActive("mteth") : setActive("conf")
        }
        }
        nextPage={() => {
          showPreviousSchoolReport ? setActive("fileu") :
          showDoc ? setActive("doc") :
            setActive("perm")
        }}
        stdAppId={appId}
        />
      }
      {showPreviousSchoolReport && active === "fileu" ? (
        <FileUpload
          prevPage={() => {
            showPhotoUpload ? setActive("photou") :
            showBirthCertificatePage ? setActive("sbc") :
              showMotherTongueEthnicity ? setActive("mteth") : setActive("conf")
          }
          }
          nextPage={() => {
            showDoc ? setActive("doc") : setActive("perm");
          }}
          stdAppId={appId}
        />
      ) : null}
      {showDoc && active === "doc" ? (
        <DocumentInfo
          prevPage={() => {
            showPreviousSchoolReport ? setActive("fileu") :
            showPhotoUpload ? setActive("photou") :
              showBirthCertificatePage ? setActive("sbc") :
                showMotherTongueEthnicity ? setActive("mteth") : setActive("conf")
          }
          }
          nextPage={() => {
            setActive("perm");
          }}
          stdAppId={appId}
        />
      ) : null}
      {active === "perm" ? (
        <Permissions
          prevPage={() => {
            showDoc ? setActive("doc") :
              showPreviousSchoolReport ? setActive("fileu") :
              showPhotoUpload ? setActive("photou") :
                showBirthCertificatePage ? setActive("sbc") :
                  showMotherTongueEthnicity ? setActive("mteth") : setActive("conf")
          }}
          nextPage={() => {
            showPrevSchoolConsent
              ? setActive("psc")
              : showEmergencyContact
                ? setActive("ec")
                : setActive("medinfo");
          }}
          stdAppId={appId}
        />
      ) : null}
      {showPrevSchoolConsent && active === "psc" ? (
        <PrevSchoolConsent
          prevPage={() => setActive("perm")}
          nextPage={() => {
            showEmergencyContact ? setActive("ec") : setActive("medinfo");
          }}
          stdAppId={appId}
        />
      ) : null}
      {showEmergencyContact && active === "ec" ? (
        <EmergencyContactEnrollment
          prevPage={() => {
            showPrevSchoolConsent ? setActive("psc") : setActive("perm");
          }}
          nextPage={() => setActive("medinfo")}
          stdAppId={appId}
        />
      ) : null}
      {active === "medinfo" ? (
        <MedInfoEnrollment
          prevPage={() => {
            showEmergencyContact
              ? setActive("ec")
              : showPrevSchoolConsent
                ? setActive("psc")
                : setActive("perm");
          }}
          nextPage={() => setActive("medhist")}
          stdAppId={appId}
        />
      ) : null}
      {active === "medhist" ? (
        <MedicalHistory
          prevPage={() => {
            showEmergencyContact ? setActive("ec") : setActive("medinfo");
          }}
          nextPage={() => setActive("edun")}
          stdAppId={appId}
        />
      ) : null}

      {active === "edun" ? (
        <EducationNeeds
          prevPage={() => setActive("medhist")}
          nextPage={() => setActive("eduo")}
          stdAppId={appId}
        />
      ) : null}
      {active === "eduo" ? (
        <EducationOffer
          prevPage={() => setActive("edun")}
          nextPage={() => setActive(nextHelp.eduo)}
          stdAppId={appId}
        />
      ) : null}
      {active === "lp" ? (
        <LanguagePreference
          languagesDescription={languagesDescription}
          prevPage={() => setActive(prevHelp.lp)}
          nextPage={() => setActive(nextHelp.lp)}
          stdAppId={appId}
        />
      ) : null}
      {active === "sc" ? (
        <SubjectChoice
          subjectsDescription={subjectsDescription}
          prevPage={() => setActive(prevHelp.sc)}
          nextPage={() => setActive(nextHelp.sc)}
          stdAppId={appId}
        />
      ) : null}
      {active === "abp" ? (
        <AntiBullying
          prevPage={() => setActive(prevHelp.abp)}
          nextPage={() => setActive("thanku")}
          stdAppId={appId}
        />
      ) : null}
      {active === "thanku" ? <ThankyouEnrol /> : null}

      <Box mr="23px" ml="23px">
        <Footer center />
      </Box>
    </Grid>
  );
};

export default Enrollment;
