import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useMutation } from "react-query";

import { Text } from "../../../components/common/text/index";
import { Button } from "../../../components/common/button/index";
import { Input } from "../../../components/common/input/index";
import Logo from "../../../components/common/logo/index";
import { Loader } from "../../../components/common/loader";
import { Footer } from "../../../components/common/footer/index";
import request, { setRefreshToken, setToken } from "../../../utils/authUtil";
import { CheckNetwork } from "../../../utils/helper";
import { useStyle } from "./admin-login.style";

const AdminLogin = () => {
  interface UserData {
    userName: string;
    password: string;
  }

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [validError, setValidError] = useState({
    usernameError: false,
    emailAcceptError: false,
    passwordError: false,
  });

  const validate = () => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    return {
      usernameError: userName.length === 0,
      emailAcceptError: !emailRegex.test(userName),
      passwordError: password.length === 0,
    };
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "email":
        setUserName(event.target.value);
        setValidError({
          ...validError,
          usernameError: event.target.value.length === 0,
        });
        break;
      case "password":
        setPassword(event.target.value);
        setValidError({
          ...validError,
          passwordError: event.target.value.length === 0,
        });
    }
  };

  const adminLogin = (loginInfo: UserData) =>
    request({
      url: "/auth/school-employee-login/",
      method: "post",
      data: {
        email: loginInfo.userName,
        password: loginInfo.password,
        device_type: "web",
      },
    });

  const loginMutation = useMutation(adminLogin, {
    onSuccess: (res) => {
      setError("");
      setLoader(false);
      if (res.data.results.access_token) {
        setToken(res.data.results.access_token);
        setRefreshToken(res.data.results.refresh_token);
        localStorage.setItem("type", res.data.results.user_details.user_type);
        localStorage.setItem(
          "employee_type",
          res.data.results.user_details.employee_type
        );
        window.location.reload();
      } else {
        setLoader(false);
        setError(res.data.status_message);
      }
    },
    onError: (res) => {
      setError(JSON.stringify(res));
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const err = validate();
    setValidError(err);
    if (
      err.passwordError === false &&
      err.usernameError === false &&
      err.emailAcceptError === false
    ) {
      // @ts-ignore
      loginMutation.mutate({ userName, password });
    }
  };

  const classes = useStyle();
  return (
    <>
      <Loader open={loader} />
      <Grid
        className={classes.root}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div>
            <CheckNetwork />
            <Logo />
          </div>
        </Grid>
        <Grid container justifyContent="center">
          <div className={classes.card}>
            <div style={{ marginBottom: 34 }}>
              <Text heading>Admin Login</Text>
            </div>
            {/* error msg */}
            <p
              style={{
                margin: 10,
                color: "#EB5757",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {error}
            </p>
            <form>
              <Grid container direction="column">
                <Input
                  label="Email*"
                  id="userName"
                  value={userName}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => onValueChange(e, "email")}
                  error={
                    validError.usernameError || validError.emailAcceptError
                  }
                  errorMessage={
                    validError.usernameError
                      ? "Required"
                      : "Please enter valid email ID"
                  }
                />
                <Input
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> };
                  }) => onValueChange(e, "password")}
                  error={validError.passwordError}
                />
                <Box display="flex" alignItems="center" mt="-25px" mb="50px">
                  <Link
                    to="/admin/forgot-password"
                    style={{ textDecoration: "none" }}
                    className={classes.btnLink}
                  >
                    <u>Forgot Password</u>
                    <Icon style={{ fontSize: "15px" }}>call_made_icon</Icon>
                  </Link>
                </Box>
              </Grid>
              <Box display="flex" justifyContent="center">
                <Box mr="30px" width="177px">
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Button
                      label="Back"
                      light
                      startIcon="arrow_back_ios"
                      iconSize="13px"
                    />
                  </Link>
                </Box>
                <Box width="177px">
                  <Link to="/admin/" style={{ textDecoration: "none" }}>
                    <Button
                      label="Login"
                      type="submit"
                      onClick={handleSubmit}
                    />
                  </Link>
                </Box>
              </Box>
            </form>
          </div>
        </Grid>
        <Grid container>
          <div style={{ width: "100vw" }}>
            <Footer center />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminLogin;
