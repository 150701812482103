import { useState, useEffect } from "react";
import { useStyle } from "./subject-choice.style";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Loader } from "../../../../components/common/loader/index";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputLabel from "@material-ui/core/InputLabel";
import request from "../../../../utils/authUtil";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
  subjectsDescription: string;
};

export const SubjectChoice = (props: Props) => {
  const styles = useStyle();
  const schoolID = localStorage.getItem("school-id");
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    studData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLanguages = () => {
    request({
      url: `student-enrolment/student-enrol-subjects/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      const tmp: any = [];
      res.data.results.map((lang: any, index: any) =>
        tmp.push({
          id: `${index}`,
          label: lang.subject_name,
        })
      );
      setItems(tmp);
    });
  };

  const studData = () => {
    request({
      url: `student-enrolment/student-subject-preference/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length === 0) {
        getLanguages();
      } else {
        const tmp: any = [];
        res.data.results.map(
          (lang: any) =>
            (tmp[lang.preference_number - 1] = {
              id: lang.uuid,
              label: lang.subject,
            })
        );
        setItems(tmp);
      }
    });
  };

  const onSubmit = () => {
    postLang();
  };

  const postLang = () => {
    const data: any = [];
    items.map((lang: any, index: any) =>
      data.push({
        subject: lang.label,
        preference_number: index + 1,
        uuid: lang.id,
      })
    );
    request({
      url: `student-enrolment/student-subject-preference/?student_app_uuid=${stdAppId}`,
      method: "put",
      data: data,
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLoader(false);
        nextPage();
      } else {
        setLoader(false);
      }
    });
  };
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const temp: any = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(temp);
  };
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Enrolment</div>
              </div>
              <ProgressBar total={7} steps={6} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Subject Choices"
                description="Next: Anti-Bullying Policy"
              />
            </div>
            <p className={styles.title}>Description</p>
            <div className={styles.description}>
              <p>{props.subjectsDescription}</p>
            </div>
            <br/>
            <p className={styles.title}>Subject options</p>
            <InputLabel className={styles.label}>
              Drag the blocks below, in order of preference, the subjects your
              student/child wishes to study
            </InputLabel>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item: any, index: any) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className={styles.langItem}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <p>{item.label}</p>
                            <Icon className={styles.icon}>more_vert_icon</Icon>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSubmit}
                    />
                  </div>
                </Grid>
              </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
