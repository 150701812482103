import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  root: {
    // width: "96%",
    textTransform: "none",
    borderRadius: 4,
    // color: theme.palette.text.primary,
    padding: "16px 9px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    outline: "none",
    fontFamily: `"LFT Etica", sans-serif`,
    background: theme.palette.common.white,
    fontSize: "16px",
  },
  fontColor: {
    color: theme.palette.text.primary,
  },
  disabledFontColor: {
    color: theme.palette.grey[500],
  },
  input: {
    padding: 0,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  focused: {
    border: "1px solid",
    borderColor: theme.palette.common.black,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  calender_icon: {
    position: "absolute",
    top: "50%",
    marginTop: "-12px",
    right: "20px",
    fontSize: "24px",
    cursor: "pointer",
    color: "#757575",
  },
  disabled_calender_icon: {
    position: "absolute",
    top: "50%",
    marginTop: "-12px",
    right: "20px",
    fontSize: "24px",
    color: "#757575",
  },
}));
