import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  title:{
    marginTop:10
  },
  dialogActions: {
    margin:"0 auto",
    paddingBottom:"15px"
  },
  error:{
    color:theme.palette.error.main
  }
}));
