import { useState, useEffect, useCallback } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./emergency-contact-enrollment.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Input } from "../../../../components/common/input";
import request from "../../../../utils/authUtilv2";
import { Loader } from "../../../../components/common/loader";
import { ProgressBar } from "../../../../components/common/progress-bar";
import Box from "@material-ui/core/Box/Box";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId?: string;
};

export const EmergencyContactEnrollment = (props: Props) => {
  const [contactInfo, setContactInfo] = useState({
    contact_name: "",
    contact_number: "",
  });
  const [error, setError] = useState({
    contact_name: false,
    contact_number: false,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;

  const validateEmergencyContact = () => {
    let phoneRegex = /^[0-9\b]+$/;
    return {
      contact_name: contactInfo.contact_name.length === 0,
      contact_number: !phoneRegex.test(contactInfo.contact_number),
    };
  };

  const setEmergencyContact = async () => {
    const err = validateEmergencyContact();
    setError(err);
    if (err.contact_name === false && err.contact_number === false) {
      setLoader(true);
      const res = await request({
          url: `student-application/student-emergency-contact/?stu_app_uuid=${stdAppId}`,
          method: 'put',
          data: contactInfo,
      });
      if(res.data.status_code === 1){
          setLoader(false);
          nextPage();
      }
    }
  };

    const getEmergencyContact = useCallback(async () => {
      setLoader(true);
      const res = await request({
        url: `student-application/student-emergency-contact/?stu_app_uuid=${stdAppId}`,
        method: "get",
      });
      if (Object.keys(res.data.results).length > 0) {
        setContactInfo({
          contact_name: res.data.results.contact_name,
          contact_number: res.data.results.contact_number,
        });
      }
      setLoader(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getEmergencyContact();
    }, [getEmergencyContact]);

  const onValueChange = (e: any, field: string) => {
    const t = { ...contactInfo };
    if (field === "contact_name") {
      t.contact_name = e.target.value;
      setContactInfo(t);
      setError({
        ...error,
        contact_name: e.target.value.length === 0,
      });
    } else {
      let phoneRegex = /^[0-9\b]+$/;
      t.contact_number = e.target.value;
      setContactInfo(t);
      setError({
        ...error,
        contact_number: !phoneRegex.test(e.target.value),
      });
    }
  };

  return (
    <div className={styles.container}>
      {/* part1 */}

      {/* part2 */}
      <div className={styles.bodyContainer}>
        {/* part3 */}

        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={7} steps={3} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Emergency Contact"
                description="Next: Medical Info"
              />
            </div>
            <Grid container direction="column">
              <Input
                label="Contact Name*"
                value={contactInfo.contact_name}
                onChange={(e) => {
                  onValueChange(e, "contact_name");
                }}
                error={error.contact_name}
              />
              <Input
                label="Contact Number*"
                value={contactInfo.contact_number}
                onChange={(e) => {
                  onValueChange(e, "contact_number");
                }}
                error={error.contact_number}
                errorMessage={contactInfo.contact_number.length === 0 ? "Required" : "Enter only numbers as input"}
              />
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button
                    label="Next"
                    light
                    endIcon="navigate_next"
                    onClick={setEmergencyContact}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Loader open={loader} />
      </div>
    </div>
  );
};
