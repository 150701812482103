import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  card: {
    marginTop: 52,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "80%",
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },
  buttonWidth: {
    margin: "29px 0px auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  },
  title: {
    fontWeight: 600,
    fontSize: "15px",
    marginBottom: "25px",
  },
  langItem: {
    display: "flex",
    minWidth: "43.93px",
    height: "43.93px",
    textTransform: "none",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: "15px 15px 15px 12px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.common.white,
    cursor: "all-scroll !important"
  },
  label: {
    marginBottom: 20,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.grey[300],
  },
}));
