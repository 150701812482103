import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyle } from "./loader.style";
type loaderProps = {
  open?: boolean;
};


export const Loader: React.FC<loaderProps> = ({ open = true }) => {
  const classes = useStyle();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress className={classes.loader} />
    </Backdrop>
  );
};
