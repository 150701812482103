/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useStyle } from "./education-status.style";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { AddButton } from "../add-guardian/add-button";
import EducationItem from "./edustatus";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import moment from "moment";
import { InputRadio } from "../../../../components/common/input-radio";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface addSchoolData {
  past_education_uuid: string;
  primarySchool: string;
  school_roll_number: string;
  fromDate: any;
  toDate: any;
}

const EducationStatus: React.FC<Props> = ({ nextPage, prevPage, stdAppId }) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [addSchool, setAddSchool] = useState<addSchoolData[]>([
    {
      past_education_uuid: "",
      primarySchool: "",
      school_roll_number: "",
      fromDate: null,
      toDate: null,
    },
  ]);
  const [error, setError] = useState<string[]>([]);
  const schoolID = localStorage.getItem("school-id");
  const [isRollNumber, setIsRollNumber] = useState("MANDATORY");

  useEffect(() => {
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setIsRollNumber(res.data.results.is_school_roll_number);
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-app-past-education/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((response) => {
      const temp: any = [];
      if (response.data) {
        setLoader(false);
        if (Object.keys(response?.data.results).length !== 0) {
          response.data.results.map((item: any) => {
            temp.push({
              past_education_uuid: item.past_education_uuid,
              primarySchool: item.primary_school_attended,
              school_roll_number: item.school_roll_number,
              fromDate: new Date(item.from_date),
              toDate: new Date(item.to_date),
            });
          });
          setAddSchool(temp);
        }
      } else {
        setLoader(false);
      }
    });
  }, []);

  const handleChangeRadio = () => {
    const temp = [...error];
    setCheck(!check);
    if (temp.includes("confirm-sixth")) {
      temp.splice(error.indexOf("confirm-sixth"), 1);
    }
    setError(temp);
  };

  const onSaveClick = () => {
    const temp = [...error];
    addSchool.map((item: any, index: any) => {
      if (item.primarySchool.length === 0) {
        if (!temp.includes(`school${index}`)) {
          temp.push(`school${index}`);
        }
      }
      if (index === 0 && isRollNumber === "MANDATORY") {
        if (
          item.school_roll_number === null ||
          item.school_roll_number.length === 0
        ) {
          if (!temp.includes(`roll_no${index}`)) {
            temp.push(`roll_no${index}`);
          }
        }
      }
      if (item.fromDate === null) {
        if (!temp.includes(`from${index}`)) {
          temp.push(`from${index}`);
        }
      }
      if (item.toDate === null) {
        if (!temp.includes(`to${index}`)) {
          temp.push(`to${index}`);
        }
      }
    });
    if (!check) {
      if(!temp.includes("confirm-sixth")){
        temp.push("confirm-sixth");
      }
    }
    if (temp.length !== 0) {
      setError(temp);
    } else {
      setLoader(true);
      const data = addSchool.map((item: any) => {
        return {
          past_education_uuid: item.past_education_uuid || "",
          primary_school_attended: item.primarySchool,
          school_roll_number: item.school_roll_number,
          from_date: moment(item.fromDate).format("YYYY-MM-DD"),
          to_date: moment(item.toDate).format("YYYY-MM-DD"),
        };
      });
      request({
        url: `student-application/student-app-past-education/?student_app_uuid=${stdAppId}`,
        method: "put",
        data: data,
      }).then((res) => {
        if (res.data.status_code === 1) {
          setLoader(false);
          nextPage();
        } else {
          setLoader(false);
        }
      });
    }
  };

  const addSchoolHandler = () => {
    setAddSchool([
      ...addSchool,
      {
        past_education_uuid: "",
        primarySchool: "",
        school_roll_number: "",
        fromDate: null,
        toDate: null,
      },
    ]);
  };

  const onChangeHelper = (name: string, value: any, index: any) => {
    const temp = [...error];
    if (value.length === 0) {
      setError([...error, `${name}${index}`]);
    } else {
      if (temp.includes(`${name}${index}`)) {
        temp.splice(error.indexOf(`${name}${index}`), 1);
      }
    }
    setError(temp);
  };

  const onChange = (value: any, ind: any, type: string) => {
    const xyz = [...addSchool];
    if (type === "schoolname") {
      xyz[ind].primarySchool = value;
      onChangeHelper("school", value, ind);
    }
    if (type === "school_roll_number") {
      xyz[ind].school_roll_number = value;
      onChangeHelper("roll_no", value, ind);
    }
    if (type === "from") {
      xyz[ind].fromDate = value;
      onChangeHelper("from", value, ind);
    }
    if (type === "to") {
      xyz[ind].toDate = value;
      onChangeHelper("to", value, ind);
    }
    setAddSchool(xyz);
  };

  const deleteHandler = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `student-application/student-app-past-education/?student_app_uuid=${stdAppId}`,
        method: "delete",
        data: {
          past_education_uuid: id,
        },
      }).then((response) => {
        const temp = [...addSchool];
        temp.splice(index, 1);
        setAddSchool(temp);
        setLoader(false);
      });
      setError([]);
    } else {
      const temp = [...addSchool];
      temp.splice(index, 1);
      setAddSchool(temp);
      setError([]);
    }
  };

  const DateValueCheck = (index: any) => {
    const temp: any = [...addSchool];
    if (temp[index].toDate < temp[index].fromDate) {
      temp[index].toDate = null;
    }
    setAddSchool(temp);
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Loader open={loader} />
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={4} />
            </Box>
            <Welcome title="Education status" description="Next: School info" />

            {/* form one */}
            <Grid container direction="column">
              <div>
                {addSchool.map((item: any, index: any) => {
                  return (
                    <Box mt={4}>
                      <EducationItem
                        onChange={onChange}
                        data={item}
                        index={index}
                        errorSchool={error.includes(`school${index}`)}
                        errorRoll_no={error.includes(`roll_no${index}`)}
                        errorFrom={error.includes(`from${index}`)}
                        errorTo={error.includes(`to${index}`)}
                        onDelete={deleteHandler}
                        id={item.past_education_uuid}
                        DateValueCheck={DateValueCheck}
                        showRollNumber={isRollNumber !== "HIDE"}
                        isRollNumberMandatory={isRollNumber === "MANDATORY"}
                      />
                    </Box>
                  );
                })}
                <Box width="100%" mt={10} mb={6}>
                  <AddButton
                    label="Add another school"
                    onClick={addSchoolHandler}
                  />
                </Box>
                <Box width="100%">
                  <b style={{ fontSize: '14px' }}>
                    Please confirm your child is currently in 6th class{" "}
                    <span style={{ color: "red" }}>*</span>
                  </b>
                  <InputRadio
                    radioHolderOne="I confirm"
                    onChange={handleChangeRadio}
                    check={check}
                    error={error.includes("confirm-sixth")}
                  />
                </Box>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSaveClick}
                    />
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
export default EducationStatus;
