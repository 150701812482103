import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import { useStyle } from "./status-card.style";
import { IconRounded } from "../../../components/common/icon/index";
import { Button } from "../../../components/common/button/index";
import { Text } from "../../../components/common/text/index";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import request from "../../../utils/authUtil";
import MaterialButton from "@material-ui/core/Button";
import { ConfirmBox } from "../../../components/common/confirm-box";

type StatusCardProps = {
  studentID: string;
  studentName: string;
  appStatus?: string;
  enrollStatus: string;
  review?: any;
  begin?: any;
  enrolment?: any;
  refetch?: any;
  waitingListNumber?: any;
  onClick?: any;
  setLoader?: any;
  completeDate: string;
  completeByTime: string;
};

export const StatusCard: React.FC<StatusCardProps> = ({
  studentName,
  studentID,
  appStatus,
  enrollStatus,
  review,
  begin,
  enrolment,
  refetch,
  waitingListNumber,
  onClick,
  setLoader,
  completeDate,
  completeByTime,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const [openOne, setOpenOne] = useState<boolean>(false);
  const [openTwo, setOpenTwo] = useState<boolean>(false);
  const[ openWaitlistPopup, setOpenWaitlistPopup ] = useState<boolean>(false);
  const [blockAcceptOffer, setBlockAcceptOffer] = useState<boolean>(false);

  useEffect(() => {
    const dateString = `${completeDate}T${completeByTime}Z`;
    const completedByDate = new Date(dateString);
    const currentDate = new Date();
    const va = completedByDate.getTime() < currentDate.getTime();
    setBlockAcceptOffer(va);
  }, []);
  const handleBeginClick = () => {
    localStorage.removeItem("currentStudentAppID");
    localStorage.setItem("currentStudentAppID", studentID);
    history.push("/parent/application");
  };

  const handleReviewClick = () => {
    localStorage.removeItem("currentStudentAppID");
    localStorage.setItem("currentStudentAppID", studentID);
    localStorage.setItem("studentEnrollmentStatus", enrollStatus);
    review();
  };

  const handleEnrollClick = () => {
    localStorage.removeItem("currentStudentAppID");
    localStorage.setItem("currentStudentAppID", studentID);
    enrolment();
  };

  const acceptOffer = () => {
    setLoader(true);
    request({
      url: `student-application/application-status-change/?student_app_uuid=${studentID}`,
      method: "put",
      data: {
        application_status: "ACCEPT_OFFER",
      },
    }).then((res) => {
      refetch();
    });
  };

  const declineOffer = () => {
    setLoader(true);
    request({
      url: `student-application/application-status-change/?student_app_uuid=${studentID}`,
      method: "put",
      data: {
        application_status: "DECLINE",
      },
    }).then((res) => {
      refetch();
    });
  };

  let content = null;
  if (appStatus === "DRAFT") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>
              Create Application
              <br />
              <span className={classes.smallText}>
                {appStatus === "DRAFT" ? "In Draft" : null}
              </span>
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 60, width: "80%" }}>
            <Text paragraph>
              Thank you for adding a student/child. You can now begin their
              application form.
            </Text>
          </div>
          <div className={classes.buttonWidth}>
            <Button label="Begin" onClick={handleBeginClick} />
          </div>
        </Grid>
      </div>
    );
  }

  if (appStatus === "WAITING") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>
              Waitlist
              <br />
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 60, width: "80%" }}>
            <Text paragraph>
              {waitingListNumber
                ? `Your candidature is in waiting list, and waiting list number is ${waitingListNumber}.`
                : `Your candidature is in waiting list.`}
            </Text>
          </div>
          <div className={classes.buttonWidth}>
          <Button label="Cancel" onClick={() => setOpenWaitlistPopup(true)}/>
          </div>
        </Grid>
      </div>
    );
  }

  if (appStatus === "WITHDRAWN") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>
              Withdrawn
              <br />
              <span className={classes.smallText}>Application withdrawn</span>
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 60, width: "80%" }}>
            <Text paragraph>
              Your offer has been withdrawn. Please contact the school
              authority.
            </Text>
          </div>
        </Grid>
      </div>
    );
  }

  if (
    appStatus === "APPLIED" ||
    appStatus === "IN_REVIEW" ||
    appStatus === "ACCEPTED"
  ) {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#E7F3E6" />
            <div className={classes.text}>
              Application
              <br />
              <span className={classes.smallText}>
                {appStatus === "APPLIED" ? "Applied" : null}
                {appStatus === "IN_REVIEW" ? "In review" : null}
                {appStatus === "ACCEPTED" ? "Accepted" : null}
              </span>
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 19, width: "80%" }}>
            <Text paragraph>
              Your application is now in review. Please keep an eye on your
              emails for confirmation of your applications success.
            </Text>
          </div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.textButton}
            onClick={handleReviewClick}
          >
            Review
            <Icon
              style={{
                fontSize: 20,
                marginTop: 3,
              }}
            >
              navigate_next
            </Icon>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (appStatus === "OFFERED") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>Accept Offer</div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 47, width: "80%" }}>
            <Text paragraph>
              Your application has been selected. Please accept or decline the
              offer.
            </Text>
          </div>
          <div className={classes.acceptOfferButtons}>
            <div style={{ width: "28%" }}>
              <MaterialButton
                className={cn(classes.declineButton)}
                onClick={() => setOpenTwo(true)}
              >
                Decline
              </MaterialButton>
            </div>
            <div style={{ width: "28%" }}>
              <Button disabled={blockAcceptOffer} label="Accept" onClick={() => setOpenOne(true)} />
            </div>
          </div>
          <Divider />

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              className={classes.nameContainer}
            >
              <div className={cn(classes.tickIcon)}>
                <Icon className={classes.completeIcon}>check_circle_icon</Icon>
              </div>

              <div className={classes.text}>
                Application
                <br />
                <span className={classes.smallText}>Approved</span>
              </div>
            </Grid>
            <Grid
              container
              className={cn(classes.buttonContainer, classes.textButton)}
              justifyContent="flex-end"
              alignItems="center"
              onClick={handleReviewClick}
            >
              Review
              <Icon
                style={{
                  fontSize: 20,
                  marginTop: 3,
                }}
              >
                navigate_next
              </Icon>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (appStatus === "DECLINE") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>Offer Declined</div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 47, width: "80%" }}>
            <Text paragraph>You have declined the Offer.</Text>
          </div>

          <Divider />
        </Grid>
      </div>
    );
  }

  if (appStatus === "ACCEPT_OFFER") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#F2F2F2" />
            <div className={classes.text}>
              {enrollStatus !== "SUBMITTED" ? "Create Enrolment" : "Enrolment"}
              <br />
              <span className={classes.smallText}>
                {enrollStatus === "NOT_STARTED" ? "Not started yet" : ""}
                {enrollStatus === "DRAFT" ? "In Draft" : ""}
                {enrollStatus === "SUBMITTED" ? "Submitted" : ""}
              </span>
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 47, width: "80%" }}>
            <Text paragraph>
              {enrollStatus !== "SUBMITTED"
                ? `Your application has been approved you may now begin your student's/child's
              enrolment..`
                : `Your enrolment is finished. School Authorities will reach out to you.`}
            </Text>
          </div>
          <div
            style={{
              width: "40%",
              marginBottom: "40px",
              marginLeft: "60%",
            }}
          >
            {enrollStatus !== "SUBMITTED" ? (
              <Button label="Begin" onClick={handleEnrollClick} />
            ) : null}
          </div>
          <Divider />

          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-start"
              className={classes.nameContainer}
            >
              <div className={cn(classes.tickIcon)}>
                <Icon className={classes.completeIcon}>check_circle_icon</Icon>
              </div>

              <div className={classes.text}>
                Application
                <br />
                <span className={classes.smallText}>Approved</span>
              </div>
            </Grid>
            <Grid
              container
              className={cn(classes.buttonContainer, classes.textButton)}
              justifyContent="flex-end"
              alignItems="center"
              onClick={handleReviewClick}
            >
              Review
              <Icon
                style={{
                  fontSize: 20,
                  marginTop: 3,
                }}
              >
                navigate_next
              </Icon>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (appStatus === "CANCELLED") {
    content = (
      <div className={classes.content}>
        <Grid container direction="column">
          <Grid container alignItems="center" justifyContent="flex-start">
            <IconRounded iconName="list_alt" bgColor="#E7F3E6" />
            <div className={classes.text}>
              Cancelled
              <br />
            </div>
          </Grid>
          <div style={{ marginTop: 26, marginBottom: 19, width: "80%" }}>
            <Text paragraph>Your application has been cancelled.</Text>
          </div>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.head}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <IconRounded
            letter={studentName.slice(0, 1)}
            bgColor="rgba(251, 173, 22, 0.2)"
          />
          <div className={classes.text}>{studentName}</div>
        </Grid>
      </Grid>
      {content}

      <ConfirmBox
        open={openOne}
        setOpen={setOpenOne}
        onClick={acceptOffer}
        arg={true}
        message={`Are you sure you want to accept the offer?`}
      />
      <ConfirmBox
        open={openTwo}
        setOpen={setOpenTwo}
        onClick={declineOffer}
        arg={true}
        message={`Are you sure you wish to decline this offer? Once confirmed the application cannot be continued.`}
      />
      <ConfirmBox
        open={openWaitlistPopup}
        setOpen={setOpenWaitlistPopup}
        onClick={declineOffer}
        arg={true}
        message={`Are you sure you want to cancel your application?`}
      />
    </div>
  );
};
