import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {},
  header: {
    fontSize: "15px",
    fontWeight: 600,
    marginBottom: "15px",
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#2B2B33",
  },
  tab: {
    marginRight: 50,
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    width: 200
  },
  tabButton: {
    display: "block",
    backgroundColor: "inherit",
    color: theme.palette.common.black,
    padding: "12px 22px",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14
  },
  applicationPill: {
    backgroundColor: "#848fe4",
    color: "white",
    padding: "4px",
    borderRadius: "2px",
  },
  enrolmentPill: {
    backgroundColor: "#e48484",
    color: "white",
    padding: "4px",
    borderRadius: "2px",
  },
  legends: {
    marginTop: "60px",
    fontWeight: 200,
    paddingLeft: "18px",
    paddingBottom: "16px",
    border: "2px solid #5a5a5a58",
    borderStyle: "dashed",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },
}));
