import React, { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Box } from "@material-ui/core";
import { useStyle } from "./mother-tongue.style";
import Icon from "@material-ui/core/Icon";
import { Button } from "../../../../components/common/button/index";
import Grid from "@material-ui/core/Grid";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import { dropDownHelper } from "../../../../utils/helper";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import {
  getMotherTongueAndEthnicity,
  getMotherTongueAndEthnicityList,
  postMotherTongueAndEthnicityData,
} from "./mother-tongue-ethnicity.api";

type Props = {
  prevPage?: any;
  nextPage?: any;
  stdAppId: any;
};

const MotherTongueEthnicity: React.FC<Props> = ({
  nextPage,
  prevPage,
  stdAppId,
}) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [motherTongue, setMotherTongue] = useState("");
  const [ethnicity, setEthnicity] = useState([
    { name: "-- Select Ethnicity --", value: "" },
  ]);
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [error, setError] = useState({
    motherTongue: false,
    ethnicity: false,
  });

  const handleChangeMotherTongueRadio = (name: string, index: number) => {
    setMotherTongue(name);
    setError({
      ...error,
      motherTongue: false,
    });
  };

  const getData = async () => {
    setLoader(true);
    let res1 = await getMotherTongueAndEthnicityList();
    const result = dropDownHelper({
      items: res1?.ethnicityList?.data?.results,
      name: "key",
      value: "value",
    });
    setEthnicity([...ethnicity, ...result]);

    let res2 = await getMotherTongueAndEthnicity(stdAppId);
    let motherTongueEthData = res2?.data?.results;
    if (motherTongueEthData) {
      if (motherTongueEthData?.mother_tongue.length !== 0) {
        setMotherTongue(motherTongueEthData?.mother_tongue);
      }
      if (motherTongueEthData?.ethnicity_cultural_background) {
        setSelectedEthnicity(
          motherTongueEthData?.ethnicity_cultural_background
        );
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const validate = () => {
    return {
      motherTongue: motherTongue === "",
      ethnicity: selectedEthnicity === "",
    };
  };
  const onSubmit = async () => {
    const err = validate();
    setError(err);
    if (err.motherTongue === false && err.ethnicity === false) {
      setLoader(true);
      const motherTongueData = {
        mother_tongue: motherTongue,
        ethnicity_cultural_background: selectedEthnicity,
      };
      try {
        await postMotherTongueAndEthnicityData(stdAppId, motherTongueData);
        setLoader(false);
        nextPage();
      } catch (err) {
        setLoader(false);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>Enrollment</div>
            </div>
            <ProgressBar total={7} steps={1} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome
              title="Mother Tongue and Ethnicity"
              description="Next: Education status"
            />
          </div>
          <Box>
            <InputRadioButton
              label="Is English or Irish the Mother Tongue of the student? * (MOTHER TONGUE is the language a child speaks as their
                      first language)"
              placeHolderOne="YES"
              placeHolderTwo="NO"
              answer={motherTongue}
              onChange={handleChangeMotherTongueRadio}
              index={0}
              valueOne={true}
              valueTwo={false}
              isCheckString
              error={error.motherTongue}
            />
          </Box>
          <Box>
            <p className={styles.disclamer}>
              IN RESPECT OF THE NEXT QUESTION YOU MAY OPT NOT TO PROVIDE AN
              ANSWER
            </p>
            <SelectDropDown
              label={
                "To which ethnic or cultural background does the above-named student belong? *"
              }
              item={ethnicity}
              value={selectedEthnicity}
              onChange={(e: any) => {
                if (e.target.value) {
                  setSelectedEthnicity(e.target.value);
                  setError((err: any) => ({
                    ...err,
                    ethnicity: false,
                  }));
                }
              }}
              error={error.ethnicity}
            />
          </Box>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onSubmit}
              />
            </div>
          </Grid>
        </Box>
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default MotherTongueEthnicity;
