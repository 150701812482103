import React from "react";
import { useTranslation } from "react-i18next";
import { languageCodeOnly } from "../i18n";
import Select from '@material-ui/core/Select';
import { MenuItem } from "@material-ui/core";

export const supportedLanguages = [
    { code: "en", name: "English" },
    { code: "ga", name: "Gaeilge" },
];
interface Props { onChange: (language: any) => void }

function LanguageSwitcher({ onChange }: Props) {
    const { i18n } = useTranslation();

    return (
        <div style={{ paddingRight: 50 }} >
            <Select
                value={languageCodeOnly(i18n.language)}
                onChange={(e) => onChange(e.target.value)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center"
                    },
                    getContentAnchorEl: null
                }}
            >
                {supportedLanguages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                        {lang.name}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}

export default LanguageSwitcher;
