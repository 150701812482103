import React, { useState } from "react";
import requestv3 from "../../../../utils/authUtilv3";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import  Autocomplete  from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Input } from "../../../../components/common/input";
import { AdminButton } from "../../../../components/common/admin-button";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import { MessageBox } from "../../../../components/common/message-Box";


type Props = {};

interface parent_data_interface {
  parent_email : string,
  parent_mobile_number : string,
  parent_name : string,
}

const DEFAULT_SELECTED_USER_DATA = {
  parent_email : "",
  parent_mobile_number : "",
  parent_name : "",
  }

const DEFAULT_PASSWORD_DATA = {
  password:"",
  confirm_password:"",
}

const ManualResetPassword: React.FC<Props> = () => {


  // const [inputValue , setInputValue] = useState<string>("")
  const [selectedUser, setSelectedUser] = React.useState<parent_data_interface>(DEFAULT_SELECTED_USER_DATA);
  const [passwords, setPasswords] = useState<any>(DEFAULT_PASSWORD_DATA)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [noUserFound, setNoUserFound] = useState<boolean>(false)

  const [parentsList, setParentsList] = React.useState<parent_data_interface[]>([])
  const [isPopUpOpen , setIsPopUpOpen] = useState<boolean>(false)
  const [MessagePopUpBoxState, setMessagePopUpBoxState] = useState<any>({
    message: "",
    isOpen: false,
  })

  const [ error, setError] = useState<boolean>(false)

  const autoCompleteOnChange = (value:any)=>{
    setSelectedUser((user:any)=>value)
    setPasswords(DEFAULT_PASSWORD_DATA)
  }

  const fetchUsersList = async(val:string)=>{
    setIsLoading(true)
    const res = await requestv3({
      url:`/school-settings/get-parent-to-reset-password/`,
      method:'post',
      data:{
        parent_email:val,
      }
    })
    if(res?.data?.status_code === 1){
      if(res?.data?.results?.length === 0){
        setNoUserFound(true)
      }
      else{setNoUserFound(false)}
      setParentsList((prev)=>res?.data?.results)
    }
    setIsLoading(false)
  }

  const onBlur = (e:any)=>{
      setParentsList((prev)=>[])
  }

  const onValueChange = (e: any, inputType: string) => {
    setPasswords((prev:any) => {
      return {
        ...prev,
        [inputType]: e?.target?.value,
      };
    });
  }

  const onConfirmPasswordBlur = () =>{
    if(passwords["confirm_password"]!== passwords["password"]){
      setError(true)
    }
    else{setError(false)}
  }

  const onResetPassword = async()=>{
    const res = await requestv3({
      url:`/school-settings/parent-reset-password/`,
      method:'put',
      data:{
        parent_email:selectedUser?.parent_email,
        password:passwords?.password,
      }
    })
    setMessagePopUpBoxState({
      isOpen:true,
      message:res?.data?.status_message,
    })
    if(res?.data?.status_code === 1){
      setParentsList([])
      setPasswords(DEFAULT_PASSWORD_DATA)
      setSelectedUser(DEFAULT_SELECTED_USER_DATA)
    }
  }

  const onKeyUpFn = (e:any)=>{
    if(e?.target?.value?.length !== 0){
      let lengthOfInput = e?.target?.value?.replace(/\s+/g, '').length
      let condition1 = lengthOfInput >= 3
      if(condition1){
        if(e?.code === 'Backspace' && !condition1){
          setParentsList([])
        }
      fetchUsersList(e?.target?.value)
    }
    }
    else{
      setSelectedUser(DEFAULT_SELECTED_USER_DATA)
      setParentsList([])
    }
  }

  const closeMessageBox = (val:boolean)=>{
    setMessagePopUpBoxState((prev:any)=>({...prev,isOpen:val}))
  }


  let formInputsCondition = !(selectedUser?.parent_email?.length > 0)

  let AdminBtnCondition = formInputsCondition ||
                            passwords?.password?.length === 0 ||
                            passwords?.confirm_password?.length === 0 ||
                            error

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap="16px">
      <Box display="flex" flexDirection="column" alignItems="flex-start"
        paddingX="24px" paddingY="24px" width="100%">
        <h3>Search for an existing user</h3>
        <h4 style={{fontWeight:"lighter"}}>You can reset the selected user's password manually here</h4>
      </Box>
      <Box display="flex" gridGap="36px" flexDirection="column" width="60%" alignItems="flex-start">
        <Autocomplete
          options={parentsList}
          value={selectedUser}
          fullWidth={true}
          onChange={(_,val)=>autoCompleteOnChange(val)}
          noOptionsText={noUserFound ? "No such user found!!" : "start typing to search for a user"}
          autoHighlight
          loading = {isLoading}
          loadingText={"loading data ..."}
          onKeyUp={onKeyUpFn}
          onBlur={onBlur}
          getOptionLabel={(option:any) => option?.parent_email}
          renderInput={(params) => <TextField {...params} label="User email"/>}
        />
        <Grid container direction="column">
        <Input
          label="User's Email"
          value={selectedUser?.parent_email}
          type="text"
          disabled={true}
          />
          <Input
            label="New Password"
            value={passwords?.password}
            type="password"
            onChange={(e) => onValueChange(e, "password")}
            disabled={formInputsCondition}
          />
          <Input
            label="Confirm New Password"
            value={passwords?.confirm_password}
            type="password"
            onChange={(e) => onValueChange(e, "confirm_password")}
            error={error}
            onBlur={onConfirmPasswordBlur}
            errorMessage="Confirm password does not match with password"
            disabled={formInputsCondition}
          />
        </Grid>
      </Box>
        <Box marginBottom="40px">
      <AdminButton type="submit" label="Reset Password" disabled={AdminBtnCondition} onClick={()=>setIsPopUpOpen(true)}/>
      </Box>
      <ConfirmBox
        message="Are you sure you want to reset this user's password?"
        arg={false}
        open={isPopUpOpen}
        setOpen={setIsPopUpOpen}
        onClick={onResetPassword}
      />
      <MessageBox
        message={MessagePopUpBoxState?.message}
        open={MessagePopUpBoxState?.isOpen}
        setOpen={closeMessageBox}
      />
    </Box>
  );
};

export default ManualResetPassword;