import { Box, Checkbox, InputLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "./my-school.style";
import { Loader } from "../../../components/common/loader/index";
import { SelectDropDown } from "../../../components/common/select-dropdown/index";
import { county } from "../../parent/application/county";
import { Upload } from "../../../components/common/upload";
import axios from "axios";
import { ParentUser } from "../../../utils/authUtil";
import { Type } from "../../../utils/document-types";
import { DownLoad } from "../../../components/common/download";
type Props = {};

const MySchool: React.FC<Props> = ({}) => {
  const classes = useStyle();
  const [show, setShow] = useState("details");
  const [active, setActive] = useState("details");
  const [helperFlag, setHelperFlag] = useState(false);
  const schoolID = localStorage.getItem("school-id");
  const [error, setErrorAPI] = useState("");
  const [loader, setLoader] = useState(false);
  const [validError, setValidError] = useState({
    schoolName_err: false,
    // uscode_err: false,
    website_err: false,
    logoUrl_err: false,
    bannerUrl_err: false,
    tagline_err: false,
    // phoneNumber_err: false,
    // email_err: false,
  });
  const [validAddress, setValidAddress] = useState({
    addressLine1_err: false,
    country_err: false,
    town_err: false,
    city_err: false,
    eirCode_err: false,
  });
  const [emailValidate, setEmailValidate] = useState(false);
  interface ISchoolDetails {
    schoolName: string;
    website: string;
    usCode: string;
    colour: string;
    logoURL: string;
    logoBurgerURL: string;
    bannerURL: string;
    tagLine: string;
    phoneNumber: string;
    emailPref: boolean;
    email: string;
  }

  interface IschoolAddress {
    addressUUID: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    town: string;
    city: string;
    eirCode: string;
  }
  const [schoolDetails, setSchoolDetails] = useState<ISchoolDetails>({
    schoolName: "",
    website: "",
    usCode: "",
    colour: "",
    logoURL: "",
    logoBurgerURL: "",
    bannerURL: "",
    tagLine: "",
    phoneNumber: "",
    emailPref: false,
    email: "",
  });
  const [country, setCountry] = useState([
    { name: "-- Select Country --", value: "" },
  ]);

  const [schoolAddress, setSchoolAddress] = useState<IschoolAddress>({
    addressUUID: "",
    addressLine1: "",
    addressLine2: "",
    country: "IE",
    town: "",
    city: "",
    eirCode: "",
  });

  const [catchmentAreaDoc, setCatchmentareaDoc] = useState({
    isUploaded: false,
    document_name_onChange: "",
    document_name_uploaded: "",
    document_type: "",
    document_presingne_url: "",
    document_uuid: "",
    document_url: "",
    document_text: "",
  });

  const [validFields, setValidFields] = useState({
    isFile: false,
    isAccept: "",
  });

  const [err, setError] = useState<any>({
    catchment_area: false,
    accept_certificate: false,
  });

  const [fileUrl, setFileUrl] = useState<string>();

  const getSignedUrl = async (fileName: any) => {
    if (fileName) {
      if (!fileName.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setValidFields({
          ...validFields,
          isFile: false,
          isAccept: "acceptError",
        });
        setError({
          ...err,
          catchment_area: false,
          accept_certificate: true,
        });
        return null;
      } else {
        setLoader(true);
        const res = await request({
          url: `/school/school-catchment-area-details/?school_uuid=${schoolID}`,
          method: "post",
          data: {
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  const getCatchmentArea = () => {
    setLoader(true);
    request({
      url: `/school/school-catchment-area-details/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data.status_code === 1 && res.data.results.length !== 0) {
        setCatchmentareaDoc({
          ...catchmentAreaDoc,
          isUploaded: true,
          document_name_uploaded: res.data.results.file_name,
          document_url: res.data.results.image_url,
          document_presingne_url: res.data.results.image_presigned_url,
          document_text: res.data.results.image_text,
        });
      } else {
        setCatchmentareaDoc({
          ...catchmentAreaDoc,
          isUploaded: false,
          document_name_onChange: "",
          document_name_uploaded: "",
          document_presingne_url: "",
          document_url: "",
        });
      }
    });
  };

  const textOnChange = (event: any) => {
    setCatchmentareaDoc({
      ...catchmentAreaDoc,
      document_text: event.target.value,
    });
  };

  const change = (event: any) => {
    new Promise((res, rej) => {
      getSignedUrl(event.target?.files[0]?.name).then((response) => {
        setLoader(false);
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setFileUrl(`${response.url}${response.fields.key}`);
          setCatchmentareaDoc({
            ...catchmentAreaDoc,
            document_name_onChange: event.target.files[0].name,
          });
          setValidFields({
            ...validFields,
            isFile: true,
            isAccept: "",
          });
          setError({
            ...err,
            catchment_area: false,
            accept_certificate: false,
          });
          setLoader(false);
        }
      );
    });
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const getCountry = () => {
    setLoader(true);
    request({
      url: `student-application/country-details/`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setCountry([...country, ...res?.data?.results]);
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `/school/school-details/?school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        setLoader(false);
        if (response.data) {
          const schoolDetailsTemp = { ...schoolDetails };
          schoolDetailsTemp.schoolName = response.data.results.name;
          schoolDetailsTemp.website = response.data.results.website;
          schoolDetailsTemp.usCode = response.data.results.uscode;
          schoolDetailsTemp.colour = response.data.results.colour;
          schoolDetailsTemp.emailPref = response.data.results.email_preference;
          schoolDetailsTemp.logoURL = response.data.results.logo_aws_url;
          schoolDetailsTemp.logoBurgerURL =
            response.data.results.logo_burger_aws_url;
          schoolDetailsTemp.tagLine = response.data.results.school_tagline;
          schoolDetailsTemp.bannerURL = response.data.results.banner_aws_url;
          schoolDetailsTemp.phoneNumber = response.data.results.phone_number;
          schoolDetailsTemp.email = response.data.results.email;
          setSchoolDetails(schoolDetailsTemp);
        } else {
          setErrorAPI(response.status_message);
          setLoader(false);
        }
      })
      .catch();

    getCatchmentArea();
    //school address
    //TODO Add school uuid to the get
    request({
      url: `/school/school-address-details/?uuid=`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          if (Object.keys(response.data.results).length === 0) {
            setHelperFlag(false);
          } else {
            setHelperFlag(true);
            const addressTemp = { ...schoolAddress };
            addressTemp.addressUUID = response.data.results[0].address_uuid;
            addressTemp.addressLine1 =
              response.data.results[0].address_line_one;
            addressTemp.addressLine2 =
              response.data.results[0].address_line_two;
            addressTemp.eirCode = response.data.results[0].eir_code;
            addressTemp.town = response.data.results[0].town;
            addressTemp.eirCode = response.data.results[0].eir_code;
            addressTemp.city = response.data.results[0].city;
            addressTemp.country = response.data.results[0].country;
            setSchoolAddress(addressTemp);
          }
        } else {
          setErrorAPI(response.status_message);
        }
      })
      .catch();
    getCountry();
  }, []);

  const handleTabChange = (value: string) => {
    setShow(value);
    setActive(value);
  };

  const SchoolDetailsOnChange = (e: any, inputType?: string) => {
    switch (inputType) {
      case "schoolName":
        setSchoolDetails({ ...schoolDetails, schoolName: e.target.value });
        setValidError({
          ...validError,
          schoolName_err: e.target.value.length === 0,
        });
        break;
      case "website":
        setSchoolDetails({ ...schoolDetails, website: e.target.value });
        setValidError({
          ...validError,
          website_err: e.target.value.length === 0,
        });
        break;
      case "usCode":
        setSchoolDetails({ ...schoolDetails, usCode: e.target.value });
        // setValidError({
        //   ...validError,
        //   uscode_err: e.target.value.length === 0,
        // });
        break;
      case "colour":
        setSchoolDetails({ ...schoolDetails, colour: e.target.value });
        break;
      case "logoURL":
        setSchoolDetails({ ...schoolDetails, logoURL: e.target.value });
        setValidError({
          ...validError,
          logoUrl_err: e.target.value.length === 0,
        });
        break;
      case "logoBurgerURL":
        setSchoolDetails({ ...schoolDetails, logoBurgerURL: e.target.value });

        break;
      case "bannerURL":
        setSchoolDetails({ ...schoolDetails, bannerURL: e.target.value });
        setValidError({
          ...validError,
          bannerUrl_err: e.target.value.length === 0,
        });
        break;
      case "tagLine":
        setSchoolDetails({ ...schoolDetails, tagLine: e.target.value });
        setValidError({
          ...validError,
          tagline_err: e.target.value.length === 0,
        });
        break;
      case "phoneNumber":
        setSchoolDetails({ ...schoolDetails, phoneNumber: e.target.value });
        // setValidError({
        //   ...validError,
        //   phoneNumber_err: e.target.value.length === 0,
        // });
        break;
      case "email":
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setSchoolDetails({ ...schoolDetails, email: e.target.value });
        if (e.target.value.length > 0) {
          setEmailValidate(!mailformat.test(e.target.value));
        } else {
          setEmailValidate(false);
        }
        break;
      case "emailPref":
        setSchoolDetails({
          ...schoolDetails,
          emailPref: !schoolDetails.emailPref,
        });
    }
  };

  const schoolAddressOnChange = (e: any, inputype: any) => {
    switch (inputype) {
      //   addressUUID: "",
      // addressLine1: "",
      // addressLine2: "",
      // country: "IE",
      // town: "",
      // city: "",
      // eirCode: "",
      case "addressLine1":
        setSchoolAddress({
          ...schoolAddress,
          addressLine1: e.target.value,
        });
        setValidAddress({
          ...validAddress,
          addressLine1_err: e.target.value.length === 0,
        });
        break;
      case "addressLine2":
        setSchoolAddress({
          ...schoolAddress,
          addressLine2: e.target.value,
        });
        break;
      case "country":
        setSchoolAddress({
          ...schoolAddress,
          country: e.target.value,
        });
        setValidAddress({
          ...validAddress,
          country_err: e.target.value === "",
        });
        break;
      case "town":
        setSchoolAddress({
          ...schoolAddress,
          town: e.target.value,
        });
        setValidAddress({
          ...validAddress,
          town_err: e.target.value.length === 0,
        });
        break;
      case "city":
        setSchoolAddress({
          ...schoolAddress,
          city: e.target.value,
        });
        setValidAddress({
          ...validAddress,
          city_err: e.target.value === "",
        });
        break;
      case "eirCode":
        setSchoolAddress({
          ...schoolAddress,
          eirCode: e.target.value,
        });
        setValidAddress({
          ...validAddress,
          eirCode_err: e.target.value.length === 0,
        });
        break;
    }
  };

  const ValidateSchoolDetails = () => {
    // var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return {
      schoolName_err: schoolDetails.schoolName.length === 0,
      website_err: schoolDetails.website.length === 0,
      logoUrl_err: schoolDetails.logoURL.length === 0,
      bannerUrl_err: schoolDetails.bannerURL.length === 0,
      tagline_err: schoolDetails.tagLine.length === 0,
      // uscode_err: schoolDetails.usCode.length === 0,
      // phoneNumber_err: schoolDetails.phoneNumber.length === 0,
      // email_err:
      //   schoolDetails.email.length === 0 ||
      //   !mailformat.test(schoolDetails.email),
    };
  };

  const validateSchoolAddress = () => {
    return {
      addressLine1_err: schoolAddress.addressLine1.length === 0,
      country_err: schoolAddress.country === "",
      town_err: schoolAddress.town.length === 0,
      city_err: schoolAddress.city === "",
      eirCode_err: schoolAddress.eirCode.length === 0,
    };
  };

  const onSaveClickTwo = () => {
    setLoader(true);
    onSubmit();
    const err = ValidateSchoolDetails();
    setValidError(err);
    if (
      err.schoolName_err === false &&
      err.logoUrl_err === false &&
      err.bannerUrl_err === false &&
      err.tagline_err === false
      // err.uscode_err === false &&
      // err.phoneNumber_err === false
    ) {
      setLoader(true);
      var data = {
        school_uuid: schoolID,
        school_name: schoolDetails.schoolName,
        school_website: schoolDetails.website,
        school_uscode: schoolDetails.usCode,
        school_email: schoolDetails.email,
        phone_number: schoolDetails.phoneNumber,
        colour: schoolDetails.colour,
        email_preference: schoolDetails.emailPref,
        logo_aws_url: schoolDetails.logoURL,
        logo_burger_aws_url: schoolDetails.logoBurgerURL,
        banner_aws_url: schoolDetails.bannerURL,
        school_tagline: schoolDetails.tagLine,
      };

      //@ts-ignore
      data.address_uuid = schoolAddress.addressUUID;

      request({
        url: "/school/school-details/",
        method: "put",
        data: data,
      }).then((response) => {
        setLoader(false);
      });
    }
  };

  const onSaveClick = () => {
    const err = validateSchoolAddress();
    setValidAddress(err);
    if (
      err.addressLine1_err === false &&
      err.city_err === false &&
      err.country_err === false &&
      err.eirCode_err === false &&
      err.town_err === false
    ) {
      setLoader(true);
      const meth = helperFlag ? "put" : "post";
      var data = {
        school_uuid: schoolID,
        address_line_one: schoolAddress.addressLine1,
        address_line_two: schoolAddress.addressLine2,
        town: schoolAddress.town,
        city: schoolAddress.city,
        eir_code: schoolAddress.eirCode,
        country: schoolAddress.country,
      };
      if (helperFlag) {
        //@ts-ignore
        data.address_uuid = schoolAddress.addressUUID;
      }
      request({
        url: "/school/school-address-details/",
        method: meth,
        data: data,
      }).then((response) => {
        setLoader(false);
      });
    }
  };

  const onSubmit = async () => {
    await request({
      url: `/school/school-catchment-area-details/?school_uuid=${schoolID}`,
      method: "put",
      data: {
        catchment_area_image: fileUrl ? fileUrl : catchmentAreaDoc.document_url,
        catchment_area_text: catchmentAreaDoc.document_text,
      },
    }).then((response) => {
      const { status } = response;
      if (status === 200) {
        getCatchmentArea();
        setLoader(false);
      }
    });
  };

  const deleteDocument = () => {
    setLoader(true);
    request({
      url: `/school/school-catchment-area-details/?school_uuid=${schoolID}`,
      method: "delete",
    })
      .then(() => {
        setLoader(false);
        getCatchmentArea();
      })
      .then(() => {
        setInitialState();
      });
  };

  const setInitialState = () => {
    setCatchmentareaDoc({
      ...catchmentAreaDoc,
      document_name_onChange: "",
    });
    setValidFields({
      isFile: false,
      isAccept: "",
    });
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div style={{ marginBottom: "50px" }}>
        <Box>
          <Box className={classes.heading}> My School</Box>
        </Box>
        {/* error msg */}
        <p
          style={{
            margin: 10,
            color: "#EB5757",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {error}
        </p>
        <div style={{ width: "30vw" }}>
          {show === "details" ? (
            <div>
              <Input
                label="School Name"
                value={schoolDetails.schoolName}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "schoolName");
                }}
                error={validError.schoolName_err}
              />
              <Input
                label="US Code"
                value={schoolDetails.usCode}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "usCode");
                }}
                // error={validError.uscode_err}
              />
              <Input
                label="Website"
                value={schoolDetails.website}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "website");
                }}
                error={validError.website_err}
              />
              {/* <Input
                label="Color"
                value={schoolDetails.colour}
                type="text"
                error={
                  errorSubmitTwo ? errorCheck.includes("colourError") : false
                }
                onChange={(e: any) => {
                  setSchoolDetails({
                    ...schoolDetails,
                    colour: e.target.value,
                  });
                  LengthCheck(e.target.value, "colourError");
                }}
              /> */}
              <Box
                display="flex"
                alignItems="center"
                style={{ marginBottom: "34px", marginLeft: "-13px" }}
              >
                <Checkbox
                  color="default"
                  checked={schoolDetails.emailPref}
                  onChange={(e: any) =>
                    setSchoolDetails({
                      ...schoolDetails,
                      emailPref: !schoolDetails.emailPref,
                    })
                  }
                />
                <InputLabel
                  className={cn(
                    classes.label,
                    error ? classes.labelError : null
                  )}
                >
                  Email Preference
                </InputLabel>
              </Box>
              <Input
                label="Logo URL"
                value={schoolDetails.logoURL}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "logoURL");
                }}
                error={validError.logoUrl_err}
              />
              {/* <Input
                label="Logo Burger URL"
                value={schoolDetails.logoBurgerURL}
                type="text"
                error={
                  errorSubmitTwo
                    ? errorCheck.includes("logoburgerError")
                    : false
                }
                onChange={(e: any) => {
                  setSchoolDetails({
                    ...schoolDetails,
                    logoBurgerURL: e.target.value,
                  });
                  LengthCheck(e.target.value, "logoburgerError");
                }}
              /> */}

              <Input
                label="Banner URL"
                value={schoolDetails.bannerURL}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "bannerURL");
                }}
                error={validError.bannerUrl_err}
              />
              <Input
                label="TagLine"
                value={schoolDetails.tagLine}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "tagLine");
                }}
                error={validError.tagline_err}
              />
              <Input
                label="Phone Number"
                value={schoolDetails.phoneNumber}
                type="number"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "phoneNumber");
                }}
                // error={validError.phoneNumber_err}
              />
              <Input
                label="Email"
                value={schoolDetails.email}
                type="text"
                onChange={(e: any) => {
                  SchoolDetailsOnChange(e, "email");
                }}
                error={emailValidate}
                errorMessage="Please enter valid email"
              />
              <Box>
                {catchmentAreaDoc.isUploaded === true ? (
                  <>
                    <DownLoad
                      id="Download-cathcment-doc"
                      label="Catchment Area doc"
                      file={catchmentAreaDoc.document_name_uploaded}
                      onDelete={deleteDocument}
                      downloadLink={catchmentAreaDoc.document_presingne_url}
                    />
                  </>
                ) : (
                  <Upload
                    id="Catchment-Area"
                    label="Catchment Area"
                    placeholder="Upload"
                    onChange={(e: any) => change(e)}
                    isFile={validFields.isFile}
                    error={err.catchment_area || err.accept_certificate}
                    file={catchmentAreaDoc.document_name_onChange}
                    errorMessage={
                      validFields.isAccept === "acceptError"
                        ? "Only  files .png .jpg and .jpeg  are allowed"
                        : "Is required"
                    }
                    onDelete={() => setInitialState()}
                  />
                )}
              </Box>
              <Input
                label="Catchment Text"
                type="text"
                value={catchmentAreaDoc.document_text}
                onChange={textOnChange}
              />
              <Box
                style={{
                  width: "100px",
                  marginTop: 40,
                  alignItems: "end",
                }}
              >
                <AdminButton label="Save" onClick={onSaveClickTwo} />
              </Box>
            </div>
          ) : null}
          {show === "address" ? (
            <div>
              <Input
                label="Address Line 1"
                value={schoolAddress.addressLine1}
                type="text"
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "addressLine1");
                }}
                error={validAddress.addressLine1_err}
              />
              <Input
                label="Address Line 2"
                value={schoolAddress.addressLine2}
                type="text"
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "addressLine2");
                }}
              />
              <Input
                label="Town/City *"
                value={schoolAddress.town}
                type="text"
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "town");
                }}
                error={validAddress.town_err}
              />
              <SelectDropDown
                label="County *"
                item={county}
                value={schoolAddress.city}
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "city");
                }}
                error={validAddress.city_err}
              />
              <SelectDropDown
                label="Country *"
                item={country}
                value={schoolAddress.country}
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "country");
                }}
                error={validAddress.country_err}
                disabled
              />
              <Input
                label="Eir Code"
                value={schoolAddress.eirCode}
                type="text"
                onChange={(e: any) => {
                  schoolAddressOnChange(e, "eirCode");
                }}
                error={validAddress.eirCode_err}
              />
              <Box
                style={{
                  width: "100px",
                  marginTop: 40,
                  alignItems: "end",
                }}
              >
                <AdminButton label="Save" onClick={onSaveClick} />
              </Box>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className={classes.tab}>
          <button
            className={cn(
              classes.tabButton,
              active === "details" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("details")}
          >
            School Details
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "address" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("address")}
          >
            School Address
          </button>
        </div>
        <Loader open={loader} />
      </div>
    </Grid>
  );
};

export default MySchool;
