import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "../start-selection/start-selection.style";
import ManualDropDown from "./manual-criteria-dropdown";
import ManualTable from "./manual-table";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";
import { TextFieldCustom } from "../start-selection/text-field-component";
import { useQuery, UseQueryResult } from "react-query";

const ManualSelection = () => {
  const classes = useStyle();
  const history = useHistory();
  const [seats, setSeats] = useState<any>({});
  const schoolId = localStorage.getItem("school-id");
  const [criterias, setCriterias] = useState<any>([
    {
      criteria_name: "All",
      criteria_uuid: "",
    },
  ]);
  const [loader, setLoader] = useState<boolean>(false);
  const [singCriteria, setSinglrCriteria] = useState<any>({
    sing_criteria_name: "ALL",
    sing_criteria_uuid: "",
  });
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [localData, setLocalData] = useState([]);

  const getCriterias = (): any => {
    setLoader(true);
    request({
      url: `/school-settings/offers-criteria-details/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      const temp: any = [];
      res.data.results.criteria_list.map((criteria: any) => {
        temp.push({
          criteria_name: criteria.criteria_name,
          criteria_uuid: criteria.criteria_uuid,
        });
      });
      setCriterias(temp);
      setLoader(false);
    });
  };

  const getRowData = () => {
    const res = request({
      url: `school-settings/manual-offers-selection-list/?school_uuid=${schoolId}&criteria_uuid=${singCriteria.sing_criteria_uuid}&page=${pageNumber}&search_text=${searchText}`,
      method: "get",
    });
    return res;
  };

  const {
    refetch,
    isError,
    error: manualError,
    data: manualData,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["manuallist", { pageNumber, searchText, singCriteria }],
    () => getRowData(),
    {
      select: (manuallist) => manuallist.data.results,
    }
  );

  useEffect(() => {
    if (manualData) {
      if (Object.keys(manualData).length !== 0) {
        const tab = manualData.application_list.map((item: any) => {
          return {
            ...item,
            id: item.ID,
          };
        });
        setLocalData(tab);
        setSeats({
          total: manualData.total_places,
          total_available: manualData.places_available,
          total_selected: manualData.total_selected,
        });
        setTotalPages(manualData.total_pages);
        setPageNumber(manualData.current_page);
      } else {
        setLocalData([]);
      }
    } else {
      setLocalData([]);
    }
  }, [manualData]);

  useEffect(() => {
    getCriterias();
  }, []);

  const onSelectCriteria = (crit: string, crit_id: string) => {
    setSinglrCriteria({
      ...singCriteria,
      sing_criteria_name: crit,
      sing_criteria_uuid: crit_id,
    });
  };

  const manualUpdateOffers = (id: any, appStatus: any) => {
    setLoader(true);
    const data = {
      ID: id,
      application_status: appStatus,
      criteria_uuid: singCriteria.sing_criteria_uuid,
    };
    request({
      url: `school-settings/manual-update-offers-application/?school_uuid=${schoolId}`,
      method: "put",
      data: data,
    }).then((res) => {
      if (res.data.results) {
        setSeats({
          total: res.data.results.total_places,
          total_available: res.data.results.places_available,
          total_selected: res.data.results.total_selected,
        });
        const tab = res.data.results.application_list.map((item: any) => {
          return {
            ...item,
            id: item.ID,
          };
        });
        setTotalPages(res.data.results.total_pages);
        setPageNumber(res.data.results.current_page);
        setLocalData(tab);
      } else {
        setSeats({
          total: seats.total,
          total_available: seats.total,
        });
        setTotalPages(0);
        setPageNumber(0);
        setLocalData([]);
      }
      setLoader(false);
    });
  };

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div className={classes.subContainer}>
        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{manualError}</p>
          </div>
        ) : null}
        <Box marginBottom="40px">
          <Box display="flex" alignItems="center">
            <Icon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => history.push("/admin/offers")}
            >
              arrow_back
            </Icon>
            <p>Go Back</p>
          </Box>
          <Box textAlign="center">
            <p className={classes.textOne}>Manual Selection Screen</p>
          </Box>
          {/* Search Box */}
          <Box
            display="flex"
            justifyContent="end"
            position="relative"
            bottom="75px"
          >
            <input
              onChange={(e: any) => searchTextChange(e.target.value)}
              type="text"
              placeholder="Find an applicant"
              className={classes.inputStyle}
              style={{ borderBottom: "1px solid #000000" }}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                bottom: "7px",
              }}
            >
              <Icon className={classes.iconStyle}>search</Icon>
            </div>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            {criterias && (
              <ManualDropDown
                options={criterias}
                criteria={singCriteria.sing_criteria_name}
                onClick={onSelectCriteria}
              />
            )}
          </Box>

          <Box>
            <TextFieldCustom placeholder="Total seats" value={seats?.total} />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total Selected"
              value={seats?.total_selected}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total seats available"
              value={seats?.total_available}
            />
          </Box>
        </Box>
        <Box marginTop="60px">
          <ManualTable data={localData} onClick={manualUpdateOffers} />
        </Box>
        {localData.length !== 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "50px",
              marginTop: "20px",
            }}
          >
            <div>{`${pageNumber || 0} of ${totalPages || 0}`}</div>
            <div>
              <button
                onClick={() => setPageNumber(pageNumber - 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === 1 || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_left_outlined
                </Icon>
              </button>
              <button
                onClick={() => setPageNumber(pageNumber + 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === totalPages || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_right_outlined
                </Icon>
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <Loader open={loader} />
    </Grid>
  );
};

export default ManualSelection;
