import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    textTransform: "none",
    borderRadius: 50,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  secondaryMain: {
    border: "1px solid",
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    "&:hover":{
      backgroundColor:theme.palette.primary.light,
    }
  },
  secondaryLight: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    "&:hover":{
      backgroundColor:theme.palette.secondary.main,
    }
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));
