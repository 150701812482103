/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Box } from "@material-ui/core";
import { useStyle } from "./confirm.style";
import Icon from "@material-ui/core/Icon";
import { InputDatePicker } from "../../../../components/common/date-picker";
import { Button } from "../../../../components/common/button/index";
import Grid from "@material-ui/core/Grid";
import request, { ParentUser } from "../../../../utils/authUtil";
import request2 from "../../../../utils/authUtilv2";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Type } from "../../../../utils/document-types";
import Image from "../../image-checkbox/index";
import moment from "moment";

type Props = {
  prevPage?: any;
  nextPage?: any;
  stdAppId: any;
  showEmergencyContact?: boolean;
};

const Confirm: React.FC<Props> = ({
  nextPage,
  prevPage,
  stdAppId,
  showEmergencyContact,
}) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [studDetails, setStudentDetails] = useState<any>();
  const parentID = ParentUser.uuid;
  const [consentDate, setConsentDate] = useState(new Date());
  const [guardDetails, setGuardDetails] = useState([{}]);
  const [emergencyContact, setEmergencyContact] = useState<any>();
  const [signCheck, setSignCheck] = useState(false);
  const schoolID = localStorage.getItem("school-id");
  const [localData, setLocalData] = useState({
    signImgUrl: "",
    document_uuid: "",
  });
  const [terms, setTerms] = useState<string>("");
  const [error, setError] = useState({
    signCheckError: false,
  });

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-guardian-details/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data) {
        setStudentDetails(res.data.results.student_details);
        setGuardDetails(res.data.results.guardian_details);
      }
    });
    setLoader(true);
    request({
      url: `/auth/parent-documents-upload-credentials?user_uuid=${parentID}&document_type=${Type.signature}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setLocalData({
        ...localData,
        signImgUrl: res.data.results.document_url,
        document_uuid: res.data.results.document_uuid,
      });
    });
    setLoader(true);
    request2({
      url: `student-application/student-emergency-contact/?stu_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (Object.keys(res.data.results).length > 0) {
        setEmergencyContact(res.data.results);
      }
    });
    setLoader(true);
    request({
      url: `auth/application-server-date/`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setConsentDate(new Date(res.data.results));
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-school-application-consent/?school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        setTerms(response.data.results.terms_consent);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const validate = () => {
    return {
      signCheckError: signCheck === false,
    };
  };

  const onSubmitHandler = () => {
    const data = {
      parent_document_uuid: localData.document_uuid,
      document_page: "confirm_details",
      document_date: moment(consentDate).format("YYYY-MM-DD"),
    };
    request({
      url: `student-application/student-application-parent-documents/?student_app_uuid=${stdAppId}&document_uuid=${localData.document_uuid}`,
      method: "put",
      data: data,
    }).then((res) => {
      if (res.data.status_code === 1) {
        setLoader(false);
        nextPage();
      } else {
        setLoader(false);
      }
    });
  };

  const nextButton = () => {
    const err = validate();
    setError(err);
    if (err.signCheckError === false) {
      onSubmitHandler();
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        {/* part3 */}
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>Application</div>
            </div>
            <ProgressBar total={6} steps={3} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome
              title="Confirm details"
              // description="Next: ASD Confirmation"
            />
          </div>

          <p className={styles.textOne}>Student/Child Details</p>
          {studDetails && (
            <Box mt="6px">
              <p className={styles.textTwo}>
                {studDetails.first_name} {studDetails.last_name}
              </p>
              <p className={styles.textTwo}>{studDetails.address_line_one}</p>
              {studDetails.address_line_two ? (
                <p className={styles.textTwo}>{studDetails.address_line_two}</p>
              ) : null}
              <p className={styles.textTwo}>{studDetails.town}</p>
              <p className={styles.textTwo}>{studDetails.city}</p>
              <p className={styles.textTwo}>{studDetails.eir_code}</p>
              <p className={styles.textTwo}>{studDetails.country}</p>

              <div className={styles.gridContainer}>
                <p className={styles.textTwo}> Birth Certificate Forename</p>
                <p className={styles.textTwo}>
                  {studDetails?.legal_first_name || ""}
                </p>
                <p className={styles.textTwo}> Birth Certificate Surname</p>
                <p className={styles.textTwo}>
                  {studDetails?.legal_last_name || ""}
                </p>
                <span className={styles.textTwo}> D.O.B. </span>
                <span className={styles.textTwo}>
                  {studDetails.date_of_birth}
                </span>
                <span className={styles.textTwo}>PPS No.</span>
                <span className={styles.textTwo}>{studDetails.PPSNumber}</span>
                <span className={styles.textTwo}>
                  {studDetails?.gender && "Gender: "}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.gender || ""}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.religion && "Religion: "}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.religion || ""}
                </span>
              </div>
            </Box>
          )}

          {guardDetails &&
            guardDetails.map((guard: any, index) => {
              return (
                <Box marginBottom="30px" key={index}>
                  <p className={styles.textOne}>Guardian Details</p>
                  <Box mt="6px">
                    <p className={styles.textTwo}>
                      {guard.first_name} {guard.last_name}
                    </p>
                    <p className={styles.textTwo}>{guard.address_line_one}</p>
                    {guard.address_line_two ? (
                      <p className={styles.textTwo}>{guard.address_line_two}</p>
                    ) : null}
                    <p className={styles.textTwo}>{guard.town}</p>
                    <p className={styles.textTwo}>{guard.city}</p>
                    <p className={styles.textTwo}>{guard.eir_code}</p>
                    <p className={styles.textTwo}>{guard.country}</p>
                    <Box display="flex" alignItems="center" marginTop="15px">
                      <Box display="block" width="80px">
                        <p className={styles.textTwo}>Email</p>
                        <p className={styles.textTwo}>Phone:</p>
                        <p className={styles.textTwo}>Relationship: </p>
                      </Box>
                      <Box display="block" marginLeft="60px">
                        <p className={styles.textTwo}>{`${guard.email}`}</p>

                        <p className={styles.textTwo}>{`${guard.phone}`}</p>
                        <p className={styles.textTwo}>{`${guard.relation}`}</p>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}

          {showEmergencyContact &&
            emergencyContact &&
            Object.keys(emergencyContact).length > 0 && (
              <Box marginBottom="30px">
                <p className={styles.textOne}>Emergency Contact</p>
                <Box mt="6px">
                  <Box display="flex" alignItems="center" marginTop="15px">
                    <Box display="block" width="80px">
                      <p className={styles.textTwo}>Name:</p>
                      <p className={styles.textTwo}>Phone:</p>
                    </Box>
                    <Box display="block" marginLeft="60px">
                      <p
                        className={styles.textTwo}
                      >{`${emergencyContact.contact_name}`}</p>
                      <p
                        className={styles.textTwo}
                      >{`${emergencyContact.contact_number}`}</p>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

          {/* part 4 */}
          <Box>
            <p className={styles.textOne}>Confirm Details</p>
            <Box mt="6px">
              {
                <Box mb="10px">
                  <p className={styles.textThree}>{terms}</p>
                </Box>
              }
            </Box>
          </Box>
          {/* part 5 */}

          <Box mt={5}>
            {localData.signImgUrl ? (
              <Image
                src={localData.signImgUrl}
                check={signCheck}
                change={(e: any) => {
                  setSignCheck(!signCheck);
                  signCheck
                    ? setError({ signCheckError: true })
                    : setError({ signCheckError: false });
                }}
                label="Your signature"
                error={error.signCheckError}
                errorMessage="Signature confirmation is required"
              />
            ) : null}
          </Box>
          <Box>
            <InputDatePicker
              label="Date of consent"
              value={consentDate}
              disabled
            />
          </Box>
          {/* part6 */}
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={nextButton}
              />
            </div>
          </Grid>
        </Box>
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default Confirm;
