import { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ParentDashboard from "../pages/parent/parent-dashboard/index";
import ParentLogin from "../pages/common/parent-login/index";
import LandingPage from "../pages/common/landing-page/index";
import ChangePassword from "../pages/parent/change-password/index";
import ApplicationStart from "../pages/parent/application-start/index";
import Profile from "../pages/parent/profile/index";
import EditProfile from "../pages/parent/profile/edit-profile";
import ReviewPage from "../pages/parent/review-page/index";
import Cancel from "../pages/parent/cancel/index";
import Application from "../pages/parent/application/index";
import Signature from "../pages/parent/signature-capture/index";
import EnrollmentStart from "../pages/parent/enrollment-start/index";
import Enrollment from "../pages/parent/enrollment/index";

interface Props { }

const ParentRoutes = (props: Props) => {
  const token = localStorage.getItem("token");
  const [signatureFlag, setSignatureFlag] = useState<string | null>();
  useEffect(() => {
    setSignatureFlag(localStorage.getItem("isSignature"));
  }, []);

  if (!token) {
    return <ParentLogin />;
  }
  const authenticated = (
    <Switch>
      <Route exact path="/parent/dashboard">
        <ParentDashboard />
      </Route>
      <Route exact path="/parent/change-password">
        <ChangePassword />
      </Route>
      <Route exact path="/parent">
        <LandingPage />
      </Route>
      <Route exact path="/parent/application">
        <ApplicationStart />
      </Route>
      <Route path="/parent/application/:appId">
        <Application />
      </Route>
      <Route exact path="/parent/enrolment">
        <EnrollmentStart />
      </Route>
      <Route path="/parent/enrolment/:appId">
        <Enrollment />
      </Route>
      <Route exact path="/parent/profile">
        <Profile />
      </Route>
      <Route exact path="/parent/edit-profile">
        <EditProfile/>
      </Route>
      <Route exact path="/parent/review-page">
        <ReviewPage />
      </Route>
      <Route exact path="/parent/cancel">
        <Cancel />
      </Route>
      <Route exact path="/parent/signature">
      <Signature />
      </Route>
    </Switch>
  );

  return <>{signatureFlag === "false" ? <Signature /> : authenticated}</>;
};

export default ParentRoutes;
