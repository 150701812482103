import { useEffect, useState } from "react";
import { Input } from "../../../../components/common/input";
import { Box } from "@material-ui/core";
import requestv2 from "../../../../utils/authUtilv2";
import { Loader } from "../../../../components/common/loader/index";
import Grid from "@material-ui/core/Grid";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";
import { AdminButton } from "../../../../components/common/admin-button/index";
import { ConfirmBox } from "../../../../components/common/confirm-box/index";
import { updatePdf } from "./single-application.api";
import { SelectDropDown } from "../../../../components/common/select-dropdown/index";

type Props = {
  applicationID?: any;
  type?: string;
  UIType?: string;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const CriteriaDetails: React.FC<Props> = ({
  applicationID,
  type,
  UIType,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [schoolInfo, setSchoolInfo] = useState<any>([]);
  const [formDisable, setFormDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const getSchoolInfo = async (): Promise<any> => {
    setLoader(true);
    await requestv2({
      url: `student-application/student-app-criteria-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      if (Object.keys(res.data?.results).length !== 0) {
        setSchoolInfo(res.data.results);
      }

      setLoader(false);
    });
  };

  const postSchoolInfo = async (): Promise<any> => {
    await requestv2({
      url: `student-application/student-app-criteria-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "put",
      data: schoolInfo,
    }).then(() => {
      getSchoolInfo();
      updatePdf(applicationID);
      setFormDisable(true);
    });
  };

  const handleRadioChange = (name: string, index: number) => {
    const temp = [...schoolInfo];
    temp[index].answer = name;
    setSchoolInfo(temp);
  };
  const handleInput = (item: any, value: any, ind: any, field: any) => {
    const temp = [...schoolInfo];
    if (field === "followup_answer") temp[ind].followup_answer = value;
    if (field === "further_answer") temp[ind].further_answer = value;

    setSchoolInfo(temp);
  };

  const cancelEdit = () => {
    setFormDisable(true);
    getSchoolInfo();
  };

  useEffect(() => {
    getSchoolInfo();
  }, []);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <div>
          <Loader open={loader} />
          <Box width="600px">
            {schoolInfo.map((item: any, index: number) => (
              <Box key={index}>
                <InputRadioButton
                  label={item.question}
                  placeHolderOne="No"
                  placeHolderTwo="Yes"
                  valueOne={false}
                  valueTwo={true}
                  answer={item.answer}
                  isDisabled={formDisable}
                  index={index}
                  onChange={handleRadioChange}
                />
                {item?.answer && item?.further_detail_required ? (
                  <Box>
                    {item.further_question_type === "text" && (
                      <Input
                        label={item.follow_up_question}
                        disabled={formDisable}
                        value={item.followup_answer}
                        onChange={(e) =>
                          handleInput(
                            item,
                            e.target.value,
                            index,
                            "followup_answer"
                          )
                        }
                      />
                    )}
                    {item.further_question_type === "drop" && (
                      <Drop
                        item={item}
                        onChange={handleInput}
                        index={index}
                        values={item.details_list}
                        label={item.follow_up_question}
                        formDisable={formDisable}
                      />
                    )}
                  </Box>
                ) : null}
                {item?.answer &&
                item?.followup_answer &&
                item?.further_question_required &&
                item?.further_question ? (
                  <Box>
                    <Input
                      label={item.further_question}
                      disabled={formDisable}
                      value={item.further_answer}
                      onChange={(e) =>
                        handleInput(
                          item,
                          e.target.value,
                          index,
                          "further_answer"
                        )
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            ))}
          </Box>
        </div>
      </Grid>
      {localStorage.getItem("employee_type") === "Super Admin" &&
      currentGlobalDate === selectedGlobalDate ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              // disabled={isDisabled}
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={postSchoolInfo}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </>
  );
};

type DropProps = {
  item: any;
  onChange: any;
  index: any;
  values: any;
  label: string;
  formDisable: boolean;
};
const Drop: React.FC<DropProps> = ({
  item,
  onChange,
  index,
  values,
  label,
  formDisable,
}) => {
  let options = [{ name: "--Select--", value: "" }];
  options = [
    ...options,
    ...values.map((option: string) => ({
      name: option,
      value: option,
    })),
  ];

  return (
    <SelectDropDown
      label={label}
      item={options}
      value={item.followup_answer}
      onChange={(e: any) => {
        onChange(item, e.target.value, index, "followup_answer");
      }}
      disabled={formDisable}
    />
  );
};

export default CriteriaDetails;
