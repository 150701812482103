import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from "react";
import { useStyle } from "./criteria-tab.style";
import { Loader } from "../../../components/common/loader/index";
import SpecialDropDown from "./special-dropdown";
import { getCustomSettings } from "../custom-settings/cust-settings-api";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  applicationID: string;
  readyToSwitch?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const SpecialCriteria: React.FC<Props> = ({
  applicationID,
  readyToSwitch,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState(false);
  const [rollOver, setRollOver] = useState<boolean>(false);
  useEffect(() => {
    getCustomSettings(applicationID).then((res) => {
      if (res.data.status_code === 1) {
        setRollOver(res.data.results.allow_roll_over);
      }
    });
  }, []);
  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.specContainer}>
          <Box display="flex" alignItems="center" mb={1}>
            <Checkbox
              disabled={selectedGlobalDate !== currentGlobalDate}
              color="default"
              onChange={(e) => {
                setRollOver(!rollOver);
              }}
              checked={rollOver}
            />
            <p className={classes.text}>Allow Roll Over</p>
          </Box>
          <SpecialDropDown
            Id={applicationID}
            readyToSwitch={readyToSwitch}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
            allowRollOver={rollOver}
          />
        </div>
      </Grid>
    </div>
  );
};

export default SpecialCriteria;
