import cn from "classnames";
import { useStyle } from "./text.style";

type TextProps = {
  heading?: boolean;
  subHeading?: boolean;
  paragraph?: boolean;
};

export const Text: React.FC<TextProps> = ({
  children,
  heading,
  subHeading,
  paragraph,
}) => {
  const classes = useStyle();

  return (
    <div
      className={cn(
        classes.root,
        heading ? classes.heading : null,
        subHeading ? classes.subHeading : null,
        paragraph ? classes.paragraph : null
      )}
    >
      {children}
    </div>
  );
};
