import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  description: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
  },
  buttonWidth:{
    width:'210px',
    margin: "40px 0px",
  [theme.breakpoints.down("sm")]: {
    margin: "40px auto",    
    width: "298px",
  }, 
  
},
margin:{
marin:"20px 0px"
},
  textPadding: {
    paddingLeft: "25px",
  },
  
  checkIcon:{
    color:theme.palette.success.main
  },
  
}));
