import { Box, ListItem, makeStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@material-ui/core/Link";
import colors from "../theme/colors";

const useStyle = makeStyles((theme) => ({
  button: {
    fontWeight: "bold",
  },
  selectedStyle: {
    backgroundColor: "#2B2B33",
    borderTopRightRadius: "23px",
    borderBottomRightRadius: "23px",
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.8),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
  },
  menuStyle: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1.8),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: "space-between",
  },
}));
interface Props {
  path: string;
  name: string;
  startIcon: JSX.Element;
  secondPath?: any;
}

const MenuItem = ({ path, name, startIcon: StartIcon, secondPath }: Props) => {
  const styles = useStyle();
  const { pathname } = useLocation();
  const style =
    pathname === path || pathname.includes(`${secondPath}`)
      ? styles.selectedStyle
      : styles.menuStyle;
  const buttonColor =
    pathname === path || pathname.includes(`${secondPath}`)
      ? "white"
      : "#2B2B33";
  const endIcon = (
    <ArrowForwardIosIcon
      fontSize="small"
      style={{
        color:
          pathname === path || pathname.includes(`${secondPath}`)
            ? "white"
            : colors.primary.main,
      }}
    />
  );

  return (
    <ListItem className={style}>
      <Link
        style={{
          color: buttonColor,
          fontWeight: "bold",
          textDecoration: "none",
        }}
        component={RouterLink}
        to={path}
      >
        <Box style={{ alignItems: "center", display: "flex", fontSize: "0.8em" }}>
          {StartIcon}
          <span style={{ marginLeft: "10px" }}>{name}</span>
        </Box>
      </Link>
      <Box style={{}}>{endIcon}</Box>
    </ListItem>
  );
};

export default MenuItem;
