import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./start-selection.style";
import { AdminButton } from "../../../../components/common/admin-button/index";
import { TextFieldCustom } from "./text-field-component/index";
import SelectionDropDown from "./selection-dropdown-component/index";
import DataTable from "./selection-table/index";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";
import { MessageBox } from "../../../../components/common/message-Box/index";

const StartSelection = () => {
  const classes = useStyle();
  const history = useHistory();
  const schoolId = localStorage.getItem("school-id");
  const [criterias, setCriterias] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [totalSeats, setTotalSeats] = useState<number>();
  const [availableSeats, setAvailableSeats] = useState<number>();
  const [totalSelected, setTotalSelected] = useState<number>();
  const [availableCandidates, setAvailableCandidates] = useState<number>();
  const [currentCriteria, setCurrentCriteria] = useState<any>(null);
  // const [selectedApplications, setSelectedApplications] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [disableActionButton, setDisableActionButton] =
    useState<boolean>(false);
  const [buttonType, setButtonType] = useState<string>("");
  const [selectionComplete, setSelectionComplete] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const getCriterias = (): any => {
    setLoader(true);
    request({
      url: `/school-settings/offers-criteria-details/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      setTotalSeats(res.data.results.total_places);
      setCriterias(res.data.results.criteria_list);
      setLoader(false);
    });
  };

  const getApplications = (criteria: any): any => {
    setLoader(true);
    setCurrentCriteria(criteria);
    request({
      url: `/school-settings/offers-criteria-application-list/?school_uuid=${schoolId}&criteria_uuid=${criteria.criteria_uuid}`,
      method: "get",
    }).then((res) => {
      setAvailableSeats(res.data.results.places_available);
      setAvailableCandidates(res.data.results.no_of_candidates);
      setTotalSelected(res.data.results.total_selected);
      const tab = res.data.results.application_list.map((item: any) => {
        return {
          ...item,
          id: item.ID,
        };
      });
      setTableData(tab);
      setDisableActionButton(false);
      if (
        res.data.results.places_available >= res.data.results.no_of_candidates
      ) {
        setButtonType("normal");
      }
      if (
        res.data.results.no_of_candidates > res.data.results.places_available
      ) {
        setButtonType("lottery");
      }
      setLoader(false);
    });
  };

  // const saveSelectedList = (list: any) => {
  //   setSelectedApplications(list);
  // };

  const selectApplications = () => {
    setLoader(true);
    let nextCriteria: any = "";
    if (criterias.indexOf(currentCriteria) < criterias.length) {
      nextCriteria = criterias[criterias.indexOf(currentCriteria) + 1];
    }
    const application_list = tableData.map((dt: any) => dt.id);
    const dt = {
      criteria_uuid: currentCriteria.criteria_uuid,
      is_special: currentCriteria.is_special,
      places_available: availableSeats,
      no_of_candidates: availableCandidates,
      next_criteria_uuid: nextCriteria?.criteria_uuid || "",
      application_list: application_list,
    };

    request({
      url: `/school-settings/update-offers-criteria-application/?school_uuid=${schoolId}`,
      method: "post",
      data: dt,
    }).then((res) => {
      setAvailableSeats(res.data.results.places_available);
      setAvailableCandidates(res.data.results.no_of_candidates);
      setTotalSelected(res.data.results.total_selected);
      const tab = res.data.results.application_list.map((item: any) => {
        return {
          ...item,
          id: item.ID,
        };
      });
      setTableData(tab);
      setDisableActionButton(true);
      getCriterias();
      setLoader(false);
      if (res.data.results.selection_process_status === "COMPLETED") {
        setSelectionComplete(true);
        setRedirectToDashboard(true);
      } 
    });
  };

  useEffect(() => {
    getCriterias();
  }, []);

  useEffect(() => {
    if (selectionComplete === false && redirectToDashboard) {
      history.push(`/admin/offers`);
    }
  }, [selectionComplete]);
  return (
    <Grid container direction="row" justifyContent="space-between">
      <div className={classes.subContainer}>
        <Box marginBottom="40px">
          <Box display="flex" alignItems="center">
            <Icon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => history.push("/admin/offers")}
            >
              arrow_back
            </Icon>
            <p>Go Back</p>
          </Box>
          <Box textAlign="center">
            <p className={classes.textOne}>Candidate Selection Screen</p>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            {criterias && (
              <SelectionDropDown
                options={criterias}
                checkboxClick={getApplications}
              />
            )}
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total places"
              value={totalSeats ? totalSeats?.toString() : ""}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total Selected"
              value={totalSelected ? totalSelected?.toString() : ""}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total places available for "
              value={`${currentCriteria?.criteria_name || ""}: ${
                availableSeats || ""
              }`}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="No. of candidates available for  "
              value={`${currentCriteria?.criteria_name || ""}: ${
                availableCandidates || ""
              }`}
            />
          </Box>
          <Box maxWidth="250px">
            {buttonType === "normal" ? (
              <AdminButton
                onClick={selectApplications}
                label="Select Checked candidates"
                padding="10px"
                disabled={disableActionButton}
              />
            ) : null}
          </Box>
          <Box maxWidth="250px">
            {buttonType === "lottery" ? (
              <AdminButton
                label="Start Lottery Process"
                padding="10px"
                onClick={() =>
                  history.push(
                    `/admin/lottery/${currentCriteria.criteria_uuid}`
                  )
                }
              />
            ) : null}
          </Box>
        </Box>
        {buttonType === "lottery" ? (
          <Box display="flex" justifyContent="center" marginTop="20px">
            <Box textAlign="center" width="60%">
              <p className={classes.secondTitle}>
                Number of candidates who meet {`${currentCriteria?.criteria_name}`} is greater than the
                number of places available, please use the lottery process to
                select candidate for {`${currentCriteria?.criteria_name}`} to
                continue
              </p>
            </Box>
          </Box>
        ) : null}
        <Box marginTop={buttonType === "lottery" ? "20px" : "60px"}>
          {tableData ? (
            <DataTable
              data={tableData}
              // onCheck={saveSelectedList}
            />
          ) : (
            <Box textAlign="center">
              <p className={classes.secondTitle}>
                Select a Criteria to start Selection Process
              </p>
            </Box>
          )}
        </Box>
      </div>
      <MessageBox
        message="The Selection Process is complete."
        open={selectionComplete}
        setOpen={setSelectionComplete}
      />
      <Loader open={loader} />
    </Grid>
  );
};

export default StartSelection;
