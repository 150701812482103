import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    borderTopRadius: 4,
    color: theme.palette.text.primary,
    padding: "0% 3%",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: "16px",
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  checked: {
    color: theme.palette.success.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginRight: "-7px",
  },
  checkedGrey: {
    color: theme.palette.grey[200],
    "&:hover": {
      backgroundColor: "transparent",
    },
    marginRight: "-7px",
  },
  borderGreen: {
    border: "1px solid",
    borderColor: theme.palette.success.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
}));
