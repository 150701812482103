export const county = [
  {
    name: "-- Select County --",
    value: "",
  },
  {
    name: "Antrim",
    value: "Antrim",
  },
  {
    name: "Armagh",
    value: "Armagh",
  },
  {
    name: "Derry",
    value: "Derry",
  },
  {
    name: "Carlow",
    value: "Carlow",
  },
  {
    name: "Cavan",
    value: "Cavan",
  },
  {
    name: "Clare",
    value: "Clare",
  },
  {
    name: "Cork",
    value: "Cork",
  },
  {
    name: "Donegal",
    value: "Donegal",
  },
  {
    name: "Down",
    value: "Down",
  },
  {
    name: "Dublin",
    value: "Dublin",
  },
  {
    name: "Fermanagh",
    value: "Fermanagh",
  },
  {
    name: "Galway",
    value: "Galway",
  },
  {
    name: "Kerry",
    value: "Kerry",
  },
  {
    name: "Kildare",
    value: "Kildare",
  },
  {
    name: "Kilkenny",
    value: "Kilkenny",
  },
  {
    name: "Laois",
    value: "Laois",
  },
  {
    name: "Leitrim",
    value: "Leitrim",
  },
  {
    name: "Limerick",
    value: "Limerick",
  },
  {
    name: "Longford",
    value: "Longford",
  },
  {
    name: "Louth",
    value: "Louth",
  },
  {
    name: "Mayo",
    value: "Mayo",
  },
  {
    name: "Meath",
    value: "Meath",
  },
  {
    name: "Monaghan",
    value: "Monaghan",
  },
  {
    name: "Offaly",
    value: "Offaly",
  },
  {
    name: "Roscommon",
    value: "Roscommon",
  },
  {
    name: "Sligo",
    value: "Sligo",
  },
  {
    name: "Tyrone",
    value: "Tyrone",
  },
  {
    name: "Waterford",
    value: "Waterford",
  },
  {
    name: "Westmeath",
    value: "Westmeath",
  },
  {
    name: "Wexford",
    value: "Wexford",
  },
  {
    name: "Tipperary",
    value: "Tipperary",
  },
  {
    name: "Wicklow",
    value: "Wicklow",
  },
].sort((a, b) => {
  return a.name >= b.name ? 1 : -1;
});
