import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "./time-picker.style";
import cn from "classnames";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

type InputProps = {
  label?: string;
  value?: any;
  error?: boolean;
  errorMessage?: string | null;
  onChange?: any;
  disabled?: boolean;
};

export const TimePicker: React.FC<InputProps> = ({
  label,
  value,
  error = false,
  errorMessage = "Required",
  onChange,
  disabled,
}) => {
  const classes = useStyle();
  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <InputLabel
          className={cn(classes.label, error ? classes.labelError : null)}
        >
          {label}
        </InputLabel>
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            "aria-label": "change time",
            disableRipple: true,
            disableFocusRipple: true,
          }}
          ampm={false}
          format="HH:mm"
          keyboardIcon={<Icon className={classes.icon}>access_time_icon</Icon>}
          className={cn(classes.root, error ? classes.error : "")}
          variant="inline"
          invalidDateMessage=""
          disabled={disabled}
          InputProps={{
            disableUnderline: true,
            classes: { root: classes.inputStyle },
          }}
        />
      </MuiPickersUtilsProvider>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
