import { Box } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import request from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader/index";
import { InputDatePicker } from "../../../components/common/date-picker";
import { TimePicker } from "../../../components/common/time-picker/index";

import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  error?: any;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface IreldateData {
  openDate: Date | null;
  cutOffdate: Date | null;
  notificationDate: Date | null;
  completedByDate: Date | null;
  closeDateTime: Date | null;
  notificationDateTime: Date | null;
  completedByTime: Date | null;
  openDateTime: Date | null;
}

interface PostreldateType {
  id: string;
  reldate: IreldateData;
}

const RelevantDates: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [reldate, setRelDate] = useState<IreldateData>({
    openDate: null,
    cutOffdate: null,
    notificationDate: null,
    completedByDate: null,
    closeDateTime: null,
    notificationDateTime: null,
    completedByTime: null,
    openDateTime: null,
  });
  const [validError, setValidError] = useState({
    openDate_err: false,
    cutOffdate_err: false,
    notificationDate_err: false,
    completedByDate_err: false,
    closeDateTime_err: false,
    notificationDateTime_err: false,
    completedByTime_err: false,
    openDateTime_err: false,
  });

  const getRelDate = async (id: string): Promise<any> => {
    const res = await request({
      url: `school-settings/application-dates-details/?application_uuid=${id}`,
      method: "get",
    });
    return res;
  };

  const onValueChange = (e: any, inputType: string) => {
    switchReady(false);
    switch (inputType) {
      case "openDate":
        setRelDate({
          ...reldate,
          openDate: e,
        });
        setValidError({
          ...validError,
          openDate_err: e === null,
        });
        break;
      case "cutOffdate":
        setRelDate({
          ...reldate,
          cutOffdate: e,
        });
        setValidError({
          ...validError,
          cutOffdate_err: e === null,
        });
        break;
      case "notificationDate":
        setRelDate({
          ...reldate,
          notificationDate: e,
        });
        setValidError({
          ...validError,
          notificationDate_err: e === null,
        });
        break;
      case "completedByDate":
        setRelDate({
          ...reldate,
          completedByDate: e,
        });
        setValidError({
          ...validError,
          completedByDate_err: e === null,
        });
        break;
      case "closeDateTime":
        setRelDate({
          ...reldate,
          closeDateTime: e,
        });
        setValidError({
          ...validError,
          closeDateTime_err:
            e === null || moment(e).toString() === "Invalid date",
        });
        break;
        case "openDateTime":
        setRelDate({
          ...reldate,
          openDateTime: e,
        });
        setValidError({
          ...validError,
          openDateTime_err:
            e === null || moment(e).toString() === "Invalid date",
        });
        break;
      case "notificationDateTime":
        setRelDate({
          ...reldate,
          notificationDateTime: e || moment(e).toString() === "Invalid date",
        });
        setValidError({
          ...validError,
          notificationDateTime_err: e === null,
        });
        break;
      case "completedByTime":
        setRelDate({
          ...reldate,
          completedByTime: e || moment(e).toString() === "Invalid date",
        });
        setValidError({
          ...validError,
          completedByTime_err: e === null,
        });
        break;
    }
  };

  const validate = () => {
    return {
      openDate_err: reldate.openDate === null,
      cutOffdate_err: reldate.cutOffdate === null,
      notificationDate_err: reldate.notificationDate === null,
      completedByDate_err: reldate.completedByDate === null,
      closeDateTime_err:
        reldate.closeDateTime === null ||
        moment(reldate.closeDateTime).toString() === "Invalid date",
      openDateTime_err:
        reldate.openDateTime === null ||
        moment(reldate.openDateTime).toString() === "Invalid date",
      notificationDateTime_err:
        reldate.notificationDateTime === null ||
        moment(reldate.notificationDateTime).toString() === "Invalid date",
      completedByTime_err:
        reldate.completedByTime === null ||
        moment(reldate.completedByTime).toString() === "Invalid date",  
    };
  };

  const postRelDate = async ({
    id,
    reldate,
  }: PostreldateType): Promise<any> => {
    const err = validate();
    setValidError(err);
    if (
      err.closeDateTime_err === false &&
      err.openDateTime_err === false &&
      err.completedByDate_err === false &&
      err.cutOffdate_err === false &&
      err.notificationDateTime_err === false &&
      err.notificationDate_err === false &&
      err.openDate_err === false &&
      err.completedByTime_err === false
    ) {
      setLoader(true);
      const res = await request({
        url: `school-settings/application-dates-details/?application_uuid=${id}`,
        method: "put",
        data: {
          close_date_time: moment(reldate.closeDateTime).format("HH:mm"),
          open_time: moment(reldate.openDateTime).format("HH:mm"),
          open_date: moment(reldate.openDate).format("DD/MM/YYYY"),
          cut_Off_date: moment(reldate.cutOffdate).format("DD/MM/YYYY"),
          notification_date: moment(reldate.notificationDate).format(
            "DD/MM/YYYY"
          ),
          notification_date_time: moment(reldate.notificationDateTime).format(
            "HH:mm"
          ),
          completed_by_date: moment(reldate.completedByDate).format(
            "DD/MM/YYYY"
          ),
          completed_by_time: moment(reldate.completedByTime).format(
            "HH:mm"
          ),
        },
      });
      if (res) {
        setLoader(false);
        switchReady(true);
      }
      return res;
    }
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getReldateError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["reldate", applicationID, selectedGlobalDate],
    () => getRelDate(applicationID),
    {
      select: (reldate) => reldate.data,
    }
  );

  const mutation: UseMutationResult<any, Error, PostreldateType> = useMutation(
    async ({ id, reldate }) => postRelDate({ id, reldate })
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setRelDate({
          ...reldate,
          openDate: new Date(data.results.open_date),
          cutOffdate: new Date(data.results.cut_Off_date),
          notificationDate: new Date(data.results.notification_date),
          completedByDate: new Date(data.results.completed_by_date),
          closeDateTime: new Date(
            Date.prototype.setHours.apply(
              new Date(),
              data.results.close_date_time.split(":")
            )
          ),openDateTime: new Date(
            Date.prototype.setHours.apply(
              new Date(),
              data.results.open_time.split(":")
            )
          ),
          notificationDateTime: new Date(
            Date.prototype.setHours.apply(
              new Date(),
              data.results.notification_date_time.split(":")
            )
          ),
          completedByTime: new Date(
            Date.prototype.setHours.apply(
              new Date(),
              data.results.completed_by_time.split(":")
            )
          ),
        });
      }
    }
  }, [data]);

  const onSaveClick = () => {
    mutation.mutate({ id: applicationID, reldate });
  };

  return (
    <div>
      <div>
        {/* post loader */}
        <Loader open={loader} />
      </div>
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getReldateError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <>
          <Box width="400px">
            <InputDatePicker
              label="Open Date"
              value={reldate.openDate}
              onChange={(e: any) => {
                onValueChange(e, "openDate");
              }}
              error={validError.openDate_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box marginBottom="34px" width="400px">
            <TimePicker
              label="Open Time"
              value={reldate.openDateTime}
              onChange={(e: any) => {
                onValueChange(e, "openDateTime");
              }}
              error={validError.openDateTime_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box width="400px">
            <InputDatePicker
              label="Close Date"
              value={reldate.cutOffdate}
              onChange={(e: any) => {
                onValueChange(e, "cutOffdate");
              }}
              error={validError.cutOffdate_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box marginBottom="34px" width="400px">
            <TimePicker
              label="Close Date Time"
              value={reldate.closeDateTime}
              onChange={(e: any) => {
                onValueChange(e, "closeDateTime");
              }}
              error={validError.closeDateTime_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box width="400px">
            <InputDatePicker
              label="Notification Date"
              value={reldate.notificationDate}
              onChange={(e: any) => {
                onValueChange(e, "notificationDate");
              }}
              error={validError.notificationDate_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box marginBottom="34px" width="400px">
            <TimePicker
              label="Notification Time"
              value={reldate.notificationDateTime}
              onChange={(e: any) => {
                onValueChange(e, "notificationDateTime");
              }}
              error={validError.notificationDateTime_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box width="400px">
            <InputDatePicker
              label="Completed By Date"
              value={reldate.completedByDate}
              onChange={(e: any) => {
                onValueChange(e, "completedByDate");
              }}
              error={validError.completedByDate_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          <Box marginBottom="34px" width="400px">
            <TimePicker
              label="Completed By Time"
              value={reldate.completedByTime}
              onChange={(e: any) => {
                onValueChange(e, "completedByTime");
              }}
              error={validError.completedByTime_err}
              disabled={selectedGlobalDate !== currentGlobalDate}
            />
          </Box>
          {selectedGlobalDate === currentGlobalDate && (
            <Box style={{ width: 100, marginTop: 40 }} mb="40px">
              <AdminButton label="Save" onClick={onSaveClick} />
            </Box>
          )}
        </>
      ) : null}
    </div>
  );
};

export default RelevantDates;
