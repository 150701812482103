import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./add-student.style";
import { IconRounded } from "../icon/index";
import { AddButton } from "../add-button/index";
import { Input } from "../input/index";
import { Button } from "../button/index";
import request, { ParentUser } from "../../../utils/authUtil";
import { Loader } from "../loader";
type AddStudentProps = {
  refetchStudents?: any;
  isActive: boolean,
};

export const AddStudent: React.FC<AddStudentProps> = ({ refetchStudents, isActive }) => {
  const classes = useStyle();
  const [show, setShow] = useState(false);
  const addButtonHandler = () => {
    setShow(true);
  };
  const [loader, setLoader] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState({
    firstNameErr: false,
    lastNameErr: false,
  });
  const schoolID = localStorage.getItem("school-id");
  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "firstName":
        setFirstName(event.target.value);
        setError({
          ...error,
          firstNameErr: event.target.value.length === 0,
        });
        break;
      case "lastName":
        setLastName(event.target.value);
        setError({
          ...error,
          lastNameErr: event.target.value.length === 0,
        });
        break;
    }
  };

  const validate = () => {
    return {
      firstNameErr: firstName.length === 0,
      lastNameErr: lastName.length === 0,
    };
  };

  const onSave = async () => {
    const err = validate();
    setError(err);
    setLoader(true);
    if (err.firstNameErr === false && err.lastNameErr === false) {
      var data = {
        school_uuid: schoolID,
        parent_uuid: ParentUser.uuid,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
      };
      await request({
        url: "student-application/student-application-details/",
        method: "post",
        data: data,
      });
      await refetchStudents();
      setFirstName("");
      setLastName("");
      setLoader(false);
      setShow(false);
    }
  };

  return (
    <div className={classes.root}>
      <Loader open={loader} />
      <Grid container direction="row" alignItems="center">
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          className={classes.nameContainer}
        >
          <IconRounded iconName="face" bgColor="rgba(251, 173, 22, 0.2)" />
          <div className={classes.text}>Add Student/Child</div>
        </Grid>
        <Grid
          container
          className={classes.buttonContainer}
          justifyContent="flex-end"
          alignItems="center"
        >
          {!show ? (
            <AddButton
              iconName="add_button"
              iconSize="25px"
              isDisabled={!isActive}
              onClick={addButtonHandler}
            />
          ) : null}
        </Grid>
      </Grid>
      {show ? (
        <div style={{ marginTop: 20 }}>
          <Grid container direction="column">
            <Input
              label="First name of student/child"
              grey
              value={firstName}
              onChange={(e) => onValueChange(e,"firstName")}
              error={error.firstNameErr}
            />
            <Input
              label="Last name of student/child"
              grey
              value={lastName}
              onChange={(e) => onValueChange(e,"lastName")}
              error={error.lastNameErr}
            />
            <div className={classes.buttonWidth}>
              <Button label="Save" onClick={onSave} />
            </div>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};
