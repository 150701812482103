import React, { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Box } from "@material-ui/core";
import { useStyle } from "./confirm-enroll.style";
import Icon from "@material-ui/core/Icon";
import { Button } from "../../../../components/common/button/index";
import Grid from "@material-ui/core/Grid";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import request from "../../../../utils/authUtil";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";
import { InputRadio } from "../../../../components/common/input-radio/index";

type Props = {
  prevPage?: any;
  nextPage?: any;
  stdAppId: any;
};

const ConfirmEnrollment: React.FC<Props> = ({
  nextPage,
  prevPage,
  stdAppId,
}) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [studDetails, setStudentDetails] = useState<any>();
  const [guardDetails, setGuardDetails] = useState([{}]);
  const [signGend, setSingGend] = useState({
    identify_gender: false,
    single_gender_school_notice: "",
  });
  const schoolID = localStorage.getItem("school-id");
  const [gender, setGender] = useState<string>("");
  const [check, setCheck] = useState<boolean>(false);
  const [error, setError] = useState({ confirmErr: false });
  const handleRadioChangeSing = () => {
    setCheck(!check);
    check ? setError({ confirmErr: true }) : setError({ confirmErr: false });
  };
  const getSingleGendNotice = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      if (res.data) {
        setSingGend({
          ...signGend,
          identify_gender: res.data.results.is_single_gender_school,
          single_gender_school_notice:
            res.data.results.single_gender_school_notice,
        });
      }
      setLoader(false);
    });
  };

  const getGenderData = () => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrol-application-details/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      if (res.data) {
        setGender(res.data.results.identify_gender);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-guardian-details/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data) {
        setStudentDetails(res.data.results.student_details);
        setGuardDetails(res.data.results.guardian_details);
      }
      getSingleGendNotice();
      getGenderData();
    });
  }, []);
  const validate = () => {
    return {
      confirmErr: check === false,
    };
  };
  const onSubmit = () => {
    const err = validate();
    setError(err);
    if (err.confirmErr === false) {
      setLoader(true);
      const data = {
        identify_gender: gender,
      };
      request({
        url: `student-enrolment/student-enrol-application-details/?student_app_uuid=${stdAppId}`,
        method: "put",
        data: data,
      }).then((response) => {
        if (response.data.status_code === 1) {
          setLoader(false);
          nextPage();
        } else {
          setLoader(false);
        }
      });
    }
  };

  const handleRadioChange = (name: string) => {
    if (name === `MALE`) {
      setGender("MALE");
    }
    if (name === `FEMALE`) {
      setGender("FEMALE");
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>Application</div>
            </div>
            <ProgressBar total={7} steps={1} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome title="Confirm details" description="Next: More details" />
          </div>

          {studDetails && (
            <>
              <p className={styles.textOne}>Student/Child Details</p>
              <Box>
                <p className={styles.textTwo}>
                  {studDetails.first_name} {studDetails.last_name}
                </p>
                <p className={styles.textTwo}>{studDetails.address_line_one}</p>
                {studDetails.address_line_two ? (
                  <p className={styles.textTwo}>
                    {studDetails.address_line_two}
                  </p>
                ) : null}
                <p className={styles.textTwo}>{studDetails.town}</p>
                <p className={styles.textTwo}>{studDetails.city}</p>
                <p className={styles.textTwo}>{studDetails.eir_code}</p>
                <p className={styles.textTwo}>{studDetails.country}</p>

                <div className={styles.gridContainer}>
                  <p className={styles.textTwo}> Birth Certificate Forename</p>
                  <p className={styles.textTwo}>
                    {studDetails?.legal_first_name || ""}
                  </p>
                  <p className={styles.textTwo}> Birth Certificate Surname</p>
                  <p className={styles.textTwo}>
                    {studDetails?.legal_last_name || ""}
                  </p>
                  <span className={styles.textTwo}> D.O.B. </span>
                  <span className={styles.textTwo}>
                    {studDetails.date_of_birth}
                  </span>
                  <span className={styles.textTwo}>PPS No.</span>
                  <span className={styles.textTwo}>
                    {studDetails.PPSNumber}
                  </span>
                  <span className={styles.textTwo}>
                    {studDetails?.gender && "Gender: "}
                  </span>
                  <span className={styles.textTwo}>
                    {studDetails?.gender || ""}
                  </span>
                  <span className={styles.textTwo}>
                    {studDetails?.religion && "Religion: "}
                  </span>
                  <span className={styles.textTwo}>
                    {studDetails?.religion || ""}
                  </span>
                </div>
              </Box>
            </>
          )}

          {guardDetails &&
            guardDetails.map((guard: any, index) => {
              return (
                <>
                  <p className={styles.textOne}>Guardian Details</p>
                  <Box>
                    <p className={styles.textTwo}>
                      {guard.first_name} {guard.last_name}
                    </p>
                    <p className={styles.textTwo}>{guard.address_line_one}</p>
                    {guard.address_line_two ? (
                      <p className={styles.textTwo}>{guard.address_line_two}</p>
                    ) : null}
                    <p className={styles.textTwo}>{guard.town}</p>
                    <p className={styles.textTwo}>{guard.city}</p>
                    <p className={styles.textTwo}>{guard.eir_code}</p>
                    <p className={styles.textTwo}>{guard.country}</p>
                    <Box display="flex" alignItems="center" mt="15px" mb="30px">
                      <Box display="block" width="80px">
                        <p className={styles.textTwo}>Email</p>
                        <p className={styles.textTwo}>Phone:</p>
                        <p className={styles.textTwo}>Relationship: </p>
                      </Box>
                      <Box display="block" marginLeft="60px">
                        <p className={styles.textTwo}>{`${guard.email}`}</p>

                        <p className={styles.textTwo}>{`${guard.phone}`}</p>
                        <p className={styles.textTwo}>{`${guard.relation}`}</p>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}

          <Box>
            <Box>
              {signGend.identify_gender === true ? (
                <>
                  <p className={styles.textOne}>
                    Prospective Student's/Child's Gender
                  </p>
                  <Box mb="30px">
                    <p className={styles.textThree}>
                      {signGend.single_gender_school_notice}
                    </p>
                  </Box>
                  <InputRadioButton
                    label="Gender"
                    placeHolderOne="Female"
                    placeHolderTwo="Male"
                    valueOne="FEMALE"
                    valueTwo="MALE"
                    onChange={handleRadioChange}
                    isCheckString
                    answer={gender}
                    index={0}
                  />
                </>
              ) : null}
              <InputRadio
                radioHolderOne="I confirm"
                onChange={handleRadioChangeSing}
                check={check}
                error={error.confirmErr}
                errorMessage="Required"
              />
            </Box>
          </Box>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onSubmit}
              />
            </div>
          </Grid>
        </Box>
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default ConfirmEnrollment;
