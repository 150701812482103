/* eslint-disable no-useless-escape */
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { Text } from "../../../components/common/text/index";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./forgot-password.style";
import { Footer } from "../../../components/common/footer/index";
import { Input } from "../../../components/common/input/index";
import { useState } from "react";
import request from "../../../utils/authUtil";
import { Box } from "@material-ui/core";
import Logo from "../../../components/common/logo";
import { Loader } from "../../../components/common/loader/index";
import { CheckNetwork } from "../../../utils/helper";

const ParentForgotPassword = () => {
  const classes = useStyle();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [validError, setValidError] = useState({
    emailError: false,
    emailAcceptError: false,
  });

  const validate = () => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    return {
      emailError: email.length === 0,
      emailAcceptError: !emailRegex.test(email),
    };
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "email":
        setEmail(event.target.value);
        setValidError({
          ...validError,
          emailError: event.target.value.length === 0,
        });
        break;
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const err = validate();
    setValidError(err);
    if (err.emailError === false && err.emailAcceptError === false) {
      setLoader(true);
      request({
        url: "/auth/parent-forgot-password/",
        method: "post",
        data: {
          email,
          device_type: "web",
        },
      })
        .then((response) => {
          setLoader(false);
          if (response.data.status_code === 1) {
            setSuccess("Forgot password e-mail sent successfully!!");
            setEmail("");
            setError("");
          } else if (response.data.status_code === -1) {
            setError(response.data.status_message);
            setSuccess("");
          }
        })
        .catch((e) => {
          setError(e);
        });
    }
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <CheckNetwork />
          <Loader open={loader} />
          <Logo />
        </div>
        <div style={{ width: "130px" }}>
          <Link to="/parent/dashboard" style={{ textDecoration: "none" }}>
            <Button label="Login" light />
          </Link>
        </div>
      </Grid>
      <Grid container justifyContent="center">
        <div className={classes.card}>
          <div style={{ marginBottom: 34 }}>
            <Text heading>Forgot password</Text>
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          {/* success msg*/}
          <p
            style={{
              margin: 10,
              color: "#0B8A00",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {success}
          </p>

          <div style={{ marginBottom: 34 }}>
            <Text paragraph>Please confirm your email</Text>
          </div>
          <Grid container direction="column">
            <Input
              label="Your email *"
              value={email}
              onChange={(e: any) => {
                onValueChange(e, "email");
              }}
              error={validError.emailError || validError.emailAcceptError}
              errorMessage={
                validError.emailError
                  ? "Required"
                  : "Please enter valid email ID"
              }
            />
          </Grid>

          <Box display="flex" justifyContent="center">
            <Box mr="30px" width="30%">
              <Link to="/parent" style={{ textDecoration: "none" }}>
                <Button
                  label="Back"
                  light
                  startIcon="arrow_back_ios"
                  iconSize="13px"
                />
              </Link>
            </Box>
            <Box width="30%">
              <Button label="Submit" onClick={onSubmit} />
            </Box>
          </Box>
        </div>
      </Grid>
      <Grid container>
        <div style={{ width: "100vw" }}>
          <Footer center />
        </div>
      </Grid>
    </Grid>
  );
};

export default ParentForgotPassword;
