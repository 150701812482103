import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({

container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "5vh",
    minWidth:"10px"
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
}));
