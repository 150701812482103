import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  iconlabel: {
    color: theme.palette.grey[300],
    marginRight: 2,
    fontSize: 18,
  },
  buttonWidth: {
    margin: "44px 0px",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "43%",
    },
  },
  
  icon: {
    color: theme.palette.grey[300],
    marginRight: "10px",
    fontSize: "18px",
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.grey[300],
  },
  textOne: {
    fontSize: "14px",
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  card: {
    marginTop: 82,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "80%",
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%"
    },
  },
  completeIcon: {
    color: theme.palette.success.main,
  },
  checkButton: {
    cursor: "pointer",
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: "bold",
    border: 'double',
    padding: 10,
    marginTop: -12,
  },
}));
