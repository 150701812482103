/* eslint-disable no-restricted-globals */
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { AdminButton } from "../../../components/common/admin-button/index";
import Question from "./question";
import { useStyle } from "./edu-ques.style";
import cn from "classnames";
import { Loader } from "../../../components/common/loader/index";
import { getEducation, postEducation } from "./edy-ques.api";
import request from "../../../utils/authUtil";
import { ConfirmBox } from "../../../components/common/confirm-box";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import Languages from "./languages";
import SubjectChoice from "./subject-choice";
type Props = {
  applicationID: string;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface educationData {
  edu_uuid: string;
  eduQuestion: string;
  details: boolean;
  furtherDetails: string;
  furtherDetails_uuid: string;
  require_supporting_doc: any;
}

interface postEducationData {
  id: string;
  education: educationData[];
}

const EducationalQuestions: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const default_state = [
    {
      edu_uuid: "",
      eduQuestion: "",
      details: false,
      furtherDetails: "",
      furtherDetails_uuid: "",
      require_supporting_doc: false,
    },
  ];
  const [education, setEducation] = useState<educationData[]>([
    {
      edu_uuid: "",
      eduQuestion: "",
      details: false,
      furtherDetails: "",
      furtherDetails_uuid: "",
      require_supporting_doc: false,
    },
  ]);
  const [error, setError] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [active, setActive] = useState("questions");
  const [show, setShow] = useState("questions");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [nextTab, setNextTab] = useState<string>("");
  const [saved, setSaved] = useState<boolean>(true);

  const tabs = [
    { name: "Questions", value: "questions" },
    { name: "Languages", value: "languages" },
    { name: "Subject Choices", value: "subject" },
  ];

  const addQuestionHandler = () => {
    readyToSwitch(false);
    setEducation([
      ...education,
      {
        edu_uuid: "",
        eduQuestion: "",
        details: false,
        furtherDetails: "",
        furtherDetails_uuid: "",
        require_supporting_doc: false,
      },
    ]);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getEducationError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["education", applicationID, selectedGlobalDate],
    () => getEducation(applicationID),
    {
      select: (education) => education.data,
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        const temp: any = [];
        data?.results.map((eduQuestions: any) => {
          temp.push({
            edu_uuid: eduQuestions.education_question_uuid,
            eduQuestion: eduQuestions.question,
            details: eduQuestions.further_detail_required,
            require_supporting_doc: eduQuestions.further_detail_required
              ? eduQuestions.education_question_follow_up_school_application
                ? eduQuestions
                    .education_question_follow_up_school_application[0]
                    .require_supporting_doc
                : false
              : false,
            furtherDetails: eduQuestions.further_detail_required
              ? eduQuestions.education_question_follow_up_school_application
                ? eduQuestions
                    .education_question_follow_up_school_application[0]
                    ?.follow_up_question
                : ""
              : "",
            furtherDetails_uuid: eduQuestions.further_detail_required
              ? eduQuestions.education_question_follow_up_school_application
                ? eduQuestions
                    .education_question_follow_up_school_application[0]
                    ?.education_question_follow_up_uuid
                : ""
              : "",
          });
        });
        setEducation(temp);
      } else {
        setEducation(default_state);
      }
    }
  }, [data]);

  const handleTabChange = (value: string) => {
    setNextTab(value);
    if (saved) {
      setShow(value);
      setActive(value);
    } else {
      setOpenConfirm(true);
    }
  };

  const changeTab = () => {
    setShow(nextTab);
    setActive(nextTab);
    setSaved(true);
    ready(true);
  };

  const readyToSwitch = (val: boolean) => {
    setSaved(val);
    ready(val);
  };

  const mutation: UseMutationResult<any, Error, postEducationData> =
    useMutation(async ({ id, education }) => postEducation({ id, education }), {
      onSuccess: (res) => {
        if (res) {
          const temp: any = [];
          res.data.results.map((eduQuestions: any) => {
            temp.push({
              edu_uuid: eduQuestions.education_question_uuid,
              eduQuestion: eduQuestions.question,
              details: eduQuestions.further_detail_required,
              require_supporting_doc: eduQuestions.further_detail_required
                ? eduQuestions.education_question_follow_up_school_application
                  ? eduQuestions
                      .education_question_follow_up_school_application[0]
                      .require_supporting_doc
                  : false
                : false,
              furtherDetails: eduQuestions.further_detail_required
                ? eduQuestions.education_question_follow_up_school_application
                  ? eduQuestions
                      .education_question_follow_up_school_application[0]
                      ?.follow_up_question
                  : ""
                : "",
              furtherDetails_uuid: eduQuestions.further_detail_required
                ? eduQuestions.education_question_follow_up_school_application
                  ? eduQuestions
                      .education_question_follow_up_school_application[0]
                      ?.education_question_follow_up_uuid
                  : ""
                : "",
            });
          });
          setEducation(temp);
          readyToSwitch(true);
          setLoader(false);
        }
      },
      onError: (error) => {
        setLoader(false);
      },
    });

  const onChange = (value: any, ind: any, type: any) => {
    const xyz = [...education];
    const temp = [...error];
    readyToSwitch(false);
    if (type === "eduQuestion") {
      xyz[ind].eduQuestion = value;
    }
    setEducation(xyz);
    if (value.length === 0) {
      if (!temp.includes("edu" + `${ind}`)) {
        temp.push("edu" + `${ind}`);
      }
    } else if (temp.includes("edu" + `${ind}`)) {
      const val = temp.indexOf("edu" + `${ind}`);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  const onFurChange = (value: any, ind: any, type: any) => {
    const xyz = [...education];
    const temp = [...error];
    readyToSwitch(false);
    if (type === "details") {
      xyz[ind].details = value;
    }

    if (type === "require_supporting_doc") {
      xyz[ind].require_supporting_doc = value;
    }
    if (type === "furtherDetails") {
      xyz[ind].furtherDetails = value;
    }
    setEducation(xyz);
    if ((type = "furtherDetails")) {
      if (value.length == 0) {
        if (!temp.includes(`${ind}`)) {
          temp.push(`${ind}`);
        }
      } else if (temp.includes(`${ind}`)) {
        const val = temp.indexOf(`${ind}`);
        if (ind > -1) {
          temp.splice(val, 1);
        }
      }
    }
    setError(temp);
  };

  const onSaveClick = () => {
    // temporary array to capture the indexes of the error
    const temp = [...error];
    try {
      education.map((item: any, index: any) => {
        if (item.eduQuestion.length === 0) {
          if (!temp.includes("edu" + `${index}`)) {
            temp.push("edu" + `${index}`);
          }
        }
        if (
          item.eduQuestion &&
          item.details &&
          item.furtherDetails.length === 0
        ) {
          // checking if the temp array already has the index, if not then include the index
          if (!temp.includes(index)) {
            temp.push(`${index}`);
          }
        }
        // removing the index whose error is resolved
        else if (temp.includes(`${index}`)) {
          const val = temp.indexOf(`${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
      });
      if (temp.length !== 0) {
        // if error exist then throw the error
        throw temp;
      } else {
        // if no error then call api
        setError([]); //clears the error array before sending the api request
        setLoader(true);
        mutation.mutate({ id: applicationID, education });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteHandler = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/application-education-question-details/`,
        method: "delete",
        data: {
          education_question_uuid: id,
        },
      }).then(() => {
        const temp = [...education];
        temp.splice(index, 1);
        setEducation(temp);
        setError([]);
        setLoader(false);
      });
    } else {
      const temp = [...education];
      temp.splice(index, 1);
      setEducation(temp);
      setError([]);
    }
  };

  const classes = useStyle();
  return (
    <div>
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getEducationError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <Grid container direction="row" justifyContent="space-between">
          <div>
            <div style={{ width: "45vw" }}>
              {show === "languages" ? (
                <Languages
                  applicationID={applicationID}
                  switchReady={readyToSwitch}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              ) : null}
              {show === "subject" ? (
                <SubjectChoice
                  applicationID={applicationID}
                  switchReady={readyToSwitch}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              ) : null}
              {show === "questions" ? (
                <Grid container direction="column" justifyContent="flex-start">
                  <div className={classes.criteriaContainer}>
                    {education.map((item: any, index: number) => {
                      return (
                        <div
                          key={`${item?.edu_uuid}${index}`}
                          style={{ marginBottom: 50 }}
                        >
                          <Question
                            questionLabel={`Question ${index + 1}`}
                            data={item}
                            index={index}
                            change={onChange}
                            furChange={onFurChange}
                            errorEdu={error.includes("edu" + `${index}`)}
                            error={error}
                            onDelete={deleteHandler}
                            selectedGlobalDate={selectedGlobalDate}
                            currentGlobalDate={currentGlobalDate}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {selectedGlobalDate === currentGlobalDate && (
                    <>
                      <div style={{ width: "300px", marginTop: "20px" }}>
                        <AdminButton
                          label="Add further Questions"
                          endIcon="add"
                          light
                          onClick={addQuestionHandler}
                        />
                      </div>
                      <div
                        style={{ width: 100, marginTop: 40, marginBottom: 60 }}
                      >
                        <AdminButton label="Save" onClick={onSaveClick} />
                      </div>
                    </>
                  )}
                </Grid>
              ) : null}
            </div>
          </div>
          <div>
            <div className={classes.tab}>
              {tabs.map((tab, index) => (
                <button
                  key={`${tab?.name}${index}`}
                  className={cn(
                    classes.tabButton,
                    active === tab.value ? classes.tabActive : null
                  )}
                  onClick={() => handleTabChange(tab.value)}
                >
                  {tab.name}
                </button>
              ))}
              {/* <button
                className={cn(
                  classes.tabButton,
                  active === "questions" ? classes.tabActive : null
                )}
                onClick={() => handleTabChange("questions")}
              >
                Questions
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "languages" ? classes.tabActive : null
                )}
                onClick={() => handleTabChange("languages")}
              >
                Languages
              </button>
              <button
                className={cn(
                  classes.tabButton,
                  active === "languages" ? classes.tabActive : null
                )}
                onClick={() => handleTabChange("languages")}
              >
                Subject Choices
              </button> */}
            </div>
          </div>
        </Grid>
      ) : null}
      <ConfirmBox
        message={`You have unsaved data. Do you want to continue without saving?`}
        onClick={changeTab}
        open={openConfirm}
        setOpen={setOpenConfirm}
      />
    </div>
  );
};

export default EducationalQuestions;
