import { useEffect, useState } from "react";
import { useStyle } from "../../applications/single-application/student-details.style";
import { Box, Tabs, Tab } from "@material-ui/core";
import StudentDetails from "../../applications/single-application/student-details";
import GuardianDetails from "../../applications/single-application/guardian-details";
import MedInfoView from "./med-info-view";
import EduHistView from "./edu-hist-view";
import Icon from "@material-ui/core/Icon";
import Documents from "../../applications/single-application/documents";
import ApplicationTrial from "../../applications/single-application/application-trail";
import Communication from "../../applications/single-application/comunication";
import CriteriaDetails from "../../applications/single-application/criteriaDetails";
import Consents from "../../applications/single-application/consents";
import request from "../../../../utils/authUtil";
import requestv2 from "../../../../utils/authUtilv2";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "../../../../components/common/button/index";
import { Loader } from "../../../../components/common/loader";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import { SelectDropDown } from "../../../../components/common/select-dropdown";

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyle();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={classes.container}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index: any) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const enrollReviewOptions = [
  { name: "--Not Reviewed--", value: "" },
  { name: "Reviewed", value: "REVIEWED" },
  { name: "Pending", value: "PENDING" },
];

const StudentEnrolView: React.FC<Props> = () => {
  const classes = useStyle();
  const history = useHistory();
  const { applicationId } = useParams<{ applicationId: string }>();
  const school_uuid = localStorage.getItem("school-id");
  const [value, setValue] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [studData, setStudData] = useState<any>({});
  const [revPopup, setRevPopup] = useState<boolean>(false);
  const [currentEnrollReviewStatus, setCurrentEnrollReviewStatus] =
    useState<string>(enrollReviewOptions[0].value);
  const [updatedEnrollReviewStatus, setUpdatedEnrollReviewStatus] =
    useState<string>("");

  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const getStudentData = async (): Promise<any> => {
    await request({
      url: `school-settings/student-application/?school_uuid=${school_uuid}&student_app_uuid=${applicationId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setStudData(res.data.results.Student_Details);
        setCurrentEnrollReviewStatus(
          res.data.results.Student_Details.enrolment_reviewed_status
        );
      }
    });
  };

  const downloadPdf = () => {
    setLoader(true);
    request({
      url: `school-settings/enrolment-student-application-pdf/?student_app_uuid=${applicationId}`,
      method: "get",
    }).then((response) => {
      if (response.data.results) {
        window.open(response.data.results.document_presingne_url, "_parent");
        setLoader(false);
      }
    });
  };

  const markAsReviewed = () => {
    setLoader(true);
    requestv2({
      url: `school-settings/mark-student-enrolment-reviewed/?stu_app_uuid=${applicationId}`,
      method: "put",
      data: {
        enrolment_reviewed_status: updatedEnrollReviewStatus,
      },
    }).then((res) => {
      if (res.data.status_code === 1) {
        setCurrentEnrollReviewStatus(updatedEnrollReviewStatus);
        setLoader(false);
      }
    });
  };

  const onChange = (e: any) => {
    if (e.target.value !== "") {
      setRevPopup(true);
      setUpdatedEnrollReviewStatus(e.target.value);
    }
  };

  useEffect(() => {
    getStudentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box marginRight="40px">
      <Loader open={loader} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          background: "#F9F8F7",
          padding: "16px",
          height: "235px",
        }}
      >
        <Box>
          <Icon
            style={{ cursor: "pointer", paddingTop: "12px" }}
            onClick={() => history.push("/admin/enrolment")}
          >
            arrow_back
          </Icon>
          <p>
            {studData.first_name} {studData.last_name}
          </p>
          <p>
            Application ID : <b>{studData?.application_id}</b>
          </p>
          <p>
            Application Status : <b>{studData?.application_status}</b>
          </p>
          <Box
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              width: "200px",
            }}
          >
            <p>Enrolment Review Status</p>
            <SelectDropDown
              value={currentEnrollReviewStatus}
              onChange={onChange}
              item={enrollReviewOptions}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <div style={{ width: "200px" }}>
            <Button
              startIcon="download_icon"
              label="Download Pdf"
              onClick={downloadPdf}
            />
          </div>
        </Box>
      </Box>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="desktop"
          aria-label="scrollable auto tabs example"
          style={{ marginLeft: "-40px", marginRight: "-40px" }}
        >
          <Tab
            className={classes.tab}
            label="Student Information"
            {...a11yProps(0)}
          />
          <Tab className={classes.tab} label="Guardian" {...a11yProps(1)} />
          <Tab className={classes.tab} label="Criteria" {...a11yProps(2)} />
          <Tab
            className={classes.tab}
            label="Educational Details"
            {...a11yProps(3)}
          />
          <Tab
            className={classes.tab}
            label="Medical Details"
            {...a11yProps(4)}
          />
          <Tab className={classes.tab} label="Consents" {...a11yProps(5)} />
          <Tab
            className={classes.tab}
            label="Communication"
            {...a11yProps(6)}
          />
          <Tab className={classes.tab} label="Documents" {...a11yProps(7)} />
          <Tab
            className={classes.tab}
            label="Application Trail"
            {...a11yProps(8)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <StudentDetails
            applicationID={applicationId}
            type="enroll"
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GuardianDetails
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CriteriaDetails
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <EduHistView
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <MedInfoView
            applicationID={applicationId}
            selectedGlobalDate={selectedGlobalDate}
            currentGlobalDate={currentGlobalDate}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Consents applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Communication applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Documents applicationID={applicationId} />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <ApplicationTrial applicationID={applicationId} />
        </TabPanel>
      </div>
      <ConfirmBox
        open={revPopup}
        setOpen={setRevPopup}
        onClick={markAsReviewed}
        arg={true}
        message={`Do you want to mark the application as ${updatedEnrollReviewStatus.toLowerCase()} ?`}
      />
    </Box>
  );
};

export default StudentEnrolView;
