import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles(theme => ({
    root: {
        width: "100%",
        textTransform: "none",
        borderRadius: 4,        
        color: theme.palette.text.primary,
        padding: "12px 12px",
        border: "1px solid #E6E4E3",
        borderColor: theme.palette.grey[200],
        fontFamily:   `"LFT Etica", sans-serif`,
      },
      "&:focus": {
        outline:'none'
      },
    btmMargin: {
        marginBottom: 34,
    },
    whiteColor: {
        backgroundColor: theme.palette.common.white,
    },
    greyColor: {
        backgroundColor: theme.palette.background.default,
    },
    input: {
        padding: 0,
        fontFamily:   `"LFT Etica", sans-serif`,
    },
    focused: {
        border: "1px solid",
        borderColor: theme.palette.common.black,
    },
    error: {
        border: "1px solid",
        borderColor: theme.palette.error.main
    },
    label: {
        marginBottom: 6.5,
        fontFamily:   `"LFT Etica", sans-serif`,
        fontSize: 13,
        color: theme.palette.text.primary,
    },
    labelError: {
        color: theme.palette.error.main
    },
    errorMessage: {
        color: theme.palette.error.main,
        marginTop: 7,
        fontSize: 11,
    },
    passwordIcon: {
        cursor: "pointer",
        color: theme.palette.grey[300]
    }
}))