import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "./general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  error?: any;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface IntakeData {
  totalIntake: string;
  specialAllocation: string;
  resctrictedPercent: string;
  actual: string;
}

interface PostIntakeType {
  id: string;
  intake: IntakeData;
}

const Intake: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [validError, setValidError] = useState<any>({
    totalIntake_err: false,
    specialAllocation_err: false,
    resctrictedPercent_err: false,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [intake, setIntake] = useState<IntakeData>({
    totalIntake: "",
    specialAllocation: "",
    resctrictedPercent: "25",
    actual: "",
  });

  const getIntake = async (id: string): Promise<any> => {
    const res = await request({
      url: `school-settings/application-intake-details/?application_uuid=${id}`,
      method: "get",
    });
    return res;
  };

  const validate = () => {
    return {
      totalIntake_err: intake.totalIntake.length === 0,
      specialAllocation_err: intake.specialAllocation.length === 0,
      resctrictedPercent_err: intake.resctrictedPercent.length === 0,
    };
  };

  const valueOnChange = (e: any, inputType: string) => {
    switchReady(false);
    switch (inputType) {
      case "totalIntake":
        setIntake({
          ...intake,
          totalIntake: e.target.value,
          specialAllocation:
            e.target.value > 0
              ? Math.floor(
                  (parseInt(e.target.value) *
                    parseInt(intake.resctrictedPercent)) /
                    100
                ).toString()
              : "",
        });
        setValidError({
          ...validError,
          totalIntake_err: e.target.value.length === 0,
        });
        break;
      // case "resctrictedPercent":
      //   setIntake({
      //     ...intake,
      //     resctrictedPercent: e.target.value,
      //   });
      //   setValidError({
      //     ...validError,
      //     resctrictedPercent_err: e.target.value.length === 0,
      //   });
      //   break;
      // case "specialAllocation":
      //   setIntake({
      //     ...intake,
      //     specialAllocation: e.target.value,
      //   });
      //   setValidError({
      //     ...validError,
      //     specialAllocation_err: e.target.value.length === 0,
      //   });
      //   break;
    }
  };

  const postIntake = async ({ id, intake }: PostIntakeType): Promise<any> => {
    setLoader(true);
    const res = await request({
      url: `school-settings/application-intake-details/?application_uuid=${id}`,
      method: "put",
      data: {
        total_intake: intake.totalIntake,
        special_allocation: intake.specialAllocation,
        resctricted_percent: intake.resctrictedPercent,
        actual: intake.actual,
      },
    });
    if (res) {
      setLoader(false);
      switchReady(true);
    }
    return res;
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getIntakeError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["intake", applicationID, selectedGlobalDate],
    () => getIntake(applicationID),
    {
      select: (intake) => intake.data,
    }
  );

  const mutation: UseMutationResult<any, Error, PostIntakeType> = useMutation(
    async ({ id, intake }) => postIntake({ id, intake })
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setIntake({
          ...intake,
          totalIntake: data.results.total_intake,
          specialAllocation: data.results.special_allocation,
          resctrictedPercent: data.results.resctricted_percent,
          actual: data.results.actual,
        });
      }
    }
  }, [data]);

  const onSaveClick = () => {
    const err = validate();
    setValidError(err);
    if (
      err.totalIntake_err === false &&
      err.resctrictedPercent_err === false &&
      err.specialAllocation_err === false
    ) {
      mutation.mutate({ id: applicationID, intake });
    }
  };

  return (
    <div>
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getIntakeError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <div>
          <Input
            label="Total Intake"
            value={intake.totalIntake}
            type="text"
            onChange={(e: any) => {
              valueOnChange(e, "totalIntake");
            }}
            disabled={selectedGlobalDate !== currentGlobalDate}
            error={validError.totalIntake_err}
          />
          <Input
            label="Special Allocation"
            value={intake.specialAllocation}
            type="text"
            disabled={true}
            error={validError.specialAllocation_err}
          />
          <Input
            label="Resctricted Percent (In percentage)"
            value={`${intake.resctrictedPercent} %`}
            type="text"
            disabled={true}
            error={validError.resctrictedPercent_err}
          />
          {/* <Input
            label="Actual"
            value={intake.actual}
            type="text"
            onChange={(e: any) => {
              setIntake({ ...intake, actual: e.target.value });
              LengthCheck(e.target.value, "actError");
            }}
            error={errorSubmit ? errorCheck.includes("actError") : false}
          /> */}
          {selectedGlobalDate === currentGlobalDate && (
            <Box style={{ width: 100, marginTop: 40 }} mb="40px">
              <AdminButton label="Save" onClick={onSaveClick} />
            </Box>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Intake;
