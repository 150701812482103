import MaterialButton from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { useStyle } from "./admin-button.style";
import cn from "classnames";

type ButtonProps = {
  label?: string;
  light?: Boolean;
  onClick?: any;
  disabled?: boolean | undefined;
  className?: string;
  startIcon?: string;
  endIcon?: string;
  iconSize?: string;
  type?: string;
  padding?: string;
};

export const AdminButton: React.FC<ButtonProps> = ({
  label,
  light = false,
  onClick,
  disabled,
  className,
  startIcon,
  endIcon,
  iconSize,
  type,
  padding,
}) => {
  const classes = useStyle();
  return (
    <MaterialButton
      className={cn(
        classes.root,
        light ? classes.light : classes.primary,
        className
      )}
      onClick={onClick}
      disabled={disabled}
      startIcon={
        startIcon && (
          <Icon style={{ fontSize: `${iconSize}` }}>{startIcon}</Icon>
        )
      }
      endIcon={
        endIcon && <Icon style={{ fontSize: `${iconSize}` }}>{endIcon}</Icon>
      }
      type={type ? "submit" : undefined}
    >
      {label}
    </MaterialButton>
  );
};
