import Icon from "@material-ui/core/Icon";
import { useStyle } from "./upload.styles";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import cn from "classnames";
import Box from "@material-ui/core/Box";
import { useRef } from "react";

type uploadProps = {
  name?: string;
  label?: string;
  iconSize?: string;
  placeholder?: string;
  onChange?: any;
  isFile?: boolean;
  file?: any;
  id?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  belowLabel?: string;
  onDelete?: any;
  showDeleteIcon?:boolean;
};

export const Upload: React.FC<uploadProps> = ({
  name,
  label,
  iconSize,
  placeholder,
  onChange,
  isFile,
  file,
  id,
  error = false,
  errorMessage = "Is Required",
  disabled = false,
  belowLabel,
  onDelete,
  showDeleteIcon=true,
}) => {
  const classes = useStyle();
  const ref: any = useRef();
  const reset = () => {
    ref.current.value = "";
  };
  return (
    <div style={{ marginBottom: 34 }}>
      <form>
        <input
          name={name}
          type="file"
          className={classes.input}
          onChange={onChange}
          id={id}
          disabled={disabled}
          ref={ref}
        />
      </form>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : "")}
      >
        {label}
      </InputLabel>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="100%">
          <label htmlFor={id}>
            <Button
              className={cn(classes.root, error ? classes.error : "")}
              component="span"
              disabled={disabled}
            >
              {file ? file : placeholder}
              {!isFile ? (
                <Icon
                  className={classes.uploadIcon}
                  style={{
                    fontSize: `${iconSize}`,
                  }}
                >
                  publish_icon
                </Icon>
              ) : (
                <Icon
                  className={classes.completeIcon}
                  style={{
                    fontSize: `${iconSize}`,
                  }}
                >
                  check_circle_icon
                </Icon>
              )}
            </Button>
            <InputLabel className={classes.belowLabel}>{belowLabel}</InputLabel>
            {error ? (
              <InputLabel className={classes.errorMessage}>
                {errorMessage}
              </InputLabel>
            ) : null}
          </label>
        </Box>
        {isFile && showDeleteIcon? (
          <Icon
            onClick={() => {
              onDelete();
              reset();
            }}
            className={classes.deleteIcon}
            style={{
              fontSize: `${iconSize}`,
              color: "#757575",
            }}
          >
            delete
          </Icon>
        ) : null}
      </Box>
    </div>
  );
};
