import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "../../../../../components/common/input-radio-button-two/input-radio-button.style";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Box } from "@material-ui/core";
import cn from "classnames";
type InputRadioProps = {
  label?: string;
  answer?: string;
  onChange?: any;
  type?: string;
  error?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
  twoGuardian?: boolean;
};

export const GuardianRadioButton: React.FC<InputRadioProps> = ({
  label,
  answer,
  onChange,
  type,
  error = false,
  errorMessage = "Required",
  isDisabled,
  twoGuardian,
}) => {
  const classes = useStyle();
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel className={error ? classes.labelError : classes.label}>
        {label}
      </InputLabel>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup aria-label="gender" name="radio-buttons-group">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={cn(
              classes.root,
              classes.borderTopBox,
              answer === "GUARDIAN_ONE" ? classes.borderGreen : ""
            )}
          >
            <p className={classes.placeholder}>Guardian 1</p>
            <FormControlLabel
              value={isDisabled === true ? "disabled" : "GUARDIAN_ONE"}
              label=""
              control={
                <Radio
                  className={
                    answer === "GUARDIAN_ONE"
                      ? classes.checked
                      : classes.checkedGrey
                  }
                  disableRipple
                  color="default"
                />
              }
              onClick={() =>
                isDisabled !== true ? onChange("GUARDIAN_ONE", type) : null
              }
              checked={answer === "GUARDIAN_ONE"}
              disabled={isDisabled}
            />
          </Box>
          {twoGuardian === true ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={cn(
                  classes.root,
                  classes.borderBottomBox,
                  answer === "GUARDIAN_TWO" ? classes.borderGreen : ""
                )}
              >
                <p className={classes.placeholder}>Guardian 2</p>
                <FormControlLabel
                  value={isDisabled === true ? "disabled" : "GUARDIAN_TWO"}
                  control={
                    <Radio
                      className={
                        answer === "GUARDIAN_TWO"
                          ? classes.checked
                          : classes.checkedGrey
                      }
                      color="default"
                      disableRipple
                    />
                  }
                  label=""
                  onClick={() =>
                    isDisabled !== true ? onChange("GUARDIAN_TWO", type) : null
                  }
                  checked={answer === "GUARDIAN_TWO"}
                  disabled={isDisabled}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={cn(
                  classes.root,
                  classes.borderBottomBox,
                  answer === "GUARDIAN_BOTH" ? classes.borderGreen : ""
                )}
              >
                <p className={classes.placeholder}>Both</p>
                <FormControlLabel
                  value={isDisabled === true ? "disabled" : "GUARDIAN_BOTH"}
                  control={
                    <Radio
                      className={
                        answer === "GUARDIAN_BOTH"
                          ? classes.checked
                          : classes.checkedGrey
                      }
                      color="default"
                      disableRipple
                    />
                  }
                  label=""
                  onClick={() =>
                    isDisabled !== true ? onChange("GUARDIAN_BOTH", type) : null
                  }
                  checked={answer === "GUARDIAN_BOTH"}
                  disabled={isDisabled}
                />
              </Box>
            </>
          ) : null}
        </RadioGroup>
      </FormControl>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
