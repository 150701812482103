import { ThemeProvider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Routes from "./components/Routes";
import theme from "./theme";
import axios from "axios";
import { Loader } from "../src/components/common/loader";
import request from "./utils/authUtil";

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 1000,
        refetchOnWindowFocus: false,
      },
    },
  });
  const [loading, setLoading] = useState(true);

  const schoolID = localStorage.getItem("school-id");
  const [logoUrl, setLogoUrl] = useState<string>("");

  useEffect(() => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const favicon = document.createElement("link");
    favicon.type = "image/x-icon";
    favicon.rel = "icon";
    favicon.href = logoUrl;
    const prevFavicon = head.querySelector('link[rel="icon"]');
    if (prevFavicon) {
      head.removeChild(prevFavicon);
    }
    head.appendChild(favicon);
  }, [logoUrl]);

  useEffect(() => {
    request({
      url: `auth/school-logo/?school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data) {
        if (Object.keys(response.data.results).length !== 0) {
          setLogoUrl(response.data.results);
        }
      } else {
        setLogoUrl("");
      }
    });
  }, []);

  const baseUrl =
    "https://school-enrolment-basic-info.s3.eu-west-1.amazonaws.com/";

  const get_school_id = async () => {
    const schoolName = window.location.href.split("/")[2];
    await axios
      .get(`${baseUrl}${schoolName}.json`)
      .then((req) => {
        localStorage.setItem("school-id", req.data.school_uuid);
        localStorage.setItem("name", req.data.school_name);
        setLoading(false);
      })
      .catch();
  };

  useEffect(() => {
    const env = process.env.REACT_APP_API_SCHOOL_UUID as string;
    const name = process.env.REACT_APP_SCHOOL_NAME as string;

    if (env === "allSchool") {
      if (!localStorage.getItem("school-id")) {
        get_school_id();
      } else {
        setLoading(false);
      }
    } else {
      localStorage.setItem("school-id", env);
      localStorage.setItem("name", name);
      setLoading(false);
    }
  }, []);
  return (
    <React.Suspense fallback="Loading...">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          {!loading ? (
            <Router>
              <Routes />
            </Router>
          ) : (
            <Loader open={true} />
          )}
        </ThemeProvider>
      </QueryClientProvider>
    </React.Suspense>
  );
};

export default App;
