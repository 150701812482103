import { useStyle } from "../delete-dialogbox/delete-dialog.style";
import { AdminButton } from "../admin-button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

type MessageProps = {
  message: string;
  open: boolean;
  setOpen: any;
};

export const MessageBox: React.FC<MessageProps> = ({
  open = false,
  setOpen,
  message,
}) => {
  const classes = useStyle();
  const handleClose = () => {
    setOpen(false);
  };
  // Check if the message contains the link
  const hasLink = message.includes("support@uniqueschools.ie");
  // If the message contains the link, split the message into two parts: text before the link and text after the link
  let beforeLink = "";
  let afterLink = "";
  if (hasLink) {
    const messageParts = message.split(/(support@uniqueschools\.ie)/i);
    beforeLink = messageParts[0];
    afterLink = messageParts[2];
  } else {
    beforeLink = message;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <div>
            <Typography>{beforeLink}</Typography>
            {hasLink && (
              <Link href="mailto:support@uniqueschools.ie">
                <Typography>support@uniqueschools.ie</Typography>
              </Link>
            )}
            {afterLink && <Typography>{afterLink}</Typography>}
          </div>
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <div style={{ width: "100px" }}>
            <AdminButton
              onClick={() => {
                handleClose();
              }}
              label="OK"
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
