/* eslint-disable no-restricted-globals */
import { useState, useEffect } from "react";
import { useStyle } from "./medical-tab.style";
import { AdminButton } from "../../../components/common/admin-button/index";
import Medical from "./medical";
import request from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader/index";
import { getMedical, postMedical } from "./med-ques-api";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  ready: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};
interface medicalFurtherDetails {
  medical_question_follow_up_uuid: string;
  follow_up_question: string;
  require_supporting_doc: boolean;
}

interface medicalData {
  medical_question_uuid: string;
  medicalQuestion: string;
  details: boolean;
  furtherDetails: medicalFurtherDetails[];
}

interface postMedicalData {
  id: string;
  medical: medicalData[];
}

const MedicalTab: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const default_state = [
    {
      medical_question_uuid: "",
      medicalQuestion: "",
      details: false,
      furtherDetails: [
        {
          medical_question_follow_up_uuid: "",
          follow_up_question: "",
          require_supporting_doc: false,
        },
      ],
    },
  ];
  const [medical, setMedical] = useState<medicalData[]>(default_state);

  const [error, setError] = useState<string[]>([]);

  const addMedicalHandler = () => {
    ready(false);
    setMedical([
      ...medical,
      {
        medical_question_uuid: "",
        medicalQuestion: "",
        details: false,
        furtherDetails: [
          {
            medical_question_follow_up_uuid: "",
            follow_up_question: "",
            require_supporting_doc: false,
          },
        ],
      },
    ]);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getMedicalError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["medical", applicationID, selectedGlobalDate],
    () => getMedical(applicationID),
    {
      select: (medical) => medical.data,
    }
  );

  const mutation: UseMutationResult<any, Error, postMedicalData> = useMutation(
    async ({ id, medical }) => postMedical({ id, medical }),
    {
      onSuccess: (res) => {
        if (res) {
          const temp: any = [];
          res.data.results.map((medQuestions: any) => {
            temp.push({
              medical_question_uuid: medQuestions.medical_question_uuid,
              medicalQuestion: medQuestions.question,
              details: medQuestions.further_detail_required,
              furtherDetails: medQuestions.further_detail_required
                ? medQuestions.medical_question_follow_up_school_application
                : [],
            });
          });
          setMedical(temp);
          ready(true);
          setLoader(false);
        }
      },
      onError: (error) => {
        setLoader(false);
      },
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        const temp: any = [];
        data.results.map((medQuestions: any) => {
          temp.push({
            medical_question_uuid: medQuestions.medical_question_uuid,
            medicalQuestion: medQuestions.question,
            details: medQuestions.further_detail_required,
            furtherDetails: medQuestions.further_detail_required
              ? medQuestions.medical_question_follow_up_school_application
              : [],
          });
        });
        setMedical(temp);
      } else {
        setMedical(default_state);
      }
    }
  }, [data]);

  const addFollowUpHandler = (index: number) => {
    const temp: any = [...medical];
    const temp2 = [
      ...temp[index].furtherDetails,
      {
        medical_question_follow_up_uuid: "",
        follow_up_question: "",
        require_supporting_doc: false,
      },
    ];
    temp[index].furtherDetails = temp2;
    setMedical(temp);
    ready(false);
  };

  const onChange = (
    value: any,
    ind: number,
    type: string,
    followUpIndex: number,
    followUpType?: string
  ) => {
    ready(false);
    const xyz: any = [...medical];
    const temp = [...error];
    if (type === "medicalQuestion") {
      xyz[ind].medicalQuestion = value;
    }
    setMedical(xyz);
    if ((type = "medicalQuestion")) {
      if (value.length == 0) {
        if (!temp.includes(`${ind}`)) {
          temp.push(`${ind}`);
        }
      } else if (temp.includes(`${ind}`)) {
        const val = temp.indexOf(`${ind}`);
        if (ind > -1) {
          temp.splice(val, 1);
        }
      }
    }
    setError(temp);
  };

  const onFurChange = (
    value: any,
    ind: number,
    type: string,
    followUpIndex: number,
    followUpType?: string
  ) => {
    ready(false);
    const xyz: any = [...medical];
    const temp = [...error];
    if (type === "details") {
      xyz[ind].details = value;
    }
    if (type === "furtherDetails") {
      const temp = [...xyz[ind].furtherDetails];
      if (followUpType === "follow_up_question") {
        temp[followUpIndex].follow_up_question = value;
      }
      if (followUpType === "require_supporting_doc") {
        temp[followUpIndex].require_supporting_doc = value;
      }
      xyz[ind].furtherDetails = temp;
    }
    setMedical(xyz);
    if ((type = "furtherDetails")) {
      if (value.length === 0) {
        if (!temp.includes(`${ind}${followUpIndex}`)) {
          temp.push(`${ind}${followUpIndex}`);
        }
      } else if (temp.includes(`${ind}${followUpIndex}`)) {
        const val = temp.indexOf(`${ind}${followUpIndex}`);
        if (ind > -1) {
          temp.splice(val, 1);
        }
      }
    }
    setError(temp);
  };

  const onSaveClick = () => {
    // temporary array to capture the indexes of the error
    const temp = [...error];
    try {
      medical.map((item: any, index: number) => {
        if (item.medicalQuestion.length == 0) {
          if (!temp.includes(`${index}`)) {
            temp.push(`${index}`);
          }
        } else if (
          item.medicalQuestion &&
          item.details &&
          item.furtherDetails.length > 0
        ) {
          item.furtherDetails.map((subItem: any, subIndex: number) => {
            if (subItem.follow_up_question.length === 0) {
              if (!temp.includes(`${index}${subIndex}`)) {
                temp.push(`${index}${subIndex}`);
              }
            }
          });
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        mutation.mutate({ id: applicationID, medical });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteHandler = (
    questionId: string = "",
    followUpId: string = "",
    questionIndex: number,
    followUpIndex: number
  ) => {
    if (questionId.length !== 0 || followUpId.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/application-medical-question-details/`,
        method: "delete",
        data: {
          medical_question_uuid: questionId,
          medical_question_follow_up_uuid: followUpId,
        },
      }).then(() => {
        if (questionId) {
          const temp = [...medical];
          temp.splice(questionIndex, 1);
          setError([]);
          setMedical(temp);
          setLoader(false);
        } else if (followUpId) {
          const xyz: any = [...medical];
          const temp = [...medical[questionIndex].furtherDetails];
          temp.splice(followUpIndex, 1);
          xyz[questionIndex].furtherDetails = temp;
          setError([]);
          setMedical(xyz);
          setLoader(false);
        }
      });
    } else {
      if (questionIndex && followUpIndex === -1) {
        const temp = [...medical];
        temp.splice(questionIndex, 1);
        setMedical(temp);
        setError([]);
      } else {
        const xyz: any = [...medical];
        const temp = [...medical[questionIndex].furtherDetails];
        temp.splice(followUpIndex, 1);
        xyz[questionIndex].furtherDetails = temp;
        setMedical(xyz);
        setError([]);
      }
    }
  };

  return (
    <div className={classes.medicalContainer}>
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getMedicalError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <div>
          {medical.map((item: any, index: number) => {
            return (
              <div
                key={`${item?.medical_question_uuid}`}
                style={{ marginBottom: 50 }}
              >
                <Medical
                  index={index}
                  medicalLabel={`Question ${index + 1}`}
                  data={item}
                  change={onChange}
                  furChange={onFurChange}
                  addFollowUp={() => addFollowUpHandler(index)}
                  onDelete={deleteHandler}
                  errorArray={error}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              </div>
            );
          })}
          {selectedGlobalDate === currentGlobalDate && (
            <>
              <div style={{ width: "230px", marginTop: "60px" }}>
                <AdminButton
                  label="Add further Questions"
                  endIcon="add"
                  light
                  onClick={addMedicalHandler}
                />
              </div>
              <div style={{ width: 100, marginTop: 60, marginBottom: 60 }}>
                <AdminButton label="Save" onClick={onSaveClick} />
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default MedicalTab;
