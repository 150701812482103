import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid",
    borderColor: theme.palette.divider,
    alignItems: "start",
    fontSize: "14px",
    fontFamily: `"LFT Etica", sans-serif`,
    letterSpacing: "0em",
    paddingTop: "10px",
    paddingBottom: "40px",
    "& button": {
      marginBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      // flexDirection: "row",
      border: "none",
      "& button": {
        marginRight: "30px",
      },
    },
  },
  btnLink: {
    border: "none",
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: "14px",
    color: "#1A1A1A",
    cursor: "pointer",
    backgroundColor: "transparent",
    [theme.breakpoints.down("md")]: {
      lineHeight: "40px",
    },
  },
  alignCenter: {
    justifyContent: "center",
  },
  textBold: {
    fontSize: "14px",
    Weight: 600,
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  supportEmailContainer: {
    width: "100%",
    alignItem: "center",

    "& p": {
      textAlign: "center",
    },
  },
}));
