import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    display: "block",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  title: {
    fontSize: "28px",
    lineHeight: "33px",
    fontWeight: 700,
  },
  subTitle: {
    color: "#757575",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "-15px",
  },
  description: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "25.5px",
  },
}));
