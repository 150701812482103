import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  medicalContainer: {
    width: "65%",
    marginTop: 30,
  },
  inputContainer: {
    width: "100%",
    marginTop: 10,
  },
  secondaryContainer: {
    marginTop: 10,
  },
  container: {
    marginLeft: "-20px",
  },
  extraButton: {
    outline: "none",
    border: "none",
    backgroundColor: theme.palette.grey[200],
    padding: "7px 15px",
    cursor: "pointer",
  },
  trash_icon: {
    marginLeft: "15px",
    cursor:"pointer",
    color:'#757575'
  },
}));
