import { useStyle } from "../../all-communications-tab/com.style";
import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import request from "../../../../utils/authUtil";
import request3 from "../../../../utils/authUtilv3";
import { Loader } from "../../../../components/common/loader";
import Icon from "@material-ui/core/Icon";
import { ViewDoc } from "../../../../components/common/view-doc/index";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";
import { AdminButton } from "../../../../components/common/admin-button";

type Props = {
  applicationID: string;
};

type currentUser = {
  created: string;
  type: string;
  to: string;
  user: string;
  description: string;
  comm_message_uuid: string;
};

const DEFAULT_CURRENT_USER = {
  created: "",
  type: "",
  to: "",
  user: "",
  description: "",
  comm_message_uuid: "",
};

const columns = [
  { id: "user", label: "user", minWidth: 100 },
  { id: "to", label: "To", minWidth: 100 },
  {
    id: "Type",
    label: "Type",
    minWidth: 100,
  },
  { id: "Created", label: "Created", minWidth: 100 },
  {
    id: "description",
    label: "Description",
    minWidth: 100,
    align: "right",
  },
  {
    id: "View",
    label: "",
    minWidth: 100,
    align: "center",
  },
  {
    id: "resend_email",
    label: "",
    minWidth: 100,
    align: "center",
  },
];

const Communication: React.FC<Props> = ({ applicationID }) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentSelectedUser, setCurrentSelectedUser] =
    useState<currentUser>(DEFAULT_CURRENT_USER);
  const [isSendEmailPopupOpen, setIsSendEmailPopupOpen] =
    useState<boolean>(false);
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };
  const schoolId = localStorage.getItem("school-id");
  const [communication, setCommunication] = useState([{}]);
  const [htmlString, setHtmlString] = useState("");
  const [openDoc, setOpenDoc] = useState<boolean>(false);

  const getCommunication = () => {
    setLoader(true);
    request({
      url: `school-settings/application-communication/?student_app_uuid=${applicationID}`,
      method: "get",
    }).then((res) => {
      setCommunication(res?.data?.results);
      setLoader(false);
    });
  };

  const getHtmlString = (commMessageUuid: string) => {
    setLoader(true);
    request({
      url: `school-settings/all-communication-messages/?school_uuid=${schoolId}&comm_message_uuid=${commMessageUuid}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setHtmlString(res.data.results);
    });
  };

  const resendEmailCommunication = async (commEmailUuid: string) => {
    setLoader(true);
    await request3({
      url: `school-settings/resend-communication-email/?student_app_uuid=${applicationID}`,
      method: "post",
      data: {
        comms_email_uuid: commEmailUuid,
      },
    });
    setLoader(false);
  };

  useEffect(() => {
    getCommunication();
  }, []);

  return (
    <Box>
      <Loader open={loader} />
      <Box>
        <Paper
          style={{
            overflow: "hidden",
            marginTop: "20px",
            marginBottom: "30px",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      className={classes.fontFamily}
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {communication
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        <>
                          <TableCell className={classes.fontFamily}>
                            {item?.user}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.to}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.type}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.created}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.description}
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                width: 80,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                getHtmlString(item?.comm_message_uuid);
                                setOpenDoc(true);
                              }}
                            >
                              <Icon
                                style={{
                                  color: "#757575",
                                  marginRight: 10,
                                }}
                              >
                                remove_red_eye_icon
                              </Icon>
                              VIEW
                            </span>
                          </TableCell>
                          <TableCell>
                            <AdminButton
                              onClick={() => {
                                setCurrentSelectedUser(item);
                                setIsSendEmailPopupOpen(true);
                              }}
                              label="Resend"
                            />
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ViewDoc
          openDoc={openDoc}
          setOpen={setOpenDoc}
          docName="html"
          htmlString={htmlString}
        />
        <ConfirmBox
          message={`Do you want to resend this email to ${currentSelectedUser?.to}`}
          onClick={() => {
            resendEmailCommunication(currentSelectedUser?.comm_message_uuid);
          }}
          open={isSendEmailPopupOpen}
          setOpen={setIsSendEmailPopupOpen}
        />
      </Box>
    </Box>
  );
};

export default Communication;
