import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { Box, Icon } from "@material-ui/core";
import { Button } from "../../../../components/common/button/index";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import { useState } from "react";
import { useStyle } from "../start-selection/selection-table/selection-table.style";
type Props = {
  data?: any;
  onClick?: any;
};

const ManualTable: React.FC<Props> = ({ data, onClick }) => {
  const classes = useStyle();
  const [open, setOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState({
    id: "",
    status: "",
  });

  const onEditStatus = (id: any, status: any) => {
    setOpen(true);
    setEditData({
      ...editData,
      id: id,
      status: status,
    });
  };
  const columns: GridColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "student_name",
      headerName: "Student",
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "criteria",
      headerName: "Criteria",
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "application_status",
      headerName: "Status",
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Box
            className={
              params.row.application_status === "Selected"
                ? classes.app_status
                : ""
            }
          >
            {params.row.application_status}
          </Box>
        );
      },
      align: "center",
    },
    {
      field: "view",
      headerName: "View Application",
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return (
          <span
            onClick={() =>
              window.open(
                `/admin/applications/${params?.row?.student_app_uuid}`
              )
            }
            style={{
              width: 80,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Icon
              style={{
                color: "#757575",
                marginRight: 10,
              }}
            >
              remove_red_eye_icon
            </Icon>
            VIEW
          </span>
        );
      },
    },
    {
      field: " ",
      headerName: "Edit",
      minWidth: 250,
      align: "center",
      sortable: false,
      headerAlign: "center",
      renderCell: (params: any) => {
        return (
          <Box width="150px">
            <Button
              startIcon="edit"
              label="Edit"
              squared
              light
              onClick={() =>
                onEditStatus(params?.row?.id, params?.row?.application_status)
              }
            />
          </Box>
        );
      },
    },
  ];

  const rows = data;

  return (
    <>
      <DataGrid
        autoHeight={true}
        className={classes.root}
        rows={rows}
        columns={columns}
        disableSelectionOnClick={true}
        disableColumnFilter={true}
        disableColumnMenu={true}
        hideFooterPagination={true}
        hideFooter={true}
      />
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={onClick}
        id={editData.id}
        status={editData.status}
        arg={true}
        message={`Are you sure you want to edit application ${editData.id} status?`}
      />
    </>
  );
};

export default ManualTable;
