import Icon from "@material-ui/core/Icon";
import React, { useState } from "react";
import { useStyle } from "./download.styles";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import cn from "classnames";
import { Box } from "@material-ui/core";
import { DeleteBox } from "../delete-dialogbox/index";
import { ViewDoc } from "../view-doc/index";
type downloadProps = {
  id?: string;
  label?: string;
  iconSize?: string;
  onChange?: any;
  file?: any;
  onDelete?: any;
  downloadLink: string;
  index?: number;
  viewOnly?: boolean;
};

export const DownLoad: React.FC<downloadProps> = ({
  label,
  iconSize,
  file,
  id,
  onDelete,
  downloadLink,
  index,
  viewOnly = false,
}) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  return (
    <div style={{ marginBottom: 34 }}>
      <DeleteBox
        index={index}
        id={id}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
      <InputLabel className={cn(classes.label)}>{label}</InputLabel>
      <label htmlFor={id}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            className={cn(classes.root)}
            component="span"
            onClick={() => setOpenDoc(true)}
          >
            {file}
            <Icon
              className={classes.uploadIcon}
              style={{
                fontSize: `${iconSize}`,
                color:'#757575'
              }}
            >
              remove_red_eye_icon
            </Icon>
          </Button>

          {!viewOnly ? (
            <Icon
              onClick={() => setOpen(true)}
              className={classes.deleteIcon}
              style={{
                fontSize: `${iconSize}`,
                color:'#757575'
              }}
            >
              delete
            </Icon>
          ) : null}
        </Box>
      </label>
      <ViewDoc
        openDoc={openDoc}
        docName={file}
        url={downloadLink}
        setOpen={setOpenDoc}
      />
    </div>
  );
};
