import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  documentContainer: {
    width: "65%",
    marginTop: 30,
  },
  textAreaContainer: {
    width: "100%",
    marginTop: 10,
    fontSize: "30px",
  },
  secondaryContainer: {
    marginTop: 10,
  },
  container: {
    marginLeft: "-20px",
  },
  extraButton: {
    outline: "none",
    border: "none",
    backgroundColor: theme.palette.grey[200],
    padding: "7px 15px",
    cursor: "pointer",
  },
  trash_icon: {
    marginLeft: "15px",
    marginTop: "20px",
    cursor: "pointer",
    color: '#757575'
  },
  root: {
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    fontFamily: `"LFT Etica", sans-serif`,
    borderRadius: "5px",
    maxWidth: "98%",
    minWidth: "98%",
    maxHeight: "100px",
    minHeight: "100px",
    padding: "20px",
    "&:focus": {
      outline: "none",
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
    fontSize: "16px",
    background: theme.palette.common.white,
  },
  rootError: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
    borderRadius: "5px",
    maxWidth: "98%",
    minWidth: "98%",
    maxHeight: "100px",
    minHeight: "100px",
    padding: "20px",
    "&:focus": {
      outline: "none",
    },
    fontFamily: `"LFT Etica", sans-serif`,
    background: theme.palette.common.white,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 12,
  },
  radiobtn: {
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    "&.Mui-checked": {
      color: theme.palette.grey[700],
    },
    "&.Mui-checked:hover ": {
      boxShadow: "none",
      background: theme.palette.grey[100],
    },
    "&:hover ": {
      boxShadow: "none",
      background: theme.palette.grey[100],
    },
  },
}));
