import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { Input } from "../../../components/common/input/index";
import { Icon } from "@material-ui/core";
import { useStyle } from "./my-document.style";
import { DocDeleteBox } from "./doc-delete/index";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import cn from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

type DocumentProps = {
  id?: string;
  documentLabel: string;
  data: any;
  index: number;
  change?: any;
  onDeleteFur?: any;
  furChange?: any;
  askChange?: any;
  addFollowUp?: any;
  onDelete?: any;
  errorArray?: any;
  errorDesc?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
  error?: boolean;
  errorMessage?: string | null;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Document: React.FC<DocumentProps> = ({
  documentLabel,
  id,
  data,
  index,
  change,
  furChange,
  askChange,
  onDelete,
  errorArray,
  selectedGlobalDate,
  currentGlobalDate,
  error = false,
  errorMessage = "Required",
}) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const addnew = (index: any, chkbx: boolean) => {
    furChange(!chkbx, index, "further_details_required", 0);
  };

  const handleChange = (event: any) => {
    if (event.target.value === "Application") {
      askChange(true, index, "ask_in_application", 0);
    }
    if (event.target.value === "Enrollment") {
      askChange(false, index, "ask_in_application", 0);
    }
  };
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <DocDeleteBox
        id={data.app_document_uuid}
        subId=""
        index={index}
        subIndex={-1}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
      <Text subHeading>{documentLabel}</Text>
      <Box display="flex">
        <div className={classes.textAreaContainer}>
          <TextareaAutosize
            value={data.document_description}
            onChange={(e: any) =>
              change(e.target.value, index, "document_description", 0, e)
            }
            id={id}
            maxRows={4}
            minRows={4}
            style={{ resize: "none", fontSize: "16px", width: "100%" }}
            className={cn(
              errorArray.includes(`doc${index}`)
                ? classes.rootError
                : classes.root
            )}
            disabled={selectedGlobalDate !== currentGlobalDate}
          />
          {errorArray.includes(`doc${index}`) ? (
            <InputLabel className={classes.errorMessage}>
              {errorMessage}
            </InputLabel>
          ) : null}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <Box mb={2} ml={4}>
            <Icon
              className={classes.trash_icon}
              onClick={() => setOpen(true)}
              style={{ marginTop: "65px" }}
            >
              delete
            </Icon>
          </Box>
        )}
      </Box>

      <Box mb={2} mt={2}>
        <Box>
          <FormControl component="fieldset">
            <RadioGroup
              value={data.ask_in_application ? "Application" : "Enrollment"}
              onChange={handleChange}
            >
              <Box>
                <FormControlLabel
                  value="Application"
                  control={<Radio className={classes.radiobtn} />}
                  label="Application"
                />
                <FormControlLabel
                  value="Enrollment"
                  control={<Radio className={classes.radiobtn} />}
                  label="Enrollment"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Checkbox
          color="default"
          onChange={() => addnew(index, data.further_details_required)}
          checked={data.further_details_required}
          disabled={selectedGlobalDate !== currentGlobalDate}
          style={{ marginLeft: "-13px" }}
        />
        <Text paragraph>Further details required?</Text>
      </Box>
      {data.further_details_required ? (
        <div>
          <Box display="flex">
            <div style={{ width: "65%" }}>
              <Input
                value={data.follow_up_document_question}
                disabled={selectedGlobalDate !== currentGlobalDate}
                onChange={(e: any) =>
                  furChange(
                    e.target.value,
                    index,
                    "app_document_question_follow_up_school_application",
                    "follow_up_document_question"
                  )
                }
                error={errorArray.includes(`${index}`)}
                maxlength="200"
              />
            </div>
          </Box>
        </div>
      ) : null}
    </Grid>
  );
};

export default Document;
