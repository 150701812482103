import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import request from "../../../utils/authUtil";
import { useStyle } from "../applications/applications-admin.style";
import { ViewDoc } from "../../../components/common/view-doc/index";
import { AdminButton } from "../../../components/common/admin-button";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";
import { MessageBox } from "../../../components/common/message-Box/index";
import Filter from "../offers/filter";
type Props = {};

interface DocumentData {
  application_id: string;
  last_modified: string;
  name: string;
  document_type: string;
  document_presingne_url: string;
  document_name: string;
}

const Documents: React.FC<Props> = () => {
  const classes = useStyle();
  const schoolId = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(true);
  const [searched, setSearched] = useState<string>("");
  const [openDoc, setOpenDoc] = useState<boolean>(false);
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const [openMsg, setOpenMsg] = useState<boolean>(false);
  const [document, setDocument] = useState<DocumentData[]>([
    {
      application_id: "",
      last_modified: "",
      name: "",
      document_type: "",
      document_presingne_url: "",
      document_name: "",
    },
  ]);
  const [tab, setTab] = useState<string>("list");
  const [filterOption, setFilterOption] = useState<string>("");
  const requestSearch = (searchedVal: string) => {
    setShowSearch(false);
    if (searchedVal !== "") {
      setSearched(searchedVal);
      const filteredRows = document.filter((row) => {
        return (
          row.name
            .toString()
            .toLowerCase()
            .includes(searchedVal.toString().toLowerCase()) ||
          row.document_name
            .toString()
            .toLowerCase()
            .includes(searchedVal.toString().toLowerCase()) ||
          row.document_type
            .toString()
            .toLowerCase()
            .includes(searchedVal.toString().toLowerCase())
        );
      });
      setDocument(filteredRows);
    } else {
      setDocument(data.results.All_Documents_Details);
      setSearched("");
      setShowSearch(true);
    }
  };
  const cancelSearch = () => {
    setDocument(data.results.All_Documents_Details);
    setShowSearch(true);
    setSearched("");
  };

  const getDocuments = async (): Promise<any> => {
    const res = await request({
      url: `school-settings/all-documents-details/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}&status=${filterOption}`,
      method: "get",
    });
    return res;
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getTemplateError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["document", { selectedGlobalDate , filterOption }],
    () => getDocuments(),
    {
      select: (document) => document.data,
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setDocument(data.results.All_Documents_Details);
    }
  }, [data]);

  useEffect(()=>{
    let filterString:any = localStorage?.getItem("filterState")
    let documentFilter = JSON?.parse(filterString)?.document

    if(documentFilter){
      console.log("filter",filterOption)
      setFilterOption((prev)=>documentFilter)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const columns = [
    {
      id: "ID",
      label: "ID",
      minWidth: 100,
    },
    {
      id: "Created",
      label: "Created",
      minWidth: 100,
    },
    {
      id: "By",
      label: "By",
      minWidth: 100,
    },
    {
      id: "Type",
      label: "Type",
      minWidth: 100,
    },
    {
      id: "Documentname",
      label: "Document name ",
      minWidth: 100,
      align: "right",
    },
    {
      id: "Documentview",
      label: "",
      minWidth: 100,
      align: "right",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [localState, setLocalState] = useState({
    doc_name: "",
    url: "",
  });
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onOpenDoc = (name: string, url: string) => {
    setOpenDoc(true);
    setLocalState({ ...localState, doc_name: name, url: url });
  };

  const downloadAll = async () => {
    const res = await request({
      url: `school-settings/export-bulk-zip-document/`,
      method: "get",
    });
    if (res.data.results) {
      setOpenMsg(true);
    }
  };
  const filterSelectFn = (e: any) => {
    let filterString:any = localStorage?.getItem("filterState")
    let filter = JSON?.parse(filterString)
    localStorage.setItem("filterState", JSON.stringify({ ...filter, "document": e?.value }));
    setFilterOption(e?.value);
  };
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Box mb="40px">
        <div>
          <Loader open={loader} />
        </div>

        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{getTemplateError}</p>
          </div>
        ) : null}
        {isSuccess ? (
          <div style={{ margin: "-85px 0px 50px 0px" }}>
            <Box mt="90px">
              <Box className={classes.heading}>Documents</Box>
            </Box>
            <Box className={classes.boxStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="block">
                  <p>Documents</p>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  borderBottom="1px solid #000000"
                >
                  <input
                    value={searched}
                    onChange={(searchVal: any) =>
                      requestSearch(searchVal.target.value)
                    }
                    type="text"
                    placeholder="Search documents"
                    className={classes.inputStyle}
                  />
                  <div>
                    {showSearch ? (
                      <Icon className={classes.iconStyle}>search</Icon>
                    ) : (
                      <Icon
                        onClick={cancelSearch}
                        className={classes.iconStyle}
                      >
                        close
                      </Icon>
                    )}
                  </div>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Icon
                  style={{ marginRight: "10px", cursor: "pointer" }}
                  onClick={() => setTab("list")}
                >
                  toc_icon
                </Icon>
                <Icon
                  style={{ cursor: "pointer" }}
                  onClick={() => setTab("view")}
                >
                  view_comfy_icon
                </Icon>
              </Box>
              <Box
                display="flex"
                alignItems="flex-end"
                gridGap={20}
                marginTop="20px"
              >
                <Filter
                  options={DROP_DOWN_OPTIONS}
                  checkboxClick={filterSelectFn}
                  label="Document Type"
                  defaultVal={filterOption}
                />
                <div style={{ width: "130px" }}>
                  <AdminButton onClick={downloadAll} label="Export All" />
                </div>
              </Box>
            </Box>

            {/* <Box className={classes.boxStyle}>
              <Box>
                <p>Documents</p>
                <Box display="flex" alignItems="center">
                  <Icon
                    style={{ marginRight: "10px", cursor: "pointer" }}
                    onClick={() => setTab("list")}
                  >
                    toc_icon
                  </Icon>
                  <Icon
                    style={{ cursor: "pointer" }}
                    onClick={() => setTab("view")}
                  >
                    view_comfy_icon
                  </Icon>
                </Box>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="start"
                ></Box>
              </Box> */}
            {/* Search Box */}
            {/* <Box
                display="flex"
                justifyContent="end"
                position="relative"
                bottom="75px"
              >
                <input
                  value={searched}
                  onChange={(searchVal: any) =>
                    requestSearch(searchVal.target.value)
                  }
                  type="text"
                  placeholder="Search documents"
                  className={classes.inputStyle}
                />
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "7px",
                  }}
                >
                  {showSearch ? (
                    <Icon className={classes.iconStyle}>search</Icon>
                  ) : (
                    <Icon onClick={cancelSearch} className={classes.iconStyle}>
                      close
                    </Icon>
                  )}
                </div>
              </Box> */}
            {/* </Box> */}
            {tab === "list" ? (
              <Paper
                style={{
                  width: "94%",
                  overflow: "hidden",
                  marginTop: "20px",
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column, index) => (
                          <TableCell
                            className={classes.root}
                            key={`${column.id}${index}`}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {document &&
                        document
                          ?.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => {
                            return (
                              <TableRow
                                key={`${item?.application_id}${index}`}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                <>
                                  <TableCell className={classes.root}>
                                    {item.application_id || 0}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    {item.last_modified}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    {item.name}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    {item.document_type}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    {item.document_name}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    <span
                                      onClick={() =>
                                        onOpenDoc(
                                          item.document_name,
                                          item.document_presingne_url
                                        )
                                      }
                                      style={{
                                        width: 80,
                                        cursor: "pointer",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Icon
                                        style={{
                                          color: "#757575",
                                          marginRight: 10,
                                        }}
                                      >
                                        remove_red_eye_icon
                                      </Icon>
                                      VIEW
                                    </span>
                                  </TableCell>
                                </>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 15, 25, 50, 100]}
                  component="div"
                  count={document.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : null}
            {tab === "view" ? (
              <div style={{ width: "94%", marginTop: "40px" }}>
                <Grid container spacing={3}>
                  {document.map((item) => (
                    <>
                      <Grid item lg={3}>
                        <Box
                          style={{ cursor: "pointer" }}
                          onDoubleClick={() =>
                            onOpenDoc(
                              item.document_name,
                              item.document_presingne_url
                            )
                          }
                        >
                          <Box
                            className={classes.viewContainer}
                            height="192px"
                            color="black"
                          ></Box>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <p className={classes.viewBold}>{item.name}</p>
                            <p className={classes.viewNormal}>
                              {item.document_type}
                            </p>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
            ) : null}
            <ViewDoc
              openDoc={openDoc}
              setOpen={setOpenDoc}
              docName={localState.doc_name}
              url={localState.url}
            />
          </div>
        ) : null}
      </Box>
      <MessageBox
        message="You will recieve an email containing the documents."
        open={openMsg}
        setOpen={setOpenMsg}
      />
    </Grid>
  );
};

export default Documents;

const DROP_DOWN_OPTIONS = [
  {
    name: "All",
    value: "",
  },
  {
    name: "Birth Certificate",
    value: "BIRTHCERTIFICATE",
  },
  {
    name: "Previous school report",
    value: "PREVIOUS_SCHOOL_REPORT",
  },
  {
    name: "Utility Bill 1",
    value: "UTILITY_BILL",
  },
  {
    name: "Utility Bill 2",
    value: "UTILITY_BILL_SECOND",
  },
  {
    name: "Others",
    value: "OTHERS",
  },
];
