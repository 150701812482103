import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button/index";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "../general-tab/general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";

type Props = {
  applicationID?: string;
  error?: any;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const Withdrawals: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [error, setError] = useState<Number[]>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [idPre, setId] = useState<string>("");
  const [indexPre, setIndex] = useState<number>(0);
  const schoolId = localStorage.getItem("school-id");
  const default_state = [
    {
      withdrawl_text: "",
      order: null,
      school_application: "",
      withdrawl_uuid: "",
    },
  ];
  const [withDrawal, setWithDrawal] = useState<any>(default_state);

  const getWithdrawal = () => {
    setLoader(true);
    request({
      url: `school-settings/school-withdrawls/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            response.data.results.map((item: any) => {
              temp.push({
                withdrawl_text: item.withdrawl_text,
                order: item.order,
                school_application: item.school_application,
                withdrawl_uuid: item.withdrawl_uuid,
              });
            });
            setWithDrawal(temp);
          } else {
            setWithDrawal(default_state);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  };

  useEffect(() => {
    getWithdrawal();
  }, [applicationID, selectedGlobalDate]);

  const addWithdrawal = () => {
    ready(false);
    setWithDrawal([
      ...withDrawal,
      { withdrawl_text: "", order: null, withdrawl_uuid: "" },
    ]);
  };

  const onChange = (value: any, ind: any) => {
    ready(false);
    const xyz = [...withDrawal];
    const temp = [...error];
    xyz[ind].withdrawl_text = value;
    setWithDrawal(xyz);
    if (value.length == 0) {
      if (!temp.includes(ind)) {
        temp.push(ind);
      }
    } else if (temp.includes(ind)) {
      const val = temp.indexOf(ind);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  const onSaveClick = () => {
    const temp = [...error];
    try {
      withDrawal.map((item: any, index: any) => {
        if (item.withdrawl_text.length == 0) {
          if (!temp.includes(index)) {
            temp.push(index);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        const data = withDrawal.map((item: any, index: number) => {
          return {
            withdrawl_text: item.withdrawl_text,
            order: item.order === null ? index : item.order,
            withdrawl_uuid: item.withdrawl_uuid,
          };
        });
        request({
          url: `school-settings/school-withdrawls/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
          method: "put",
          data: data,
        }).then((res) => {
          getWithdrawal();
          ready(true);
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteWithdrawal = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/school-withdrawls/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
        method: "delete",
        data: {
          withdrawl_uuid: id,
        },
      }).then(() => {
        const temp = [...withDrawal];
        temp.splice(index, 1);
        setWithDrawal(temp);
        setError([]);
        getWithdrawal();
      });
    } else {
      const temp = [...withDrawal];
      temp.splice(index, 1);
      setWithDrawal(temp);
      setError([]);
    }
  };

  const deleteHandler = (id: string, index: number) => {
    setOpen(true);
    setId(id);
    setIndex(index);
  };

  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.preReqContainer}>
          {withDrawal.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                key={index}
                width="80%"
              >
                <Box width="85%">
                  <Input
                    label={`Withdrawal ${index + 1}`}
                    type="text"
                    value={item.withdrawl_text}
                    onChange={(e: { target: { value: any } }) =>
                      onChange(e.target.value, index)
                    }
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    error={error.includes(index)}
                  />
                </Box>
                {selectedGlobalDate === currentGlobalDate && (
                  <Icon
                    style={{ cursor: "pointer", color: "#757575", marginTop: "24px" }}
                    onClick={() => deleteHandler(item.withdrawl_uuid, index)}
                  >
                    delete
                  </Icon>
                )}
              </Box>
            );
          })}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <>
            <div style={{ width: "300px", marginTop: "20px" }}>
              <AdminButton
                label="Add Withdrawals"
                endIcon="add"
                light
                onClick={addWithdrawal}
              />
            </div>
            <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
              <AdminButton label="Save" onClick={onSaveClick} />
            </div>
          </>
        )}
        <DeleteBox
          index={indexPre}
          id={idPre}
          onClick={() => {
            deleteWithdrawal(idPre, indexPre);
          }}
          open={open}
          setOpen={setOpen}
        />
      </Grid>
    </div>
  );
};

export default Withdrawals;
