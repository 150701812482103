/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "../../../../components/common/button/index";
import { Welcome } from "../../../../components/common/welcome";
import { Info } from "../../../../components/common/info";
import { Box } from "@material-ui/core";
import { useStyle } from "./thankyou-submit.style";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";

const ThankYouSubmit = () => {
  const styles = useStyle();
  const history = useHistory();
  const name = localStorage.getItem("name");
  const [userEmail, setUserEmail] = useState({
    email: "",
  });
  const parentID = ParentUser.uuid;
  const [loader, setLoader] = useState<boolean>(false);
  const getUserData = async (): Promise<any> => {
    if (parentID) {
      setLoader(true);
      const response = await request({
        url: `/parent/parent-profile-page/?uuid=${parentID}`,
        method: "get",
      });
      return response;
    }
  };
  useEffect(() => {
    getUserData().then((res) => {
      setLoader(false);
      if (res) {
        setUserEmail({
          ...userEmail,
          email: res.data.results.email,
        });
      }
    });
    localStorage.removeItem("currentStudentAppID");
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Loader open={loader} />
        <Box mt={5}>
          <Welcome title="Thank you for submitting your student's/child's application" />
          <Box mt="40px">
            <p className={styles.textOne}>
              Your application to {name} has been received.
              A confirmation and PDF record of your application has been sent 
              to <b className={styles.textBold}>{userEmail.email}</b>
            </p>
          </Box>
          <Box mt="25px">
            <p>
              If/when a letter of offer is issued we will require you to
              complete the Enrolment part of this application
            </p>
          </Box>
        </Box>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-around"
        >
          <div className={styles.buttonWidth}>
            <Button
              label="Continue"
              onClick={() => history.push("/parent/dashboard")}
            />
          </div>
        </Grid>
        <Box mt="40px">
          <Info />
        </Box>
      </div>
    </div>
  );
};

export default ThankYouSubmit;
