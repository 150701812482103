import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({

  root: {
    fontFamily: `"LFT Etica", sans-serif`,
  },
  subContainer: {
    marginLeft: "100px",
    marginRight: "100px",
    marginBottom: "50px",
    marginTop: "50px",
    width: "calc(100% - 200px)",
    alignItems: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
    justifyContent: "center",
  },
  textContainer: {
    border: "1px solid",
    borderColor: theme.palette.grey[400],
  },
  textOne: {
    fontWeight: 600,
    fontSize: 20,
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  textTwo: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily:    `"LFT Etica", sans-serif`,
  },
  secondTitle: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily:    `"LFT Etica", sans-serif`,
  },
  link: {
    "&:focus": {
      textDecoration: "none",
    },
    "&:active": {
      textDecoration: "none",
    },
  },
  lotteryBox: {
    width: "400px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    margin: "0 auto",
    marginTop: "40px",
  },
  border: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[200],
  },
  title: {
    marginTop: 10,
  },
  dialogActions: {
    margin: "0 auto",
    paddingBottom: "15px",
  },
  iconStyle: {
    color: theme.palette.grey[300],
    cursor: "pointer",
    marginTop:"8px"
  },
  inputStyle: {
    padding: "15px",
    border: "none",
    background: "none",
    outline: "none",
    
  },
}));
