import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    fontFamily: `"LFT Etica", sans-serif`,
  },
  app_status: {
    color: theme.palette.success.main,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  tooltip_font: {
    fontSize: 16,
  }
}));
