import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  heading: {
    fontSize: 28,
    fontWeight: "bold",
  },
  subHeading: {
    fontSize: 15,
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: 14,
  },
}));
