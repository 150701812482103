import React, { Dispatch, useState } from "react";
import { modalStyle } from "./applicationTrialModal.styled";
import { Button } from "../../../../components/common/button";
import request from "../../../../utils/authUtil";
import Modal from "@material-ui/core/Modal";
import { Box, Icon } from "@material-ui/core";
import { TextArea } from "../../../../components/common/text-area-component/index";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: any;
  applicationID?: any;
  setTrailData?: any;
}

const ApplicationTrialModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  applicationID,
  setTrailData,
}: Props) => {
  const classes = modalStyle();
  //state for textbox values
  const [textboxData, setTextboxData] = useState("");
  //event handler
  //1.continue button handler
  const trialContinueHandler = () => {
    if (textboxData)
      request({
        url: `school-settings/application-trail/`,
        method: "post",
        data: {
          student_app_uuid: applicationID,
          notes: textboxData,
        },
      }).then((response) => {
        if (response.data.status_code === 1) {
          setTrailData(response.data.results);
          setTextboxData("");
          setIsModalOpen(false);
        }
      });
  };

  return (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className={classes.modalContainer}>
        <Box mt={5}>
          <Box
            className={classes.cancelIcon}
            onClick={() => setIsModalOpen(false)}
          >
            <Icon>cancel_icon</Icon>
          </Box>
          <Box>
            <TextArea
              label="ADD NOTES :"
              value={textboxData}
              onChange={(e: any) => setTextboxData(e.target.value)}
            />
          </Box>
          <div className={classes.continueBtn}>
            <Button
              label="Continue"
              padding="0.4rem 2rem"
              onClick={trialContinueHandler}
            ></Button>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default ApplicationTrialModal;
