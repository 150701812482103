import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { useState, useEffect } from "react";
import request from "../../utils/authUtil";
import ApplicationSetup from "./application-setup/index";
import { useQuery, UseQueryResult } from "react-query";
import AllCommunications from "./all-communications-tab";
import Templates from "./templates-tab";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  tabMain: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontWeight: "bold",
  },
  tab: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#F7F8FC",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    maxWidth: "100%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Communications() {
  const styles = useStyle();
  const [value, setValue] = useState<number>();
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const [flag, setFlag] = useState<boolean>(false);
  const [appId, setAppId] = useState<string>("");
  useEffect(() => {
    setValue(0);
  }, []);

  const getApplication = async (): Promise<any> => {
    const schoolId = localStorage.getItem("school-id");
    const res = await request({
      url: `/school-settings/school-application/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    });
    return res;
  };
  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["applicationId", { selectedGlobalDate }],
    () => getApplication(),
    {
      select: (app) => app.data,
    }
  );

  useEffect(() => {
    if (data) {
      if (data?.results.length === 0) {
        setFlag(false);
      } else {
        setFlag(true);
        setAppId(data?.results[0].application_uuid);
      }
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div>
          <p>Loading.....</p>
        </div>
      ) : null}
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
        </div>
      ) : null}
      {isSuccess ? (
        <>
          {flag ? (
            <Box className={styles.root}>
              <Box>
                <Box mb={1}>
                  <Box className={styles.heading}>Communications</Box>
                </Box>
                <div className={styles.tabMain}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    className={styles.tabs}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      className={styles.tab}
                      label="Manage Templates"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={styles.tab}
                      label="Communications"
                      {...a11yProps(0)}
                    />
                  </Tabs>

                  <TabPanel value={value} index={0}>
                    <Templates
                      currentGlobalDate={currentGlobalDate}
                      selectedGlobalDate={selectedGlobalDate}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <AllCommunications
                      applicationID={appId}
                      currentGlobalDate={currentGlobalDate}
                      selectedGlobalDate={selectedGlobalDate}
                    />
                  </TabPanel>
                </div>
              </Box>
            </Box>
          ) : (
            <ApplicationSetup />
          )}
        </>
      ) : null}
    </>
  );
}
