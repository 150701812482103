import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import ManualResetPassword from "./manual-reset-password";

type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  tabMain: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontWeight: "bold",
  },
  tab: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#F7F8FC",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    maxWidth: "100%",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const Users: React.FC<Props> = () => {
  const [value, setValue] = useState<number>(0);
  const styles = useStyle();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className={styles.root}>
        <Box>
          <Box mb={1}>
            <Box className={styles.heading}>Reset Password</Box>
          </Box>
          <div className={styles.tabMain}>
            <Tabs
              value={value}
              onChange={handleChange}
              className={styles.tabs}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab
                className={styles.tab}
                label="Reset Password"
                {...a11yProps(0)}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <ManualResetPassword/>
            </TabPanel>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Users;
