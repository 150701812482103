import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "650px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  adminLogin: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoImage: {
    width: 50,
    height: 65,
  },
  howItWorks: {
    fontSize: "14px",
    color: "#757575",
    fontWeight: 600,
  },
  stepsText: {
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "25px",
  },
  textLight: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "23px",
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
}));
