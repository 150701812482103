import { useStyle } from "./start-selection/selection-dropdown-component/selection-drop.style";
import { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import cn from "classnames";

type Props = {
  options: any;
  checkboxClick?: any;
  label?: string;
  defaultVal?: any;
};
const Filter: React.FC<Props> = ({ defaultVal, options, checkboxClick, label }) => {
  const classes = useStyle();
  const ref: any = useRef();
  const [isDrop, setIsDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setIsDrop(false);
    });
  }, []);

  const isDropdown = () => {
    setIsDrop(!isDrop);
  };

  const DefaultCritState = defaultVal?.length > 0 ? options?.find((option:any) => option.value === defaultVal)?.name : options[0].name;

  const [crit, setCrit] = useState<string>(DefaultCritState);

  const optionClick = (criteria: any, index: number) => {
    checkboxClick(criteria);
    isDropdown();
    setCrit(criteria.name);
  };

  return (
    <div>
      <InputLabel className={cn(classes.label)}>{label}</InputLabel>
      <Box position="relative">
        <div ref={ref}>
          <Box
            display="flex"
            alignItems="center"
            fontWeight={600}
            onClick={isDropdown}
          >
            <Box
              width="180px"
              className={classes.select}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <p className={classes.textOne}>{crit}</p>
              <Icon className={classes.arrowIcon}>
                {isDrop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
              </Icon>
            </Box>
          </Box>
          <Box className={isDrop ? classes.dropContainer : classes.displayNone}>
            <div>
              {options.map((item: any, index: number) => (
                <Box
                  key={item.value}
                  className={cn(
                    classes.root,
                    index !== options.length - 1 ? classes.itemBorder : ""
                  )}
                  onClick={() => optionClick(item, index)}
                >
                  <Box
                    padding="10px"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div>{item.name}</div>
                  </Box>
                </Box>
              ))}
            </div>
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Filter;
