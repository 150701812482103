import { Input } from "../../../../components/common/input";
import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import request from "../../../../utils/authUtil";
import request3 from "../../../../utils/authUtilv3"
import { Loader } from "../../../../components/common/loader/index";
import { AdminButton } from "../../../../components/common/admin-button";
import cn from "classnames";
import { useStyle } from "./student-details.style";
import Grid from "@material-ui/core/Grid";
import theme from "../../../../theme";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import { county } from "../../../parent/application/county";
import { ConfirmBox } from "../../../../components/common/confirm-box/index";
import { GuardianRadioButton } from "../../../parent/application/guardian-details/guardian-info-radio/guardian-radio";
import { dropDownHelper } from "../../../../utils/helper";
import { updatePdf } from "./single-application.api";

type Props = {
  applicationID?: any;
  UIType?: string;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const GuardianDetails: React.FC<Props> = ({
  applicationID,
  UIType,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const school_uuid = localStorage.getItem("school-id");
  const student_app_uuid = applicationID;
  const [error, setError] = useState<string>("");
  const [errorEdit, setErrorEdit] = useState("");
  const [GuardianData, setGuardianData] = useState<any>({});
  const [guardianDataTwo, setGuardianDataTwo] = useState<any>({});
  const [loader, setLoader] = useState<any>(false);
  const [hideSecondGuard, setHideSecondGuard] = useState<any>(false);
  const [hideSecondaryGuardInfoForm, setHideSecondaryGuardInfoForm] = useState<any>(true);
  const [hasSecondaryGuardInfo, setHasSecondaryGuardInfo] = useState<any>(false);
  const [show, setShow] = useState("guardianDetails1");
  const [active, setActive] = useState("guardianDetails1");
  const classes = useStyle();
  const [formDisable, setFormDisable] = useState(true);
  const [twoGuardians, setTwoGuardians] = useState<boolean>(false);
  const [prevGuardianOne, setPrevGuardianOne] = useState({});
  const [prevGuardianTwo, setPrevGuardianTwo] = useState({});
  const [open, setOpen] = useState(false);
  const [corData, setCorData] = useState({
    email_communication: "",
    post_communication: "",
  });
  const [guardianCores, setguardianCores] = useState([
    {
      type: "email",
      label: "Indicated preference to receive school texts",
      radioHolderOne: "GUARDIAN_ONE_1",
      radioHolderTwo: "GUARDIAN_TWO_1",
      radioHolderThree: "GUARDIAN_BOTH_1",
      value: "",
    },
    {
      type: "communication",
      label: "Preffered correspondence address",
      radioHolderOne: "GUARDIAN_ONE_2",
      radioHolderTwo: "GUARDIAN_TWO_2",
      radioHolderThree: "GUARDIAN_BOTH_2",
      value: "",
    },
  ]);
  const [country, setCountry] = useState([
    { name: "-- Select Country --", value: "" },
  ]);


  const Relative = [
    { name: "--Select--", value: "" },
    { name: "Mother", value: "MOTHER" },
    { name: "Father", value: "FATHER" },
    { name: "Legal Guardian", value: "Legal_GUARDIAN" },
    { name: "Host Parent", value:'HOST_PARENT'},
    { name: "Overseas Agent", value: "OVERSEAS_AGENT"},
  ];

  const getCountry = async () => {
    await request({
      url: `student-application/country-details/`,
      method: "get",
    }).then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "name",
        value: "value",
      });
      setCountry([...country, ...result]);
    });
  };

  const getCorrespondance = async () => {
    await request({
      url: `student-application/guardian-communication-prefrence/?student_app_uuid=${applicationID}`,
      method: "get",
    }).then((res) => {
      const temp = guardianCores.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            value: res.data.results.email_communication,
          };
        } else {
          return {
            ...item,
            value: res.data.results.post_communication,
          };
        }
      });
      setguardianCores(temp);
    });
  };

  const getFirstGuardianData = async (): Promise<any> => {
    setLoader(true);
    await request({
      url: `school-settings/primary-guardian-details/?school_uuid=${school_uuid}&student_app_uuid=${student_app_uuid}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res?.data?.status_code === 1) {
        setGuardianData(res.data.results.Guardian_Details);
        setPrevGuardianOne(res.data.results.Guardian_Details);
      } else if (res?.data?.status_code === -1) {
        setLoader(false);
        setError(res.data.status_message);
      }
    });
  };
  const getSecondGuardianData = async (): Promise<any> => {
    setLoader(true);
    let res = await request({
      url: `school-settings/secondary-guardian-details/?school_uuid=${school_uuid}&student_app_uuid=${student_app_uuid}`,
      method: "get",
    })
      setLoader(false);
      if (res?.data?.status_code === 1) {
        if (Object.keys(res.data.results.Guardian_Details).length !== 0) {
          setHasSecondaryGuardInfo(true);
          setHideSecondGuard(false);
          setGuardianDataTwo(res.data.results.Guardian_Details);
          setPrevGuardianTwo(res.data.results.Guardian_Details);
        } else if (
          Object.keys(res.data.results.Guardian_Details).length === 0
        ) {
          setHideSecondGuard(true);
          setHasSecondaryGuardInfo(false);
        }
      } else if (res?.data.status_code === -1) {
        setLoader(false);
        setError(res.data.status_message);
      }
  };

  const getAllGuardianDetails = async () => {
    await request({
      url: `student-application/guardian-details/?student_app_uuid=${applicationID}&guardian_uuid=`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length === 2) {
        setTwoGuardians(true);
      }
    });
  };

  useEffect(() => {
    getFirstGuardianData();
    getSecondGuardianData();
    getCorrespondance();
    getAllGuardianDetails();
    getCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioChange = (value: string, type: any) => {
    if (type === "email") {
      setCorData({
        ...corData,
        email_communication: value,
      });
      var temp = guardianCores;
      temp = guardianCores.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            value: value,
          };
        } else {
          return item;
        }
      }) as typeof guardianCores;
      setguardianCores(temp);
    }
    if (type === "communication") {
      setCorData({
        ...corData,
        post_communication: value,
      });
      temp = guardianCores.map((item, index) => {
        if (index === 1) {
          return {
            ...item,
            value: value,
          };
        } else {
          return item;
        }
      }) as typeof guardianCores;
      setguardianCores(temp);
    }
  };

  const saveGuardianAddress = async () => {
    var guardId: any;
    var data: any;
    if (show === "guardianAddress1") {
      guardId = GuardianData.uuid;
      data = {
        address_line_one: GuardianData.address_line_one,
        address_line_two: GuardianData.address_line_two,
        town: GuardianData.town,
        city: GuardianData.city,
        country: GuardianData.country,
        eir_code: GuardianData.eir_code,
        is_current: true,
        is_permanent: true,
      };
    } else if (show === "guardianAddress2") {
      guardId = guardianDataTwo.uuid;
      data = {
        address_line_one: guardianDataTwo.address_line_one,
        address_line_two: guardianDataTwo.address_line_two,
        town: guardianDataTwo.town,
        city: guardianDataTwo.city,
        country: guardianDataTwo.country,
        eir_code: guardianDataTwo.eir_code,
        is_current: true,
        is_permanent: true,
      };
    }
    await request({
      url: `student-application/guardian-address-details/?guardian_uuid=${guardId}`,
      method: "put",
      data,
    }).then((res) => {
      if (show === "guardianAddress1") {
        getFirstGuardianData();
      } else if (show === "guardianAddress2") {
        getSecondGuardianData();
      }
      setFormDisable(true);
    });
  };

  const saveGuardianDetails = async () => {
    var data;
    if (show === "guardianDetails1") {
      data = {
        uuid: GuardianData.uuid,
        guardian_first_name: GuardianData.first_name,
        guardian_last_name: GuardianData.last_name,
        guardian_email: GuardianData.email,
        guardian_phone: parseInt(GuardianData.phone_number),
        guardian_relation: GuardianData.relationship_to_student,
        guardian_phone_verified: true,
        guardian_email_verified: true,
        guardian_type: "PRIMARY",
        mother_maiden_name: GuardianData.mother_maiden_name,
      };
    } else if (show === "guardianDetails2") {
      data = {
        uuid: guardianDataTwo.uuid,
        guardian_first_name: guardianDataTwo.first_name,
        guardian_last_name: guardianDataTwo.last_name,
        guardian_email: guardianDataTwo.email,
        guardian_phone: parseInt(guardianDataTwo.phone_number),
        guardian_relation: guardianDataTwo.relationship_to_student,
        guardian_phone_verified: true,
        guardian_email_verified: true,
        guardian_type: "SECONDARY",
      };
    }
    await request({
      url: `student-application/guardian-details/?student_app_uuid=${applicationID}`,
      method: "put",
      data,
    }).then(() => {
      if (show === "guardianDetails1") {
        getFirstGuardianData();
      } else if (show === "guardianDetails2") {
        getSecondGuardianData();
      }
      setFormDisable(true);
    });
  };

  const saveSecondaryGuardianInformation = async () => {
    let data = {
      guardian_first_name: guardianDataTwo.first_name,
      guardian_last_name: guardianDataTwo.last_name,
      guardian_email: guardianDataTwo.email,
      guardian_phone: parseInt(guardianDataTwo.phone_number),
      guardian_relation: guardianDataTwo.relationship_to_student,
      guardian_phone_verified: true,
      guardian_email_verified: true,
      guardian_type: "SECONDARY",
      address_line_one: guardianDataTwo.address_line_one,
      address_line_two: guardianDataTwo.address_line_two,
      town: guardianDataTwo.town,
      city: guardianDataTwo.city,
      country: guardianDataTwo.country,
      eir_code: guardianDataTwo.eir_code,
      is_current: true,
      is_permanent: true,
    };
    await request3({
      url: `school-settings/add-secondary-guardian-details/?student_app_uuid=${applicationID}`,
      method: "post",
      data,
    })
      setLoader(true);
      setHideSecondaryGuardInfoForm(true);
      getSecondGuardianData();
      setFormDisable(true);
      setLoader(false);
  }

  const saveGuardianCorrespondance = async (): Promise<any> => {
    const data = {
      email_communication: corData.email_communication,
      post_communication: corData.post_communication,
    };
    await request({
      url: `student-application/guardian-communication-prefrence/?student_app_uuid=${applicationID}`,
      method: "put",
      data,
    }).then(() => {
      getCorrespondance();
      setFormDisable(true);
    });
  };
  const saveData = () => {
    if (show === "guardianAddress1" || (show === "guardianAddress2" && hasSecondaryGuardInfo)) {
      saveGuardianAddress();
    }
    if (show === "guardianDetails1" || (show === "guardianDetails2" && hasSecondaryGuardInfo)){
      saveGuardianDetails();
    }
    if (show === "preference") {
      saveGuardianCorrespondance();
    }
    if((show === "guardianAddress2" ||show === "guardianDetails2") && !hasSecondaryGuardInfo){
      saveSecondaryGuardianInformation();
    }
    updatePdf(applicationID);
    setErrorEdit("");
  };

  const cancelEdit = () => {
    setHideSecondaryGuardInfoForm(true);
    setFormDisable(true);
    setGuardianData(prevGuardianOne);
    setGuardianDataTwo(prevGuardianTwo);
    setErrorEdit("");
  };

  const handleTabChange = (value: string) => {
    if (formDisable) {
      setFormDisable(true);
      setShow(value);
      setActive(value);
    } else {
      setErrorEdit(
        "Please save data before changing tab or click on cancel button"
      );
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <div>
          <Loader open={loader} />
          <Box width="600px">
            {show === "guardianDetails1" ? (
              <>
                <Box display="flex">
                  <Box style={{ width: "50%" }} marginRight={3}>
                    <Input
                      label="First name "
                      value={GuardianData.first_name}
                      onChange={(e) => {
                        setGuardianData({
                          ...GuardianData,
                          first_name: e.target.value,
                        });
                      }}
                      disabled={formDisable}
                    />
                  </Box>
                  <Box style={{ width: "50%" }}>
                    <Input
                      label="Last name "
                      value={GuardianData.last_name}
                      disabled={formDisable}
                      onChange={(e) => {
                        setGuardianData({
                          ...GuardianData,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </Box>
                </Box>
                <Box style={{ fontWeight: "normal" }}>
                  <Box style={{ fontWeight: "normal" }}>
                    <SelectDropDown
                      label="Relationship to the student *"
                      item={Relative}
                      value={GuardianData.relationship_to_student}
                      disabled={formDisable}
                      onChange={(e: any) => {
                        setGuardianData({
                          ...GuardianData,
                          relationship_to_student: e.target.value,
                        });
                      }}
                    />
                  </Box>
                </Box>

                <Input
                  label="Please enter child’s mothers maiden name"
                  value={GuardianData.mother_maiden_name}
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      mother_maiden_name: e.target.value,
                    });
                  }}
                  disabled={formDisable}
                />
                <Input
                  type="number"
                  label="Phone"
                  value={GuardianData.phone_number}
                  disabled={formDisable}
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      phone_number: e.target.value,
                    });
                  }}
                />
                <Input
                  label="Email"
                  type="email"
                  value={GuardianData.email}
                  disabled={formDisable}
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      email: e.target.value,
                    });
                  }}
                />
              </>
            ) : null}
            {show === "guardianAddress1" ? (
              <>
                <Input
                  label="Address line 1 "
                  value={GuardianData.address_line_one}
                  disabled={formDisable}
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      address_line_one: e.target.value,
                    });
                  }}
                />
                <Input
                  label="Address line 2 "
                  value={GuardianData.address_line_two}
                  disabled={formDisable}
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      address_line_two: e.target.value,
                    });
                  }}
                />
                <Input
                  value={GuardianData.town}
                  disabled={formDisable}
                  label="Town/City *"
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      town: e.target.value,
                    });
                  }}
                />
                <SelectDropDown
                  label={GuardianData.country === "IE" ? "County *" : "County"}
                  item={county}
                  disabled={formDisable}
                  value={GuardianData.city}
                  onChange={(e: any) => {
                    setGuardianData({
                      ...GuardianData,
                      city: e.target.value,
                    });
                  }}
                />
                <SelectDropDown
                  label="Country *"
                  item={country}
                  value={GuardianData.country}
                  disabled={formDisable}
                  onChange={(e: any) => {
                    setGuardianData({
                      ...GuardianData,
                      country: e.target.value,
                    });
                  }}
                />
                {/* <Input value={GuardianData.country_of_birth}   disabled={formDisable} /> */}
                <Input
                  value={GuardianData.eir_code}
                  disabled={formDisable}
                  label="Eir code"
                  onChange={(e) => {
                    setGuardianData({
                      ...GuardianData,
                      eir_code: e.target.value,
                    });
                  }}
                />
              </>
            ) : null}
            {show === "guardianDetails2" ? (
              hideSecondGuard ? hideSecondaryGuardInfoForm ? (
                <>
                <div>Second Guardian Details is not provided</div>
                {localStorage.getItem("employee_type") ===
                  "Super Admin" ? (
                  <Box display="flex" flexDirection="row" alignItems="center" gridGap="24px" mt="36px">
                    <p>Add Second Guardian Information</p>
                    <Box width="100px">
                      <AdminButton
                        startIcon="add"
                        onClick={()=>{
                          setFormDisable(false);
                          setHideSecondaryGuardInfoForm(false);
                        }} 
                        label="Add"/>
                    </Box>
                  </Box>
                ):null}
                </>
              )
              :null
               : (
                <>
                  <Box display="flex">
                    <Box style={{ width: "50%" }} marginRight={3} mt={3}>
                      <Input
                        label="First name "
                        value={guardianDataTwo.first_name}
                        disabled={formDisable}
                        onChange={(e) => {
                          setGuardianDataTwo({
                            ...guardianDataTwo,
                            first_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Box style={{ width: "50%" }} mt={3}>
                      <Input
                        label="Last name "
                        value={guardianDataTwo.last_name}
                        disabled={formDisable}
                        onChange={(e: any) => {
                          setGuardianDataTwo({
                            ...guardianDataTwo,
                            last_name: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  </Box>
                  <Box style={{ fontWeight: "normal" }}>
                    <SelectDropDown
                      label="Relationship to the student *"
                      item={Relative}
                      value={guardianDataTwo.relationship_to_student}
                      disabled={formDisable}
                      onChange={(e: any) => {
                        setGuardianDataTwo({
                          ...guardianDataTwo,
                          relationship_to_student: e.target.value,
                        });
                      }}
                    />
                  </Box>
                  <Input
                    type="number"
                    label="Phone"
                    value={guardianDataTwo.phone_number}
                    disabled={formDisable}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        phone_number: e.target.value,
                      });
                    }}
                  />
                  <Input
                    label="Email"
                    type="email"
                    value={guardianDataTwo.email}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        email: e.target.value,
                      });
                    }}
                  />
                </>
              )
            ) : null}
            {show === "guardianAddress2" ? (
              hideSecondGuard ? hideSecondaryGuardInfoForm ? (
                <>
                <div>Second Guardian Address is not provided</div>
                {localStorage.getItem("employee_type") ===
                  "Super Admin" ? (
                  <Box display="flex" flexDirection="row" alignItems="center" gridGap="24px" mt="36px">
                    <p>Add Second Guardian Information</p>
                    <Box width="100px">
                      <AdminButton
                        startIcon="add"
                        onClick={()=>{
                          setFormDisable(false);
                          setHideSecondaryGuardInfoForm(false);
                        }} 
                        label="Add"/>
                    </Box>
                  </Box>
                ):null}
                </>
              ) : null : (
                <>
                  <Input
                    label="Address line 1 "
                    value={guardianDataTwo.address_line_one}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        address_line_one: e.target.value,
                      });
                    }}
                  />
                  <Input
                    label="Address line 2 "
                    value={guardianDataTwo.address_line_two}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        address_line_two: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={guardianDataTwo.town}
                    disabled={formDisable}
                    label="Town/City *"
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        town: e.target.value,
                      });
                    }}
                  />
                  <SelectDropDown
                    label={
                      guardianDataTwo.country === "IE" ? "County *" : "County"
                    }
                    item={county}
                    disabled={formDisable}
                    value={guardianDataTwo.city}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        city: e.target.value,
                      });
                    }}
                  />
                  <SelectDropDown
                    label="Country *"
                    item={country}
                    disabled={formDisable}
                    value={guardianDataTwo.country}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        country: e.target.value,
                      });
                    }}
                  />
                  {/* <Input value={guardianDataTwo.country_of_birth}   disabled={formDisable} /> */}
                  <Input
                    value={guardianDataTwo.eir_code}
                    disabled={formDisable}
                    label="Eir code"
                    onChange={(e: any) =>
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        eir_code: e.target.value,
                      })
                    }
                  />
                </>
              )
            ) : null}
              {(show === "guardianAddress2" || show === "guardianDetails2") &&
              !hideSecondaryGuardInfoForm &&
              !hasSecondaryGuardInfo ?
              <>
              <Box display="flex">
                <Box style={{ width: "50%" }} marginRight={3} mt={3}>
                  <Input
                    label="First name "
                    value={guardianDataTwo.first_name}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </Box>
                <Box style={{ width: "50%" }} mt={3}>
                  <Input
                    label="Last name "
                    value={guardianDataTwo.last_name}
                    disabled={formDisable}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box style={{ fontWeight: "normal" }}>
                <SelectDropDown
                  label="Relationship to the student *"
                  item={Relative}
                  value={guardianDataTwo.relationship_to_student}
                  disabled={formDisable}
                  onChange={(e: any) => {
                    setGuardianDataTwo({
                      ...guardianDataTwo,
                      relationship_to_student: e.target.value,
                    });
                  }}
                />
              </Box>
              <Input
                type="number"
                label="Phone"
                value={guardianDataTwo.phone_number}
                disabled={formDisable}
                onChange={(e: any) => {
                  setGuardianDataTwo({
                    ...guardianDataTwo,
                    phone_number: e.target.value,
                  });
                }}
              />
              <Input
                label="Email"
                type="email"
                value={guardianDataTwo.email}
                disabled={formDisable}
                onChange={(e) => {
                  setGuardianDataTwo({
                    ...guardianDataTwo,
                    email: e.target.value,
                  });
                }}
              />
              <Input
                    label="Address line 1 "
                    value={guardianDataTwo.address_line_one}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        address_line_one: e.target.value,
                      });
                    }}
                  />
                  <Input
                    label="Address line 2 "
                    value={guardianDataTwo.address_line_two}
                    disabled={formDisable}
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        address_line_two: e.target.value,
                      });
                    }}
                  />
                  <Input
                    value={guardianDataTwo.town}
                    disabled={formDisable}
                    label="Town/City *"
                    onChange={(e) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        town: e.target.value,
                      });
                    }}
                  />
                  <SelectDropDown
                    label={
                      guardianDataTwo.country === "IE" ? "County *" : "County"
                    }
                    item={county}
                    disabled={formDisable}
                    value={guardianDataTwo.city}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        city: e.target.value,
                      });
                    }}
                  />
                  <SelectDropDown
                    label="Country *"
                    item={country}
                    disabled={formDisable}
                    value={guardianDataTwo.country}
                    onChange={(e: any) => {
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        country: e.target.value,
                      });
                    }}
                  />
                  {/* <Input value={guardianDataTwo.country_of_birth}   disabled={formDisable} /> */}
                  <Input
                    value={guardianDataTwo.eir_code}
                    disabled={formDisable}
                    label="Eir code"
                    onChange={(e: any) =>
                      setGuardianDataTwo({
                        ...guardianDataTwo,
                        eir_code: e.target.value,
                      })
                    }
                  />
            </>:null
            }

            {show === "preference" ? (
              <>
                <Box style={{ fontWeight: "normal" }}>
                  <Box mb={5}>
                    <Box mb="25px">
                      <b>Correspondence preferences</b>
                    </Box>
                    {guardianCores.map((item, index) => (
                      <GuardianRadioButton
                        label={item.label}
                        isDisabled={formDisable}
                        twoGuardian={twoGuardians}
                        onChange={handleRadioChange}
                        type={item.type}
                        answer={item.value}
                      />
                    ))}
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </div>
        <div>
          <Box className={classes.subTab} height="200px">
            <button
              className={cn(
                classes.tabButton,
                active === "guardianDetails1" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("guardianDetails1");
              }}
            >
              Guardian 1 Details
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "guardianAddress1" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("guardianAddress1");
              }}
            >
              Guardian 1 Address
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "guardianDetails2" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("guardianDetails2");
              }}
            >
              Guardian 2 Details
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "guardianAddress2" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("guardianAddress2");
              }}
            >
              Guardian 2 Address
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "preference" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("preference");
              }}
            >
              Preference
            </button>
          </Box>
        </div>
      </Grid>
      {!formDisable && (
        <Box mt="20px" mb="20px">
          <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
        </Box>
      )}
      {(show === "guardianAddress2" || show === "guardianDetails2") &&
      hideSecondGuard && hideSecondaryGuardInfoForm ? null : localStorage.getItem("employee_type") === "Super Admin"
        && currentGlobalDate === selectedGlobalDate ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              // disabled={isDisabled}
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={saveData}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </>
  );
};
export default GuardianDetails;
