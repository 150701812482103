import { Route, Switch } from "react-router-dom";
import LandingPage from "../pages/common/landing-page";
import ParentForgotPassword from "../pages/common/parent-forgot-password";
import AdminForgotPassword from "../pages/common/admin-forgot-password";
import Register from "../pages/common/register/index";
import ResetPassword from "../pages/parent/reset-password";
import AdminResetPassword from "../pages/admin/reset-password";
import AdminRoutes from "./AdminRoutes";
import ParentRoutes from "./ParentRoutes";

const Routes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/registration">
          <Register />
        </Route>
        <Route exact path="/admin/forgot-password">
          <AdminForgotPassword />
        </Route>
        <Route exact path="/parent/forgot-password">
          <ParentForgotPassword />
        </Route>
        <Route exact path="/reset-password/:hash/:userId">
          <AdminResetPassword />
        </Route>
        <Route exact path="/parent/reset-password/:hash/:userId">
          <ResetPassword />
        </Route>
        <Route path="/admin">
          <AdminRoutes />
        </Route>
        <Route path="/parent">
          <ParentRoutes />
        </Route>
      </Switch>
    </div>
  );
}

export default Routes;
