/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { InputRadio } from "../../../../components/common/input-radio/index";
import { useStyle } from "./guardian-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { SelectDropDown } from "../../../../components/common/select-dropdown/index";
import { ParentUser } from "../../../../utils/authUtil";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Loader } from "../../../../components/common/loader/index";
import { county } from "../county";
import {
  getUserParentDetails,
  getAllGuardianDetails,
  createGuardian,
  getGuardianAddress,
  postGuardianAddress,
  getStudentAddressData,
  getCountry,
} from "./guardians.api";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const Guardians = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;

  const [currentVisible, setCurrentVisible] = useState<string>("details");

  const [guardianDetails, setGuardianDetails] = useState([
    {
      uuid: "",
      guardian_first_name: "",
      guardian_last_name: "",
      guardian_email: "",
      guardian_phone: "",
      guardian_relation: "",
      guardian_phone_verified: true,
      guardian_email_verified: true,
      guardian_type: "PRIMARY",
      student_application: stdAppId,
      mother_maiden_name: "",
    },
    {
      uuid: "",
      guardian_first_name: "",
      guardian_last_name: "",
      guardian_email: "",
      guardian_phone: "",
      guardian_relation: "",
      guardian_phone_verified: true,
      guardian_email_verified: true,
      guardian_type: "SECONDARY",
      student_application: stdAppId,
      mother_maiden_name: "",
    },
  ]);
  const [guardianAddress, setGuardianAddress] = useState([
    {
      guardian_uuid: "",
      address_line_one: "",
      address_line_two: "",
      town: "",
      city: "",
      country: "IE",
      eir_code: "",
      is_current: true,
      is_permanent: true,
      guardian_details: "",
    },
    {
      guardian_uuid: "",
      address_line_one: "",
      address_line_two: "",
      town: "",
      city: "",
      country: "IE",
      eir_code: "",
      is_current: true,
      is_permanent: true,
      guardian_details: "",
    },
  ]);
  const [guardianIndex, setGuardianIndex] = useState(0);
  const [secondGuardSaved, setSecondGuardSaved] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [country, setCountry] = useState([
    { name: "Choose Country", value: "" },
  ]);
  const [showAdd, setShowAdd] = useState(true);
  const [check, setCheck] = useState<boolean>(false);
  const [guardianTwoTitle, setGuardianTwoTitle] = useState<boolean>(false);

  useEffect(() => {
    getCountry().then((res) => {
      setCountry([...country, ...res.data.results]);
    });
  }, []);

  const handleChangeRadio = () => {
    setCheck(!check);
    sameAsStudent();
  };

  const Relative = [
    { name: "--Select--", value: "" },
    { name: "Mother", value: "MOTHER" },
    { name: "Father", value: "FATHER" },
    { name: "Legal Guardian", value: "Legal_GUARDIAN" },
    { name: "Host Parent", value:'HOST_PARENT'},
    { name: "Overseas Agent", value: "OVERSEAS_AGENT"},
  ];
  const [error, setError] = useState<any>({
    guardian_first_name: false,
    guardian_last_name: false,
    guardian_email: false,
    guardian_phone: false,
    guardian_relation: false,
    address_line_one: false,
    city: false,
    town: false,
    eir_code: false,
    // country: false,
  });

  // ON Page Load
  useEffect(() => {
    if (ParentUser.uuid) {
      setLoader(true);
      getAllGuardianDetails(stdAppId).then((res) => {
        if (res.data.results.length === 0) {
          callUserDetail(ParentUser.uuid || "");
        }
        if (res.data.results.length === 1) {
          setGuardianDetails([
            ...res.data.results,
            {
              uuid: "",
              guardian_first_name: "",
              guardian_last_name: "",
              guardian_email: "",
              guardian_phone: "",
              guardian_relation: "",
              guardian_phone_verified: true,
              guardian_email_verified: true,
              guardian_type: "SECONDARY",
              student_application: stdAppId,
            },
          ]);
          setLoader(false);
        }
        if (res.data.results.length > 1) {
          setGuardianDetails([...res.data.results]);
          setShowAdd(false);
          setLoader(false);
        }
      });
    }
  }, [ParentUser]);

  const callUserDetail = async (userId: string) => {
    getUserParentDetails(userId).then((res) => {
      setLoader(false);
      const dt = res.data.results;
      const temp = [...guardianDetails];
      temp[0] = {
        uuid: "",
        guardian_first_name: dt.first_name,
        guardian_last_name: dt.last_name,
        guardian_email: dt.email,
        guardian_phone: dt.mobile_number,
        guardian_relation: "",
        guardian_phone_verified: true,
        guardian_email_verified: true,
        guardian_type: "PRIMARY",
        student_application: stdAppId,
        mother_maiden_name: dt.mother_maiden_name,
      };
      setGuardianDetails(temp);
    });
  };

  useEffect(() => {
    if (
      currentVisible === "address" &&
      guardianDetails[guardianIndex].uuid !== ""
    ) {
      getGuardianAddress(guardianDetails[guardianIndex].uuid).then((res) => {
        setLoader(false);
        if (Object.keys(res.data.results).length !== 0) {
          const temp = [...guardianAddress];
          temp[guardianIndex] = res.data.results;
          setGuardianAddress(temp);
        }
      });
    }
  }, [currentVisible]);

  const detailsBack = () => {
    if (guardianIndex === 0) {
      prevPage();
    }
    if (guardianIndex === 1) {
      if (secondGuardSaved === true) {
        setCurrentVisible("address");
        setGuardianIndex(0);
      } else if (secondGuardSaved === false) {
        setCurrentVisible("add");
      }
    }
  };

  const addressNext = () => {
    if (guardianIndex === 0) {
      showAdd ? setCurrentVisible("add") : onAddGuardian();
    } else if (guardianIndex === 1) {
      nextPage();
    }
  };

  const onAddGuardian = () => {
    setCurrentVisible("details");
    setGuardianIndex(1);
    setGuardianTwoTitle(true);
  };

  const backButton = () => {
    if (currentVisible === "details") {
      detailsBack();
    }
    if (currentVisible === "address") {
      setCurrentVisible("details");
    }
    if (currentVisible === "add") {
      setCurrentVisible("address");
    }
  };

  const sameAsStudent = () => {
    getStudentAddressData(stdAppId).then((res) => {
      setLoader(false);
      if (Object.keys(res.data.results).length !== 0) {
        const temp = guardianAddress[guardianIndex];
        temp.address_line_one = res.data.results.address_line_one;
        temp.address_line_two = res.data.results.address_line_two;
        temp.city = res.data.results.city;
        temp.country = res.data.results.country;
        temp.eir_code = res.data.results.eir_code;
        temp.town = res.data.results.town;
        const arr = [...guardianAddress];
        arr[guardianIndex] = temp;
        setGuardianAddress(arr);
      }
    });
  };
  const saveGuardianData = () => {
    setErrorOnGuardianDetails();
  };

  const validateGuardianDetails = () => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    return {
      guardian_first_name:
        guardianDetails[guardianIndex].guardian_first_name.length === 0,
      guardian_last_name:
        guardianDetails[guardianIndex].guardian_last_name.length === 0,
      guardian_email: !emailRegex.test(
        guardianDetails[guardianIndex].guardian_email
      ),
      guardian_phone:
        guardianDetails[guardianIndex].guardian_phone.length === 0,
      guardian_relation:
        guardianDetails[guardianIndex].guardian_relation === "",
    };
  };

  const onValueChange = (e: any, ind: any, inputType: string) => {
    const t = [...guardianDetails];
    switch (inputType) {
      case "guardian_first_name":
        t[ind].guardian_first_name = e.target.value;
        setGuardianDetails(t);
        setError({
          ...error,
          guardian_first_name:
            guardianDetails[guardianIndex].guardian_first_name.length === 0,
        });
        break;
      case "guardian_last_name":
        t[guardianIndex].guardian_last_name = e.target.value;
        setGuardianDetails(t);
        setError({
          ...error,
          guardian_last_name:
            guardianDetails[guardianIndex].guardian_last_name.length === 0,
        });
        break;
      case "guardian_relation":
        t[guardianIndex].guardian_relation = e.target.value;
        setGuardianDetails(t);
        setError({
          ...error,
          guardian_relation:
            guardianDetails[guardianIndex].guardian_relation === "",
        });
        break;
      case "guardian_phone":
        let phoneRegex = /^[0-9\b]+$/;
        t[guardianIndex].guardian_phone = e.target.value;
        setGuardianDetails(t);
        setError({
          ...error,
          guardian_phone: !phoneRegex.test(e.target.value),
        });
        break;
      case "guardian_email":
        let emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
        t[guardianIndex].guardian_email = e.target.value;
        setGuardianDetails(t);
        setError({
          ...error,
          guardian_email: !emailRegex.test(e.target.value),
        });
        break;
    }
  };

  const onValueChangeTwo = (e: any, guardianIndex: any, inputType: string) => {
    const t = [...guardianAddress];
    switch (inputType) {
      case "address_line_one":
        t[guardianIndex].address_line_one = e.target.value;
        setGuardianAddress(t);
        setError({
          ...error,
          address_line_one: e.target.value.length === 0,
        });
        break;
      case "address_line_two":
        t[guardianIndex].address_line_two = e.target.value;
        setGuardianAddress(t);
        break;
      case "town":
        t[guardianIndex].town = e.target.value;
        setGuardianAddress(t);
        setError({
          ...error,
          town: e.target.value.length === 0,
        });
        break;
      case "city":
        t[guardianIndex].city = e.target.value;
        setError({
          ...error,
          city: t[guardianIndex].country === "IE" && e.target.value === "",
        });
        break;
      case "country":
        t[guardianIndex].country = e.target.value;
        setError({
          ...error,
          country: e.target.value === "",
        });
        if (t[guardianIndex].country === "IE") {
          setError({
            ...error,
            city: e.target.value === "",
          });
        }
        break;
      case "eir_code":
        t[guardianIndex].eir_code = e.target.value;
        setError({
          ...error,
          eir_code: e.target.value.length === 0,
        });
        break;
    }
  };
  const setErrorOnGuardianDetails = () => {
    const err = validateGuardianDetails();
    setError(err);
    if (
      err.guardian_first_name === false &&
      err.guardian_last_name === false &&
      err.guardian_email === false &&
      err.guardian_phone === false &&
      err.guardian_relation === false
    ) {
      setLoader(true);
      createGuardian(stdAppId, guardianDetails[guardianIndex]).then((res) => {
        if (res.data.status_code === 1) {
          const t = [...guardianDetails];
          t[guardianIndex] = res.data.results;
          setGuardianDetails(t);
          setCurrentVisible("address");
          setLoader(false);
        }
      });
    }
  };

  const saveGuardianAddress = () => {
    setErrorOnGuardianAddress();
  };
  const validateGuardianAddress = () => {
    return {
      address_line_one:
        guardianAddress[guardianIndex].address_line_one.length === 0,
      city:
        guardianAddress[guardianIndex].city === "" &&
        guardianAddress[guardianIndex].country === "IE",
      country: guardianAddress[guardianIndex].country === "",
      town: guardianAddress[guardianIndex].town.length === 0,
      eir_code: guardianAddress[guardianIndex].eir_code.length === 0,
    };
  };
  const setErrorOnGuardianAddress = () => {
    const err = validateGuardianAddress();
    setError(err);
    if (
      err.address_line_one === false &&
      err.city === false &&
      // err.country === false &&
      err.town === false &&
      err.eir_code === false
    ) {
      setLoader(true);
      postGuardianAddress(
        guardianDetails[guardianIndex].uuid,
        guardianAddress[guardianIndex]
      ).then((res) => {
        if (res.data.status_code === 1) {
          setCheck(false);
          setSecondGuardSaved(true);
          setLoader(false);
          addressNext();
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon style={{ cursor: "pointer" }} onClick={backButton}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={2} />
            </Box>
            <div style={{ marginBottom: 60 }}>
              <Welcome
                title={`${
                  guardianTwoTitle ? "Guardian 2 Details" : "Guardian Details"
                }`}
                description="Next: Confirm details"
              />
            </div>
            <>
              {currentVisible === "details" ? (
                <Grid container direction="column">
                  <Input
                    label="First name *"
                    value={guardianDetails[guardianIndex].guardian_first_name}
                    onChange={(e) => {
                      onValueChange(e, guardianIndex, "guardian_first_name");
                    }}
                    error={error.guardian_first_name}
                  />
                  <Input
                    label="Last name *"
                    value={guardianDetails[guardianIndex].guardian_last_name}
                    onChange={(e) => {
                      onValueChange(e, guardianIndex, "guardian_last_name");
                    }}
                    error={error.guardian_last_name}
                  />
                  <Box style={{ fontWeight: "normal" }}>
                    <SelectDropDown
                      label="Relationship to the student/child *"
                      item={Relative}
                      value={guardianDetails[guardianIndex].guardian_relation}
                      onChange={(e: any) => {
                        onValueChange(e, guardianIndex, "guardian_relation");
                      }}
                      error={error.guardian_relation}
                    />
                  </Box>
                  {!guardianTwoTitle ? (
                    <Input
                      label="Please enter child’s mothers maiden name"
                      value={guardianDetails[guardianIndex].mother_maiden_name}
                      onChange={(e) => {
                        const t = [...guardianDetails];
                        t[guardianIndex].mother_maiden_name = e.target.value;
                        setGuardianDetails(t);
                      }}
                      error={error.mother_maiden_name}
                    />
                  ) : null}
                  <Input
                    label="Phone number *"
                    value={guardianDetails[guardianIndex].guardian_phone}
                    onChange={(e) => {
                      onValueChange(e, guardianIndex, "guardian_phone");
                    }}
                    error={error.guardian_phone}
                  />
                  <Input
                    label="Email *"
                    value={guardianDetails[guardianIndex].guardian_email}
                    onChange={(e) => {
                      onValueChange(e, guardianIndex, "guardian_email");
                    }}
                    error={error.guardian_email}
                    errorMessage="Email is invalid"
                  />

                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <div className={styles.buttonWidth}>
                      <Button
                        label="Next"
                        light
                        endIcon="navigate_next"
                        onClick={saveGuardianData}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : null}

              {currentVisible === "address" ? (
                <Grid container direction="column">
                  <Box maxWidth="300px">
                    <b>Guardian address</b>
                    <InputRadio
                      radioHolderOne="Same as student/child"
                      onChange={handleChangeRadio}
                      check={check}
                    />
                  </Box>
                  <Input
                    label="Address line 1 *"
                    value={guardianAddress[guardianIndex].address_line_one}
                    onChange={(e) => {
                      onValueChangeTwo(e, guardianIndex, "address_line_one");
                    }}
                    error={error.address_line_one}
                  />
                  <Input
                    label="Address line 2"
                    value={guardianAddress[guardianIndex].address_line_two}
                    onChange={(e) => {
                      onValueChangeTwo(e, guardianIndex, "address_line_two");
                    }}
                  />
                  <Input
                    label="Town/City*"
                    value={guardianAddress[guardianIndex].town}
                    onChange={(e) => {
                      onValueChangeTwo(e, guardianIndex, "town");
                    }}
                    error={error.town}
                  />
                  <SelectDropDown
                    label={
                      guardianAddress[guardianIndex].country === "IE"
                        ? "County *"
                        : "County"
                    }
                    value={guardianAddress[guardianIndex].city}
                    onChange={(e: any) => {
                      onValueChangeTwo(e, guardianIndex, "city");
                    }}
                    error={error.city}
                    item={county}
                  />
                  <SelectDropDown
                    label="Country *"
                    item={country}
                    value={guardianAddress[guardianIndex].country}
                    onChange={(e: any) => {
                      onValueChangeTwo(e, guardianIndex, "country");
                    }}
                    error={error.country}
                  />
                  <Input
                    label="Eircode *"
                    value={guardianAddress[guardianIndex].eir_code}
                    onChange={(e) => {
                      onValueChangeTwo(e, guardianIndex, "eir_code");
                    }}
                    error={error.eir_code}
                  />

                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <div className={styles.buttonWidth}>
                      <Button
                        label="Next"
                        light
                        endIcon="navigate_next"
                        onClick={saveGuardianAddress}
                      />
                    </div>
                  </Grid>
                </Grid>
              ) : null}

              {currentVisible === "add" ? (
                <>
                  <Box className={styles.add} onClick={onAddGuardian}>
                    <Grid container direction="row" alignItems="center">
                      <div className={styles.text}>Add Guardian 2 Details</div>
                      <Grid
                        container
                        className={styles.buttonContainer}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Icon
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            right: "12px",
                          }}
                        >
                          add
                        </Icon>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="space-around"
                  >
                    <div className={styles.buttonWidth}>
                      <Button
                        label="Next"
                        light
                        endIcon="navigate_next"
                        onClick={nextPage}
                      />
                    </div>
                  </Grid>
                </>
              ) : null}
            </>
          </div>
        </Grid>
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default Guardians;
