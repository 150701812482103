/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import { Button } from "../../../components/common/button/index";
import Icon from "@material-ui/core/Icon";
import Header from "../header/index";
import { useStyle } from "./application-start.style";
import { useHistory } from "react-router-dom";
import request, { ParentUser } from "../../../utils/authUtil";
import moment from "moment";
import { Loader } from "../../../components/common/loader";
import { CheckNetwork } from "../../../utils/helper";

type Props = {};

const ApplicationStart = (props: Props) => {
  const styles = useStyle();
  const history = useHistory();
  const studentAppId = localStorage.getItem("currentStudentAppID");
  const [loader, setLoader] = useState(false);
  const schoolID = localStorage.getItem("school-id");
  const parentID = ParentUser.uuid;
  const [dateData, setDateData] = useState({
    open_date: " ",
    cut_Off_date: " ",
  });
  const [preRrequisiteData, setPreRequisiteData] = useState([
    {
      pre_requisite: "",
      order: "",
    },
  ]);

  const startButtonHandler = () => {
    history.push(`/parent/application/${studentAppId}`);
  };
  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-application-dates/?school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        setLoader(false);
        if (response.data) {
          //converted timestamp into date
          const open_date = moment(response.data.results.open_date).format(
            "DD/MM/YYYY"
          );
          const cut_Off_date = moment(
            response.data.results.cut_Off_date
          ).format("DD/MM/YYYY");
          setDateData({
            ...dateData,
            open_date: open_date,
            cut_Off_date: cut_Off_date,
          });
        } else {
          setLoader(false);
        }
      })
      .catch();
  }, []);

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-prerequisites-details/?pre_requisite_for=APPLICATION&parent_uuid=${parentID}&school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        setLoader(false);
        if (response.data) {
          setPreRequisiteData(response.data.results);
        } else {
          setLoader(false);
        }
      })
      .catch();
  }, []);

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <Header />
      <CheckNetwork />
      <div className={styles.bodyContainer}>
        <p>
          <Icon
            style={{ cursor: "pointer" }}
            onClick={() => history.push(`/parent/dashboard`)}
          >
            arrow_back
          </Icon>
        </p>
        <Box mt="30px">
          <Welcome
            title="Application"
            description="Before you begin the application process you will need to have the following documentation to hand:"
          />
        </Box>
        <Box marginTop="30px" marginBottom="30px">
          {preRrequisiteData &&
            preRrequisiteData.map((preRrequisite) => (
              <Box
                display="flex"
                alignItems="center"
                marginTop="20px"
                key={preRrequisite.order}
              >
                <Icon className={styles.checkIcon}>check_circle</Icon>
                <b className={styles.textPadding}>
                  {preRrequisite.pre_requisite}
                </b>
              </Box>
            ))}
        </Box>
        <Box>
          <div>
            <p className={styles.description}>
              This application collects information about you and your child for
              the purposes of Verification of Identity and date of birth;
              Verification and assessment of admission criteria; School
              administration, all of which are tasks carried out pursuant to
              various statutory duties to which the board of management is
              subject.
            </p>
            <p className={styles.description}>
              All data is held in line with the schools Privacy Policy, a copy
              of which is available on the schools website.
            </p>
          </div>
        </Box>
        <Box>
          <div className={styles.buttonWidth}>
            <Button
              className={styles.margin}
              label="Begin"
              onClick={startButtonHandler}
            />
          </div>
        </Box>
        <Box mb={5}>
          {dateData && (
            <p className={styles.description}>
              Completed Applications will be accepted from:
              <b> {dateData.open_date} </b> <br></br>
              The closing date for applications is:
              <b>{dateData.cut_Off_date} </b>
            </p>
          )}
        </Box>
        <Box className={styles.footerContainer}>
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default ApplicationStart;
