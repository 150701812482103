import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  root: {
    fontFamily: `"LFT Etica", sans-serif`,
  },
  container: { paddingTop: 40 },
  criteriaContainer: {
    width: "60%",
    marginTop: 30,
  },
  inputContainer: {
    width: "100%",
    marginTop: 20,
  },
  downloadIcon: {
    color: theme.palette.grey[300],
    cursor: "pointer",
    marginBottom: "-9px",
  },
  tab: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#F7F8FC",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  subTab: {
    marginRight: 50,
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    width: 200,
  },
  tabButton: {
    display: "block",
    backgroundColor: "inherit",
    color: theme.palette.common.black,
    padding: "12px 22px",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14,
  },
  consentlabel: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    fontSize: 16,
    marginBottom: "20px",
  },
  disclamer: {
    marginBottom: "10px",
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.grey[300],
  },
  label: {
    marginBottom: 20,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  langItem: {
    display: "flex",
    minWidth: "43.93px",
    height: "43.93px",
    textTransform: "none",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: "15px 15px 15px 12px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    backgroundColor: theme.palette.common.white,
    cursor: "all-scroll !important"
  },
}));
