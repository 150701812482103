import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  iconlabel: {
    color: theme.palette.grey[300],
    marginRight: 2,
    fontSize: 18,
  },
  icon: {
    color: theme.palette.grey[300],
    marginRight: "10px",
    fontSize: "18px",
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.grey[300],
  },
  checkIcon: {
    fontSize: 20,
    color: theme.palette.success.main,
  },
  textOne: {
    fontSize: "14px",
    color: theme.palette.common.black,
    fontWeight: 600,
    textDecoration: "none",
    marginRight: "10px",
  },
  dropContainer: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 12,
    zIndex: 1000,
    position: "absolute",
    top: "60px",
  },
  itemBorder: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.grey[200],
  },
  displayNone: {
    display: "none",
  },
  select: {
    border: "1px solid",
    borderColor: theme.palette.primary.light,
    borderRadius: "10px",
    cursor:"pointer"
  },
  activeStyle: {
    fontWeight: 600,
  },
  disableStyle: {
    fontWeight: 400,
    color: theme.palette.grey[200],
  },

  activeBg: {
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
  },
  root:{
    "&:hover": {
      cursor: "pointer",  
      backgroundColor: "#f9f8f7"
    },
  },
  label: {
    marginBottom: 6.5,
    fontFamily:    `"LFT Etica", sans-serif`,
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));
