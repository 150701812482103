import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./edu-offer.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { AddButton } from "../../application/add-guardian/add-button";
import EduOfferItem from "./eduOffers";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import InputLabel from "@material-ui/core/InputLabel";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface eduOfferData {
  school_offer_uuid: string;
  another_school_details: string;
}

const EducationOffer = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  // const schoolID = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);
  const [schoolOffer, setSchoolOffer] = useState<boolean | string>("");
  const [schoolOfferError, setSchoolOfferError] = useState<boolean>(false);
  const [eduOffer, setEduOffer] = useState<eduOfferData[]>([
    {
      school_offer_uuid: "",
      another_school_details: "",
    },
  ]);

  const handleRadioChange = (name: boolean, index: number) => {
    if (name === true) {
      setSchoolOffer(true);
    } else if (name === false) {
      setSchoolOffer(false);
      setError([]);
    }
    setSchoolOfferError(false);
  };

  const getEduOffer = () => {
    setLoader(true);
    request({
      url: `student-enrolment/offer-from-another-school/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        if (Object.keys(response.data?.results).length !== 0) {
          const temp: any = [];
          setSchoolOffer(response.data.results[0].is_offer_another_school);
          response.data.results.map((item: any) =>
            temp.push({
              school_offer_uuid: item.school_offer_uuid,
              another_school_details: item.another_school_details,
            })
          );
          setEduOffer(temp);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  // const getLanguages = () => {
  //   request({
  //     url: `student-enrolment/student-enrol-languages/?school_uuid=${schoolID}`,
  //     method: "get",
  //   }).then((res) => {
  //     const tmp: any = [];
  //     res.data.results.map((lang: any, index: any) =>
  //       tmp.push({
  //         id: `${index}`,
  //         label: lang.language_name,
  //       })
  //     );
  //     setItems(tmp);
  //   });
  // };

  // const studData = () => {
  //   request({
  //     url: `student-enrolment/student-language-preference/?student_app_uuid=${stdAppId}`,
  //     method: "get",
  //   }).then((res) => {
  //     if (res.data.results.length === 0) {
  //       getLanguages();
  //     } else {
  //       const tmp: any = [];
  //       res.data.results.map(
  //         (lang: any) =>
  //           (tmp[lang.preference_number - 1] = {
  //             id: lang.uuid,
  //             label: lang.language,
  //           })
  //       );
  //       setItems(tmp);
  //     }
  //   });
  // };

  useEffect(() => {
    getEduOffer();
    // studData();
  }, []);

  const onChange = (value: any, ind: any) => {
    const xyz = [...eduOffer];
    xyz[ind].another_school_details = value;
    setEduOffer(xyz);

    const tempErr = [...error];
    if (value.length === 0) {
      if (!tempErr.includes(`follow${ind}`)) {
        tempErr.push(`follow${ind}`);
      }
    } else if (tempErr.includes(`follow${ind}`)) {
      const val = tempErr.indexOf(`follow${ind}`);
      if (val > -1) {
        tempErr.splice(val, 1);
      }
    }
    setError(tempErr);
  };

  const addSchoolHandler = () => {
    setEduOffer([
      ...eduOffer,
      {
        school_offer_uuid: "",
        another_school_details: "",
      },
    ]);
  };

  const onSubmit = () => {
    const temp = [...error];
    try {
      schoolOffer === ""
        ? setSchoolOfferError(true)
        : setSchoolOfferError(false);
      eduOffer.map((item: any, index: any) => {
        if (schoolOffer && item.another_school_details.length === 0) {
          if (!temp.includes(`follow${index}`)) {
            temp.push(`follow${index}`);
          }
        } else if (temp.includes(`follow${index}`)) {
          const val = temp.indexOf(`follow${index}`);
          if (val > -1) {
            temp.splice(val, 1);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setError([]);
        setLoader(true);
        const data = eduOffer.map((item: any) => {
          let offer_uuid = item.school_offer_uuid || "";
          return {
            school_offer_uuid: offer_uuid,
            is_offer_another_school: schoolOffer,
            another_school_details: schoolOffer
              ? item.another_school_details
              : "",
          };
        });
        request({
          url: `student-enrolment/offer-from-another-school/?student_app_uuid=${stdAppId}`,
          method: "put",
          data: data,
        }).then((response) => {
          // postLang();
          nextPage();
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  // const postLang = () => {
  //   const data: any = [];
  //   items.map((lang: any, index: any) =>
  //     data.push({
  //       language: lang.label,
  //       preference_number: index + 1,
  //       uuid: lang.id,
  //     })
  //   );
  //   request({
  //     url: `student-enrolment/student-language-preference/?student_app_uuid=${stdAppId}`,
  //     method: "put",
  //     data: data,
  //   }).then((response) => {
  //     if (response.data.status_code === 1) {
  //       setLoader(false);
  //       nextPage();
  //     } else {
  //       setLoader(false);
  //     }
  //   });
  // };

  const onDelete = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `student-enrolment/offer-from-another-school/?student_app_uuid=${stdAppId}`,
        method: "delete",
        data: {
          school_offer_uuid: id,
        },
      }).then((response) => {
        if (response.data.status_code === 1) {
          getEduOffer();
          setLoader(false);
        } else {
          setLoader(false);
        }
      });
    } else {
      const temp = [...eduOffer];
      temp.splice(index, 1);
      setEduOffer(temp);
      setError([]);
    }
  };

  // const [items, setItems] = useState<any>([]);

  // Reorder the list items
  // const reorder = (list: any, startIndex: any, endIndex: any) => {
  //   const result = Array.from(list);
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result;
  // };

  // const onDragEnd = (result: any) => {
  //   if (!result.destination) {
  //     return;
  //   }

  //   const temp: any = reorder(
  //     items,
  //     result.source.index,
  //     result.destination.index
  //   );
  //   setItems(temp);
  // };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Enrolment</div>
                </div>
                <ProgressBar total={7} steps={6} />
              </Box>
              <div style={{ marginBottom: 30 }}>
                <Welcome
                  title="Educational needs"
                  description="Next: Anti-Bullying Policy"
                />
              </div>
              <p className={styles.title}>Offer from another school</p>
              <InputRadioButton
                label="I/we have applied for and am/are awaiting confirmation of an offer of admission from another school(s)"
                placeHolderOne="No"
                placeHolderTwo="Yes"
                valueTwo={true}
                valueOne={false}
                answer={schoolOffer}
                onChange={handleRadioChange}
                error={schoolOfferError}
              />
              {eduOffer.map((item: any, index: any) => (
                <Box mb="5px">
                  <EduOfferItem
                    error={error.includes(`follow${index}`)}
                    data={item}
                    onDelete={onDelete}
                    index={index}
                    onChange={onChange}
                    schoolOffer={schoolOffer}
                  />
                </Box>
              ))}
              {schoolOffer && (
                <Box width="100%" mb={5}>
                  <AddButton
                    label="Add another school"
                    onClick={addSchoolHandler}
                  />
                </Box>
              )}
              {/* <p className={styles.title}>Language options</p>
              <InputLabel className={styles.label}>
                Drag the blocks below, in order of preference, the language your
                student/child wishes to study
              </InputLabel> */}
              {/* <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {items.map((item: any, index: any) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className={styles.langItem}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <p>{item.label}</p>
                              <Icon className={styles.icon}>
                                more_vert_icon
                              </Icon>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext> */}
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSubmit}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default EducationOffer;
