import React, { useState } from "react";
import { modalStyle } from "./applicationTrialModal.styled";
import { Button } from "../../../../components/common/button";
import request from "../../../../utils/authUtil";
import Modal from "@material-ui/core/Modal";
import { Box, Icon } from "@material-ui/core";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import { Upload } from "../../../../components/common/upload";
import axios from "axios";
import { Loader } from "../../../../components/common/loader";

interface Props {
  isModalOpen: boolean;
  modalHandler: any;
  applicationID?: any;
  parentID?: any;
}

const DROP_MENU = [
  {
    name: "BIRTHCERTIFICATE",
    value: "BIRTHCERTIFICATE",
  },
  {
    name: "UTILITY_BILL_SECOND",
    value: "UTILITY_BILL_SECOND",
  },
  {
    name: "UTILITY_BILL",
    value: "UTILITY_BILL",
  },
  {
    name: "PREVIOUS_SCHOOL_REPORT",
    value: "PREVIOUS_SCHOOL_REPORT",
  },
  {
    name: "PASSPORT_PHOTO",
    value: "PASSPORT_PHOTO",
  },
  {
    name: "OTHERS",
    value: "OTHERS",
  },
];

const DEFAULT_DOC_STATE = {
  doc_name: "",
  document_uuid: "",
  isFile: false,
  isUploaded: false,
  document_presingne_url: "",
};

const DocumentsModal: React.FC<Props> = ({
  isModalOpen,
  modalHandler,
  applicationID,
  parentID,
}: Props) => {
  const classes = modalStyle();

  const schoolID = localStorage.getItem("school-id");
  const [dropValue, setDropValue] = useState(DROP_MENU[0].value);
  const [loader, setLoader] = useState<boolean>(false);
  const [document, setDocument] = useState(DEFAULT_DOC_STATE);
  const [error, setError] = useState<any>({
    document_empty_err: false,
    document_accept_err: false,
  });
  const [awsUrl, setAwsUrl] = useState("");

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    file: any
  ) => {
    if (fileName) {
      const res = await request({
        url: "/auth/parent-documents-upload-credentials",
        method: "post",
        data: {
          user_uuid: userId,
          school_uuid: schoolId,
          document_type: documentType,
          file_name: fileName,
        },
      });
      return res;
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const setFileName = (name: string) => {
    setDocument({
      ...document,
      doc_name: name,
      isFile: true,
    });
  };

  const onSubmit = async () => {
    await request({
      url: `school-settings/parent-student-documents-details/?school_uuid=${schoolID}`,
      method: "put",
      data: {
        student_app_uuid: applicationID,
        document_type: dropValue,
        document_url: awsUrl,
        document_for: "ENROLMENT",
      },
    }).then((response) => {
      if (response.data.status_code === 1) {
        setDropValue("");
        setDocument(DEFAULT_DOC_STATE);
        modalHandler(false, true);
      }
    });
  };

  const change = (event: any, documentType: string) => {
    setLoader(true);
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        documentType,
        event.target?.files[0]?.name,
        event.target.files
      ).then((response) => {
        if (response === null) {
          setLoader(false);
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setFileName(event.target.files[0].name);
          setAwsUrl(`${response.url}${response.fields.key}`);
          setError({
            ...error,
            document_empty_err: false,
            document_accept_err: false,
          });
          setLoader(false);
        }
      );
    });
  };

  const deleteDocument = (id: string, type: string) => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${applicationID}&document_uuid=${id}`,
      method: "delete",
    }).then(() => {
      setDocument(DEFAULT_DOC_STATE);
      setLoader(false);
    });
  };

  return (
    <Modal open={isModalOpen} onClose={() => modalHandler(false, false)}>
      <div className={classes.modalContainer}>
        <Loader open={loader} />
        <Box mt={5}>
          <Box
            className={classes.cancelIcon}
            onClick={() => modalHandler(false, false)}
          >
            <Icon>cancel_icon</Icon>
          </Box>
          <Box>
            <SelectDropDown
              label="Document Type"
              item={DROP_MENU}
              value={dropValue}
              onChange={(e: any) => setDropValue(e.target.value)}
            />
          </Box>
          <Box>
            <Upload
              id="new_doc"
              placeholder="Upload Document"
              label="Please upload document associated with selected document type."
              onChange={(e: any) => change(e, dropValue)}
              file={document.doc_name}
              isFile={document.isFile}
              error={error.document_empty_err || error.document_accept_err}
              errorMessage={"Required"}
              onDelete={() => deleteDocument(document.document_uuid, dropValue)}
              showDeleteIcon={true}
            />
          </Box>
          <div className={classes.continueBtn}>
            <Button
              label="Continue"
              disabled={awsUrl === "" || dropValue === ""}
              padding="0.4rem 2rem"
              onClick={onSubmit}
            ></Button>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default DocumentsModal;
