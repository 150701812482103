import request from "../../../utils/authUtil";
export const register = (data: any) => {
  const regData = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    phone: data.phone,
    password: data.password,
    email_verification_key: data.email_verification_key,
    email_verified: data.email_verified,
    email_verified_date: data.email_verified_date,
    phone_verification_key: data.phone_verification_key,
    phone_verified: data.phone_verified,
    phone_verified_date: data.phone_verified_date,
  }
  return request({
      url: "/auth/school-parent-register/",
      method: "post",
      data: regData,
    });
  }

export const sendEmailOtp = ({email, resend}: any) => request({
      url: "/auth/parent-registration-email-otp/",
      method: "post",
      data: {
        email : email,
        resend : resend,
    },
    });

export const sendPhoneOtp = ({phone, resend}: any) => request({
      url: "/auth/parent-registration-phone-otp/",
      method: "post",
      data: {
        phone : phone,
        resend : resend,
    },
    });
  
export const verifyEmailOtp = ({email, otp}: any) => request({
      url: "/auth/parent-email-otp-verification/",
      method: "post",
      data: {
        email : email,
        otp : otp
    },
    });
  
export const verifyPhoneOtp = ({phone, otp}: any) => request({
      url: "/auth/parent-phone-otp-verification/",
      method: "post",
      data: {
        phone : phone,
        otp : otp
    },
    });
  