import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    minWidth: "43px",
    maxWidth: "43px",
    height: "43px",
    textTransform: "none",
    borderRadius: "50%",
    justifyContent: "center",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  letter: {
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: "bold"
  }
}));
