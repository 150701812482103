import {useState} from 'react'
import { Box } from '@material-ui/core'
import {useStyle} from "../../../applications/single-application/student-details.style";
import cn from "classnames";
import ASDDetails from './asd-details/asd-details';
import ASDDocuments from './asd-documents/asd-documents';
import theme from "../../../../../theme";


type Props = {
    applicationID?: any;
}

const ASDInfo = (props: Props) => {
    const [show, setShow] = useState("details");
    const [active, setActive] = useState("details");
    const [formDisable, setFormDisable] = useState(true);
    const [errorEdit, setErrorEdit] = useState("");
    const classes = useStyle();

    
  const handleTabChange = (value: string) => {
    if (formDisable) {
      setShow(value);
      setActive(value);
      setFormDisable(true);
    } else {
      setErrorEdit(
        "Please save data before changing tab or click on cancel button"
      );
    }
  };

  return (
        <>
        {!formDisable && (
                <Box mt="20px" mb="20px">
                <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
                </Box>
            )}
            <Box display="flex" justifyContent="space-between">
                <Box width="600px" mb="20px">
                    {
                        show === "details" ? <ASDDetails
                        formDisable={formDisable}
                        setFormDisable={setFormDisable}
                        setErrorEdit={setErrorEdit}
                        applicationID={props.applicationID}/> : null
                    }
                    {
                        show === "documents" ? <ASDDocuments
                        
                        formDisable={formDisable}
                        setFormDisable={setFormDisable}
                        errorEdit={errorEdit}
                        setErrorEdit={setErrorEdit}
                        applicationID={props.applicationID}/> : null
                    }
                </Box>
                <div>
                <Box className={classes.subTab} height="100px">
                    <button
                    className={cn(
                        classes.tabButton,
                        active === "details" ? classes.tabActive : null
                    )}
                    onClick={() => {
                        handleTabChange("details");
                    }}
                    >
                    ASD details
                    </button>
                    <button
                    className={cn(
                        classes.tabButton,
                        active === "documents" ? classes.tabActive : null
                    )}
                    onClick={() => {
                        handleTabChange("documents");
                    }}
                    >
                    ASD Documents
                    </button>
                </Box>
                </div>
            </Box>
        </>
  )
}

export default ASDInfo