import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { useStyle } from "./perm.style";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../../components/common/button/index";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface permInfoPostData {
  picture_permission_consent: string | boolean;
  assessment_testing_consent: string | boolean;
}

const Permissions: React.FC<Props> = ({ nextPage, prevPage, stdAppId }) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [localData, setLocalData] = useState({
    picture_permission_consent: "",
    assessment_testing_consent: "",
  });
  const [localPostData, setLocalPostData] = useState<permInfoPostData>({
    picture_permission_consent: "",
    assessment_testing_consent: "",
  });
  const [error, setError] = useState({
    picture_permission_err: false,
    assessment_testing_err: false,
  });

  const handleRadioChangeOne = (name:boolean) => {
    setError({ ...error, picture_permission_err: false });
    if (name === false) {
      setLocalPostData({ ...localPostData, picture_permission_consent: false });
    }
    if (name === true) {
      setLocalPostData({ ...localPostData, picture_permission_consent: true });
    }
  };

  const handleRadioChangeTwo = (name: boolean) => {
    setError({ ...error, assessment_testing_err: false });
    if (name === false) {
      setLocalPostData({ ...localPostData, assessment_testing_consent: false });
    }
    if (name === true) {
      setLocalPostData({ ...localPostData, assessment_testing_consent: true });
    }
  };

  const validate = () => {
    return {
      picture_permission_err: localPostData.picture_permission_consent === "",
      assessment_testing_err: localPostData.assessment_testing_consent === "",
    };
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-school-application-consent/?school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLocalData({
          ...localData,
          picture_permission_consent:
            response.data.results.picture_permission_consent,
          assessment_testing_consent:
            response.data.results.assessment_testing_consent,
        });
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    const err = validate();
    setError(err);
    if (
      err.picture_permission_err === false &&
      err.assessment_testing_err === false
    ) {
      setLoader(true);
      const data = {
        terms_consent: true,
        code_of_behavior_consent: true,
        anti_bullying_parent_consent: false,
        anti_bullying_student_consent: false,
        picture_permission_consent: localPostData.picture_permission_consent,
        contact_previous_school_consent: true,
        assessment_testing_consent: localPostData.assessment_testing_consent,
        acceptable_use_policy: true,
        document_upload_page_consent: true,
      };
      request({
        url: `student-application/student-application-consent/?student_app_uuid=${stdAppId}`,
        method: "put",
        data: data,
      }).then((response) => {
        if (response.data.status_code === 1) {
          setLoader(false);
          nextPage();
        } else {
          setLoader(false);
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>Enrolment</div>
            </div>
            <ProgressBar total={7} steps={3} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome title="Permissions" description="Next: Medical Info" />
          </div>
          <Box>
            <p className={styles.title}>Picture permission</p>
            <Box mt="20px" mb="25px">
              <p className={styles.description}>
                {localData.picture_permission_consent}
              </p>
            </Box>
            <InputRadioButton
              placeHolderTwo="I consent"
              placeHolderOne="I do not consent"
              valueTwo={true}
              valueOne={false}
              onChange={handleRadioChangeOne}   
              index={0}
              answer={localPostData.picture_permission_consent}
              error={error.picture_permission_err}
            />
          </Box>
          <Box>
            <p className={styles.title}>Assesment testing</p>
            <Box mt="20px" mb="25px">
              <p className={styles.description}>
                {localData.assessment_testing_consent}
              </p>
            </Box>    
            <InputRadioButton
              placeHolderTwo="I consent"
              placeHolderOne="I do not consent"
              valueTwo={true}
              valueOne={false}
              onChange={handleRadioChangeTwo}   
              index={0}
              answer={localPostData.assessment_testing_consent}
              error={error.assessment_testing_err}
            />         
          </Box>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onSubmit}
              />
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default Permissions;
