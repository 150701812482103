import { Quill } from "react-quill";

function main (value) {
    if (value) {
      const cursorPosition = this.quill.getSelection().index;
      this.quill.insertText(cursorPosition, value);
      this.quill.setSelection(cursorPosition + value.length);
  }
  }

const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large"];
Quill.register(Size, true);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      header: main
    }
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
const QuillToolbar = ({ dropdownValues }) => (
  <div id="toolbar">
    <span className="ql-formats">
      <select className="ql-size" defaultValue="medium">
        <option value="small">Small</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select>
      <select className="ql-header">
        <option value="">Placeholders</option> 
        {dropdownValues.map((item) => (
           <option value={item.value} key={item.value}>{item.name}</option> 
        ))}
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
    </span>
    <span className="ql-formats">
      <button className="ql-clean" />
    </span>
  </div>
);

export default QuillToolbar;
