import { Box, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher";
import { DropDown } from "./header-dropdown/index";
import GlobalYearDropDown from "./year-dropdown";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "10px",
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    justifyContent: "space-between",
    paddingRight: theme.spacing(10),
    alignItems: "center",
    '@media (max-width: 1023px)': {
      marginLeft: "40px",
    }
  },
}));

function handleBreadCrumbClick(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  event.preventDefault();
}

type Props = {
  globalDateItem: any;
  selectedGlobalDate: any;
  handleChangeGlobaldate: any;
};

const Header: React.FC<Props> = ({
  globalDateItem,
  selectedGlobalDate,
  handleChangeGlobaldate,
}) => {
  const classes = useStyle();
  const { i18n } = useTranslation();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="/" onClick={handleBreadCrumbClick}>
          Admin
        </Link>
        <Link
          color="inherit"
          href="/getting-started/installation/"
          onClick={handleBreadCrumbClick}
        >
          Settings
        </Link>
      </Breadcrumbs>
      <GlobalYearDropDown
        globalDateItem={globalDateItem}
        selectedGlobalDate={selectedGlobalDate}
        handleChangeGlobaldate={handleChangeGlobaldate}
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <LanguageSwitcher onChange={(lang) => i18n.changeLanguage(lang)} />
        <DropDown />
      </Box>
    </div>
  );
};
export default Header;
