import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily:   `"LFT Etica", sans-serif`,
    borderRadius:4,
    padding:"6px 12px 6px 8px",
    width:"100%"
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  light: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.5,
    },
  },
}));
