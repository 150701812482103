import { useState, useEffect } from "react";
import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import Header from "../header/index";
import { useStyle } from "./review.style";
import Icon from "@material-ui/core/Icon";
import { Button } from "../../../components/common/button/index";
import { SecondaryButton } from "../../../components/common/secondary-button/index";
import cn from "classnames";
import request from "../../../utils/authUtil";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/common/loader";
import { CheckNetwork } from "../../../utils/helper";
import moment from "moment";

const ReviewApplication = () => {
  const styles = useStyle();
  const name = localStorage.getItem("name");
  const [loader, setLoader] = useState<boolean>(false);
  const stdAppId = localStorage.getItem("currentStudentAppID");
  const enrolmentStatus = localStorage
    .getItem("studentEnrollmentStatus");
  const [studDetails, setStudentDetails] = useState<any>();
  const [guardDetails, setGuardDetails] = useState([]);
  const [schoolDetails, setSchoolDetails] = useState([]);
  const [criteriaDetails, setCriteriaDetails] = useState([]);
  const [studentProfile, setStudentProfile] = useState<any>();

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/application-review-details/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      if (res.data) {
        setLoader(false);
        setStudentDetails(res.data.results.student_details);
        setGuardDetails(res.data.results.guardian_details);
        setSchoolDetails(res.data.results.education_details);
        setCriteriaDetails(res.data.results.criteria_details);
        setStudentProfile(res.data.results.student_profile);
      }
    });
  }, []);

  const downloadPdf = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-review-pdf/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((response) => {
      if (response.data.results) {
        window.open(response.data.results, "_parent");
        setLoader(false);
      }
    });
  };

  return (
    <div className={styles.container}>
      <CheckNetwork />
      <Loader open={loader} />
      <Header />
      <div className={styles.bodyContainer}>
        <Box mt="30px" mb="30px" className={styles.card}>
          <div>
            <p>
              <Link
                to="/parent/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  cursor: "pointer",
                }}
              >
                <Icon>arrow_back</Icon>
              </Link>
            </p>
          </div>
          <Box mb="30px">
            <Welcome
              title="Review Application"
              description={`Below is the Application you made to ${name}`}
            />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "25px",
            }}
          >
            <div style={{ width: "200px" }}>
              <Button
                startIcon="download_icon"
                light
                onClick={downloadPdf}
                label="Download Pdf"
              />
            </div>
            {studDetails && studDetails.legacy_application_date && (
              <div>
                <b>Application Date</b> :{" "}
                {`${moment(studDetails.legacy_application_date).format(
                  "DD-MM-YYYY"
                )}`}
              </div>
            )}
          </div>

          <p className={styles.textOne}>Student/Child Details</p>
          {studDetails && (
            <Box mt="6px">
              <p className={styles.textTwo}>
                {studDetails.first_name} {studDetails.last_name}
              </p>
              <p className={styles.textTwo}>{studDetails.address_line_one}</p>
              {studDetails.address_line_two ? (
                <p className={styles.textTwo}>{studDetails.address_line_two}</p>
              ) : null}
              <p className={styles.textTwo}>{studDetails.town}</p>
              <p className={styles.textTwo}>{studDetails.city}</p>
              <p className={styles.textTwo}>{studDetails.eir_code}</p>
              <p className={styles.textTwo}>{studDetails.country}</p>
              <div className={styles.gridContainer}>
                <p className={styles.textTwo}> Birth Certificate Forename</p>
                <p className={styles.textTwo}>
                  {studDetails?.legal_first_name || ""}
                </p>
                <p className={styles.textTwo}> Birth Certificate Surname</p>
                <p className={styles.textTwo}>
                  {studDetails?.legal_last_name || ""}
                </p>
                <span className={styles.textTwo}> D.O.B. </span>
                <span className={styles.textTwo}>
                  {studDetails.date_of_birth}
                </span>
                <span className={styles.textTwo}>PPS No.</span>
                <span className={styles.textTwo}>{studDetails.PPSNumber}</span>
                <span className={styles.textTwo}>
                  {studDetails?.gender && "Gender: "}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.gender || ""}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.religion && "Religion: "}
                </span>
                <span className={styles.textTwo}>
                  {studDetails?.religion || ""}
                </span>
              </div>

            </Box>
          )}
          {/* Guardian Details */}
          <Box mt="30px">
            {guardDetails &&
              guardDetails.map((guard: any, index) => {
                return (
                  <div key={index}>
                    <p className={styles.textOne}>Guardian Details</p>
                    <Box mt="6px">
                      <p className={styles.textTwo}>
                        {guard.first_name} {guard.last_name}
                      </p>
                      <p className={styles.textTwo}>{guard.address_line_one}</p>
                      {guard.address_line_two ? (
                        <p className={styles.textTwo}>
                          {guard.address_line_two}
                        </p>
                      ) : null}
                      <p className={styles.textTwo}>{guard.town}</p>
                      <p className={styles.textTwo}>{guard.city}</p>
                      <p className={styles.textTwo}>{guard.eir_code}</p>
                      <p className={styles.textTwo}>{guard.country}</p>
                      <Box
                        display="flex"
                        alignItems="center"
                        marginBottom="30px"
                        marginTop="15px"
                      >
                        <Box display="block" width="80px">
                          <p className={styles.textTwo}>Email</p>
                          <p className={styles.textTwo}>Phone:</p>
                          <p className={styles.textTwo}>Relationship: </p>
                        </Box>
                        <Box display="block" marginLeft="60px">
                          <p className={styles.textTwo}>{`${guard.email}`}</p>

                          <p className={styles.textTwo}>{`${guard.phone}`}</p>
                          <p
                            className={styles.textTwo}
                          >{`${guard.relation}`}</p>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                );
              })}
          </Box>
          {/* School / Educational Details */}
          <Box mb="30px">
            <p className={styles.textOne}>Education Details</p>
            {schoolDetails &&
              schoolDetails.map((school: any, index: any) => {
                return (
                  <Box mt="6px" key={index}>
                    <p className={styles.textTwo}>
                      {school.primary_school_attended}
                    </p>
                    <p
                      className={styles.textTwo}
                    >{`${school.from_date} - ${school.to_date}`}</p>
                  </Box>
                );
              })}
          </Box>
          {/* Criteria Info */}
          <Box mb="30px">
            <p className={styles.textOne}>Criteria Details</p>
            <Box mt="6px">
              {criteriaDetails &&
                criteriaDetails.map((criteria: any, index: any) => {
                  return (
                    <Box key={index} mb="10px">
                      <p className={styles.textTwo}>
                        {criteria.criteria}
                        {criteria.criteria_answer === true ? "   Yes" : "   No"}
                        <br />
                        {criteria.criteria_followup_question !== "" &&
                        criteria.criteria_answer === true ? (
                          <>
                            <ul>
                              <li>{criteria.criteria_followup_question}</li>
                              <li>{criteria.criteria_followup_answer}</li>
                            </ul>
                          </>
                        ) : null}
                      </p>
                    </Box>
                  );
                })}
            </Box>
          </Box>
          {/* Student Profile */}
          <Box>
            {studentProfile && (
              <p className={styles.textOne}>Student/Child Profile</p>
            )}
            <Box mt="6px">
              <p className={styles.textTwo}>
                {studentProfile && studentProfile.profile}
              </p>
            </Box>
          </Box>
          {/* part4 */}
          {enrolmentStatus !== "SUBMITTED" ? (
            <Box className={styles.buttonContainer} mt="70px" mb="30px">
              <Box className={cn(styles.buttonStyle, styles.alignment)}>
                <Link to="/parent/cancel" style={{ textDecoration: "none" }}>
                  <Button label="Cancel application" light />
                </Link>
              </Box>
              <Box className={styles.buttonStyle}>
                <SecondaryButton
                  label="Delete application"
                  startIcon="delete"
                  light
                />
              </Box>
            </Box>
          ) : null}
        </Box>
        {/* part5 */}
        <Box display="flex" justifyContent="center">
          <Link to="/parent/dashboard" className={styles.backButton}>
            <Button label="Back" startIcon="navigate_before" />
          </Link>
        </Box>
        <Box className={styles.footerContainer}>
          <Footer center />
        </Box>
      </div>
    </div>
  );
};

export default ReviewApplication;
