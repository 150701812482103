import { useStyle } from "./special-dropdown.style";
import { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";
import { AdminButton } from "../../../../components/common/admin-button/index";
type Props = {
  Id?: string;
  readyToSwitch?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
  allowRollOver: boolean;
};
const SpecialDropDown: React.FC<Props> = ({
  Id,
  readyToSwitch,
  selectedGlobalDate,
  currentGlobalDate,
  allowRollOver,
}) => {
  const defaultSpecCriteria = {
    criteria_uuid: "",
    criteriaQuestion: "SELECT",
  };
  const default_criteria = [
    {
      criteria_uuid: "",
      criteriaQuestion: "",
    },
  ];
  const [specCriteria, setSpecCriteria] = useState(defaultSpecCriteria);
  const [loader, setLoader] = useState<boolean>(false);
  const classes = useStyle();
  const ref: any = useRef();
  const [criteria, setCriteria] = useState(default_criteria);

  const [drop, setDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setDrop(false);
    });
  }, [selectedGlobalDate]);

  useEffect(() => {
    setLoader(true);
    request({
      url: `school-settings/application-criteria-details/?application_uuid=${Id}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            const temp: any = [];
            response.data.results.map((criteria: any) => {
              temp.push({
                criteria_uuid: criteria.criteria_uuid,
                criteriaQuestion: criteria?.question,
              });
            });
            setCriteria(temp);
            let is_special = response.data.results.find(
              (element: any) => element.is_special === true
            );
            if (is_special) {
              setSpecCriteria({
                ...specCriteria,
                criteria_uuid: is_special.criteria_uuid,
                criteriaQuestion: is_special?.question,
              });
            } else {
              setSpecCriteria(defaultSpecCriteria);
            }
          } else {
            setCriteria(default_criteria);
          }
        } else {
          setLoader(false);
        }
      })
      .catch();
  }, [Id, selectedGlobalDate]);

  const isDropdown = () => {
    setDrop(!drop);
  };

  const selectCriteria = (index: number, type: string) => {
    readyToSwitch(false);
    if (type === "EMPTY") {
      setSpecCriteria({
        ...specCriteria,
        criteriaQuestion: "SELECT",
        criteria_uuid: "",
      });
    } else {
      setSpecCriteria({
        ...specCriteria,
        criteriaQuestion: criteria[index].criteriaQuestion,
        criteria_uuid: criteria[index].criteria_uuid,
      });
    }
  };

  const onSaveClick = () => {
    setLoader(true);
    const data = {
      criteria_uuid: specCriteria.criteria_uuid,
      allow_roll_over: allowRollOver
    };
    request({
      url: `school-settings/application-criteria-is-special/?application_uuid=${Id}`,
      method: "put",
      data: data,
    }).then(() => {
      readyToSwitch(true);
      setLoader(false);
    });
  };

  return (
    <Box display="flex">
      <Loader open={loader} />
      <div ref={ref}>
        <p className={classes.label}>Select Special Criteria</p>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fontWeight={600}
          className={classes.container}
          onClick={isDropdown}
          width="300px"
        >
          <div style={{ fontWeight: 400 }}>{specCriteria.criteriaQuestion}</div>
          <Icon className={classes.arrowIcon}>
            {drop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
          </Icon>
        </Box>
        <Box className={drop ? classes.dropContainer : classes.displayNone}>
          <Box
            onClick={() => {
              selectCriteria(0, "EMPTY");
              isDropdown();
            }}
            display="flex"
            alignItems="center"
            className={classes.itemBorder}
            fontWeight={600}
            marginTop="3px"
          >
            <p className={classes.textOne}>NONE</p>
          </Box>
          {criteria.map((items, index) => (
            <div key={index}>
              <Box
                onClick={() => {
                  selectCriteria(index, "");
                  isDropdown();
                }}
                display="flex"
                alignItems="center"
                className={
                  index !== criteria.length - 1 ? classes.itemBorder : ""
                }
                fontWeight={600}
              >
                <p className={classes.textOne}>{items.criteriaQuestion}</p>
              </Box>
            </div>
          ))}
        </Box>
        {selectedGlobalDate === currentGlobalDate && (
          <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
            <AdminButton label="Save" onClick={onSaveClick} />
          </div>
        )}
      </div>
    </Box>
  );
};

export default SpecialDropDown;
