import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Input } from "../../../../components/common/input/index";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import { Loader } from "../../../../components/common/loader/index";
import { DownLoad } from "../../../../components/common/download";
import { useStyle } from "../../applications/single-application/student-details.style";
import cn from "classnames";
import EducationItem from "../../../parent/application/education-status/edustatus";
import { AdminButton } from "../../../../components/common/admin-button/index";
import { ConfirmBox } from "../../../../components/common/confirm-box/index";
import theme from "../../../../theme";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import { updatePdf } from "./single-enrolment.api";

type Props = {
  applicationID?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface eduOfferData {
  school_offer_uuid: string;
  is_offer_another_school: boolean | string;
  another_school_details: string;
}

interface eduNeedsData {
  education_uuid: string;
  question: string;
  answer: boolean | string;
  answer_uuid: string;
  further_detail_required: boolean;
  follow_up_supporting_doc: boolean;
  follow_up_question: string;
  followup_answer: string;
  followup_answer_uuid: string;
  followup_education_uuid: string;
  supported_doc_url: string;
  isFile: boolean;
  isUploaded: boolean;
  fileName: string;
  document_presingne_url: string;
}

interface previousSchoolData {
  pastEducationId: string;
  primarySchool: string;
  school_roll_number: string;
  fromDate: any;
  toDate: any;
}

const EduHistView: React.FC<Props> = ({
  applicationID,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [show, setShow] = useState("PreviousSchool");
  const [active, setActive] = useState("PreviousSchool");
  const [empty, setEmpty] = useState<boolean>(false);
  const [emptyInfo, setEmptyInfo] = useState<boolean>(false);
  const [emptyOffers, setEmptyOffers] = useState<boolean>(false);
  const [formDisable, setFormDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [errorEdit, setErrorEdit] = useState("");
  const [subjectChoice, setSubjectChoice] = useState<any>([]);
  const [subChoice, setSubChoice] = useState<any>([]);
  const [langChoice, setLangChoice] = useState<any>([]);
  const [languageChoice, setLanguageChoice] = useState<any>([]);
  // const [prevData, setPrevData] = useState<any>([]);
  const [previousSchool, setPreviousSchool] = useState<previousSchoolData[]>([
    {
      pastEducationId: "",
      primarySchool: "",
      school_roll_number: "",
      fromDate: null,
      toDate: null,
    },
  ]);
  const classes = useStyle();
  const [eduNeeds, setEduNeeds] = useState<eduNeedsData[]>([
    {
      education_uuid: "",
      question: "",
      answer: "",
      answer_uuid: "",
      follow_up_supporting_doc: false,
      further_detail_required: false,
      follow_up_question: "",
      followup_answer: "",
      followup_answer_uuid: "",
      followup_education_uuid: "",
      supported_doc_url: "",
      isFile: false,
      isUploaded: false,
      fileName: "",
      document_presingne_url: "",
    },
  ]);
  const [eduOffer, setEduOffer] = useState<eduOfferData[]>([
    {
      school_offer_uuid: "",
      is_offer_another_school: "",
      another_school_details: "",
    },
  ]);

  // get functions //

  const getEduOffer = async () => {
    setLoader(true);
    let response = await request({
      url: `student-enrolment/offer-from-another-school/?student_app_uuid=${applicationID}`,
      method: "get",
    });
    if (response.data.status_code === 1) {
      if (Object.keys(response.data?.results).length !== 0) {
        const temp: any = [];
        response.data.results.map((item: any) => {
          temp.push({
            school_offer_uuid: item.school_offer_uuid,
            is_offer_another_school: item.is_offer_another_school,
            another_school_details: item.another_school_details,
          });
        });
        setEduOffer(temp);
      } else if (Object.keys(response.data?.results).length === 0) {
        setEmptyOffers(true);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getEduNeeds = async () => {
    setLoader(true);
    let response = await request({
      url: `student-enrolment/student-enrol-education-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "get",
    });
    if (response.data.status_code === 1) {
      if (response.data?.results[0].answer === "") {
        setEmptyInfo(true);
      }
      if (Object.keys(response.data?.results).length !== 0) {
        const temp: any = [];
        response.data.results.map((ques: any, index: number) => {
          temp.push({
            education_uuid: ques.education_uuid,
            question: ques.question,
            further_detail_required: ques.further_detail_required,
            followup_education_uuid: ques.followup_education_uuid_uuid,
            follow_up_question: ques.follow_up_question,
            follow_up_supporting_doc: ques.follow_up_supporting_doc,
            answer_uuid: ques.answer_uuid,
            answer: ques.answer,
            followup_answer_uuid:
              ques.answer === "" ? "" : ques.followup_answer_uuid,
            followup_answer: ques.answer === "" ? "" : ques.followup_answer,
            supported_doc_url: ques.supported_doc_url,
            isFile: ques.supported_doc_url !== "",
            isUploaded: ques.supported_doc_url !== "",
            fileName: ques.document_name,
            document_presingne_url: ques.document_presingne_url,
          });
        });
        setEduNeeds(temp);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getPreviousSchool = async () => {
    setLoader(true);
    let response = await request({
      url: `school-settings/school-student-app-previous-education/?school_uuid=${schoolID}&student_app_uuid=${applicationID}
      `,
      method: "get",
    });
    if (Object.keys(response.data?.results).length !== 0) {
      const temp: any = [];
      response.data.results.map((item: any, index: number) => {
        temp.push({
          pastEducationId: item.past_education_uuid,
          primarySchool: item.primary_school_attended,
          school_roll_number: item.school_roll_number,
          fromDate: new Date(item.from_date),
          toDate: new Date(item.to_date),
        });
      });
      setPreviousSchool(temp);
      setLoader(false);
    } else if (Object.keys(response.data?.results).length === 0) {
      setEmpty(true);
    }
  };

  const getSubjectChoices = async () => {
    let res = await request({
      url: `student-enrolment/student-subject-preference/?student_app_uuid=${applicationID}`,
      method: "get",
    });
    const tmp: any = [];
    res.data.results.map(
      (lang: any) =>
        (tmp[lang.preference_number - 1] = {
          id: lang.uuid,
          label: lang.subject,
        })
    );
    setSubjectChoice(tmp);
    setSubChoice(tmp);
  };

  const getLanguageChoices = async () => {
    let res = await request({
      url: `student-enrolment/student-language-preference/?student_app_uuid=${applicationID}`,
      method: "get",
    });
    const tmp: any = [];
    res.data.results.map(
      (lang: any) =>
        (tmp[lang.preference_number - 1] = {
          id: lang.uuid,
          label: lang.language,
        })
    );
    setLanguageChoice(tmp);
    setLangChoice(tmp);
  };

  useEffect(() => {
    getEduNeeds();
    getEduOffer();
    getPreviousSchool();
    getSubjectChoices();
    getLanguageChoices();
  }, []);

  // helper and save  functions prev schools
  const addSchoolHandler = () => {
    setPreviousSchool([
      ...previousSchool,
      {
        pastEducationId: "",
        primarySchool: "",
        school_roll_number: "",
        fromDate: null,
        toDate: null,
      },
    ]);
  };

  const onChangePrevSchool = (value: any, ind: any, type: string) => {
    const xyz = [...previousSchool];
    if (type === "schoolname") {
      xyz[ind].primarySchool = value;
    }
    if (type === "school_roll_number") {
      xyz[ind].school_roll_number = value;
    }
    if (type === "from") {
      xyz[ind].fromDate = value;
    }
    if (type === "to") {
      xyz[ind].toDate = value;
    }
    setPreviousSchool(xyz);
  };

  const postSub = async () => {
    const data: any = [];
    subChoice.map((lang: any, index: any) =>
      data.push({
        subject: lang.label,
        preference_number: index + 1,
        uuid: lang.id,
      })
    );
    let response = await request({
      url: `student-enrolment/student-subject-preference/?student_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    });

    if (response.data.status_code === 1) {
      setLoader(false);
      getSubjectChoices();
      setFormDisable(true);
    } else {
      setLoader(false);
      getSubjectChoices();
      setFormDisable(true);
    }
  };

  const postLang = async () => {
    const data: any = [];
    langChoice.map((lang: any, index: any) =>
      data.push({
        language: lang.label,
        preference_number: index + 1,
        uuid: lang.id,
      })
    );
    let response = await request({
      url: `student-enrolment/student-language-preference/?student_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    });
    if (response.data.status_code === 1) {
      setLoader(false);
      getLanguageChoices();
      setFormDisable(true);
    } else {
      setLoader(false);
      getLanguageChoices();
      setFormDisable(true);
    }
  };

  const savePrevSchool = async () => {
    const data = previousSchool.map((item: any) => {
      return {
        past_education_uuid: item.pastEducationId || "",
        primary_school_attended: item.primarySchool,
        school_roll_number: item.school_roll_number,
        from_date: moment(item.fromDate).format("YYYY-MM-DD"),
        to_date: moment(item.toDate).format("YYYY-MM-DD"),
      };
    });
    await request({
      url: `student-application/student-app-past-education/?student_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    });
    getPreviousSchool();
    setFormDisable(true);
  };

  const DateValueCheck = (index: any) => {
    const temp: any = [...previousSchool];
    if (temp[index].toDate < temp[index].fromDate) {
      temp[index].toDate = null;
    }
    setPreviousSchool(temp);
  };

  const deleteHandler = async (id: string, index: number) => {
    if (id?.length !== 0) {
      setLoader(true);
      await request({
        url: `student-application/student-app-past-education/?student_app_uuid=${applicationID}`,
        method: "delete",
        data: {
          past_education_uuid: id,
        },
      });
      const temp = [...previousSchool];
      temp.splice(index, 1);
      setPreviousSchool(temp);
      setLoader(false);
    } else {
      const temp = [...previousSchool];
      temp.splice(index, 1);
      setPreviousSchool(temp);
    }
  };

  //  end prev schools

  // edu needs save and helper functions //
  const onChangeEduQuestions = (item: any, value: any, ind: any) => {
    const xyz = [...eduNeeds];
    xyz[ind].followup_answer = value;
    setEduNeeds(xyz);
  };

  const handleRadioChange = (name: string, index: number) => {
    const temp = [...eduNeeds];
    if (name === `no`) {
      temp[index].answer = false;
      setEduNeeds(temp);
    }
    if (name === `yes`) {
      temp[index].answer = true;
      setEduNeeds(temp);
    }
  };

  const onSubmitEduQuestions = async () => {
    setLoader(true);
    const data = eduNeeds.map((item: any) => {
      let follow_up = "";
      let follow_up_id = item.answer_uuid || "";
      if (item.further_detail_required && item.answer) {
        follow_up = item.followup_answer;
      }
      return {
        education_uuid: item.education_uuid,
        answer: item.answer,
        answer_uuid: follow_up_id,
        followup_answer_uuid: item.followup_answer_uuid,
        followup_answer: follow_up,
        supported_doc_url: item.supported_doc_url,
      };
    });
    let response = await request({
      url: `student-enrolment/student-enrol-education-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "put",
      data: data,
    });
    if (response.data.status_code === 1) {
      setLoader(false);
      getEduNeeds();
      setFormDisable(true);
    } else {
      setLoader(false);
    }
  };
  // end edu needs

  // common helper functions
  const handleTabChange = (value: string) => {
    if (formDisable) {
      setShow(value);
      setActive(value);
      setFormDisable(true);
    } else {
      setErrorEdit(
        "Please save data before changing tab or click on cancel button"
      );
    }
  };

  const saveData = () => {
    if (show === "PreviousSchool") {
      savePrevSchool();
    }
    if (show === "EducationalInfo") {
      onSubmitEduQuestions();
    }
    if (show === "SubjectChoice") {
      postSub();
    }
    if (show === "LanguageChoice") {
      postLang();
    }
    updatePdf(applicationID);
  };

  const cancelEdit = () => {
    setFormDisable(true);
    getSubjectChoices();
    setErrorEdit("");
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const temp: any = reorder(
      subChoice,
      result.source.index,
      result.destination.index
    );
    setSubChoice(temp);
  };

  const onDragEndLang = (result: any) => {
    console.log();

    if (!result.destination) {
      return;
    }

    const temp: any = reorder(
      langChoice,
      result.source.index,
      result.destination.index
    );
    setLangChoice(temp);
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const showAdminBtn = () => {
    if (show === "PreviousSchool") {
      return previousSchool[0]?.fromDate;
    } else if (show === "EducationalInfo") {
      return eduNeeds[0]?.answer_uuid;
    } else if (show === "SchoolOffers") {
      return eduOffer[0]?.school_offer_uuid;
    } else if (show === "SubjectChoice") {
      return subChoice?.length !== 0;
    } else if (show === "LanguageChoice") {
      return langChoice?.length !== 0;
    }
    return true;
  };
  return (
    <>
      <Loader open={loader} />
      <Box display="flex" justifyContent="space-between">
        <Box width="600px">
          {show === "PreviousSchool" ? (
            empty === false ? (
              <>
                {!formDisable && (
                  <Box width="150px" mb="40px">
                    <AdminButton
                      label="Add School"
                      onClick={addSchoolHandler}
                      light
                      endIcon="add"
                    />
                  </Box>
                )}
                {previousSchool.map((item, index) => (
                  <Box mb="4" key={index}>
                    <EducationItem
                      data={item}
                      index={index}
                      disabled={formDisable}
                      onChange={onChangePrevSchool}
                      onDelete={deleteHandler}
                      id={item.pastEducationId}
                      DateValueCheck={DateValueCheck}
                    />
                  </Box>
                ))}
              </>
            ) : (
              <Box>Previous school details not provided yet</Box>
            )
          ) : null}

          {show === "EducationalInfo" ? (
            emptyInfo === false ? (
              <>
                <p>Educational Info </p>
                {eduNeeds.map((item: any, index: number) => (
                  <Box mb="15px" key={index}>
                    <InputRadioButton
                      label={item.question}
                      placeHolderOne="No"
                      placeHolderTwo="Yes"
                      valueOne="no"
                      valueTwo="yes"
                      answer={item.answer}
                      isDisabled={formDisable}
                      onChange={handleRadioChange}
                      index={index}
                    />
                    {item.answer && item.further_detail_required ? (
                      <Box>
                        <Input
                          label={item.follow_up_question}
                          value={item.followup_answer}
                          disabled={formDisable}
                          onChange={(e: any) =>
                            onChangeEduQuestions(item, e.target.value, index)
                          }
                        />
                      </Box>
                    ) : null}
                    {item.follow_up_supporting_doc &&
                    item.answer &&
                    item.further_detail_required ? (
                      item.isUploaded ? (
                        <DownLoad
                          id={`doc_download${index}`}
                          label="View document*"
                          file={item.fileName}
                          // onDelete={() => deleteDocument(`${index}`, index)}
                          downloadLink={item.document_presingne_url}
                        />
                      ) : null
                    ) : null}
                  </Box>
                ))}
              </>
            ) : (
              <Box>Education info details not provided yet</Box>
            )
          ) : null}

          {show === "SchoolOffers" ? (
            emptyOffers === false ? (
              <>
                <p>Education Offers </p>
                {eduOffer.map((item: any, index: any) => (
                  <Box mb="30px" key={index}>
                    <InputRadioButton
                      label="I/we have applied for and am/are awaiting confirmationof an offer of admission from another school(s)"
                      placeHolderOne="No"
                      placeHolderTwo="Yes"
                      valueOne="no"
                      valueTwo="yes"
                      answer={item.is_offer_another_school}
                      index={index}
                      isDisabled
                    />
                    {item.is_offer_another_school ? (
                      <Box>
                        <Input
                          label="Please give details"
                          disabled
                          value={item.another_school_details}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </>
            ) : (
              <Box>Education offers details not provided yet</Box>
            )
          ) : null}

          {show === "SubjectChoice" ? (
            <>
              <p>Subject Choices </p>
              {formDisable ? (
                <Box>
                  {subjectChoice.map((sub: any, index: number) => (
                    <Input
                      key={index}
                      label={`Preference ${index + 1}`}
                      value={sub.label}
                      disabled={true}
                    />
                  ))}
                </Box>
              ) : (
                <>
                  <InputLabel className={classes.label}>
                    Drag the blocks below, in order of preference, the subjects
                    the student wishes to study
                  </InputLabel>

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {subChoice.map((item: any, index: any) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={classes.langItem}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <p>{item.label}</p>
                                  <Icon className={classes.icon}>
                                    more_vert_icon
                                  </Icon>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </>
          ) : null}
          {show === "LanguageChoice" ? (
            <>
              <p>Language Choices </p>
              {formDisable ? (
                <Box>
                  {languageChoice.map((lang: any, index: number) => (
                    <Input
                      key={index}
                      label={`Preference ${index + 1}`}
                      value={lang.label}
                      disabled={true}
                    />
                  ))}
                </Box>
              ) : (
                <>
                  <InputLabel className={classes.label}>
                    Drag the blocks below, in order of preference, the Languages
                    the student wishes to study
                  </InputLabel>

                  <DragDropContext onDragEnd={onDragEndLang}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {langChoice.map((item: any, index: any) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={classes.langItem}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <p>{item.label}</p>
                                  <Icon className={classes.icon}>
                                    more_vert_icon
                                  </Icon>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}
            </>
          ) : null}
        </Box>
        <div>
          <Box className={classes.subTab} height="150px">
            <button
              className={cn(
                classes.tabButton,
                active === "PreviousSchool" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("PreviousSchool");
              }}
            >
              Previous School
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "EducationalInfo" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("EducationalInfo");
              }}
            >
              Educational Info
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "SchoolOffers" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("SchoolOffers");
              }}
            >
              School Offers
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "SubjectChoice" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("SubjectChoice");
              }}
            >
              Subject Choice
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "LanguageChoice" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("LanguageChoice");
              }}
            >
              Language Choice
            </button>
          </Box>
        </div>
      </Box>
      {!formDisable && (
        <Box mt="20px" mb="20px">
          <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
        </Box>
      )}
      {localStorage.getItem("employee_type") === "Super Admin" &&
      showAdminBtn() ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              // disabled={isDisabled}
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={saveData}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </>
  );
};
export default EduHistView;
