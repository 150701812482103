import Icon from "@material-ui/core/Icon";
import { useStyle } from "./icon.style";
import cn from "classnames";

type IconProps = {
  iconName?: string;
  iconSize?: string;
  bgColor?: string;
  letter?: string;
};

export const IconRounded: React.FC<IconProps> = ({
  iconName,
  iconSize,
  bgColor,
  letter,
}) => {
  const classes = useStyle();
  return (
    <div
      className={cn(classes.root)}
      style={{
        backgroundColor: `${bgColor}`,
      }}
    >
      {letter ? (
        <div className={classes.letter}>{letter}</div>
        
      ) : (
        <Icon
          style={{
            fontSize: `${iconSize}`,
          }}
        >
          {iconName}
        </Icon>
      )}
    </div>
  );
};
