import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: "10px",
    textAlign: "center",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
  },
 
}));
