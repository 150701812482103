import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import cn from "classnames";
import { useStyle } from "./consents-tab.style";
import { AdminButton } from "../../../components/common/admin-button/index";

type ConsentProps = {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  errorMessage?: string | null;
  save?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const Consent: React.FC<ConsentProps> = ({
  id,
  label,
  value,
  onChange,
  error = false,
  errorMessage = "Required",
  save,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  return (
    <>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : null)}
      >
        {label}
      </InputLabel>
      <TextareaAutosize
        value={value}
        onChange={onChange}
        id={id}
        maxRows={7}
        minRows={7}
        style={{ resize: "none", fontSize: "16px" }}
        className={cn(error ? classes.error : classes.inputRoot)}
        disabled={selectedGlobalDate !== currentGlobalDate}
      />
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
      {selectedGlobalDate === currentGlobalDate && (
        <div style={{ width: 100, marginTop: 40 }}>
          <AdminButton label="Save" onClick={save} />
        </div>
      )}
    </>
  );
};

export default Consent;
