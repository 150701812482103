import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "../start-selection/start-selection.style";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";
import { TextFieldCustom } from "../start-selection/text-field-component";
import WaitingListTable from "./waiting-list-table";
import { AdminButton } from "../../../../components/common/admin-button";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import { useQuery, UseQueryResult } from "react-query";
import { MessageBox } from "../../../../components/common/message-Box/index";
import moment from "moment";

const WaitingList = () => {
  const classes = useStyle();
  const history = useHistory();
  const schoolId = localStorage.getItem("school-id");
  const [row, setRow] = useState([]);
  const [totalIntake, setTotalIntake] = useState<number>();
  const [placesAvailable, setPlacesAvailable] = useState<number>();
  const [totalCandidates, setTotalCandidates] = useState<number>();
  const [totalSelected, setTotalSelected] = useState<number>();
  const [searchText, setSearchText] = useState<string>("");
  const [sortTag, setSortTag] = useState<string>("");
  const [isAsc, setIsAsc] = useState<boolean | string>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [waitlistPopup, SetWaitlistPopup] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [mailSent, setMailSent] = useState<boolean>(false);
  const [sendEmailDisabled, setSendEmailDisabled] = useState<boolean>(false);

  
  const getRelDate = async () => {
    setLoader(true);
    const res = await request({
      url: `school-settings/application-dates-details`,
      method: "get",
    });
    if (res.data.status_code === 1) {
      if (Object.keys(res.data.results).length > 0) {
        const date = new Date(res.data.results.notification_date);
        const mDate = moment(date).format("DD/MM/YYYY");
        const time = res.data.results.notification_date_time;
        //moment datetime obj
        const dateTime = moment(mDate + " " + time, "DD/MM/YYYY HH:mm");
        setSendEmailDisabled(moment().isBefore(dateTime));
      }
      setLoader(false);
    }
  };


  const getList = async (): Promise<any> => {
    setLoader(true);
    const res = request({
      url: `school-settings/waiting-list/?school_uuid=${schoolId}&page=${pageNumber}&search_text=${searchText}&sorting_var=${sortTag}&is_asc=${isAsc}`,
      method: "get",
    });
    return res;
  };
  
  const { refetch, data, isFetching }: UseQueryResult<any, Error> = useQuery<
    any,
    Error
  >(["waitinglist", { pageNumber, searchText, sortTag, isAsc }], () => getList(), {
    select: (waitinglist) => waitinglist.data.results,
  });

  useEffect(() => {
    if (!isFetching) {
      setLoader(false);
      getRelDate();
    }
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data).length !== 0) {
        setTotalIntake(data.total_places);
        setPlacesAvailable(data.places_available);
        setTotalCandidates(data.no_of_candidates);
        setTotalSelected(data.total_selected);
        const tab = data.waiting_list.map((item: any) => {
          return {
            ...item,
            id: item.ID,
          };
        });
        setRow(tab);
        setTotalPages(data.total_pages);
        setPageNumber(data.current_page);
      } else {
        setRow([]);
        setPlacesAvailable(0);
        setTotalCandidates(0);
        setTotalPages(0);
        setPageNumber(0);
        setTotalSelected(0);
      }
    } else {
      setRow([]);
      setPlacesAvailable(0);
      setTotalCandidates(0);
      setTotalPages(0);
      setTotalSelected(0);
    }
  }, [data]);

  const onSelect = () => {
    setLoader(true);
    const data = {
      places_available: placesAvailable,
    };
    request({
      url: `school-settings/waiting-list/?school_uuid=${schoolId}`,
      method: "put",
      data: data,
    }).then(() => {
      refetch();
      setLoader(false);
    });
  };

  const sendWaitListEmail = () => {
    setLoader(true);
    request({
      url: `school-settings/send-email-waiting-list-applications/?school_uuid=${schoolId}`,
      method: "post",
    }).then((res) => {
      setLoader(false);
      setMailSent(true);
    });
  };

  const exportCsv = async () => {
    setLoader(true);
    await request({
      url: `/school-settings/export-csv-waiting-list-data/`,
      method: "get",
    }).then((res) => setLoader(false));
  };

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <Loader open={loader} />
      <div className={classes.subContainer}>
        <Box marginBottom="40px">
          <Box display="flex" alignItems="center">
            <Icon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => history.push("/admin/offers")}
            >
              arrow_back
            </Icon>
            <p>Go Back</p>
          </Box>
          <Box textAlign="center">
            <p className={classes.textOne}>Waiting List Screen</p>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <div style={{ width: 180 }}>
              <AdminButton
                label="Send Email"
                disabled={sendEmailDisabled}
                onClick={() => SetWaitlistPopup(true)}
              />
            </div>
            <div style={{ width: 180 }}>
              <AdminButton
                label="Export Excel"
                onClick={exportCsv}
                disabled={row?.length === 0}
              />
            </div>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <TextFieldCustom
              placeholder="Total Intake"
              value={totalIntake?.toString()}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Total Selected"
              value={totalSelected?.toString()}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="Places Currently Free :"
              value={placesAvailable?.toString()}
            />
          </Box>
          <Box>
            <TextFieldCustom
              placeholder="No. of Candidates in waiting list"
              value={totalCandidates?.toString()}
            />
          </Box>
          <Box width="220px">
            <AdminButton
              disabled={placesAvailable === 0}
              label="Select candidates from waiting list"
              onClick={() => setPopup(true)}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px solid #000000"
          >
            <input
              onChange={(e: any) => searchTextChange(e.target.value)}
              type="text"
              placeholder="Find an applicant"
              className={classes.inputStyle}
            />
            <div>
              <Icon className={classes.iconStyle}>search</Icon>
            </div>
          </Box>
        </Box>
        <div style={{ marginTop: 60 }}>
          <WaitingListTable
            data={row}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            totalPages={totalPages}
            setSortTag={setSortTag}
            setIsAsc={setIsAsc}
            refetch={refetch}
          />
        </div>
        <ConfirmBox
          open={popup}
          setOpen={setPopup}
          onClick={onSelect}
          message="Are you sure you want to select candidates from waiting list?"
        />
        <ConfirmBox
          open={waitlistPopup}
          setOpen={SetWaitlistPopup}
          onClick={() => sendWaitListEmail()}
          message="Are you sure you want to send email to the candidates for addition to the waiting list"
        />
        <MessageBox
          message="The waiting list email has been sent to the respective candidates"
          open={mailSent}
          setOpen={setMailSent}
        />
      </div>
    </Grid>
  );
};

export default WaitingList;
