/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useStyle } from "./logo.style";
import { Link } from "react-router-dom";
import { Loader } from "../loader";
import { ImageLoader } from "../image-loader/index";
import request from "../../../utils/authUtil";

type Props = {
  logoRedirect?: string;
};
const Logo: React.FC<Props> = ({ logoRedirect = "LANDING" }) => {
  const styles = useStyle();
  const schoolID = localStorage.getItem("school-id");
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("/");
  const [imgLoad, setImgLoad] = useState(true);
  const setLogoRedirect = () => {
    if (logoRedirect === "LANDING") {
      setRedirectUrl("/");
    }
    if (logoRedirect === "DASHBOARD") {
      setRedirectUrl("/parent/dashboard");
    }
    if (logoRedirect === "ADMIN") {
      setRedirectUrl("/admin");
    }
  };

  useEffect(() => {
    setLoader(true);
    setLogoRedirect();
    request({
      url: `auth/school-logo/?school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          if (Object.keys(response.data.results).length !== 0) {
            setLogoUrl(response.data.results);
          }
        } else {
          setLogoUrl("");
        }
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
    // axios
    //   .get(`${baseUrl}auth/school-logo/?school_uuid=${schoolID}`)
    //   .then((response) => {
    //     if (response.data) {
    //       if (Object.keys(response.data.results).length !== 0) {
    //         setLogoUrl(response.data.results);
    //       }
    //     } else {
    //       setLogoUrl("");
    //     }
    //     setLoader(false);
    //   })
    //   .catch(() => {
    //     setLoader(false);
    //   });
  }, []);

  return (
    <>
      <Loader open={loader} />
      <Link to={`${redirectUrl}`} style={{ textDecoration: "none" }}>
        <div style={{ display: imgLoad ? "none" : "block" }}>
          <img
            src={logoUrl}
            onLoad={() => setImgLoad(false)}
            className={styles.root}
            alt="Logo"
          />
        </div>
        {imgLoad && <ImageLoader />}
      </Link>
    </>
  );
};

export default Logo;
