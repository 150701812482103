import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  fontFamily: {
    fontFamily: `"LFT Etica", sans-serif`,
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#2B2B33",
  },
  root: {
    fontFamily:`"LFT Etica", sans-serif`,
    width: "96%",
    marginTop:"20px"
  },
  
  container: {
    marginRight: "54px",
    marginBottom: "50px",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  tabMain: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontWeight: "bold",
  },
  tab: {
    fontFamily:   `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#F7F8FC",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    maxWidth: "100%",
  },
}));
