import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "70px",
    color: theme.palette.text.primary,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
  },
  label: {
    marginBottom: 6.5,
    fontFamily:   `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main
},
labelError: {
    color: theme.palette.error.main
},
errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
},
}));
