import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  buttonWidth: {
    margin: "29px 0px auto",
    [theme.breakpoints.down("sm")]: {
      width: "35%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  },

  textOne: {
    fontSize: "13px",
    color: "#191919",
    fontWeight: 400,
  },
  textTwo: {
    fontSize: "14px",
    color: "#191919",
    fontWeight: 400,
    whiteSpace: "pre-wrap",
  },
  textThree: {
    fontSize: "14px",
    color: "#191919",
    fontWeight: 600,
  },
  card: {
    marginTop: 52,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "80%",
    borderRadius: 12,
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },
  icon: { marginLeft: "10px", fontSize: "15px" },
  link: {
    color: "black",
  },
  extraButton: {
    backgroundColor: theme.palette.common.white, //theme.palette.grey[200],
    cursor: "pointer",
    border: "1px solid",
    padding: "13px 12px",
    borderColor: " #E6E4E3",
    borderRadius: "4px",
    width: "100%",
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
}));
