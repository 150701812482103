export const Type = {
    signature: "SIGNATURE",
    birthCertificate: "BIRTHCERTIFICATE",
    pps: "PPS",
    other: "OTHERS",
    passport:"PASSPORT_PHOTO",
    previous_school_report:"PREVIOUS_SCHOOL_REPORT",
    utility:"UTILITY_BILL",
    utilityTwo:"UTILITY_BILL_SECOND",
    asd:"ASD",
}