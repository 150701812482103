import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({

  label: {
    marginBottom: 0.5,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  inputRoot: {
    width: "100%",
    fontFamily:    `"LFT Etica", sans-serif`,
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    overflow: "auto",
    outline: "none",
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
  },
  error: {
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    overflow: "auto",
    outline: "none",
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  tab: {
    marginRight: 50,
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    width: 200
  },
  tabButton: {
    display: "block",
    backgroundColor: "inherit",
    color: theme.palette.common.black,
    padding: "12px 22px",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14
  },

  preReqContainer: {
    width: "100%",
    marginTop: 30
  },
  inputContainer: {
    width: "100%",
    marginTop: 20
  }

}));
