/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useStyle } from "./application.style";
import Header from "../header/index";
import { Footer } from "../../../components/common/footer/index";
import StudentPersonalInfo from "./student-details/student-personal-info";
import StudentSibling from "./student-details/student-sibling";
import StudentBirthCertificate from "./student-details/student-birth-certificate";
import StudentAddress from "./student-details/student-address";
import Guardians from "./guardian-details/guardians";
import GuardianCorrespondence from "./guardian-details/guardian-correspondence";
import SchoolInfo from "./school-info/index";
import Confirm from "./confirm-page/index";
import EducationStatus from "./education-status/index";
import PrevSchoolConsent from "./education-status/prev-school-consent";
import { useHistory } from "react-router-dom";
import StudentProfile from "./student-profile/index";
import StudentProfileSelectionCriteria from "./student-profile/student-profile-selection-criteria";
import ThankYouSubmit from "./thankyou-submit/index";
import { CheckNetwork } from "../../../utils/helper";
import request from "../../../utils/authUtil";
import EmergencyContact from "./emergency-contact";
import DocumentInfo from "./document-page";
import requestv2 from "../../../utils/authUtilv2";
import FileUpload from "../enrollment/file-upload";
import PhotoUpload from "../enrollment/photo-upload";
import ASDConfirmation from "./asd-confirmation-page";

type ApplicationProps = {};

const Application = (props: ApplicationProps) => {
  const classes = useStyle();
  let { appId } = useParams<{ appId: string }>();
  const schoolID = localStorage.getItem("school-id");
  const history = useHistory();
  const [active, setActive] = useState<string>("spi");
  const [ready, setReady] = useState<boolean>(false);
  const [showPrevSchoolConsent, setShowPrevSchoolConsent] =
    useState<boolean>(false);
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [showEmergencyContact, setShowEmergencyContact] =
    useState<boolean>(false);
  const [showCurrentSchool, setShowCurrentSchool] = useState<boolean>(false);
  const [showCurrentSiblings, setShowCurrentSiblings] =
    useState<boolean>(false);
  const [showBirthCertificatePage, setShowBirthCertificatePage] =useState<boolean>(false);
  const [showPreviousSchoolReport, setShowPreviousSchoolReport] =useState<boolean>(false);
  const stuAppId = localStorage.getItem("currentStudentAppID");
  const [showDoc, setShowDoc] = useState<boolean>(false);
  const [showASDpage, setShowASDpage] = useState<boolean>(false);
  const [showPhoto, setShowPhoto] = useState<boolean>(false);

  useEffect(() => {
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      if (
        res.data.results.contact_previous_school &&
        res.data.results.contact_previous_school_in_application
      ) {
        setShowPrevSchoolConsent(true);
      }
      if (
        res.data.results.is_birth_certificate &&
        res.data.results.birth_certificate_in_application
      ) {
        setShowBirthCertificatePage(true);
      }if (
        res.data.results.is_previous_school_report &&
        res.data.results.previous_school_report_in_application
      ) {
        setShowPreviousSchoolReport(true);
      }
      if(
        res.data.results.is_enrol_photo_optional &&
        res.data.results.photo_in_application){
          setShowPhoto(true);
        }
      if (res.data.results.include_student_profile) {
        setShowProfile(true);
      }
      if (
        res.data.results.is_show_emergency_contact &&
        res.data.results.show_emergency_contact_in_application
      ) {
        setShowEmergencyContact(true);
      }
      if (res.data.results.is_show_current_primary_school_question) {
        setShowCurrentSchool(true);
      }
      if (res.data.results.is_show_current_siblings) {
        setShowCurrentSiblings(true);
      }
      setShowASDpage(res.data.results.allow_asd_applications)
      setReady(true);
    });
    requestv2({
      url: `student-application/check-form-type/?school_uuid=${schoolID}&stu_app_uuid=${stuAppId}&type_of_form=Application`,
      method: "get",
    }).then((res) => {
      setShowDoc(res.data.results);
    });
  }, []);
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <CheckNetwork />
      <Header />
      {ready && (
        <>
          {active === "spi" ? (
            <StudentPersonalInfo
              prevPage={() => history.push("/parent/application")}
              nextPage={() => {
                showCurrentSiblings ? setActive("ss") : showBirthCertificatePage ? setActive("sbc") : setActive("sa");
              }}
              stdAppId={appId}
            />
          ) : null}
          {showCurrentSiblings && active === "ss" ? (
            <StudentSibling
              prevPage={() => setActive("spi")}
              nextPage={() => {
                showBirthCertificatePage ? setActive("sbc") : setActive("sa");
              }}
              stdAppId={appId}
            />
          ) : null}
          {showBirthCertificatePage && active === "sbc" ? (
            <StudentBirthCertificate
              prevPage={() => {
                showCurrentSiblings ? setActive("ss") : setActive("spi");
              }}
              nextPage={() => setActive("sa")}
              stdAppId={appId}
            />
          ) : null}
          {active === "sa" ? (
            <StudentAddress
              prevPage={() => {
                showBirthCertificatePage
                ? setActive("sbc")
                : showCurrentSiblings
                ? setActive("ss")
                : setActive("spi");
              }}
              nextPage={() => setActive("g")}
              stdAppId={appId}
            />
          ) : null}
          {active === "g" ? (
            <Guardians
              prevPage={() => setActive("sa")}
              nextPage={() => setActive("gc")}
              stdAppId={appId}
            />
          ) : null}

          {active === "gc" ? (
            <GuardianCorrespondence
              prevPage={() => setActive("g")}
              nextPage={() => {
                showEmergencyContact ? setActive("ec") : setActive("cf");
              }}
              stdAppId={appId}
            />
          ) : null}
          {showEmergencyContact && active === "ec" ? (
            <EmergencyContact
              prevPage={() => setActive("gc")}
              nextPage={() => setActive("cf")}
              stdAppId={appId}
            />
          ) : null}
          {active === "cf" ? (
            <Confirm
              prevPage={() => {
                showEmergencyContact ? setActive("ec") : setActive("gc");
              }}
              nextPage={()=>{
                showCurrentSchool
                ? setActive("es")
                : showPhoto 
                ? setActive("photou")
                : showPreviousSchoolReport
                ? setActive("spsr")
                : showPrevSchoolConsent
                ? setActive("psc")
                : setActive("si");
              }}
              
              stdAppId={appId}
              showEmergencyContact={showEmergencyContact}
            />
          ) : null}
          {showCurrentSchool && active === "es" ? (
            <EducationStatus
              prevPage={() => setActive("cf")}
              nextPage={() => {
                showPhoto 
                ? setActive("photou")
                : showPreviousSchoolReport
                ? setActive("spsr")
                : showPrevSchoolConsent
                ? setActive("psc")
                : setActive("si");
              }}
              stdAppId={appId}
            />
          ) : null}
          {
            showPhoto && active === "photou" ? (
              <PhotoUpload
              prevPage={() => {
                showCurrentSchool ? setActive("es") : setActive("cf");
              }}
              nextPage={() => {
                showPreviousSchoolReport
                ? setActive("spsr")
                : showPrevSchoolConsent
                ? setActive("psc")
                : setActive("si");
              }}
              stdAppId={appId}
              />
            )
            : null
          }
          {showPreviousSchoolReport && active === "spsr" ? (
            <FileUpload
              prevPage={() => {
                showPhoto 
                ? setActive("photou") 
                : showCurrentSchool 
                ? setActive("es") 
                : setActive("cf");
              }}
              nextPage={() => showPrevSchoolConsent ? setActive("psc") : setActive("si")}
              stdAppId={appId}
            />
          ) : null}
          {showPrevSchoolConsent && active === "psc" ? (
            <PrevSchoolConsent
              prevPage={() => {
                showPreviousSchoolReport 
                ? setActive("spsr")
                : showPhoto 
                ? setActive("photou")
                : showCurrentSchool 
                ? setActive("es") 
                : setActive("cf");
              }}
              nextPage={() => setActive("si")}
              stdAppId={appId}
            />
          ) : null}
          {active === "si" ? (
            <SchoolInfo
              prevPage={() => {
                showPrevSchoolConsent
                  ? setActive("psc")
                  : showPreviousSchoolReport
                  ? setActive("spsr")
                  : showPhoto 
                  ? setActive("photou")
                  : showCurrentSchool
                  ? setActive("es")
                  : setActive("cf")
              }}
              nextPage={() => {
                showASDpage
                ? setActive("asd")
                : showProfile
                ? setActive("sp")
                : showDoc
                ? setActive("document")
                : setActive("spsc");
              }}
              stdAppId={appId}
            />
          ) : null}
          {
          showASDpage && active === "asd" ?
          (<ASDConfirmation
            prevPage={() => setActive("si")}
            nextPage={() => {
                showProfile
                ? setActive("sp")
                : showDoc
                ? setActive("document")
                : setActive("spsc");
            }}
              stdAppId={appId}
          />)
          : null
        }
          {showProfile && active === "sp" ? (
            <StudentProfile
              prevPage={() => showASDpage ? setActive('asd') : setActive("si")}
              stdAppId={appId}
              nextPage={() =>
                showDoc ? setActive("document") : setActive("spsc")
              }
            />
          ) : null}
          {showDoc && active === "document" ? (
            <DocumentInfo
              prevPage={() => {
                showProfile
                ?setActive("sp")
                :showASDpage
                ? setActive('asd')
                :setActive("si");
              }}
              stdAppId={appId}
              nextPage={() => setActive("spsc")}
            />
          ) : null}
          {active === "spsc" ? (
            <StudentProfileSelectionCriteria
              prevPage={() => {
                showDoc
                  ? setActive("document")
                  : showProfile
                  ? setActive("sp")
                  : showASDpage
                  ?setActive('asd')
                  :setActive("si");
              }}
              stdAppId={appId}
              nextPage={() => setActive("tus")}
            />
          ) : null}
          {active === "tus" ? <ThankYouSubmit /> : null}
        </>
      )}
      <Box mr="23px" ml="23px">
        <Footer center />
      </Box>
    </Grid>
  );
};

export default Application;
