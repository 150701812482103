import { Box, List, makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ForumIcon from "@material-ui/icons/Forum";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListIcon from "@material-ui/icons/List";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Group from "@material-ui/icons/Group";
import MenuItem from "./MenuItem";
import SchoolIcon from "@material-ui/icons/School";
import { useTranslation } from "react-i18next";
import Logo from "./common/logo";

const useStyle = makeStyles((theme) => ({
  button: {
    fontWeight: "bold",
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
    width: "calc(100vw - 88vw)",
    display: "flex",
    flexDirection: "column",
    fontFamily: `"LFT Etica", sans-serif`,
    '@media (max-width: 1023px)': {
      display: "none",
    }
  },
  logo: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    '@media (max-width: 1023px)': {
      marginBottom: "50px",
    }
  },
  logoImage: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  selectedStyle: {
    backgroundColor: "red",
    borderTopRightRadius: "25px",
    borderBottomRightRadius: "25px",
  },
  footer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontSize: "14px",
    paddingLeft: theme.spacing(5),
    '@media (max-width: 1023px)': {
      marginTop: "50px",
    }
  },
  drawers:
  {
    '@media (max-width: 1023px)': {
      display: "block",
      backgroundColor: theme.palette.primary.main,
      fontFamily: `"LFT Etica", sans-serif`,
      width: "180px",
    }
  }
}));

type Props = {
  show: any;
};

const SideBar: React.FC<Props> = ({ show }: Props) => {
  const styles = useStyle();
  const { t } = useTranslation();

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      classes={{ paper: `${styles.drawer} ${show ? styles.drawers : ''}` }}
    >
      <Box className={styles.logo}>
        <Logo logoRedirect="ADMIN" />
      </Box>
      <Box style={{ margin: "auto", marginLeft: "0px", marginRight: "10px" }}>
        <List>
          <MenuItem
            path="/admin"
            name={t("Dashboard")}
            startIcon={<DashboardIcon />}
          />
          <MenuItem
            path="/admin/mySchool"
            name={t("My School")}
            startIcon={<SchoolIcon />}
          />
          <MenuItem
            path="/admin/applications"
            secondPath="/admin/applications/"
            name={t("Applications")}
            startIcon={<ListIcon />}
          />
          <MenuItem
            path="/admin/enrolment"
            name={t("Enrolments")}
            secondPath="/admin/enrolment/"
            startIcon={<AssignmentIcon />}
          />
          <MenuItem
            path="/admin/offers"
            name={t("Offers")}
            startIcon={<ThumbUpIcon />}
          />
          <MenuItem
            path="/admin/documents"
            name={t("Documents")}
            startIcon={<LibraryBooksIcon />}
          />
          <MenuItem
            path="/admin/communications"
            name={t("Communication")}
            startIcon={<ForumIcon />}
          />
          <MenuItem
            path="/admin/settings/0"
            name={t("Settings")}
            startIcon={<SettingsIcon />}
          />
          <MenuItem
            path="/admin/users"
            name={t("Users")}
            startIcon={<Group />}
          />
        </List>
      </Box>
      <div className={styles.footer}>
        Powered By
        <br />
        <span style={{ fontWeight: "bold" }}>UniqueSchools</span>
      </div>
    </Drawer>
  );
};

export default SideBar;
