import request from "../../../utils/authUtil";

export const getProfileData = async (id: any): Promise<any> => {
    if (id) {
      const response = await request({
        url: `/parent/parent-profile-page/?uuid=${id}`,
        method: "get",
      });
      return response;
    }
  };

export const sendEmailOtp = ({email, resend}: any) => request({
    url: "/parent/parent-change-email-otp/",
    method: "post",
    data: {
      email : email,
      resend : resend,
  },
  });

export const verifyEmailOtp = ({email, otp}: any) => request({
    url: "/parent/parent-change-email-otp-verification/",
    method: "post",
    data: {
      email : email,
      otp : otp
  },
  });

export const saveProfileData = (data: any, uuid: any) => {
    const regData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        email_verification_key: data.email_verification_key,
        email_verified: data.email_verified,
        email_verified_date: data.email_verified_date,
      }
    return request({
        url: `/parent/parent-profile-page/?uuid=${uuid}`,
        method: "put",
        data: regData,
      });
    
}