import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  progressBottom: {
    color: theme.palette.success.main,
  },
  progressTop: {
    color: theme.palette.grey[200],
    position: "absolute",
    left: 0,
  },
  label: {
    fontFamily:    `"LFT Etica", sans-serif`,
    fontSize: "13px",
    fontWeight: 600,
  },
}));
