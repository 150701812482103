import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    padding: "14px 18px",
  },
  logoImage: {
    width: 50,
    height: 65,
  },
  card: {
    marginTop: 82,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "50%",
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },
  buttonWidth: {
    margin: "20px 0",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "43%",
    },
  },
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    padding: "40px",
    border: 0,
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    width: "35%",
    height: "30%%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  checkButton: {
    cursor: "pointer",
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: "bold",
    border: 'double',
    padding: 10,
    marginTop: -12,
  },
  completeIcon: {
    color: theme.palette.success.main,
  },
  imageError: {
    color: theme.palette.error.main,
  },
  cancelIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `'LFT Etica'  , sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
},
text:{
  fontFamily: `'LFT Etica'  , sans-serif`,
  fontSize: 13,
  color: theme.palette.text.primary,
  marginTop:"-10px",
  marginBottom:"30px",
  textAlign:"center"
  },
  otpStyle:{
    display:'flex' ,justifyContent:'center',marginTop:'30px',fontSize:'16px'
  }
}));
