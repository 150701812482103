import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "./input-radio-button.style";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Box } from "@material-ui/core";
import cn from "classnames";
type InputRadioProps = {
  label?: string;
  valueOne?: string | boolean;
  valueTwo?: string | boolean;
  placeHolderOne?: string;
  placeHolderTwo?: string;
  answer?: boolean | string;
  onChange?: any;
  index?: number;
  isCheckString?: boolean;
  error?: boolean;
  errorMessage?: string;
  isDisabled?: boolean;
};

export const InputRadioButton: React.FC<InputRadioProps> = ({
  label,
  valueOne,
  valueTwo,
  placeHolderOne = "Place holder one",
  placeHolderTwo = "Place holder two",
  answer,
  onChange,
  index = 0,
  isCheckString = false,
  error = false,
  errorMessage = "Required",
  isDisabled,
}) => {
  const classes = useStyle();
  const isValueOneChecked = () => {
    if (isCheckString) {
      return !isDisabled && answer === valueOne;
    } else if (typeof valueOne === "boolean") {
      return !isDisabled && answer === valueOne;
    } else {
      return false;
    }
  };

  const isValueTwoChecked = () => {
    if (isCheckString) {
      return !isDisabled && answer === valueTwo;
    } else if (typeof valueTwo === "boolean") {
      return !isDisabled && answer === valueTwo;
    } else {
      return false;
    }
  };
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel className={error ? classes.labelError : classes.label}>
        {label}
      </InputLabel>
      <FormControl component="fieldset" style={{ width: "100%" }}>
        <RadioGroup aria-label="gender" name="radio-buttons-group">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={cn(
              classes.root,
              classes.borderTopBox,
              isValueOneChecked() ? classes.borderGreen : ""
            )}
          >
            <p className={classes.placeholder}>{placeHolderOne}</p>
            <FormControlLabel
              value={isDisabled === true ? "disabled" : valueOne}
              label=""
              control={
                <Radio
                  className={
                    isValueOneChecked() ? classes.checked : classes.checkedGrey
                  }
                  disableRipple
                  color="default"
                />
              }
              onClick={() =>
                isDisabled !== true ? onChange(valueOne, index) : null
              }
              checked={isCheckString ? answer === valueOne : answer === false}
              disabled={isDisabled}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={cn(
              classes.root,
              classes.borderBottomBox,
              isValueTwoChecked() ? classes.borderGreen : ""
            )}
          >
            <p className={classes.placeholder}>{placeHolderTwo}</p>
            <FormControlLabel
              value={isDisabled === true ? "disabled" : valueTwo}
              control={
                <Radio
                  className={
                    isValueTwoChecked() ? classes.checked : classes.checkedGrey
                  }
                  color="default"
                  disableRipple
                />
              }
              label=""
              onClick={() =>
                isDisabled !== true ? onChange(valueTwo, index) : null
              }
              checked={isCheckString ? answer === valueTwo : answer === true}
              disabled={isDisabled}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
