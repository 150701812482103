import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { Input } from "../../../components/common/input/index";
import { Icon } from "@material-ui/core";
import { useStyle } from "./medical-tab.style";
import { MedDeleteBox } from "./med-delete/index";

type MedicalProps = {
  medicalLabel: string;
  data: any;
  index: number;
  change?: any;
  furChange?: any;
  addFollowUp?: any;
  onDelete?: any;
  errorArray?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const Medical: React.FC<MedicalProps> = ({
  medicalLabel,
  data,
  index,
  change,
  furChange,
  addFollowUp,
  onDelete,
  errorArray,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openTwo, setOpenTwo] = useState(false);
  const [item, setItem] = useState({
    subId: "",
    subIndex: 0,
  });
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <MedDeleteBox
        id={data.medical_question_uuid}
        subId=""
        index={index}
        subIndex={-1}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
      <MedDeleteBox
        id=""
        subId={item.subId}
        index={index}
        subIndex={item.subIndex}
        onClick={onDelete}
        open={openTwo}
        setOpen={setOpenTwo}
      />
      <Text subHeading>{medicalLabel}</Text>
      <Box display="flex">
        <div className={classes.inputContainer}>
          <Input
            value={data.medicalQuestion}
            onChange={(e: any) =>
              change(e.target.value, index, "medicalQuestion", 0)
            }
            disabled={selectedGlobalDate !== currentGlobalDate}
            error={errorArray.includes(`${index}`)}
            maxlength="200"
          />
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <Box>
            <Icon
              className={classes.trash_icon}
              onClick={() => setOpen(true)}
              style={{ marginTop: "24px" }}
            >
              delete
            </Icon>
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          color="default"
          onChange={() => furChange(!data.details, index, "details", 0)}
          checked={data.details}
          disabled={selectedGlobalDate !== currentGlobalDate}
          style={{ marginLeft: "-13px" }}
        />
        <Text paragraph>Further details required?</Text>
      </Box>
      {data?.details ? (
        <div>
          {data?.furtherDetails?.map((item: any, followUpIndex: number) => {
            return (
              <Box
                display="flex"
                key={`${item?.medical_question_follow_up_uuid}`}
              >
                <div style={{ width: "65%" }}>
                  <Input
                    value={item.follow_up_question}
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    onChange={(e: any) =>
                      furChange(
                        e.target.value,
                        index,
                        "furtherDetails",
                        followUpIndex,
                        "follow_up_question"
                      )
                    }
                    error={errorArray.includes(`${index}${followUpIndex}`)}
                    maxlength="200"
                  />
                </div>
                {/* <Box
                  display="flex"
                  alignItems="center"
                  width="35%"
                  mb={3}
                  ml={1}
                >
                  <Checkbox
                    color="default"
                    onChange={(e: any) =>
                      change(
                        !item.require_supporting_doc,
                        index,
                        "furtherDetails",
                        followUpIndex,
                        "require_supporting_doc"
                      )
                    }
                    checked={item.require_supporting_doc}
                  />
                  <Text paragraph>File upload required</Text>
                </Box> */}
                {selectedGlobalDate === currentGlobalDate && (
                  <Box mb={2}>
                    <Icon
                      className={classes.trash_icon}
                      style={{ marginTop: "19px" }}
                      onClick={() => {
                        setOpenTwo(true);
                        setItem({
                          ...item,
                          subId: item.medical_question_follow_up_uuid,
                          subIndex: followUpIndex,
                        });
                      }}
                    >
                      delete
                    </Icon>
                  </Box>
                )}
              </Box>
            );
          })}
          {selectedGlobalDate === currentGlobalDate && (
            <div style={{ width: "230px", marginTop: "10px" }}>
              <button className={classes.extraButton} onClick={addFollowUp}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  Add furthur details
                  <Icon style={{ fontSize: "18px", marginLeft: "4px" }}>
                    add_icon
                  </Icon>
                </Box>
              </button>
            </div>
          )}
        </div>
      ) : null}
    </Grid>
  );
};

export default Medical;
