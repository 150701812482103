import request from "../../../utils/authUtilv2";

interface documentData {
  app_document_uuid: string;
  document_description: string;
  further_details_required: boolean;
  ask_in_application: boolean;
  follow_up_document_question: string,
}
interface postDocumentData {
  id: string;
  document: documentData[];
}

export const getDocument = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/upload-application-documents/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postDocument = async ({
  id,
  document,
}: postDocumentData): Promise<any> => {
  const data = document.map((item: any) => {
    return {
      app_document_uuid: item.app_document_uuid || "",
      document_description: item.document_description,
      further_detail_required: item.further_details_required,
      ask_in_application: item.ask_in_application,
      follow_up_document_question: item.further_details_required ? item.follow_up_document_question : "",
    };
  });

  const res = await request({
    url: `school-settings/upload-application-documents/?application_uuid=${id}`,
    method: "put",
    data,
  });
  return res;
};
