import { useStyle } from "./image-checkbox.style";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import { ImageLoader } from "../../../components/common/image-loader";
import { useState } from "react";
type Props = {
  src?: string;
  check?: boolean;
  change?: any;
  label?: string;
  error?: boolean;
  errorMessage?: string;
};

const Image = ({
  src,
  check,
  change,
  label,
  error = false,
  errorMessage = "Is required",
}: Props) => {
  const classes = useStyle();
  const [imgLoad, setImgLoad] = useState(true);
  return (
    <div style={{ marginBottom: 34, position: "relative" }}>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : "")}
      >
        {label}
      </InputLabel>
      <Grid
        className={cn(classes.root, error ? classes.error : "")}
        container
        direction="row"
        justifyContent="space-between"
      >
        <div style={{ width: "100%", height: "100%",backgroundColor:"white" }}>
          {imgLoad && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <ImageLoader />
            </div>
          )}
          <div
            style={{
              height: "100%",
              display: imgLoad ? "none" : "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={src}
              alt="Im"
              height="100%"
              width="auto"
              onLoad={() => {
                setImgLoad(false);
              }}
            />
          </div>
        </div>
        <Checkbox
          checked={check}
          onChange={change}
          inputProps={{ "aria-label": "secondary checkbox" }}
          color="default"
          style={{ position: "absolute", top: "32px", right: "12px" }}
        />
      </Grid>
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};

export default Image;
