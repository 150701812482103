import React from "react";
import { useStyle } from "./textfield.style";
import {
  Box,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

interface Props {
  value: any;
  setValue: any;
  field: any;
}
const RadioComponent: React.FC<Props> = ({
  value,
  setValue,
  field,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "Application") {
      setValue({
        ...value,
        [field]: true,
      });
    } else {
      setValue({
        ...value,
        [field]: false,
      });
    }
  };

  const classes = useStyle();

  return (
    <>
      <Box>
        <FormControl>
          <RadioGroup row value={value} onChange={handleChange}>
          <FormControlLabel
              control={
                <Radio
                  className={classes.radiobtn}
                  checked={value[field]}
                />
              }
              label="Application"
              value="Application"
            />
            <FormControlLabel
              control={
                <Radio
                  className={classes.radiobtn}
                  checked={!value[field]}
                />
              }
              label="Enrolment"
              value="Enrolment"
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default RadioComponent;
