import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Text } from "../../../components/common/text/index";
import { useState } from "react";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./register.style";
import { Footer } from "../../../components/common/footer/index";
import { Input } from "../../../components/common/input/index";
import { useMutation, UseMutationResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import Logo from "../../../components/common/logo/index";
import { CheckNetwork } from "../../../utils/helper";

import {
  register,
  sendEmailOtp,
  sendPhoneOtp,
  verifyEmailOtp,
  verifyPhoneOtp,
} from "./register.api";
import OtpModal from "./otp-modal";
import { setParent, setRefreshToken, setToken } from "../../../utils/authUtil";

import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import InputLabel from "@material-ui/core/InputLabel";
import cn from "classnames";
import "./phone.css";
type Props = {};

const Register = (props: Props) => {
  const classes = useStyle();
  const history = useHistory();
  const [emailModel, setEmailModel] = useState<boolean>(false);
  const [phoneModel, setPhoneModel] = useState<boolean>(false);
  const [emailOtp, setEmailOtp] = useState<string>("");
  const [phoneOtp, setPhoneOtp] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passMatched, setPassMatched] = useState<boolean>(true);
  const [error, setError] = useState<string | Error>("");
  const [otpVerifyError, setOtpVerifyError] = useState<string>("");
  const [otpError, setOtpError] = useState({
    otpErrorEmail: false,
    otpErrorPhone: false,
  });

  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    email_verification_key: "",
    email_verified: false,
    email_verified_date: "",
    phone_verification_key: "",
    phone_verified: false,
    phone_verified_date: "",
  });
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const registerMutation: UseMutationResult<any, Error> = useMutation(
    async (registerData) => register(registerData),
    {
      onSuccess: (response) => {
        setLoader(false);
        if (response.data.status_code === 1) {
          if (response.data.results.access_token) {
            const user_details = {
              uuid: response.data.results.uuid,
              user: response.data.results.user,
            };
            setParent(user_details);
            localStorage.setItem("isSignature", "false");
            localStorage.setItem("type", response.data.results.user_type);
            localStorage.setItem(
              "fullName",
              registerData.first_name.trim() +
                " " +
                registerData.last_name.trim()
            );
            setToken(response.data.results.access_token);
            setRefreshToken(response.data.results.refresh_token);
            history.push("/parent/signature");
          }
        } else if (response.data.status_code === -1) {
          setLoader(false);
          setError(response.data.status_message);
        }
      },
      onError: (e) => {
        setError(e);
      },
    }
  );

  const sendEmail: UseMutationResult<any, Error, any> = useMutation(
    async ({ email, resend }) => sendEmailOtp({ email, resend }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_code === 1) {
          setLoader(false);
          setEmailModel(true);
        } else if (res.data.status_code === -1) {
          setError(res.data.status_message);
        }
      },
      onError: (e) => {
        setError(e);
      },
    }
  );

  const sendPhone: UseMutationResult<any, Error, any> = useMutation(
    async ({ phone, resend }) => sendPhoneOtp({ phone, resend }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_code === 1) {
          setLoader(false);
          setPhoneModel(true);
        } else if (res.data.status_code === -1) {
          setError(res.data.status_message);
        }
      },
      onError: (e) => {
        setError(e);
      },
    }
  );

  const verifyEmail: UseMutationResult<any, Error, any> = useMutation(
    async ({ email, otp }) => verifyEmailOtp({ email, otp }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_message === "OTP verified") {
          setOtpVerifyError("");
          setRegisterData({
            ...registerData,
            email_verified: true,
            email_verification_key: res.data.results.verification_key,
            email_verified_date: new Date().toISOString(),
          });
          setOtpError({
            ...otpError,
            otpErrorEmail: false,
            otpErrorPhone: false,
          });
          setEmailModel(false);
        } else {
          setOtpVerifyError(res.data.status_message);
          setLoader(false);
        }
      },
    }
  );

  const verifyPhone: UseMutationResult<any, Error, any> = useMutation(
    async ({ phone, otp }) => verifyPhoneOtp({ phone, otp }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_message === "OTP verified") {
          setOtpVerifyError("");
          setRegisterData({
            ...registerData,
            phone_verified: true,
            phone_verification_key: res.data.results.verification_key,
            phone_verified_date: new Date().toISOString(),
          });
          setOtpError({
            ...otpError,
            otpErrorEmail: false,
            otpErrorPhone: false,
          });
          setPhoneModel(false);
        } else {
          setOtpVerifyError(res.data.status_message);
          setLoader(false);
        }
      },
    }
  );

  const handleOpen = (type: string) => {
    if (type === "email") {
      sendEmail.mutate({ email: registerData.email, resend: false });
    } else {
      sendPhone.mutate({
        phone: registerData.phone,
        resend: false,
      });
    }
  };

  const handleClose = (type: string) => {
    if (type === "email") {
      setEmailModel(false);
      setOtpError({ ...otpError, otpErrorEmail: false, otpErrorPhone: false });
      setOtpVerifyError("");
      setEmailOtp("");
    } else if (type === "phone") {
      setPhoneModel(false);
      setOtpError({ ...otpError, otpErrorPhone: false, otpErrorEmail: false });
      setOtpVerifyError("");
      setPhoneOtp("");
    }
  };

  const resendOtp = (type: string) => {
    if (type === "email") {
      sendEmail.mutate({ email: registerData.email, resend: true });
    } else {
      sendPhone.mutate({
        phone: registerData.phone,
        resend: true,
      });
    }
  };

  const validate = () => {
    return {
      otpErrorEmail: emailOtp.length !== 6,
      otpErrorPhone: phoneOtp.length !== 6,
    };
  };
  const verifyOtp = (type: string) => {
    const err = validate();
    setOtpError(err);
    if (type === "email" && err.otpErrorEmail === false) {
      verifyEmail.mutate({ email: registerData.email, otp: emailOtp });
    } else if (type === "phone" && err.otpErrorPhone === false) {
      verifyPhone.mutate({
        phone: registerData.phone,
        otp: phoneOtp,
      });
    }
  };

  const matchPasssword = () => {
    if (registerData.password !== confirmPassword) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (registerData.password.length === 0) {
      if (!temp.includes("confirm_password")) {
        temp.push("confirm_password");
      }
    } else if (registerData.password.length !== 0) {
      if (temp.includes("confirm_password")) {
        const val = temp.indexOf("confirm_password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };

  const test = () => {
    if (
      confirmPassword.length !== 0 &&
      registerData.password !== confirmPassword
    ) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (registerData.password.length === 0) {
      if (!temp.includes("password")) {
        temp.push("password");
      }
    } else if (registerData.password.length !== 0) {
      if (temp.includes("password")) {
        const val = temp.indexOf("password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };
  const validateEmail = (email: any) => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    if (emailRegex.test(email)) {
      setError("");
      setLoader(true);
      handleOpen("email");
    } else {
      setError("Email should be Valid");
    }
  };
  const validatePhone = (phone: any) => {
    // let phoneRegex = /^[0-9]{10}$/;
    if (isValidPhoneNumber(registerData.phone)) {
      setError("");
      setLoader(true);
      handleOpen("phone");
    } else {
      setError("Phone Number should be Valid");
    }
  };

  const onSubmit = () => {
    if (requiredFields.length === 0) {
      if (registerData.email_verified && registerData.phone_verified) {
        registerData.first_name = registerData.first_name.trim();
        registerData.last_name = registerData.last_name.trim();
        registerData.password = registerData.password.trim();
        registerMutation.mutate(registerData);
      } else {
        setError("Email and Phone number should be Verified");
      }
    } else {
      setError("Please fill all the required fields");
    }
  };
  const emptyFieldHandler = (type: string) => {
    const temp: any = [...requiredFields];
    if (!temp.includes(type)) {
      temp.push(type);
    }
    setRequiredFields(temp);
  };

  const filledFieldHandler = (type: string) => {
    const temp: any = [...requiredFields];
    if (temp.includes(type)) {
      const val = temp.indexOf(type);
      temp.splice(val, 1);
    }
    setRequiredFields(temp);
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <CheckNetwork />
      <Loader open={loader} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <Logo />
        </div>
        <div style={{ width: "130px" }}>
          <Link to="/parent" style={{ textDecoration: "none" }}>
            <Button label="Login" light />
          </Link>
        </div>
      </Grid>
      <Grid container justifyContent="center">
        <div className={classes.card}>
          <div style={{ marginBottom: 34 }}>
            <Text heading>Register</Text>
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          <Grid container direction="column">
            <Input
              label="Your first name *"
              value={registerData.first_name}
              onChange={(e: any) =>
                setRegisterData({ ...registerData, first_name: e.target.value })
              }
              onBlur={(e: any) =>
                e.target.value.trim().length === 0
                  ? emptyFieldHandler("first_name")
                  : filledFieldHandler("first_name")
              }
              error={requiredFields.includes("first_name")}
            />
            <Input
              label="Your last name *"
              value={registerData.last_name}
              onChange={(e: any) =>
                setRegisterData({ ...registerData, last_name: e.target.value })
              }
              onBlur={(e: any) =>
                e.target.value.trim().length === 0
                  ? emptyFieldHandler("last_name")
                  : filledFieldHandler("last_name")
              }
              error={requiredFields.includes("last_name")}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ width: "87%" }}>
                <Input
                  type="email"
                  label="Your email *"
                  value={registerData.email}
                  disabled={registerData.email_verified}
                  onChange={(e: any) =>
                    setRegisterData({ ...registerData, email: e.target.value })
                  }
                  onBlur={(e: any) => {
                    // validateEmail(e.target.value);
                    e.target.value.length === 0
                      ? emptyFieldHandler("email")
                      : filledFieldHandler("email");
                  }}
                  error={requiredFields.includes("email")}
                />
              </div>
              {registerData.email_verified ? (
                <Icon className={classes.completeIcon}>check_circle_icon</Icon>
              ) : (
                <div
                  className={classes.checkButton}
                  onClick={() => validateEmail(registerData.email)}
                >
                  <div style={{ height: 15 }}>Verify</div>
                </div>
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ width: "85%" }}>
                <div style={{ marginBottom: 34 }}>
                  <InputLabel
                    className={cn(
                      classes.label,
                      requiredFields.includes("phone")
                        ? classes.labelError
                        : null
                    )}
                  >
                    Phone *
                  </InputLabel>
                  <PhoneInput
                    className={cn(
                      requiredFields.includes("phone") ? "error" : "phone"
                    )}
                    defaultCountry="IE"
                    flagUrl={
                      "https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg"
                    }
                    international
                    value={registerData.phone}
                    onChange={(val: any) => {
                      setRegisterData({ ...registerData, phone: val });
                    }}
                  />
                  {requiredFields.includes("phone") ? (
                    <InputLabel className={classes.errorMessage}>
                      Required
                    </InputLabel>
                  ) : null}
                </div>
              </div>
              {registerData.phone_verified ? (
                <Icon className={classes.completeIcon}>check_circle_icon</Icon>
              ) : (
                <div
                  className={classes.checkButton}
                  onClick={() => validatePhone(registerData.phone)}
                >
                  Verify
                </div>
              )}
            </Grid>
            <Input
              label="Password *"
              type="password"
              value={registerData.password}
              onChange={(e: any) =>
                setRegisterData({ ...registerData, password: e.target.value })
              }
              error={requiredFields.includes("password")}
              onBlur={test}
            />
            <Input
              label="Confirm password *"
              type="password"
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
              onBlur={matchPasssword}
              error={
                !passMatched || requiredFields.includes("confirm_password")
              }
              errorMessage={
                !passMatched ? "Doesn't match with Password" : "Required"
              }
            />
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-around"
            >
              <div className={classes.buttonWidth}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button label="Back" light startIcon="navigate_before" />
                </Link>
              </div>
              <div className={classes.buttonWidth}>
                <Button label="Register" onClick={onSubmit} />
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid container>
        <div style={{ width: "100vw" }}>
          <Footer center />
        </div>
      </Grid>
      {emailModel && (
        <OtpModal
          openModal={emailModel}
          closeModal={() => handleClose("email")}
          otpValue={emailOtp}
          otpValueChange={setEmailOtp}
          resendOtp={() => resendOtp("email")}
          verifyOtp={() => verifyOtp("email")}
          type="email"
          otpError={otpError.otpErrorEmail}
          errorMessage={otpVerifyError}
        />
      )}
      {phoneModel && (
        <OtpModal
          openModal={phoneModel}
          closeModal={() => handleClose("phone")}
          otpValue={phoneOtp}
          otpError={otpError.otpErrorPhone}
          otpValueChange={setPhoneOtp}
          resendOtp={() => resendOtp("phone")}
          verifyOtp={() => verifyOtp("phone")}
          type="phone"
          errorMessage={otpVerifyError}
        />
      )}
    </Grid>
  );
};

export default Register;
