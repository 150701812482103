import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "../start-selection/start-selection.style";
import WithdrawTable from "./withdraw-table";
import { Icon } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader";
import { WithdrawPopup } from "./withdraw-popup";
import { useQuery, UseQueryResult } from "react-query";


const WithDrawApplication = () => {
  const classes = useStyle();
  const history = useHistory();
  const schoolId = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [openOne, setOpenOne] = useState<string>("");
  const [openTwo, setOpenTwo] = useState<string>("");
  const [withdrawals, setWithdrawals] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [withdrawalID, setWithdrawalId] = useState<any>([]);
  const [error, setError] = useState<string>("");
  const [helper, setHelper] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");

  const getList = (): any => {
    const res = request({
      url: `school-settings/withdrawl-student-application/?school_uuid=${schoolId}&page=${pageNumber}&search_text=${searchText}`,
    });
    return res;
  };

  const getReasons = () => {
    setLoader(true);
    request({
      url: `school-settings/school-withdrawls/?school_uuid=${schoolId}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response?.data?.results).length !== 0) {
            response.data.results.map((item: any) => {
              temp.push({
                withdrawl_text: item.withdrawl_text,
                order: item.order,
                school_application: item.school_application,
                withdrawl_uuid: item.withdrawl_uuid,
              });
            });
            setReasons(temp);
          }
        } else {
          setLoader(false);
        }
      })
      .catch();
  };

  const {
    refetch,
    isError,
    error: withdrawError,
    data: widthdrawData,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["withdrawlist", { pageNumber, searchText }],
    () => getList(),
    {
      select: (withdrawlist) => withdrawlist.data.results,
    }
  );

  useEffect(() => {
    if (widthdrawData) {
      if (Object.keys(widthdrawData).length !== 0) {
        const tab = widthdrawData.applicants_list.map((item: any) => {
          return {
            ...item,
            id: item.ID,
          };
        });
        setWithdrawals(tab);
        setTotalPages(widthdrawData.total_pages);
        setPageNumber(widthdrawData.current_page);
      } else {
        setWithdrawals([]);
      }
    } else {
      setWithdrawals([]);
    }
  }, [widthdrawData]);

  useEffect(() => {
    getReasons();
  }, []);

  const clearData = () => {
    setWithdrawalId([]);
  };

  const selectWithdraw = (Id: any) => {
    setHelper(Id);
    setOpenOne("confirmBox");
    if (openTwo === "withdrawBox" && withdrawalID.length > 0) {
      setLoader(true);
      const data = {
        ID: helper,
        withdrawal_uuid: withdrawalID,
      };
      request({
        url: `school-settings/withdrawl-student-application/?school_uuid=${schoolId}`,
        method: "post",
        data: data,
      }).then((res) => {
        setWithdrawalId([]);
        getList();
        setLoader(false);
        setError("");
        setOpenTwo("");
        refetch();
      });
    } else if (openTwo === "withdrawBox" && withdrawalID.length === 0) {
      setError("Please choose at least one reason for withdrawal");
    }
  };

  const collectData = (ID: any, index: any) => {
    const temp = [...withdrawalID];
    if (temp.includes(ID)) {
      const val = temp.indexOf(ID);
      if (index > -1) {
        temp.splice(val, 1);
      }
    } else if (!temp.includes(ID)) {
      temp.push(ID);
    }
    setWithdrawalId(temp);
  };

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div className={classes.subContainer}>
        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{withdrawError}</p>
          </div>
        ) : null}
        <Box marginBottom="40px">
          <Box display="flex" alignItems="center">
            <Icon
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => history.push("/admin/offers")}
            >
              arrow_back
            </Icon>
            <p>Go Back</p>
          </Box>
          <Box textAlign="center">
            <p className={classes.textOne}>Withdraw Application Screen</p>
          </Box>
          {/* Search Box */}
          <Box
            display="flex"
            justifyContent="end"
            position="relative"
            bottom="75px"
          >
            <input
              onChange={(e: any) => searchTextChange(e.target.value)}
              type="text"
              placeholder="Find an applicant"
              className={classes.inputStyle}
              style={{borderBottom:'1px solid #000000'}}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                bottom: "7px",
              }}
            >
              <Icon className={classes.iconStyle}>search</Icon>
            </div>
          </Box>
          {!withdrawals && (
            <Box textAlign="center">
              <p className={classes.secondTitle}>
                Click on respective withdraw button to withdraw application
              </p>
            </Box>
          )}
        </Box>
        <WithdrawTable data={withdrawals} onWithdraw={selectWithdraw} />
        {withdrawals.length !== 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: "50px",
              marginTop: "20px",
            }}
          >
            <div>{`${pageNumber || 0} of ${totalPages || 0}`}</div>
            <div>
              <button
                onClick={() => setPageNumber(pageNumber - 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === 1 || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_left_outlined
                </Icon>
              </button>
              <button
                onClick={() => setPageNumber(pageNumber + 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === totalPages || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_right_outlined
                </Icon>
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <Loader open={loader} />
      <WithdrawPopup
        openOne={openOne}
        openTwo={openTwo}
        setOpenOne={setOpenOne}
        setOpenTwo={setOpenTwo}
        messageConfirm={`Are you sure you want to withdraw application ID ${helper} ?`}
        messageWithdraw={`Choose reason for application ID ${helper} withdrawal`}
        onWithdraw={selectWithdraw}
        reasons={reasons}
        collectData={collectData}
        error={error}
        setError={setError}
        clearReason={clearData}
      />
    </Grid>
  );
};

export default WithDrawApplication;
