import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./anti-bullying.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { InputDatePicker } from "../../../../components/common/date-picker";
import { InputRadio } from "../../../../components/common/input-radio/index";
import { Type } from "../../../../utils/document-types";
import Image from "../../image-checkbox/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface consentType {
  gur_consent: boolean;
  stud_consent: boolean;
  sign_check: boolean;
}

const AntiBullying = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [consentDate, setConsentDate] = useState(new Date());
  const parentID = ParentUser.uuid;
  const schoolID = localStorage.getItem("school-id");
  const [message, setMessage] = useState<string>("");
  const [consents, setConsensts] = useState({});
  const [consent, setConsent] = useState<consentType>({
    gur_consent: false,
    stud_consent: false,
    sign_check: false,
  });
  const [error, setError] = useState({
    gur_consent_err: false,
    // stud_consent_err: false,
    sign_check_err: false,
  });
  const [localData, setLocalData] = useState({
    signImgUrl: "",
    document_uuid: "",
  });

  const handleChangeRadio = (type: string) => {
    if (type === "gur_consent") {
      setConsent({ ...consent, gur_consent: !consent.gur_consent });
    }
    if (type === "stud_consent") {
      setConsent({ ...consent, stud_consent: !consent.stud_consent });
    }
    consent.gur_consent
      ? setError({ ...error, gur_consent_err: true })
      : setError({ ...error, gur_consent_err: false });
  };

  const getStudentConsents = () => {
    request({
      url: `student-application/student-application-consent/?student_app_uuid=${props.stdAppId}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLoader(false);
        setConsensts({ ...response.data.results });
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    getStudentConsents();
    request({
      url: `auth/application-server-date/`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setConsentDate(new Date(res.data.results));
    });
    setLoader(true);
    request({
      url: `/auth/parent-documents-upload-credentials?user_uuid=${parentID}&document_type=${Type.signature}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setLocalData({
        ...localData,
        signImgUrl: res.data.results.document_url,
        document_uuid: res.data.results.document_uuid,
      });
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-school-application-consent/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setMessage(res.data.results.anti_bullying_parent_consent);
      setLoader(false);
    });
  }, []);

  const apiCall = () => {
    setLoader(true);
    const data = {
      ...consents,
      anti_bullying_parent_consent: true,
    };
    request({
      url: `student-application/student-application-consent/?student_app_uuid=${props.stdAppId}`,
      method: "put",
      data: data,
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLoader(false);
        nextPage();
      } else {
        setLoader(false);
      }
    });
  };

  const validate = () => {
    return {
      gur_consent_err: consent.gur_consent === false,
      sign_check_err: consent.sign_check === false,
    };
  };

  const onNextSubmit = () => {
    const err = validate();
    setError(err);
    if (!err.gur_consent_err && !err.sign_check_err) {
      apiCall();
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Enrolment</div>
                </div>
                <ProgressBar total={7} steps={7} />
              </Box>
              <div style={{ marginBottom: 30 }}>
                <Welcome title="Anti-Bullying Policy" />
              </div>
              <Grid container direction="column">
                <Box mb="20px">
                  <p className={styles.title}>Guardian confirmation</p>
                  <p className={styles.description}>{message}</p>
                  <Box mt="20px">
                    <Box>
                      {localData.signImgUrl ? (
                        <Image
                          src={localData.signImgUrl}
                          check={consent.sign_check}
                          change={() => {
                            setConsent({
                              ...consent,
                              sign_check: !consent.sign_check,
                            });
                            consent.sign_check
                              ? setError({ ...error, sign_check_err: true })
                              : setError({ ...error, sign_check_err: false });
                          }}
                          label="Add your signature"
                          error={error.sign_check_err}
                          errorMessage="Signature confirmation is required"
                        />
                      ) : null}
                    </Box>
                    <InputDatePicker
                      label="Date signed"
                      disabled
                      value={consentDate}
                    />
                    <InputRadio
                      radioHolderOne="I consent"
                      check={consent.gur_consent}
                      errorMessage="Your consent is required"
                      onChange={() => handleChangeRadio("gur_consent")}
                      error={error.gur_consent_err}
                    />
                  </Box>
                </Box>
                {/* <Box mb="20px">
                  <p className={styles.title}>Student/Child confirmation</p>
                  <p className={styles.description}>
                    I accept that any breach of the Anti-Bullying Policy is a
                    serious offence. I confrim that I have read the
                    Anti-Bullying Policy and that I have discussed it with my
                    guardian.
                  </p>
                  <Box mt="20px">
                    <InputDatePicker
                      label="Date signed"
                      disabled
                      value={consentDate}
                    />
                    <InputRadio
                      radioHolderOne="I consent"
                      check={consent.stud_consent}
                      onChange={() => handleChangeRadio("stud_consent")}
                      error={error.stud_consent_err}
                    />
                  </Box>
                </Box> */}
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Submit"
                      light
                      endIcon="navigate_next"
                      onClick={onNextSubmit}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default AntiBullying;
