import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  inputRoot: {
    width: "calc(100% - 26px)",
    fontFamily: `"LFT Etica", sans-serif`,
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    overflow: "auto",
    outline: "none",
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.common.black,
    },
  },
  error: {
    width: "calc(100% - 26px)",
    textTransform: "none",
    fontFamily: `"LFT Etica", sans-serif`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "15px 12px",
    overflow: "auto",
    outline: "none",
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  label: {
    marginBottom: 6.5,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  radiobtn: {
    borderColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    "&.Mui-checked": {
      color: theme.palette.grey[700],
    },
    "&.Mui-checked:hover ": {
      boxShadow: "none",
      background: theme.palette.grey[100],
    },
    "&:hover ": {
      boxShadow: "none",
      background: theme.palette.grey[100],
    },
  },
  radioError: {
    color: "red",
  },
}));
