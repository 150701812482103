import { makeStyles } from "@material-ui/core";


export const useStyle = makeStyles((theme) => ({
  root:{
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  title:{
    marginTop:10
  },
  dialogActions: {
    margin:"0 auto",
    paddingBottom:"15px"
  },
}));
