import { useStyle } from "./withdraw-popup.style";
import { AdminButton } from "../../../../../components/common/admin-button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { StylesContext } from "@material-ui/styles";

type WithdrawPopupProps = {
  messageConfirm?: string;
  messageWithdraw?: string;
  openOne: string;
  openTwo: string;
  setOpenOne: any;
  setOpenTwo: any;
  onWithdraw: any;
  reasons?: any;
  collectData?: any;
  error?: string;
  setError?: any;
  clearReason?: any;
};

export const WithdrawPopup: React.FC<WithdrawPopupProps> = ({
  messageConfirm,
  openOne = "",
  openTwo = "",
  setOpenOne,
  setOpenTwo,
  messageWithdraw,
  onWithdraw,
  reasons,
  collectData,
  error = "",
  setError,
  clearReason,
}) => {
  const classes = useStyle();
  const handleCloseOne = () => {
    setOpenOne("");
  };
  const handleCloseTwo = () => {
    setOpenTwo("");
    setError("");
    clearReason();
  };
  return (
    <div>
      <>
        <Dialog
          open={openOne === "confirmBox"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{messageConfirm}</DialogTitle>
          <DialogActions className={classes.dialogActions}>
            <div style={{ width: "100px" }}>
              <AdminButton onClick={handleCloseOne} label="Cancel" light />
            </div>
            <div style={{ width: "100px" }}>
              <AdminButton
                onClick={() => {
                  setOpenTwo("withdrawBox");
                  handleCloseOne();
                }}
                label="Confirm"
              />
            </div>
          </DialogActions>
        </Dialog>
      </>
      <>
        <Dialog
          open={openTwo === "withdrawBox"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{messageWithdraw}</DialogTitle>
          <Box marginBottom="20px">
            {reasons?.map((item: any, index: number) => (
              <Box
                key={index}
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <>
                  <Checkbox
                    color="default"
                    onClick={() => collectData(item.withdrawl_uuid, index)}
                  />
                  <p style={{ marginLeft: "15px" }}>{item.withdrawl_text}</p>
                </>
              </Box>
            ))}
          </Box>
          <Box textAlign="center">
            <p className={classes.error}>{error}</p>
          </Box>
          <DialogActions className={classes.dialogActions}>
            <div
              style={{
                width: "100px",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <AdminButton onClick={handleCloseTwo} label="Cancel" light />
            </div>
            <div style={{ width: "100px", marginBottom: "10px" }}>
              <AdminButton
                onClick={() => {
                  onWithdraw();
                  handleCloseOne();
                }}
                label="Withdraw"
              />
            </div>
          </DialogActions>
        </Dialog>
      </>
    </div>
  );
};
