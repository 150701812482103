import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./reset-password.style";
import { Footer } from "../../../components/common/footer/index";
import { Input } from "../../../components/common/input/index";
import request, { clearStorage, setToken } from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader/index";
import { useHistory, useParams } from "react-router";
import Logo from "../../../components/common/logo/index";
import { CheckNetwork } from "../../../utils/helper";
type Props = {};

const ResetPassword = (props: Props) => {
  const classes = useStyle();
  const history = useHistory();
  const { userId } = useParams<{ userId: string }>();
  const { hash } = useParams<{ hash: string }>();

  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [passMatched, setPassMatched] = useState<boolean>(true);
  const [requiredFields, setRequiredFields] = useState<string[]>([]);
  const [data, setData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    request({
      url: "/auth/parent-forgot-password-verification/",
      method: "post",
      data: {
        hashcode: hash,
        user_uuid: userId,
      },
    })
      .then((response) => {
        if (response.data.status_code === 1) {
          if (response.data.results.access_token) {
            setToken(response.data.results.access_token);
          }
        } else if (response.data.status_code === -1) {
          setError(response.data.status_message);
        }
      })
      .catch((e) => {
        setError(e);
      });
  }, []);

  const matchPasssword = () => {
    if (
      data.new_password.length !== 0 &&
      data.new_password !== data.confirm_password
    ) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (data.new_password.length === 0) {
      if (!temp.includes("confirm_password")) {
        temp.push("confirm_password");
      }
    } else if (data.new_password.length !== 0) {
      if (temp.includes("confirm_password")) {
        const val = temp.indexOf("confirm_password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };

  const test = () => {
    if (
      data.confirm_password.length !== 0 &&
      data.new_password !== data.confirm_password
    ) {
      setPassMatched(false);
    } else {
      setPassMatched(true);
    }
    const temp: any = [...requiredFields];
    if (data.new_password.length === 0) {
      if (!temp.includes("new_password")) {
        temp.push("new_password");
      }
    } else if (data.new_password.length !== 0) {
      if (temp.includes("new_password")) {
        const val = temp.indexOf("new_password");
        temp.splice(val, 1);
      }
    }
    setRequiredFields(temp);
  };

  const requiredFieldCheck = () => {
    const temp = [];
    if (data.new_password.length === 0) temp.push("new_password");
    if (data.confirm_password.length === 0) temp.push("confirm_password");
    setRequiredFields([...temp]);
    if (temp.length === 0) {
      apiCall();
    }
  };

  const apiCall = () => {
    if (
      userId &&
      data.new_password &&
      data.confirm_password &&
      data.new_password === data.confirm_password
    ) {
      setLoader(true);
      request({
        url: "/auth/change-password/",
        method: "post",
        data: {
          user_uuid: userId,
          password: data.new_password,
          confirm_password: data.confirm_password,
        },
      })
        .then((response) => {
          if (response.data.status_code === 1) {
            setError("");
            setMessage(response.data.status_message);
            history.push("/parent");
          } else if (response.data.status_code === -1) {
            setError(response.data.status_message);
          }
          setLoader(false);
        });
    }
  };
  const onSubmit = async () => {
    await requiredFieldCheck();
    clearStorage();
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <CheckNetwork/>
        <Logo />
      </Grid>
      <Grid container justifyContent="center">
        <div className={classes.card}>
          <div style={{ marginBottom: 34 }}>
            <Text heading>Reset password</Text>
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          <div style={{ marginBottom: 34 }}>
            <Text paragraph>Please enter your new password below</Text>
          </div>
          <Grid container direction="column">
            <Input
              label="New password"
              type="password"
              onBlur={test}
              value={data.new_password}
              onChange={(e: any) =>
                setData({ ...data, new_password: e.target.value })
              }
              error={requiredFields.includes("new_password")}
            />
            <Input
              label="Confirm new password"
              type="password"
              value={data.confirm_password}
              onBlur={matchPasssword}
              onChange={(e: any) =>
                setData({ ...data, confirm_password: e.target.value })
              }
              error={
                !passMatched || requiredFields.includes("confirm_password")
              }
              errorMessage={
                !passMatched ? "Doesn't match with Password" : "Required"
              }
            />
          </Grid>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={classes.buttonWidth}>
              <Button label="Submit" onClick={onSubmit} />
            </div>
          </Grid>
          <p style={{ fontWeight: "bold" }}>{message}</p>
        </div>
      </Grid>
      <Grid container>
        <div style={{ width: "100vw" }}>
          <Footer center />
        </div>
      </Grid>
      <Loader open={loader} />
    </Grid>
  );
};

export default ResetPassword;
