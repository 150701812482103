import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { AdminButton } from "../../../../../components/common/admin-button/index";
import { Box } from "@material-ui/core";
import { useStyle } from "../../start-selection/selection-table/selection-table.style";
type Props = {
  data: any;
  // onCheck: any;
  singleSelect: any;
  disableEdit: boolean;
  manual: boolean;
  // sa: any;
};

const LotteryDataTable: React.FC<Props> = ({
  data,
  // onCheck,
  singleSelect,
  disableEdit,
  manual,
  // sa
}) => {
  const classes = useStyle();
  const columns: GridColumns = [
    // {
    //   field: "",
    //   headerName: "",
    //   minWidth: 100,
    //   sortable: false,
    //   renderCell: (cellValues: any) => {
    //     return (
    //       <Checkbox
    //         checked={sa.includes(cellValues.row.ID)}
    //         onChange={() => onCheck(cellValues.row.ID)}
    //         inputProps={{ "aria-label": "uncontrolled-checkbox" }}
    //       />
    //     );
    //   },
    // },
    {
      field: "id",
      headerName: "ID",
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lottery_number",
      headerName: "Lottery Number",
      minWidth: 400,
      sortable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "application_status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 400,
      sortable: false,
      renderCell: (params: any) => {
        return (
          <Box
            className={
              params.row.application_status === "Selected"
                ? classes.app_status
                : ""
            }
          >
            {params.row.application_status}
          </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      align: "center",
      minWidth: 400,
      hide: !manual,
      sortable: false,
      renderCell: (cellValues: any) => {
        return (
          <Box width="50%">
            <AdminButton
              label="Edit Status"
              endIcon="edit"
              light
              onClick={() => singleSelect(cellValues.row)}
              disabled={
                disableEdit
                  ? true && cellValues.row.application_status !== "Selected"
                  : false
              }
            />
          </Box>
        );
      },
    },
  ];

  const rows = data;

  return (
    <DataGrid
      autoHeight={true}
      className={classes.root}
      rows={rows}
      columns={columns}
      // disableSelectionOnClick={false}
      // disableColumnFilter={true}
      // disableColumnMenu={true}
      pageSize={50}
    />
  );
};

export default LotteryDataTable;
