import { useState } from "react";
import MaterialInput from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Icon from "@material-ui/core/Icon";
import { useStyle } from "./input.style";
import cn from "classnames";

type InputProps = {
  name?: string;
  id?: string;
  label?: string;
  value?: any;
  type?: string; //text or password
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string | null;
  grey?: boolean;
  onChange?:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  required?: boolean;
  onBlur?: any;
  maxValue?:number;
  minValue?:number;
  maxlength?: string | number;
  noMarginBottom?: boolean;
};

export const Input: React.FC<InputProps> = ({
  name,
  id,
  label,
  value,
  type = "text",
  placeholder,
  disabled,
  error = false,
  errorMessage = "Required",
  grey,
  onChange,
  onBlur,
  required = false,
  maxValue,
  minValue,
  maxlength,
  noMarginBottom,
}) => {
  const classes = useStyle();
  const [eyeToggle, setEyeToggle] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const eyeClickHandler = () => {
    eyeToggle ? setCurrentType(type) : setCurrentType("text");
    setEyeToggle(!eyeToggle);
  };
  return (
    <div className={cn(!noMarginBottom && classes.btmMargin)}>
      <InputLabel
        className={cn(classes.label, error ? classes.labelError : null)}
      >
        {label}
      </InputLabel>
      <MaterialInput
      inputProps={{"max":maxValue,"min":minValue,"maxLength":maxlength}}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        type={currentType}
        disableUnderline={true}
        error={error}
        className={cn(grey ? classes.greyColor : classes.whiteColor)}
        classes={{
          root: cn(classes.root),
          input: cn(classes.input),
          focused: cn(classes.focused),
          error: cn(classes.error),
        }}
        endAdornment={
          type === "password" && (
            <Icon className={classes.passwordIcon} onClick={eyeClickHandler}>
              {eyeToggle ? "visibility_off" : "visibility"}
            </Icon>
          )
        }
        onChange={onChange}
        onBlur={onBlur}
        required={required}
      />
      {error ? (
        <InputLabel className={classes.errorMessage}>{errorMessage}</InputLabel>
      ) : null}
    </div>
  );
};
