import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  root: {
    fontFamily: `"LFT Etica", sans-serif`,
    height: "100vh",
    // paddingTop: "10%",
  },
  content: {
    height: "65%",
    // paddingTop: "6%",
  },
  rightBlock: {
    height: "100%",
    padding: "30px 25px",
    overflow: "auto",
    backgroundColor: "#F9F8F7",
  },
  leftBlock: {
    height: "100%",
    padding: "25px 25px",
    overflow: "auto",
    backgroundColor: "#F9F8F7",
  },
  innerBlock: {
    height: "85%",
    paddingBottom: 20,
    overflow: "auto",
  },
  gap: {
    marginTop: 10,
  },
  lotteryNumberBlock: {
    // width: 80,
    height: 50,
    // cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  testStyle: {
    // backgroundColor: 'lightgreen',
    width: '20%',
    fontWeight: 600,
    fontSize: 16,
    paddingLeft: '13%'
  },
  firstBlock: {
    // backgroundColor: 'pink',
    width: '12%',
    paddingLeft: '5%',
    fontWeight: 600,
    fontSize: 16,
  },
  inputBlock: {
    // backgroundColor: 'lightblue',
    width: '35%',
    paddingLeft: '15%',
    fontWeight: 600,
    fontSize: 16,
  },
  testStyle2: {
    // backgroundColor: 'lightgreen',
    width: '20%',
    paddingLeft: '13%'
  },
  firstBlock2: {
    // backgroundColor: 'pink',
    width: '12%',
    paddingLeft: '5%',
  },
  inputBlock2: {
    // backgroundColor: 'lightblue',
    width: '35%',
    paddingLeft: '15%',
  },
  textOne: {
    fontWeight: 600,
    fontSize: 22,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  textTwo: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: `"LFT Etica", sans-serif`,
  },
  appIdHelper: {
    width: '30%'
  },
  selectedIcon: {
    color: theme.palette.success.main,
  },
  lotteryNumbers: {
    width: '50%',
  }
}));
