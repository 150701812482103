import { useStyle } from "../../../../components/common/delete-dialogbox/delete-dialog.style";
import { AdminButton } from "../../../../components/common/admin-button/index";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

type DeleteProps = {
  open: boolean;
  onClick: any;
  id: string;
  subId: string;
  index: number;
  subIndex: number;
  setOpen: any;
};

export const MedDeleteBox: React.FC<DeleteProps> = ({
  open = false,
  onClick,
  id,
  subId,
  subIndex,
  index,
  setOpen,
}) => {
  const classes = useStyle();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" Are you sure you want to delete this?"}
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <div style={{ width: "100px",marginRight:"10px" }}>
            <AdminButton onClick={handleClose} label="Cancel" light />
          </div>
          <div style={{ width: "100px" }}>
            <AdminButton
              onClick={() => {
                onClick(id, subId, index, subIndex);
                handleClose();
              }}
              label="Delete"
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
