import { Input } from "../../../../../components/common/input";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { useEffect, useState } from "react";
import request from "../../../../../utils/authUtil";
import { ConfirmBox } from "../../../../../components/common/confirm-box";

interface Props {
siblingInfoGetter: any;
siblingInfoSetter: any;
hasErrorSetter: any;
indexOfSibling: number;
showRemoveIcon?: boolean;
stdAppId: string;
isDisabled?: boolean;
setCheckToNo?: any;
noSiblingDataError?: any;
}

const StudentSiblingCard = (props: Props) => {
        const{
            siblingInfoGetter,
            siblingInfoSetter,
            stdAppId,
            hasErrorSetter,
            indexOfSibling,
            showRemoveIcon=true,
            isDisabled=false,
            setCheckToNo,
            noSiblingDataError,
        } = props;
        
    const [error, setError] = useState<any>({
        name_of_student: false,
        year_of_student: false,
        year_accept_error: false,
    });
    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);

useEffect(()=>{
    setError({
        year_of_student:siblingInfoGetter[indexOfSibling]?.year_of_student?.length === 0,
        year_accept_error:(siblingInfoGetter[indexOfSibling]?.year_of_student > 6 || siblingInfoGetter[indexOfSibling]?.year_of_student < 1),
        name_of_student: siblingInfoGetter[indexOfSibling]?.name_of_student.length === 0,
    })
    checkAndSetErrorsState();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])

const checkAndSetErrorsState = () => {
    let userState = siblingInfoGetter[indexOfSibling]
    if(
        
        userState?.year_of_student?.length === 0 ||
        userState?.year_of_student > 6 ||
        userState?.year_of_student < 1 ||
        userState?.name_of_student.length === 0
)
{
    hasErrorSetter((prev:any)=>({...prev,[indexOfSibling]:true}));
}
else{
    hasErrorSetter((prev:any)=>({...prev,[indexOfSibling]:false}));
}
}

const onValueChange = (e: any, inputType: string) => {
    if(inputType === "siblingName"){
        let newData = siblingInfoGetter.map((item:any,index:number)=>{
            if(index === indexOfSibling){
                return {
                    ...item,
                    name_of_student: e.target.value
                }
            }
            return item
        })
        siblingInfoSetter(newData);
        setError((error:any)=>({
            ...error,
            name_of_student: e.target.value?.trim().length === 0,
        })); 
    }
    if(inputType === "siblingYear"){
        let newData = siblingInfoGetter.map((item:any,index:number)=>{
            if(index === indexOfSibling){
                return {
                    ...item,
                    year_of_student: e.target.value
                }
            }
            return item
        })
        siblingInfoSetter(newData);
        setError((error:any)=>({
            ...error,
            year_of_student:e.target.value.length === 0,
            year_accept_error:(e.target.value > 6 || e.target.value < 1),
        }));

    }
}

const onBlurInput = ()=>{
    checkAndSetErrorsState();
}


const removeSiblingForStudent = async () => {
    await request({
    url: `student-application/student-sibling-details/?uuid=${stdAppId}`,
    method: "delete",
    data: {
    sibling_uuid:siblingInfoGetter[indexOfSibling]?.sibling_uuid,
    }
    });
    hasErrorSetter((prev:any)=>({...prev,[indexOfSibling]:false}));
};


const onRemoveCard = async()=>{
    if(siblingInfoGetter[indexOfSibling]?.sibling_uuid?.length > 0){
    removeSiblingForStudent()
    }
    let mutatedSiblingInfo = siblingInfoGetter.filter((_:any,index:number)=>{return index !== indexOfSibling});
    if(mutatedSiblingInfo?.length === 0 && setCheckToNo){
        setCheckToNo()
        noSiblingDataError()
    }
    siblingInfoSetter(mutatedSiblingInfo);
}

return (
    <>
    {!isDisabled && showRemoveIcon ?<Box width="100%" display="flex" justifyContent="space-between" alignItems="center" mb="8px">
        <h4>Sibling {indexOfSibling+1}</h4>
        <Icon style={{cursor: "pointer",
        color: "#757575"}}
        onClick={()=>setIsPopUpOpen(true)}
        >
            delete
        </Icon>
    </Box>
    :
    null}
    <Box>
        <Input
            label="Sibling Name*"
            value={siblingInfoGetter[indexOfSibling]?.name_of_student}
            onChange={(e)=>onValueChange(e, "siblingName")}
            disabled={isDisabled}
            error={error?.name_of_student}
            onBlur={()=>onBlurInput()}
        />
        <Input
            label="Sibling Year*"
            type="number"
            disabled={isDisabled}
            value={siblingInfoGetter[indexOfSibling]?.year_of_student}
            onChange={(e)=>onValueChange(e, "siblingYear")}
            error={error?.year_of_student || error?.year_accept_error}
            errorMessage={
            error?.year_of_student
                ? "Required"
                : "Year can be from 1 to 6"
            }
            maxValue={6}
            minValue={1}
            onBlur={()=>onBlurInput()}
        />
    </Box>
    <ConfirmBox
    open={isPopUpOpen}
    setOpen={setIsPopUpOpen}
    onClick={onRemoveCard}
    message="Are you sure you want to delete this sibling's data ?"
    handleCancel={()=>{}}
    />
    </>
)
}


export default StudentSiblingCard;