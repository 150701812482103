import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

export const ImageLoader = () => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};
