import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useCallback, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import request from "../../../utils/authUtil";
import request2 from "../../../utils/authUtilv2";
import request3 from "../../../utils/authUtilv3";
import { useStyle } from "../applications/applications-admin.style";
import { useHistory } from "react-router-dom";
import { AdminButton } from "../../../components/common/admin-button";
import { ConfirmBox } from "../../../components/common/confirm-box";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
} from "@material-ui/core";
import Filter from "../offers/filter";
import Tooltip from "@material-ui/core/Tooltip";

interface EnrollmentData {
  ID: any;
  student_app_uuid: string;
  Student_Name: string;
  Email: string;
  Guardian_1: string;
  application_status: string;
  enrolment_status: string;
  enrolment_reviewed_status: string;
  enrolment_submission_date: string;
}
const Enrollments: React.FC = () => {
  const classes = useStyle();
  const schoolId = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const [applications, setApplications] = useState<EnrollmentData[]>();
  const [sortTag, setSortTag] = useState<string>("");
  const [isAsc, setIsAsc] = useState<boolean | string>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [status, setStatus] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [totalApplicants, setTotalApplicants] = useState<number>(0);
  const [IsReminderEmailPopupOpen, setIsReminderEmailPopupOpen] =
    useState<boolean>(false);
  const [isSendEmailtoSubmittedPopupOpen, setIsSendEmailtoSubmittedPopupOpen] =
    useState<boolean>(false);
  const [totalFilteredApplicants, setTotalFilteredApplicants] =
    useState<number>(0);
  const [error, setError] = useState<string>("");
  const [applicationId, setApplicationId] = useState<string>("");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  // const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const getEnrollment = async (): Promise<any> => {
    const res = await request({
      url: `school-settings/enrolment-list/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}&page=${pageNumber}&search_text=${searchText}&sorting_var=${sortTag}&is_asc=${isAsc}&status=${currentStatus}`,
      method: "get",
    });
    return res;
  };

  const getApplication = useCallback(async () => {
    const res = await request({
      url: `/school-settings/school-application/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    });
    if (res.data.status_code === 1) {
      if (res.data.results.length !== 0) {
        setApplicationId(res.data.results[0].application_uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getApplication();
    let filterString: any = localStorage?.getItem("filterState")
    let enrolmentFilter = JSON?.parse(filterString)?.enrolment

    if (enrolmentFilter) {
      setCurrentStatus((prev) => enrolmentFilter)
    }
  }, [getApplication]);

  const bulkDownload = useCallback(async () => {
    if (applicationId !== "") {
      setLoader(true);
      await request2({
        url: `/school-settings/enrolment-pdf-bulk-download/?application_uuid=${applicationId}`,
        method: "get",
      }).then((response) => {
        setLoader(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  const {
    isLoading,
    isError,
    error: getTemplateError,
    data: tableData,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    [
      "enrolmentsList",
      {
        pageNumber,
        searchText,
        isAsc,
        sortTag,
        selectedGlobalDate,
        currentStatus,
      },
    ],
    () => getEnrollment(),
    {
      select: (enrolments) => enrolments.data.results,
    }
  );

  const exportCsv = async () => {
    setLoader(true);
    await request({
      url: `/school-settings/export-csv-enrolment-data/?year_uuid=${selectedGlobalDate}&status=${currentStatus}`,
      method: "get",
    }).then((res) => setLoader(false));
  };

  const sendReminder = async () => {
    setLoader(true);
    await request2({
      url: `/school-settings/reminder-email-complete-enrolment/`,
      method: "get",
    }).then((res) => setLoader(false));
  };

  useEffect(() => {
    if (tableData) {
      if (Object.keys(tableData).length !== 0) {
        setApplications(tableData?.applicants_list);
        setTotalApplicants(tableData?.total_no_of_applicants);
        setTotalFilteredApplicants(tableData.total_no_of_filters_applicants);
        setError("");
        setTotalPages(tableData.total_pages);
        setPageNumber(tableData.current_page);
      } else {
        setError("No data exist");
        setApplications([]);
        setTotalFilteredApplicants(0);
      }
    }
  }, [tableData]);

  const getOfferStatus = useCallback((): any => {
    setLoader(true);
    request2({
      url: `/school-settings/enrolment-status-list/`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setStatus([
          {
            name: "All",
            value: "",
          },
          ...res.data?.results.map((item: any) => {
            return {
              name: item.key,
              value: item.value,
            };
          }),
        ]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const onStatusChange = (item: any) => {
    let filterString: any = localStorage?.getItem("filterState")
    let filter = JSON?.parse(filterString)
    localStorage.setItem("filterState", JSON.stringify({ ...filter, "enrolment": item.value }));
    setCurrentStatus(item.value);
  };

  useEffect(() => {
    getOfferStatus();
  }, [getOfferStatus]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  const columns = [
    { id: "ID", label: "ID", minWidth: 100, isSort: true },
    { id: "Student_Name", label: "Student Name", minWidth: 100, isSort: true },
    {
      id: "Guardian",
      label: "Guardian 1",
      minWidth: 100,
      isSort: true,
    },
    { id: "Email", label: "Email", minWidth: 100, isSort: true },
    { id: "Enrolment_Status", label: "Enrolment Status", minWidth: 100 },
    {
      id: "Submission_Date",
      label: "Submission Date",
      minWidth: 100,
      align: "right",
    },
    {
      id: "Reviewed",
      label: " ",
      minWidth: 30,
      align: "right",
    },
    {
      id: "Edit",
      label: " ",
      minWidth: 100,
      align: "right",
    },
  ];

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  const firstLastPageHandler = (btnClicked: string) => {
    if (btnClicked === "first") {
      setPageNumber(1);
    } else {
      setPageNumber(totalPages);
    }
  };

  const sendEmailtoSubmittedApplicants = async () => {
    setLoader(true);
    await request3({
      url: `/school-settings/send-enrolment-submitted-emails/?application_uuid=${applicationId}`,
      method: "get",
    });
    setLoader(false);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Box mb="40px">
        <div>
          <Loader open={loader} />
        </div>

        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{getTemplateError}</p>
          </div>
        ) : null}
        <div style={{ margin: "-85px 0px 50px 0px" }}>
          <>
            <Box mt="90px">
              <Box className={classes.heading}>Enrolments</Box>
            </Box>
            <Box className={classes.boxStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="block">
                  <p>Your applicants</p>
                  <p>
                    You have received <strong>{totalApplicants}</strong>
                    {`${" applications in total."}`}
                  </p>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  borderBottom="1px solid #000000"
                >
                  <input
                    onChange={(e: any) => searchTextChange(e.target.value)}
                    type="text"
                    placeholder="Find an applicant"
                    className={classes.inputStyle}
                  />
                  <div>
                    <Icon className={classes.iconStyle}>search</Icon>
                  </div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="center"
                gridGap={24}
              >
                <Box display="flex">
                  <AdminButton
                    onClick={exportCsv}
                    label="Export Excel"
                    disabled={applications?.length === 0}
                  />
                </Box>
                <Box display="flex">
                  <AdminButton
                    onClick={() => setIsSendEmailtoSubmittedPopupOpen(true)}
                    label="Email to submitted"
                    disabled={applications?.length === 0}
                  />
                </Box>
                <Box display="flex">
                  <AdminButton
                    onClick={() => {
                      setIsReminderEmailPopupOpen(true);
                    }}
                    label="Send Reminder to Enrol"
                    disabled={applications?.length === 0}
                  />
                </Box>
                <Box display="flex">
                  <AdminButton
                    onClick={bulkDownload}
                    label="Download All (PDF)"
                    disabled={
                      applications?.filter(
                        (applicant) =>
                          applicant.enrolment_status === "Submitted"
                      ).length === 0
                    }
                  />
                </Box>
                <Box
                  marginTop="20px"
                  display="flex"
                  alignItems="center"
                  gridGap={10}
                >
                  {status && (
                    <Filter
                      options={status}
                      checkboxClick={onStatusChange}
                      label="Enrolment Status"
                      defaultVal={currentStatus}
                    />
                  )}
                  <span>
                    Total filtered applicants :{" "}
                    <strong>{`${totalFilteredApplicants}`}</strong>
                  </span>
                </Box>
              </Box>
            </Box>
            <Paper
              style={{
                width: "94%",
                overflow: "hidden",
                marginTop: "20px",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={classes.root}
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                          >
                            {column.label}
                            {column.isSort && (
                              <>
                                <Box marginLeft="15px">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc(true);
                                    }}
                                  >
                                    arrow_upward_icon
                                  </Icon>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc("");
                                    }}
                                  >
                                    arrow_downward_icon
                                  </Icon>
                                </Box>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {applications &&
                      applications.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <>
                              <TableCell className={classes.root}>
                                {item.ID}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Student_Name}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Guardian_1}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Email}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.enrolment_status}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.enrolment_submission_date}
                              </TableCell>
                              <TableCell>
                                {item.enrolment_reviewed_status !==
                                  "NOT_REVIEWED" && (
                                    <Tooltip
                                      title="Application Reviewed"
                                      interactive
                                      classes={{ tooltip: classes.tooltip_font }}
                                    >
                                      <Icon
                                        style={{
                                          display: "block",
                                          color: "#0B8A00",
                                        }}
                                      >
                                        check_circle
                                      </Icon>
                                    </Tooltip>
                                  )}
                              </TableCell>
                              <TableCell className={classes.root}>
                                <span
                                  onClick={() =>
                                    history.push(
                                      `/admin/enrolment/${item.student_app_uuid}`
                                    )
                                  }
                                  style={{
                                    width: 80,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    style={{
                                      color: "#757575",
                                      marginRight: 10,
                                    }}
                                  >
                                    remove_red_eye_icon
                                  </Icon>
                                  VIEW
                                </span>
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {error === "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "20px 50px",
                  }}
                >
                  <div style={{ padding: "0px 12px" }}>{`${pageNumber || 0
                    } of ${totalPages || 0}`}</div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("first")}
                  >
                    First
                  </button>
                  <div style={{ padding: "0px 12px" }}>
                    <button
                      onClick={() => setPageNumber(pageNumber - 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === 1 || pageNumber === 0}
                    >
                      <Icon>chevron_left_outlined</Icon>
                    </button>
                    <button
                      onClick={() => setPageNumber(pageNumber + 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === totalPages || pageNumber === 0}
                    >
                      <Icon>chevron_right_outlined</Icon>
                    </button>
                  </div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("last")}
                  >
                    Last
                  </button>
                </div>
              ) : null}
              {error !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  {error}
                </div>
              ) : null}
            </Paper>
          </>
          {/* *Table Code End* */}
        </div>
      </Box>
      <ConfirmBox
        message="Are you sure you want to send an email to all submitted applicants?"
        arg={false}
        open={IsReminderEmailPopupOpen}
        setOpen={setIsReminderEmailPopupOpen}
        onClick={sendReminder}
      />
      <ConfirmBox
        message="Are you sure you want to send an email to all submitted applicants?"
        arg={false}
        open={isSendEmailtoSubmittedPopupOpen}
        setOpen={setIsSendEmailtoSubmittedPopupOpen}
        onClick={sendEmailtoSubmittedApplicants}
      />
    </Grid>
  );
};

export default Enrollments;
