import { useEffect, useState } from "react";
import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import { Button } from "../../../components/common/button/index";
import Header from "../header/index";
import { useStyle } from "./cancel.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { SecondaryButton } from "../../../components/common/secondary-button/index";
import request from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import CheckNetwork from "../../../utils/helper";
import { MessageBox } from "../../../components/common/message-Box/index";

const Cancel = () => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [messageBox, setMessageBox] = useState<boolean>(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState<boolean>(false);
  const stdAppId = localStorage.getItem("currentStudentAppID");
  const schoolName = localStorage.getItem("name");
  const history = useHistory();
  const onCancel = () => {
    setLoader(true);
    request({
      url: `student-application/application-status-change/?student_app_uuid=${stdAppId}`,
      method: "put",
      data: {
        application_status: "CANCELLED",
      },
    }).then((response) => {
      setLoader(false);
      setMessageBox(true);
      setRedirectToDashboard(true);
    });
  };
  useEffect(() => {
    if (messageBox === false && redirectToDashboard) {
      history.push(`/parent/dashboard`);
    }
  }, [messageBox]);
  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <CheckNetwork />
      <Header />
      <div className={styles.bodyContainer}>
        <p>
          <Link
            to="/parent/review-page"
            style={{ textDecoration: "none", color: "none" }}
          >
            <Icon style={{ cursor: "pointer" }}>arrow_back</Icon>
          </Link>
        </p>
        <Box mt="30px">
          <Welcome
            title="Cancel Application"
            description="Are you sure you wish to cancel your application?"
          />
        </Box>
        <Grid container>
          <div className={styles.card}>
            <Box marginBottom="48%">
              <b>Confirm your cancellation</b>
              <p>Please confirm that you wish to cancel your application for a place in {schoolName}, once you confirmed you will not be able to re-submit your application.</p>
            </Box>
            {/* cancel button */}

            <div className={styles.buttonWidth}>
              <SecondaryButton
                light
                label="Cancel Application"
                startIcon="delete_forever"
                onClick={onCancel}
              />
            </div>
          </div>
        </Grid>
        <Box display="flex" justifyContent="center" mt="30px">
          <Link to="/parent/review-page" className={styles.backButton}>
            <Button label="Back" startIcon="navigate_before" />
          </Link>
        </Box>
        {/* part5 */}
        <Box className={styles.footerContainer}>
          <Footer center />
        </Box>
      </div>
      <MessageBox
        message="The application is successfully cancelled."
        open={messageBox}
        setOpen={setMessageBox}
      />
    </div>
  );
};

export default Cancel;
