/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./student-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Upload } from "../../../../components/common/upload/index";
import { Box } from "@material-ui/core";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Type } from "../../../../utils/document-types";
import request, { ParentUser } from "../../../../utils/authUtil";
import axios from "axios";
import { Loader } from "../../../../components/common/loader/index";
import { DownLoad } from "../../../../components/common/download";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId?: string;
};

const StudentBirthCertificate = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [fileUrl, setFileUrl] = useState<string>();
  const [birthCertificateNotice, setBirthCertificateNotice] = useState<string>();
  const [studBirthDoc, setStudBirthDoc] = useState({
    isUploaded: false,
    document_name_onChange: "",
    document_name_uploaded: "",
    document_type: "",
    document_presingne_url: "",
    document_uuid: "",
    document_url: "",
  });

  const [validFields, setValidFields] = useState({
    isFile: false,
    isAccept: "",
  });
  const [error, setError] = useState<any>({
    birth_certificate: false,
    accept_certificate: false,
  });
  const parentID = ParentUser.uuid;
  const schoolID = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);

  
const getStudentData = async (stdAppId: string | any): Promise<any> => {
  const res = await request({
    url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
    method: "get",
  })
  return res;
};

  useEffect(() => {
    getStudentData(stdAppId).then((res) => {
      console.log("res",res)
      if(res.data.status_code === 1){
        setBirthCertificateNotice(res.data.results?.birth_certificate_description)
      }
    }).catch((err)=>{
      console.log("error",err)
    })
  },[])

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any
  ) => {
    if (fileName) {
      if (!fileName.toLowerCase().match(/\.(jpg|jpeg|png|pdf)$/)) {
        setValidFields({
          ...validFields,
          isFile: false,
          isAccept: "acceptError",
        });
        setError({
          ...error,
          birth_certificate: false,
          accept_certificate: true,
        });
        return null;
      } else {
        setLoader(true);
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const getStudBirthCertificate = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=BIRTHCERTIFICATE&document_for=APPLICATION`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data.status_code === 1 && res.data.results.length !== 0) {
        setStudBirthDoc({
          ...studBirthDoc,
          isUploaded: true,
          document_name_uploaded: res.data.results[0].document_name,
          document_type: res.data.results[0].document_type,
          document_uuid: res.data.results[0].document_uuid,
          document_url: res.data.results[0].document_url,
          document_presingne_url: res.data.results[0].document_presingne_url,
        });
      } else {
        setStudBirthDoc({
          ...studBirthDoc,
          isUploaded: false,
          document_name_onChange: "",
          document_name_uploaded: "",
          document_type: "",
          document_presingne_url: "",
          document_uuid: "",
          document_url: "",
        });
      }
    });
  };

  useEffect(() => {
    getStudBirthCertificate();
  }, []);

  const change = (event: any) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.birthCertificate,
        event.target?.files[0]?.name
      ).then((response) => {
        setLoader(false);
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setFileUrl(`${response.url}${response.fields.key}`);
          setStudBirthDoc({
            ...studBirthDoc,
            document_name_onChange: event.target.files[0].name,
          });
          setValidFields({
            ...validFields,
            isFile: true,
            isAccept: "",
          });
          setError({
            ...error,
            birth_certificate: false,
            accept_certificate: false,
          });
          setLoader(false);
        }
      );
    });
  };
  


  const validate = () => {
    return {
      birth_certificate: validFields.isFile === false,
      accept_certificate: validFields.isAccept === "acceptError",
    };
  };

  const onSubmitError = async () => {
    const err = validate();
    setError(err);
    if (err.birth_certificate === false && err.accept_certificate === false) {
      setLoader(false);
      await request({
        url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_for=APPLICATION&document_uuid=${studBirthDoc.document_uuid}`,
        method: "put",
        data: {
          document_type: Type.birthCertificate,
          document_url: fileUrl,
          document_for: "APPLICATION",
        },
      }).then(() => {
        nextPage();
      });
    } else if (studBirthDoc.isUploaded === true) {
      nextPage();
    }
  };

  const onSubmitHandler = () => {
    onSubmitError();
  };

  const deleteDocument = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_uuid=${studBirthDoc.document_uuid}&document_for=APPLICATION`,
      method: "delete",
    }).then(() => {
      setLoader(false);
      getStudBirthCertificate();
    });
  };

  const setInitialState = () => {
    setStudBirthDoc({
      ...studBirthDoc,
      document_name_onChange: "",
      isUploaded:false
    });
    setValidFields({
      isFile: false,
      isAccept: "",
    });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Application</div>
                </div>
                <ProgressBar total={6} steps={1} />
              </Box>
              <div style={{ marginBottom: 30 }}>
                <Welcome
                  title="Student/Child details"
                  description="Next: Guardian details"
                />
              </div>
                  {
                    birthCertificateNotice ? (<>
                    <p>{birthCertificateNotice}</p>
                    <br/>
                    </>) : null
                  }
              <Grid container direction="column">
                <Box>
                  {studBirthDoc.isUploaded === true ? (
                    <>
                      <DownLoad
                        id="student-birth-certificate_download"
                        label="Download Birth Certificate  *"
                        file={studBirthDoc.document_name_uploaded}
                        onDelete={deleteDocument}
                        downloadLink={studBirthDoc.document_presingne_url}
                      />
                      <Box className={styles.text}>
                        <p>Click on delete icon to reupload document</p>
                      </Box>
                    </>
                  ) : (
                    <Upload
                      id="student-birth-certificate"
                      label="Please upload a copy of your student's/child's Birth Certificate  *"
                      placeholder="Upload Birth Cert"
                      onChange={(e: any) => change(e)}
                      isFile={validFields.isFile}
                      error={
                        error.birth_certificate || error.accept_certificate
                      }
                      file={studBirthDoc.document_name_onChange}
                      errorMessage={
                        validFields.isAccept === "acceptError"
                          ? "Only  files .png .jpg .jpeg and pdf are allowed"
                          : "Is required"
                      }
                      onDelete={() => setInitialState()}
                    />
                  )}
                </Box>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSubmitHandler}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Loader open={loader} />
        </div>
      </div>
    </>
  );
};

export default StudentBirthCertificate;
