
import { Box } from "@material-ui/core";
import { useStyle } from "./header.style";
import { DropDown } from "./header-dropdown/index";
import Logo from "../../../components/common/logo/index";
type Props = {};

const Header = (props: Props) => {
  const styles = useStyle();
  return (
    <div className={styles.container}>
      {/* part1 */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mr="22px"
        ml="22px"
      >
        <Box mt="9px" mr="40px">
          <Logo logoRedirect="DASHBOARD"/>
        </Box>
        <DropDown />
      </Box>
    </div>
  );
};
export default Header;
