import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./waiting-lottery.style";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import cn from "classnames";
import { Input } from "../../../../components/common/input";
import { AdminButton } from "../../../../components/common/admin-button";
import {
  endLotteryProcess,
  getWaitingList,
  saveWaitingList,
  exportCsv,
} from "./apis";
import { Loader } from "../../../../components/common/loader";
import { useHistory } from "react-router-dom";

const DEFAULT_RANK = [
  {
    waiting_list_lottery_number: "",
    wait_list_number: 1,
  },
];
const DEFAULT_LOTTERY = [
  {
    waiting_list_lottery_number: "",
    is_waiting_num_assigned: false,
    application_id: '',
  },
];
const WaitingLottery = () => {
  const classes = useStyle();
  const history = useHistory();
  const [rank, setRank] = useState<any>(DEFAULT_RANK);
  const [prevState, setPrevState] = useState<any>();
  const [currentCount, setCurrentCount] = useState<number | undefined>(1);
  const [lotteryNumbers, setLotteryNumbers] = useState(DEFAULT_LOTTERY);
  const [lastSavedWaitingList, setLastSavedWaitingList] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  useEffect(() => {
    setLoader(true);
    // getLotteryNumbers().then((res) => {
    //   setLotteryNumbers(res.data.results.waiting_lottery_list);
    // });
    // getWaitingListData().then((res) => {
    //   if (res.data.results.waiting_lottery_list.length) {
    //     setRank(res.data.results.waiting_lottery_list);
    //     setPrevState(res.data.results.waiting_lottery_list);
    //   }
    //   if (res.data.results.waiting_lottery_list.length) {
    //     setCurrentCount(res.data.results.waiting_lottery_list.length);
    //     setLastSavedWaitingList(res.data.results.waiting_lottery_list.length);
    //   } else {
    //     setCurrentCount(1);
    //   }
    // });
    getWaitingList().then((res) => {
      if (res.data.status_code === 1) {
        setLotteryNumbers(res.data.results.waiting_lottery_list);
        if (res.data.results.waiting_lottery_no_list.length) {
          setRank(res.data.results.waiting_lottery_no_list);
          setCurrentCount(res.data.results.waiting_lottery_no_list.length);
          setLastSavedWaitingList(
            res.data.results.waiting_lottery_no_list.length
          );
        }
      }
      setLoader(false);
    });
  }, []);

  // useEffect(() => {
  //   if (lotteryNumbers.length && currentCount) {
  //     setLoader(false);
  //   }
  // }, [lotteryNumbers, currentCount]);

  const addWaitingList = () => {
    setRank([
      ...rank,
      {
        wait_list_number: currentCount && String(currentCount + 1),
        waiting_list_lottery_number: "",
      },
    ]);
    currentCount && setCurrentCount(currentCount + 1);
  };

  const inputChangeHandler = (value: string, place: number) => {
    const temp = rank;
    const res = temp.map((ele: any) => {
      if (ele.wait_list_number === place) {
        return {
          wait_list_number: ele.wait_list_number,
          waiting_list_lottery_number: value,
        };
      }
      return ele;
    });
    setRank(res);
  };

  const onSaveEdit = (waiting_number: number) => {
    setLoader(true);
    const value = rank.filter(
      (item: any) => item.wait_list_number === waiting_number
    );
    saveWaitingList({
      waiting_list_lottery_no: Number(value[0].waiting_list_lottery_number),
      wait_list_number: waiting_number,
    }).then((res) => {
      if (res.data.status_code === 1) {
        setLotteryNumbers(res.data.results.waiting_lottery_list);
        setRank(res.data.results.waiting_lottery_no_list);
        setCurrentCount(res.data.results.waiting_lottery_no_list.length);
        setLastSavedWaitingList(
          res.data.results.waiting_lottery_no_list.length
        );
      }
      setLoader(false);
    });

    // assignQueryNumber(rank).then((res) => {
    //   setLotteryNumbers(res.data.results.waiting_lottery_list);
    //   setLastSavedWaitingList(res.data.results.waiting_lottery_list.length);
    // });
  };

  // const onEdit = (waiting_number: number) => {
  //   const old_value = prevState.filter((item: any) => item.wait_list_number === waiting_number);

  //   const new_value = rank.filter((item: any) => item.wait_list_number === waiting_number);

  //   const data = {
  //     previous_waiting_list_lottery_no: old_value[0].waiting_list_lottery_number,
  //     new_waiting_list_lottery_no: new_value[0].waiting_list_lottery_number,
  //     wait_list_number: waiting_number,
  //   };
  //   editWaitingList(data);
  // }

  const onProcessEnd = () => {
    endLotteryProcess().then((res) => {
      if (res.data.status_code === 1) {
        history.push(`/admin/offers`);
      }
    });
  };

  const downloadFile = async () => {
    setLoader(true);
    await exportCsv().then((res) => {
      setLoader(false);
      window.open(res.data.results, "_parent");
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      className={classes.root}
    >
      <Loader open={loader} />
      <div style={{ marginTop: 15, width: "20%", marginLeft: "40%" }}>
        <p className={classes.textOne}>Waiting List Lottery Selection</p>
      </div>

      <Grid
        container
        direction="row"
        justifyContent="space-around"
        className={classes.content}
      >
        <Grid item xs={6} className={classes.leftBlock}>
          <div className={classes.innerBlock}>
            <div className={classes.head}>
              <div className={classes.firstBlock}>Waiting List Position</div>
              <div className={classes.inputBlock}>Enter Lottery No.</div>
              <div className={classes.testStyle}>Action</div>
            </div>
            <div style={{ paddingTop: 25 }}>
              {rank.map((list: any, index: number) => (
                <div className={classes.head} key={list.wait_list_number}>
                  <div className={classes.firstBlock2}>
                    {list.wait_list_number}
                  </div>
                  <div className={classes.inputBlock2}>
                    <Input
                      key={list.wait_list_number}
                      value={list.waiting_list_lottery_number}
                      type="text"
                      noMarginBottom={true}
                      onChange={(e) =>
                        inputChangeHandler(
                          e.target.value,
                          list.wait_list_number
                        )
                      }
                      // error={validError.appTitle_err}
                      // disabled={selectedGlobalDate !== currentGlobalDate}
                    />
                  </div>
                  <div
                    className={cn(classes.testStyle2)}
                    style={{ paddingTop: 6 }}
                  >
                    {index + 1 <= lastSavedWaitingList ? (
                      <div style={{ width: "60%" }}>
                        <AdminButton
                          label="Edit"
                          onClick={() => onSaveEdit(list.wait_list_number)}
                          light
                        />
                      </div>
                    ) : (
                      <div style={{ width: "60%" }}>
                        <AdminButton
                          label="Save"
                          onClick={() => onSaveEdit(list.wait_list_number)}
                          light
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ paddingTop: 7, width: "40%", marginLeft: "30%" }}>
            <AdminButton
              label={`Add Waiting List No. ${currentCount && currentCount + 1}`}
              onClick={addWaitingList}
              disabled={lotteryNumbers.length === rank.length}
              light
            />
          </div>
        </Grid>
        <Grid item xs={3} className={classes.rightBlock}>
          <Box width="150px">
            <AdminButton
              label="Export CSV"
              startIcon="download_icon"
              light
              onClick={downloadFile}
            />
          </Box>
          
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              marginTop='25px'
            >
              <div className={cn(classes.textTwo, classes.appIdHelper)}>
                Application ID
              </div>
              <div className={classes.textTwo}>Lottery Numbers</div>
            </Box>
          
          <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" flexDirection="column" className={classes.appIdHelper}>
                {lotteryNumbers?.map((lotteryNumber) => (
                  <div className={cn(classes.gap, classes.lotteryNumberBlock)}>
                  <span>{lotteryNumber.application_id}</span>
                  </div>
                ))}
            </Box>
            <div className={classes.lotteryNumbers}>
              {lotteryNumbers?.map((lotteryNumber) => (
                <div className={cn(classes.gap, classes.lotteryNumberBlock)}>
                  <span>{lotteryNumber.waiting_list_lottery_number}</span>
                  {lotteryNumber.is_waiting_num_assigned && (
                    <span>
                      <Icon className={classes.selectedIcon}>
                        check_circle_icon
                      </Icon>
                    </span>
                  )}
                </div>
              ))}
            </div>
          </Box>
        </Grid>
      </Grid>
      <div style={{ width: "20%", marginLeft: "40%", paddingBottom: 30 }}>
        <AdminButton label="End Waiting List Lottery" onClick={onProcessEnd} />
      </div>
    </Grid>
  );
};

export default WaitingLottery;
