import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

type Props = {
  label?: string;
  onClick?: any;
};
export const AddButton: React.FC<Props> = ({ label, onClick }) => {

  return (
    <Box>
      <div
        style={{
          position: "relative",
          border: "1px solid #e4dada",
          borderRadius: "5px",
          padding: "13px 13px",
          backgroundColor:"white",
          cursor:"pointer"
        }}
        onClick={onClick}
      >
        {label}
        <Icon style={{ position: "absolute", right: "12px", top: "12px" }}>
          add
        </Icon>
      </div>
    </Box>
  );
};
