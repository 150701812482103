import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components/common/button/index";
import { IconRounded } from "../../../components/common/icon/index";
import { Welcome } from "../../../components/common/welcome";
import { Info } from "../../../components/common/info";
import { Footer } from "../../../components/common/footer";
import { Box } from "@material-ui/core";
import { useStyle } from "./landing.style";
import Logo from "../../../components/common/logo";
import { useHistory } from "react-router-dom";
import { CheckNetwork } from "../../../utils/helper";
import request from "../../../utils/authUtil";

const LandingPage = () => {
  const styles = useStyle();
  const history = useHistory();
  const isLoggedIn = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const type = localStorage.getItem("type");
  const [appTitle, setAppTitle] = useState<string>('');
  const [openDate, setOpenDate] = useState<string>('');
  const [closeDate, setCloseDate] = useState<string>('');
  if (isLoggedIn) {
    if (type === "PARENT") {
      history.push("/parent/dashboard");
    }
    if (type === "EMPLOYEE") {
      history.push("/admin");
    }
  }

  const getDetails = async () => {
    return await request({
      url: `student-application/school-app-info/`,
      method: 'get',
  });
  }
  useEffect(() => {
    getDetails().then((res) => {
      if (res.data.status_code === 1) {
        setAppTitle(res.data.results.application_title);
        setOpenDate(res.data.results.open_date);
        setCloseDate(res.data.results.cut_off_date);
      }
    });
  }, []);
  return (
    <div className={styles.container}>
      <CheckNetwork />
      {/* part1 */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mr="22px"
        ml="22px"
      >
        <Box mt="9px">
          <Logo/>
        </Box>
        {/* <div style={{ width: "129px" }} className={styles.adminLogin}>
          <Link to="admin" style={{ textDecoration: "none" }}>
            <Button label="Admin Login" light />
          </Link>
        </div> */}
      </Box>
      <div className={styles.bodyContainer}>
        {/* part2 */}
        <Box mt={5}>
          <Welcome
            title={`Admissions Platform for ${name}`}
            subTitle={appTitle}
            description={`Welcome to our school. Here you can register your account and complete your student's application. Once you have completed your application in full you will receive an email confirming that you have submitted your application to the school. If you do not see the confirmation email in your Inbox please check your Spam / Junk folder. If you still have not received your confirmation email please contact support at support@uniqueschools.ie. Please note that completing an Application Form does not automatically guarantee a place in ${name}.`}
          />
        </Box>
        {/* part3 */}
        <Box mt={5}>
          <p className={styles.howItWorks}>How it works</p>
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr="15px">
              <IconRounded
                iconName="account_circle_icon"
                bgColor="rgba(251, 173, 22, 0.2)"
              />
            </Box>
            <p className={styles.stepsText}>Register your account</p>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr="15px">
              <IconRounded
                iconName="assignment_turned_in_icon"
                bgColor="rgba(251, 173, 22, 0.2)"
              />
            </Box>
            <p className={styles.stepsText}>
              Complete your students application
            </p>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Box mr="15px">
              <IconRounded
                iconName="query_builder_icon"
                bgColor="rgba(251, 173, 22, 0.2)"
              />
            </Box>
            <p className={styles.stepsText}>
              Wait for the application to be processed
            </p>
          </Box>
          {/* <Box display="flex" alignItems="center">
            <Box mr="15px">
              <IconRounded
                iconName="assignment_turned_in_icon"
                bgColor="rgba(251, 173, 22, 0.2)"
              />
            </Box>
            <p className={styles.stepsText}>
              Complete your students enrolment form
            </p>
          </Box> */}
        </Box>
        {/* part4 */}
        <Box display="flex" mt={8} mb={3}>
          <Box mr="8px" width="177px">
            <Link to="parent" style={{ textDecoration: "none" }}>
              <Button label="Login" light />
            </Link>
          </Box>
          <Box width="177px">
            <Link to="/registration" style={{ textDecoration: "none" }}>
              <Button label="Register" />
            </Link>
          </Box>
        </Box>
        <p className={styles.textLight}>
          {`Completed Applications will be accepted from: ${openDate} The closing
          date for applications is: ${closeDate}`}
        </p>
        <Box mt="40px">
          <Info />
        </Box>
        <Box className={styles.footerContainer}>
          <Footer />
        </Box>
      </div>
    </div>
  );
};

export default LandingPage;
