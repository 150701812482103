import { useState, useEffect } from "react";
import { AdminButton } from "../../../components/common/admin-button/index";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./criteria-tab.style";
import CreateCriteria from "./create-criteria";
import { getCriteriaList } from "./criteria.api";
import { Loader } from "../../../components/common/loader/index";

type CriteriaListProps = {
  applicationID: string;
};

type Criterias = {
  criteria_uuid: string;
  question: string;
  is_special: boolean;
  order: number;
};

const default_criteria = {
  criteria_uuid: "",
  question: "",
  is_special: false,
  order: 0,
};

const ListCriteria: React.FC<CriteriaListProps> = ({ applicationID }) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(true);
  const [criteria, setCriteria] = useState<Criterias[] | []>([]);

  const addCriteriaHandler = () => {
    // readyToSwitch(false);
    setCriteria([...criteria, default_criteria]);
  };

  useEffect(() => {
    getCriteriaList(applicationID).then((response) => {
      if (response.data.results.length) {
        setCriteria(response.data.results);
      } else {
        setCriteria([default_criteria]);
      }
      setLoader(false);
    });
  }, [applicationID]);

  return (
    <div>
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.criteriaContainer}>
          {criteria.map((item, index) => (
            <div key={index}>
              <CreateCriteria
                index={index}
                applicationID={applicationID}
                criteriaLabel={`Criteria ${index + 1}`}
                criteria_uuid={item.criteria_uuid}
                criteria_question={item.question}
                order={index + 1}
                setCriteria={setCriteria}
              />
            </div>
          ))}
        </div>
        <>
          <div style={{ width: "300px", marginTop: "20px" }}>
            <AdminButton
              label="Add further Criteria"
              endIcon="add"
              light
              onClick={addCriteriaHandler}
            />
          </div>
        </>
      </Grid>
      <Loader open={loader} />
    </div>
  );
};

export default ListCriteria;
