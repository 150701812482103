import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./criteria-tab.style";
import cn from "classnames";
import ListCriteria from "./list-criteria";
import SpecialCriteria from "./special-criteria";
import CatchmentArea from "./catchment-area";
import { Instruction } from "./instructions";

type CriteriaProps = {
  applicationID: string;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};
const CriteriaTab: React.FC<CriteriaProps> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [active, setActive] = useState("questions");
  const [show, setShow] = useState("questions");

  const handleTabChange = (value: string) => {
    // setNextTab(value);
    // if (saved) {
    setShow(value);
    setActive(value);
    // } else {
    //   setOpenConfirm(true);
    // }
  };
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between">
        <div style={{ width: "60%" }}>
          {show === "questions" ? (
            <ListCriteria applicationID={applicationID} />
          ) : null}
          {show === "special" && (
            <SpecialCriteria
              applicationID={applicationID}
              readyToSwitch={ready}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          )}
          {show === "catchment" && (
            <CatchmentArea applicationID={applicationID} />
          )}
          {show === "instruction" && (
            <Instruction applicationID={applicationID} />
          )}
        </div>
        <div>
          <div className={classes.tab}>
            <button
              className={cn(
                classes.tabButton,
                active === "questions" ? classes.tabActive : null
              )}
              onClick={() => handleTabChange("questions")}
            >
              Questions
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "special" ? classes.tabActive : null
              )}
              onClick={() => handleTabChange("special")}
            >
              Special Criteria
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "catchment" ? classes.tabActive : null
              )}
              onClick={() => handleTabChange("catchment")}
            >
              Catchment Area
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "instruction" ? classes.tabActive : null
              )}
              onClick={() => handleTabChange("instruction")}
            >
              Criteria Instruction
            </button>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default CriteriaTab;
