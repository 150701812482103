import { useStyle } from "./footer.style";
import cn from "classnames";
import { Box } from "@material-ui/core";
type FooterProps = {
  className?: string;
  center?: boolean;
};

export const Footer: React.FC<FooterProps> = ({
  className,
  center = false,
}) => {
  const classes = useStyle();
  return (
    <>
      <Box
        className={cn(
          classes.root,
          className,
          center ? classes.alignCenter : ""
        )}
        fontWeight={400}
      >
        <div className={classes.btnContainer}>
          <button className={classes.btnLink}>
            Powered by <b className={classes.textBold}>Unique Schools</b>
          </button>
          <button className={classes.btnLink}>Terms and conditions</button>
          <button className={classes.btnLink}>Privacy Policy</button>
        </div>
        <div className={classes.supportEmailContainer}>
          <p>
            Need Support - email <a href='mailto: support@uniqueschools.ie'>support@uniqueschools.ie</a> and a member of the
            team will respond to you
          </p>
        </div>
      </Box>
    </>
  );
};
