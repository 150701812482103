import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  container: {
    overflowX: "auto",
    overflowY: "auto",
  },
  adminButton: {
    width: "150px !important" as any,
  },
  button: {
    padding: "10px",
    outline: "none",
    border: "none",
    cursor: "pointer",
  },
  imageContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: '80%',
    width: '80%',
  },
  image: {
    height: "600px",
    width:"100%"
  },
}));
