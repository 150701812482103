import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { useStyle } from "./edu-ques.style";
import request from "../../../utils/authUtil";
import { AdminButton } from "../../../components/common/admin-button/index";
import { Input } from "../../../components/common/input";
import { Loader } from "../../../components/common/loader/index";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";
import Checkbox from "@material-ui/core/Checkbox";
type Props = {
  applicationID?: string;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const SubjectChoice: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [error, setError] = useState<Number[]>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [idPre, setId] = useState<string>("");
  const [indexPre, setIndex] = useState<number>(0);
  const [ subjectDescription, setSubjectDescription] = useState<string>("");
  const [list, setList] = useState<any>([
    { enrolment_subject_uuid: "", subject_name: "" },
  ]);
  const [allowSubject, setAllowSubject] = useState<boolean>(false);
  useEffect(() => {
    setLoader(true);
    request({
      url: `school-settings/school-enrolment-subject/?application_uuid=${applicationID}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            response.data.results.subject.map((language: any) => {
              temp.push({
                enrolment_subject_uuid: language.enrolment_subject_uuid || "",
                subject_name: language.subject_name,
              });
            });
            setSubjectDescription(response?.data?.results?.subject_desc);
            setList(temp);
            setAllowSubject(response.data.results.allow_subject);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  }, []);

  const addLanguage = () => {
    switchReady(false);
    setList([...list, { enrolment_subject_uuid: "", subject_name: "" }]);
  };

  const onChange = (value: any, ind: any) => {
    switchReady(false);
    const temp = [...list];
    const tempErr = [...error];
    temp[ind].subject_name = value;
    setList(temp);
    if (value.length == 0) {
      if (!tempErr.includes(ind)) {
        tempErr.push(ind);
      }
    } else if (tempErr.includes(ind)) {
      const val = tempErr.indexOf(ind);
      if (ind > -1) {
        tempErr.splice(val, 1);
      }
    }
    setError(tempErr);
  };

  const deleteHandler = (id: string, index: number) => {
    setOpen(true);
    setId(id);
    setIndex(index);
  };

  const onSaveClick = () => {
    const temp = [...error];
    try {
      list.map((item: any, index: any) => {
        if (item.subject_name.length == 0) {
          if (!temp.includes(index)) {
            temp.push(index);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        let sub = list.map((item: any, index: any) => {
          return {
            enrolment_subject_uuid: item.enrolment_subject_uuid,
            order: index + 1,
            subject_name: item.subject_name,
          };
        });
        let data = {
          subject_description: subjectDescription,
          subject: sub,
          allow_subject: allowSubject,
        };
        request({
          url: `school-settings/school-enrolment-subject/?application_uuid=${applicationID}`,
          method: "put",
          data: data,
        }).then((res) => {
          const temp: any = [];
          setLoader(false);
          res.data.results.subject.map((subject: any) => {
            temp.push({
              enrolment_subject_uuid: subject.enrolment_subject_uuid || "",
              subject_name: subject.subject_name,
            });
          });
          switchReady(true);
          setList(temp);
          setAllowSubject(res.data.results.allow_subject);
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteLanguage = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/school-enrolment-subject/`,
        method: "delete",
        data: {
          enrolment_subject_uuid: id,
        },
      }).then(() => {
        const temp = [...list];
        temp.splice(index, 1);
        setList(temp);
        setError([]);
        setLoader(false);
      });
    } else {
      const temp = [...list];
      temp.splice(index, 1);
      setList(temp);
      setError([]);
    }
  };

  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.langContainer}>
          <Box display="flex" alignItems="center" mb={1}>
            <Checkbox
              disabled={selectedGlobalDate !== currentGlobalDate}
              color="default"
              style={{ marginLeft: "-13px" }}
              onChange={(e) => {
                switchReady(false);
                setAllowSubject(!allowSubject);
              }}
              checked={allowSubject}
            />
            <p className={classes.text}>
              Include Subject Choice selection question
            </p>
          </Box>
          <Box>
            <Input
            type="text"
            value={subjectDescription}
            onChange={(e)=>setSubjectDescription(e.target.value)}
            label="Subject choice description"
            />
          </Box>
          {list.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                key={index}
              >
                <Input
                  label={`Subject ${index + 1}`}
                  type="text"
                  value={item.subject_name}
                  onChange={(e: { target: { value: any } }) =>
                    onChange(e.target.value, index)
                  }
                  error={error.includes(index)}
                  disabled={selectedGlobalDate !== currentGlobalDate}
                />
                {selectedGlobalDate === currentGlobalDate && (
                  <Icon
                    style={{ cursor: "pointer", color: "#757575", marginTop: "25px" }}
                    onClick={() =>
                      deleteHandler(item.enrolment_subject_uuid, index)
                    }
                  >
                    delete
                  </Icon>
                )}
              </Box>
            );
          })}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <>
            <div style={{ width: "300px", marginTop: "20px" }}>
              <AdminButton
                label="Add further subjects"
                endIcon="add"
                light
                onClick={addLanguage}
              />
            </div>
            <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
              <AdminButton label="Save" onClick={onSaveClick} />
            </div>
          </>
        )}
        <DeleteBox
          index={indexPre}
          id={idPre}
          onClick={() => {
            deleteLanguage(idPre, indexPre);
          }}
          open={open}
          setOpen={setOpen}
        />
      </Grid>
    </div>
  );
};

export default SubjectChoice;
