import { useEffect, useState } from "react";
import PropTypes from "prop-types";

const TextSignature = (props) => {
  const {
    className,
    name,
    setImage,
    x,
    y,
    height = 68,
    font = "50px AgreementSignature",
    show = false,
  } = props;

  const [signImg, setSignImg] = useState("");
  const generateBase64Img = (img) => {
    setSignImg(img);
    setImage(img);
  };

  useEffect(() => {
    let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
    canvasTxt.font = font;
    return show ? createSignature(canvasTxt.measureText(name).width) : null;
  }, [show]);

  const createSignature = (signWidth) => {
    let canvasTxt = document.getElementById("canvasComponent").getContext("2d");
    canvasTxt.canvas.width = signWidth;
    canvasTxt.canvas.height = height;
    canvasTxt.font = font;
    canvasTxt.fillText(name, x, y);
    generateBase64Img(canvasTxt.canvas.toDataURL());
  };

  return (
    <div className={className}>
      <canvas id="canvasComponent" style={{ display: "none" }} />
      {signImg ? <img id="imageComponent" src={signImg} alt="" /> : null}
    </div>
  );
};

TextSignature.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  setImage: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  font: PropTypes.string.isRequired,
  show: PropTypes.bool,
};

export default TextSignature;
