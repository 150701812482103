import request from "../../../utils/authUtil";
import requestv2 from "../../../utils/authUtilv2";

interface criteriaData {
  criteria_uuid: string;
  criteriaQuestion: string;
  details: boolean;
  furtherDetails: string;
  furtherDetails_uuid: string;
}

interface postCriteriaData {
  id: string;
  criteria: criteriaData[];
}

type createCriteriaProp = {
  id: string;
  question: string;
  order: number;
};

type deleteCriteriaProp = {
  id: string;
  criteria_uuid: string;
};

type updateCriteriaProp = {
  criteria_uuid: string;
  question: string;
  further_detail_required: boolean;
  further_question_required: boolean;
  further_question: string;
  further_question_type: string;
  follow_up_question: string;
  require_supporting_doc: boolean;
};

export const getCriteria = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-criteria-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const getCatchmentarea = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-custom-settings-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postCriteria = async ({
  id,
  criteria,
}: postCriteriaData): Promise<any> => {
  const data = criteria.map((item: any) => {
    return {
      criteria_uuid: item.criteria_uuid || "",
      question: item.criteriaQuestion,
      further_detail_required: item.details,
      require_supporting_doc: false,
      criteria_follow_up_application_criteria: item.details
        ? [
            {
              criteria_followup_uuid: item.furtherDetails_uuid || "",
              follow_up_question: item.furtherDetails,
            },
          ]
        : [],
    };
  });

  const res = await request({
    url: `school-settings/application-criteria-details/?application_uuid=${id}`,
    method: "put",
    data,
  });
  return res;
};

export const getCriteriaList = async (id: string): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criterias/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const createCriteria = async ({
  id,
  question,
  order,
}: createCriteriaProp): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criterias/?application_uuid=${id}`,
    method: "post",
    data: {
      question,
      order,
    },
  });
  return res;
};

export const deleteCriteria = async ({
  id,
  criteria_uuid,
}: deleteCriteriaProp): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criterias/?application_uuid=${id}`,
    method: "delete",
    data: {
      criteria_uuid,
    },
  });
  return res;
};

export const getSingleCriteria = async (
  criteria_uuid: string
): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criteria-details/?criteria_uuid=${criteria_uuid}`,
    method: "get",
  });
  return res;
};

export const updateSingleCriteria = async ({
  criteria_uuid,
  question,
  further_detail_required,
  further_question_type,
  further_question_required,
  further_question,
  follow_up_question,
  require_supporting_doc,
}: updateCriteriaProp): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criteria-details/`,
    method: "put",
    data: {
      further_question_required,
      further_question,
      criteria_uuid,
      question,
      further_detail_required,
      further_question_type,
      follow_up_question,
      require_supporting_doc,
    },
  });
  return res;
};

export const createDropdownOptions = async (
  criteria_uuid: string,
  details_list: string[]
): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criteria-followup-details/`,
    method: "put",
    data: {
      criteria_uuid,
      details_list,
    },
  });
  return res;
};

export const getDropdownOptions = async (
  criteria_uuid: string
): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/application-criteria-followup-details/?criteria_uuid=${criteria_uuid}`,
    method: "get",
  });
  return res;
};

export const getInstruction = async (id: string): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/app-criteria-instruction/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const putInstruction = async (
  id: string,
  text: string
): Promise<any> => {
  const res = await requestv2({
    url: `school-settings/app-criteria-instruction/?application_uuid=${id}`,
    method: "put",
    data: {
      instruction: text,
    },
  });
  return res;
};
