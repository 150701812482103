import { useState } from "react";
import { Box, Icon } from "@material-ui/core";
import { useStyle } from "../start-selection/selection-table/selection-table.style";
import {
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
} from "@material-ui/core";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import { selectApplication, cancelApplication } from "./apis";
import { Loader } from "../../../../components/common/loader";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
  data?: any;
  pageNumber?: any;
  setPageNumber?: any;
  totalPages?: any;
  setIsAsc?: any;
  setSortTag?: any;
  refetch?: any;
};

const WaitingListTable: React.FC<Props> = ({
  data,
  pageNumber,
  setPageNumber,
  totalPages,
  setSortTag,
  setIsAsc,
  refetch,
}) => {
  const classes = useStyle();
  const [selectPopup, setSelectPopup] = useState<boolean>(false);
  const [cancelPopup, setCancelPopup] = useState<boolean>(false);
  const [selectedStd, setSelectedStd] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  const openSelectBox = (val: any) => {
    setSelectedStd(val);
    setSelectPopup(true);
  };

  const openCancelBox = (val: any) => {
    setSelectedStd(val);
    setCancelPopup(true);
  };

  const cancelCandidate = () => {
    setLoader(true);
    cancelApplication(selectedStd).then((res) => {
      setLoader(false);
      refetch();
    });
  };

  const selectCandidate = () => {
    setLoader(true);
    selectApplication(selectedStd).then((res) => {
      setLoader(false);
      refetch();
    });
  };

  const columns = [
    {
      id: "Waiting_list_position",
      label: "Waiting list position",
      minWidth: 100,
      isSort: true,
    },
    { id: "ID", label: "ID", minWidth: 100, isSort: true },
    {
      id: "Waiting_list_number",
      label: "Waiting list number",
      minWidth: 100,
      isSort: true,
    },
    { id: "Student_Name", label: "Student Name", minWidth: 100, isSort: true },
    { id: "criteria", label: "Criteria name", minWidth: 100 },
    { id: "application_status", label: "Status", minWidth: 100 },
    {
      id: "Edit",
      label: " ",
      minWidth: 100,
      align: "right",
    },
    {
      id: "actions",
      label: "Actions",
      minWidth: 100,
      align: "center",
    },
  ];

  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  className={classes.root}
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="start"
                  >
                    {column.label}
                    {column.isSort && (
                      <>
                        <Box marginLeft="15px">
                          <Icon
                            style={{
                              cursor: "pointer",
                              color: "#757575",
                              fontSize: "18px",
                            }}
                            onClick={() => {
                              setSortTag(column.id);
                              setIsAsc(true);
                            }}
                          >
                            arrow_upward_icon
                          </Icon>
                          <Icon
                            style={{
                              cursor: "pointer",
                              color: "#757575",
                              fontSize: "18px",
                            }}
                            onClick={() => {
                              setSortTag(column.id);
                              setIsAsc("");
                            }}
                          >
                            arrow_downward_icon
                          </Icon>
                        </Box>
                      </>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((item: any) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item.ID}>
                    <>
                      <TableCell className={classes.root}>
                        {item.wait_list_position}
                      </TableCell>
                      <TableCell className={classes.root}>{item.ID}</TableCell>
                      <TableCell className={classes.root}>
                        {item.wait_list_number}
                      </TableCell>
                      <TableCell className={classes.root}>
                        {item.student_name}
                      </TableCell>
                      <TableCell className={classes.root}>
                        {item.criteria}
                      </TableCell>
                      <TableCell className={classes.root}>
                        {item.application_status}
                      </TableCell>
                      <TableCell className={classes.root}>
                        <span
                          onClick={() =>
                            window.open(
                              `/admin/applications/${item.student_app_uuid}`
                            )
                          }
                          style={{
                            width: 80,
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            style={{
                              color: "#757575",
                              marginRight: 10,
                            }}
                          >
                            remove_red_eye_icon
                          </Icon>
                          VIEW
                        </span>
                      </TableCell>
                      <TableCell className={classes.root}>
                        <span>
                        <Tooltip title="Select Application" interactive classes={{ tooltip: classes.tooltip_font }}>
                          <Icon
                            style={{
                              color: "#0B8A00",
                              marginRight: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => openSelectBox(item.student_app_uuid)}
                          >
                            task_alt
                          </Icon>
                          </Tooltip>
                          <Tooltip title="Cancel Application" interactive classes={{ tooltip: classes.tooltip_font }}>
                          <Icon
                            style={{
                              color: "#EB5757",
                              cursor: "pointer",
                            }}
                            onClick={() => openCancelBox(item.student_app_uuid)}
                          >
                            cancel
                          </Icon>
                          </Tooltip>
                        </span>
                      </TableCell>
                    </>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: "50px",
          marginTop: "20px",
        }}
      >
        {totalPages ? (
          <>
            <div>{`${pageNumber || 0} of ${totalPages || 0}`}</div>
            <div>
              <button
                onClick={() => setPageNumber(pageNumber - 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === 1 || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_left_outlined
                </Icon>
              </button>
              <button
                onClick={() => setPageNumber(pageNumber + 1)}
                style={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
                disabled={pageNumber === totalPages || pageNumber === 0}
              >
                <Icon style={{ marginLeft: "13px" }}>
                  chevron_right_outlined
                </Icon>
              </button>
            </div>
          </>
        ) : null}
      </div>
      <ConfirmBox
        open={selectPopup}
        setOpen={setSelectPopup}
        onClick={selectCandidate}
        message="Are you sure you want to select the candidate?"
      />
      <ConfirmBox
        open={cancelPopup}
        setOpen={setCancelPopup}
        onClick={cancelCandidate}
        message="Are you sure you want to cancel the candidate?"
      />
      <Loader open={loader} />
    </>
  );
};

export default WaitingListTable;
