import { useStyle } from "../delete-dialogbox/delete-dialog.style";
import { AdminButton } from "../../../components/common/admin-button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

type ConfirmProps = {
  id?: string;
  status?: string;
  message: string;
  open: boolean;
  onClick: any;
  setOpen: any;
  arg?: boolean;
  handleCancel?: any;
};

export const ConfirmBox: React.FC<ConfirmProps> = ({
  open = false,
  onClick,
  handleCancel,
  setOpen,
  message,
  arg = false,
  id,
  status,
}) => {
  const classes = useStyle();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {message}
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <div style={{ width: "100px", marginRight: "10px" }}>
            <AdminButton
              onClick={() => {
                handleClose();
                handleCancel && handleCancel();
              }}
              label="Cancel"
              light
            />
          </div>
          <div style={{ width: "100px" }}>
            {arg === false ? (
              <AdminButton
                onClick={() => {
                  onClick();
                  handleClose();
                }}
                label="Confirm"
              />
            ) : (
              <AdminButton
                onClick={() => {
                  onClick(id, status);
                  handleClose();
                }}
                label="Confirm"
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
