import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Input } from "../../../../components/common/input/index";
import { TextArea } from "../../../../components/common/text-area-component/index";
import { Loader } from "../../../../components/common/loader/index";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import { useStyle } from "../../applications/single-application/student-details.style";
import { AdminButton } from "../../../../components/common/admin-button";
import { ConfirmBox } from "../../../../components/common/confirm-box";
import theme from "../../../../theme";
import cn from "classnames";
import { updatePdf } from "./single-enrolment.api";

type Props = {
  applicationID?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface medicalInfoData {
  medical_info_uuid: string;
  family_doctor_name: string;
  family_doctor_addres: string;
  family_doctor_phone: string;
  is_medical_card_holder: string | boolean;
  medical_card_number: string;
}

interface MedHistFollowUpData {
  follow_up_question: string;
  followup_answer: string;
  followup_answer_uuid: string;
  followup_medical_uuid: string;
}

interface MedHistData {
  medical_uuid: string;
  question: string;
  answer: boolean | string;
  answer_uuid: string;
  further_detail_required: boolean;
  followup_question_answer: MedHistFollowUpData[];
}

const MedInfoView: React.FC<Props> = ({
  applicationID,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [show, setShow] = useState("MedicalInfo");
  const [active, setActive] = useState("MedicalInfo");
  const [empty, setEmpty] = useState<boolean>(false);
  const [emptyHistory, setEmptyHistory] = useState<boolean>(false);
  const classes = useStyle();
  const [localData, setLocalData] = useState<medicalInfoData>({
    medical_info_uuid: "",
    family_doctor_name: "",
    family_doctor_addres: "",
    family_doctor_phone: "",
    is_medical_card_holder: "",
    medical_card_number: "",
  });
  const [errorEdit, setErrorEdit] = useState("");
  const [formDisable, setFormDisable] = useState(true);
  const [open, setOpen] = useState(false);
  const [medHistory, setMedHistory] = useState<MedHistData[]>([
    {
      medical_uuid: "",
      question: "",
      answer: "",
      answer_uuid: "",
      further_detail_required: false,
      followup_question_answer: [
        {
          follow_up_question: "",
          followup_answer: "",
          followup_answer_uuid: "",
          followup_medical_uuid: "",
        },
      ],
    },
  ]);
  const schoolID = localStorage.getItem("school-id");

  const getMedicalInfo = () => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrolment-medical-info/?student_app_uuid=${applicationID}`,
      method: "get",
    }).then((response) => {
      if (
        response.data.status_code === 1 &&
        response.data.results.length !== 0
      ) {
        setLocalData(response?.data?.results[0]);
        setLoader(false);
      } else {
        setEmpty(true);
        setLoader(false);
      }
    });
  };

  const getMedicalHistory = () => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrolment-medical-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        if (Object.keys(response.data?.results).length !== 0) {
          const temp: any = [];
          if (response.data?.results[0].answer === "") {
            setEmptyHistory(true);
          } else {
            response.data.results.map((ques: any, index: number) => {
              temp.push({
                medical_uuid: ques.medical_uuid,
                question: ques.question,
                answer: ques.answer,
                answer_uuid: ques.answer_uuid,
                further_detail_required: ques.further_detail_required,
                followup_question_answer: ques.followup_question_answer,
              });
            });
            setMedHistory(temp);
          }
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  useEffect(() => {
    getMedicalHistory();
    getMedicalInfo();
  }, []);

  const handleTabChange = (value: string) => {
    if (formDisable) {
      setShow(value);
      setActive(value);
      setFormDisable(true);
    } else {
      setErrorEdit(
        "Please save data before changing tab or click on cancel button"
      );
    }
  };

  const cancelEdit = () => {
    setFormDisable(true);
    setErrorEdit("");
  };

  const saveMedicalInfo = () => {
    setLoader(true);
    const data = {
      family_doctor_name: localData.family_doctor_name,
      family_doctor_address: localData.family_doctor_addres,
      family_doctor_phone: localData.family_doctor_phone,
      is_medical_card_holder: localData.is_medical_card_holder,
      medical_card_number:
        localData.is_medical_card_holder === true
          ? localData.medical_card_number
          : "",
    };
    request({
      url: `student-enrolment/student-enrolment-medical-info/?student_app_uuid=${applicationID}&medical_info_uuid=${localData.medical_info_uuid}`,
      method: "put",
      data: data,
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLoader(false);
        setFormDisable(true);
        getMedicalInfo();
      } else {
        setLoader(false);
      }
    });
  };

  const saveMedicalHistory = () => {
    setLoader(true);
    const data = medHistory.map((item: any) => {
      let follow_up: any = [];
      let follow_up_id = item.answer_uuid || "";
      item.followup_question_answer.map((subItem: any) => {
        follow_up.push({
          followup_medical_uuid: subItem.followup_medical_uuid,
          followup_answer: item.answer === true ? subItem.followup_answer : "",
          followup_answer_uuid: subItem.followup_answer_uuid || "",
        });
      });
      return {
        medical_uuid: item.medical_uuid,
        answer: item.answer,
        followup_question_answer: item.further_detail_required ? follow_up : [],
        answer_uuid: follow_up_id,
      };
    });
    request({
      url: `student-enrolment/student-enrolment-medical-details/?student_app_uuid=${applicationID}&school_uuid=${schoolID}`,
      method: "put",
      data: data,
    }).then((response) => {
      if (response.data.status_code === 1) {
        setLoader(false);
        getMedicalHistory();
        setFormDisable(true);
      } else {
        setLoader(false);
      }
    });
  };

  const handleRadioChange = (name: string, index: number) => {
    const temp = [...medHistory];
    if (name === `No`) {
      temp[index].answer = false;
      setMedHistory(temp);
    }
    if (name === `Yes`) {
      temp[index].answer = true;
      setMedHistory(temp);
    }
  };

  const saveData = () => {
    if (show === "MedicalInfo") {
      saveMedicalInfo();
    }
    if (show === "MedicalHistory") {
      saveMedicalHistory();
    }
    updatePdf(applicationID);
  };

  const onChangeMedHistory = (
    value: string,
    index: number,
    followIndex: number
  ) => {
    const temp = [...medHistory];
    temp[index].followup_question_answer[followIndex].followup_answer = value;
    setMedHistory(temp);
  };
  return (
    <>
      <Loader open={loader} />
      <Box display="flex" justifyContent="space-between">
        <Box width="600px" mb="20px">
          {show === "MedicalInfo" ? (
            empty === false ? (
              <>
                <Input
                  label="Family Doctor name"
                  value={localData.family_doctor_name}
                  disabled={formDisable}
                  onChange={(e) =>
                    setLocalData({
                      ...localData,
                      family_doctor_name: e.target.value,
                    })
                  }
                />
                <TextArea
                  label="Family Doctor Address"
                  value={localData.family_doctor_addres}
                  disabled={formDisable}
                  onChange={(e: any) =>
                    setLocalData({
                      ...localData,
                      family_doctor_addres: e.target.value,
                    })
                  }
                />
                <Input
                  label="Family Doctor phone"
                  value={localData.family_doctor_phone}
                  disabled={formDisable}
                  onChange={(e) =>
                    setLocalData({
                      ...localData,
                      family_doctor_phone: e.target.value,
                    })
                  }
                />
                {localData.is_medical_card_holder ? (
                  <Input
                    label="Medical card number"
                    value={localData.medical_card_number}
                    disabled={formDisable}
                    onChange={(e) =>
                      setLocalData({
                        ...localData,
                        medical_card_number: e.target.value,
                      })
                    }
                  />
                ) : null}
              </>
            ) : (
              <Box>Medical info details not provided yet</Box>
            )
          ) : null}

          {show === "MedicalHistory" ? (
            emptyHistory === false ? (
              <>
                <p>Medical History</p>
                {medHistory.map((item, index) => (
                  <Box key={item.medical_uuid}>
                    <InputRadioButton
                      label={item.question}
                      placeHolderOne="No"
                      placeHolderTwo="Yes"
                      valueOne="No"
                      valueTwo="Yes"
                      index={index}
                      answer={item.answer}
                      isDisabled={formDisable}
                      onChange={handleRadioChange}
                    />
                    {item.answer && item.further_detail_required
                      ? item.followup_question_answer.map(
                          (followItem, followIndex) => (
                            <Box key={followIndex}>
                              <Input
                                label={followItem.follow_up_question}
                                value={followItem.followup_answer}
                                disabled={formDisable}
                                onChange={(e: any) =>
                                  onChangeMedHistory(
                                    e.target.value,
                                    index,
                                    followIndex
                                  )
                                }
                              />
                            </Box>
                          )
                        )
                      : null}
                  </Box>
                ))}
              </>
            ) : (
              <Box>Medical history details not provided yet</Box>
            )
          ) : null}
        </Box>
        <div>
          <Box className={classes.subTab} height="100px">
            <button
              className={cn(
                classes.tabButton,
                active === "MedicalInfo" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("MedicalInfo");
              }}
            >
              Medical Info
            </button>
            <button
              className={cn(
                classes.tabButton,
                active === "MedicalHistory" ? classes.tabActive : null
              )}
              onClick={() => {
                handleTabChange("MedicalHistory");
              }}
            >
              Medical History
            </button>
          </Box>
        </div>
      </Box>
      {!formDisable && (
        <Box mt="20px" mb="20px">
          <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
        </Box>
      )}
      {localStorage.getItem("employee_type") === "Super Admin" ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              // disabled={formDisable}={isDisabled}
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open}
        setOpen={setOpen}
        onClick={saveData}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </>
  );
};
export default MedInfoView;
