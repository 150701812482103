import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
// import { useStyle } from "./general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  error?: any;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface urlLinkData {
  admissionPolicyUrl: string;
  codeOfBehaviourUrl: string;
  dataProtectionUrl: string;
  antiBullyingUrl: string;
  admissionNotice: string;
  assessmentTesting: string;
  aup: string;
}

interface PostUrlType {
  id: string;
  urllink: urlLinkData;
}

const UrlLinks: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const [urllink, setUrlLink] = useState<urlLinkData>({
    admissionPolicyUrl: "",
    codeOfBehaviourUrl: "",
    dataProtectionUrl: "",
    antiBullyingUrl: "",
    admissionNotice: "",
    assessmentTesting: "",
    aup: "",
  });

  // const [error, setError] = useState("");
  // const [errorCheck, setErrorCheck] = useState<string[]>([]);
  // const [errorSubmit, setErrorSubmit] = useState<Boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const getUrlLinks = async (id: string): Promise<any> => {
    const res = await request({
      url: `school-settings/application-related-urls-details/?application_uuid=${id}`,
      method: "get",
    });
    return res;
  };

  const postUrlLinks = async ({ id, urllink }: PostUrlType): Promise<any> => {
    setLoader(true);
    const res = await request({
      url: `school-settings/application-related-urls-details/?application_uuid=${id}`,
      method: "put",
      data: {
        application_uuid: id,
        admission_policy_url: urllink.admissionPolicyUrl,
        code_Of_behaviour_url: urllink.codeOfBehaviourUrl,
        data_protection_url: urllink.dataProtectionUrl,
        anti_bullying_url: urllink.antiBullyingUrl,
        admission_notice: urllink.admissionNotice,
        assessment_testing: urllink.assessmentTesting,
        acceptable_use_policy: urllink.aup,
      },
    });
    if (res) {
      setLoader(false);
      switchReady(true);
    }
    return res;
  };

  // const LengthCheck = (field: string, errorName: string) => {
  //   const tempErrorArray = [...errorCheck];
  //   switchReady(false);
  //   if (field.length === 0) {
  //     tempErrorArray.push(errorName);
  //     setErrorCheck(tempErrorArray);
  //   } else {
  //     const index = tempErrorArray.indexOf(errorName);
  //     if (index > -1) {
  //       tempErrorArray.splice(index, 1);
  //       setErrorCheck(tempErrorArray);
  //     }
  //   }
  // };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getUrllinkError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["urllink", applicationID, selectedGlobalDate],
    () => getUrlLinks(applicationID),
    {
      select: (urllink) => urllink.data,
    }
  );

  const mutation: UseMutationResult<any, Error, PostUrlType> = useMutation(
    async ({ id, urllink }) => postUrlLinks({ id, urllink })
  );

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        setUrlLink({
          ...urllink,
          admissionPolicyUrl: data.results.admission_policy_url,
          codeOfBehaviourUrl: data.results.code_Of_behaviour_url,
          dataProtectionUrl: data?.results.data_protection_url,
          antiBullyingUrl: data.results.anti_bullying_url,
          admissionNotice: data.results.admission_notice,
          assessmentTesting: data.results.assessment_testing,
          aup: data.results.acceptable_use_policy,
        });
      }
    }
  }, [data]);

  const onSaveClick = () => {
    // setErrorSubmit(true);
    // if (errorCheck.length === 0) {
    mutation.mutate({ id: applicationID, urllink });
    // }
  };

  return (
    <div>
      {/* post loader */}
      <Loader open={loader} />
      {isError ? (
        <>
          <div>
            <p>Error while Fetching data.....</p>
            <p>{getUrllinkError}</p>
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* {error} */}
          </p>
        </>
      ) : null}
      {isSuccess ? (
        <>
          <Input
            label="Admission Policy Url"
            type="text"
            value={urllink.admissionPolicyUrl}
            onChange={(e: any) => {
              setUrlLink({ ...urllink, admissionPolicyUrl: e.target.value });
              // LengthCheck(e.target.value, "opendateError");
            }}
            disabled={selectedGlobalDate !== currentGlobalDate}
            // error={errorSubmit ? errorCheck.includes("opendateError") : false}
          />
          <Input
            label="Code Of Behaviour Url"
            value={urllink.codeOfBehaviourUrl}
            type="text"
            onChange={(e: any) => {
              setUrlLink({ ...urllink, codeOfBehaviourUrl: e.target.value });
              // LengthCheck(e.target.value, "scburlError");
            }}
            disabled={selectedGlobalDate !== currentGlobalDate}
            // error={errorSubmit ? errorCheck.includes("scburlError") : false}
          />
          <Input
            label="Data Protection Url"
            value={urllink.dataProtectionUrl}
            type="text"
            disabled={selectedGlobalDate !== currentGlobalDate}
            onChange={(e: any) => {
              setUrlLink({ ...urllink, dataProtectionUrl: e.target.value });
              // LengthCheck(e.target.value, "sdpurlError");
            }}
            // error={errorSubmit ? errorCheck.includes("sdpurlError") : false}
          />
          <Input
            label="Anti-Bullying Url"
            value={urllink.antiBullyingUrl}
            type="text"
            disabled={selectedGlobalDate !== currentGlobalDate}
            onChange={(e: any) => {
              setUrlLink({ ...urllink, antiBullyingUrl: e.target.value });
              // LengthCheck(e.target.value, "anburlError");
            }}
            // error={errorSubmit ? errorCheck.includes("anburlError") : false}
          />
          <Input
            label="Admission Notice"
            value={urllink.admissionNotice}
            type="text"
            disabled={selectedGlobalDate !== currentGlobalDate}
            onChange={(e: any) => {
              setUrlLink({ ...urllink, admissionNotice: e.target.value });
              // LengthCheck(e.target.value, "adminurlError");
            }}
            // error={errorSubmit ? errorCheck.includes("adminurlError") : false}
          />
          {/* <Input
            label="Acceptable Use Policy"
            value={urllink.aup}
            type="text"
            disabled={selectedGlobalDate !== currentGlobalDate}
            onChange={(e: any) => {
              setUrlLink({ ...urllink, aup: e.target.value });
              // LengthCheck(e.target.value, "aupError");
            }}
            // error={errorSubmit ? errorCheck.includes("aupError") : false}
          /> */}
          {selectedGlobalDate === currentGlobalDate && (
            <Box style={{ width: 100, marginTop: 40 }} mb="40px">
              <AdminButton label="Save" onClick={onSaveClick} />
            </Box>
          )}
        </>
      ) : null}
    </div>
  );
};

export default UrlLinks;
