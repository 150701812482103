import { useEffect, useState } from "react";
// import { Input } from "../../../../components/common/input";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { useStyle } from "./student-details.style";
import { AdminButton } from "../../../../components/common/admin-button";
import requestV2 from "../../../../utils/authUtilv2";

type Props = {
  applicationID?: any;
};

const Consents: React.FC<Props> = ({ applicationID }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [consent, setConsent] = useState<any>({});
  const school_uuid = localStorage.getItem("school-id");
  const classes = useStyle();
  const getStudentConsents = async (): Promise<any> => {
    setLoader(true);
    await request({
      url: `school-settings/school-student-application-consents/?student_app_uuid=${applicationID}&school_uuid=${school_uuid}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setConsent(res.data.results);
        setLoader(false);
      } else if (res.data.status_code === -1) {
        setLoader(false);
      }
    });
  };

  const downloadConsents = async () => {
    setLoader(true);
    await requestV2({
      url: `student-application/signed-consents-pdf/?stu_app_uuid=${applicationID}`,
      method: "get",
    }).then((response) => {
      if (response.data.results) {
        window.open(response.data.results);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getStudentConsents();
  }, []);

  return (
    <>
      <Loader open={loader} />
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Box
            className={classes.consentlabel}
            display="flex"
            alignItems="center"
          >
            <Box>
              {`${"Terms : "}`} {consent.terms_consent ? <i>Yes</i> : <i>No</i>}
            </Box>
          </Box>
          <Box
            className={classes.consentlabel}
            display="flex"
            alignItems="center"
          >
            <Box>
              {`${"Code Of Behaviour : "}`}
              {consent.code_of_behavior_consent ? <i>Yes</i> : <i>No</i>}
            </Box>
          </Box>
          <Box
            className={classes.consentlabel}
            display="flex"
            alignItems="center"
          >
            <Box>
              {`${"Anti Bullying Parent : "} `}
              {consent.anti_bullying_parent_consent ? <i>Yes</i> : <i>No</i>}
            </Box>
          </Box>
          <Box
            className={classes.consentlabel}
            display="flex"
            alignItems="center"
          >
            <Box>
              {`${"Picture Permission : "}`}
              {consent.picture_permission_consent ? <i>Yes</i> : <i>No</i>}
            </Box>
          </Box>
          <Box
            className={classes.consentlabel}
            display="flex"
            alignItems="center"
          >
            <Box>
              {`${"Assessment Testing : "}`}
              {consent.assessment_testing_consent ? <i>Yes</i> : <i>No</i>}
            </Box>
          </Box>
        </Box>
        <div style={{ width: "200px" }}>
          <AdminButton
            startIcon="download_icon"
            label="Download Consents"
            onClick={downloadConsents}
          />
        </div>
      </Box>
    </>
  );
};

export default Consents;
