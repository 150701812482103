import { useStyle } from "./com.style";
import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import request from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader";
import Icon from "@material-ui/core/Icon";
import { ViewDoc } from "../../../components/common/view-doc/index";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";

type Props = {
  applicationID: string;
  currentGlobalDate?: any;
  selectedGlobalDate?: any;
};

const columns = [
  { id: "ID", label: "ID", minWidth: 100, isSort: true },
  { id: "Student_Name", label: "Student Name", minWidth: 100, isSort: true },
  {
    id: "Type",
    label: "Type",
    minWidth: 100,
  },
  { id: "Created", label: "Created", minWidth: 100, isSort: true },
  {
    id: "Description",
    label: "Description",
    minWidth: 100,
    align: "right",
    isSort: true,
  },
  {
    id: "View",
    label: "",
    minWidth: 100,
    align: "center",
  },
];

const AllCommunications: React.FC<Props> = ({
  applicationID,
  currentGlobalDate,
  selectedGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortTag, setSortTag] = useState<string>("");
  const [isAsc, setIsAsc] = useState<boolean | string>(true);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const schoolId = localStorage.getItem("school-id");
  const [communication, setCommunication] = useState([{}]);
  const [htmlString, setHtmlString] = useState("");
  const [openDoc, setOpenDoc] = useState<boolean>(false);
  const getCommunication = () => {
    setLoader(true);
    request({
      url: `school-settings/all-communication/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}&sorting_var=${sortTag}&is_asc=${isAsc}`,
      method: "get",
    }).then((res) => {
      setCommunication(res?.data?.results);
      setLoader(false);
    });
  };

  const getHtmlString = (commMessageUuid: string) => {
    setLoader(true);
    request({
      url: `school-settings/all-communication-messages/?school_uuid=${schoolId}&comm_message_uuid=${commMessageUuid}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setHtmlString(res.data.results);
    });
  };

  useEffect(() => {
    getCommunication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGlobalDate, sortTag, isAsc]);

  return (
    <Box className={classes.root}>
      <Loader open={loader} />
      <Box className={classes.container}>
        <Paper
          style={{
            width: "100%",
            overflow: "hidden",
            marginBottom: "40px",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => (
                    <TableCell
                      className={classes.fontFamily}
                      key={`${column.id}${index}`}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                      >
                        {column.label}
                        {column.isSort && (
                          <>
                            <Box marginLeft="15px">
                              <Icon
                                style={{
                                  cursor: "pointer",
                                  color: "#757575",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  setSortTag(column.id);
                                  setIsAsc(true);
                                }}
                              >
                                arrow_upward_icon
                              </Icon>
                              <Icon
                                style={{
                                  cursor: "pointer",
                                  color: "#757575",
                                  fontSize: "18px",
                                }}
                                onClick={() => {
                                  setSortTag(column.id);
                                  setIsAsc("");
                                }}
                              >
                                arrow_downward_icon
                              </Icon>
                            </Box>
                          </>
                        )}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {communication
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item: any, index: number) => {
                    return (
                      <TableRow
                        key={`${item?.ID}${index}`}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <>
                          <TableCell className={classes.fontFamily}>
                            {item?.ID}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.student_name}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.type}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.created}
                          </TableCell>
                          <TableCell className={classes.fontFamily}>
                            {item?.description}
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                width: 80,
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onClick={() => {
                                getHtmlString(item?.comm_message_uuid);
                                setOpenDoc(true);
                              }}
                            >
                              <Icon
                                style={{
                                  color: "#757575",
                                  marginRight: 10,
                                }}
                              >
                                remove_red_eye_icon
                              </Icon>
                              VIEW
                            </span>
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={communication.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <ViewDoc
          openDoc={openDoc}
          setOpen={setOpenDoc}
          docName="html"
          htmlString={htmlString}
        />
      </Box>
    </Box>
  );
};

export default AllCommunications;
