import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    textTransform: "none",
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  squared:{
    borderRadius:10,
  },
  round:{
    borderRadius:50,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
 
  light: {
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "transparent",
      opacity: 0.5,
    },
  },
}));
