import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useState, useEffect } from "react";
import { useStyle } from "./criteria-tab.style";
import { getCatchmentarea } from "../criteria-tab/criteria.api";
import Checkbox from "@material-ui/core/Checkbox";
import { AdminButton } from "../../../components/common/admin-button";
import  request  from "../../../utils/authUtil";
import { Loader } from "../../../components/common/loader";


type Props = {
    applicationID: string;
    Id?: string;
    showCatchmentarea?: boolean;
  };
 
const CatchmentArea: React.FC<Props> = ({applicationID}) => {
    const classes = useStyle();
    const [loader, setLoader] = useState(false);
    const [showcatchmentarea, setshowcatchmentarea] = useState<boolean>(false)
    useEffect(() => {
      setLoader(true)
      getCatchmentarea(applicationID).then((res) => {
        if (res.data.status_code === 1) {
          setshowcatchmentarea(res.data.results.is_show_catchment_area_in_application
            );
        }
      }).then(() => {
        setLoader(false)
      });
    }, []);

  const onSaveClick = () => {
    setLoader(true);
    const data = {
      is_show_catchment_area_in_application: showcatchmentarea 
    };
    request({
      url: `school-settings/application-custom-settings-details/?application_uuid=${applicationID}`,
      method: "put",
      data: data,
    }).then(() => {
      setLoader(false);
    });
  };

    return (
      <div>
        <Loader open={loader} />
        <Grid container direction="column" justifyContent="flex-start">
          <div>
            <Box display="flex" alignItems="center" mb={1}>
              <Checkbox
                color="default"
                onChange={(e) => {
                  setshowcatchmentarea(!showcatchmentarea);
                }}
                checked={showcatchmentarea}
              />
              <p className={classes.text}>Show Catchment Area</p>
            </Box>
          </div>
          <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
            <AdminButton label="Save" onClick={onSaveClick}/>
          </div>
        </Grid>
      </div>
    );
  };
  
  export default CatchmentArea;