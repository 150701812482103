import { makeStyles } from "@material-ui/core";

export const modalStyle = makeStyles((theme) => ({
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: theme.palette.common.white,
    padding: "40px",
    border: 0,
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    width: "35%",
    height: "30%%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  subContainer: {
    backgroundColor: "white",
    padding: "2rem",
    marginLeft: "15rem",
    boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
    width: "30%",
  },
  crossbtn: {
    border: "none",
    backgroundColor: "white",
    fontSize: "1.5rem",
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "90%",
  },
  continueBtn: {},
  data: {
    padding: "0rem 2rem",
    marginBottom: "1rem",

    "& textarea": {
      fontSize: "1.1rem",

      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
      width: "100%",
      height: "20vh",
      "&:focus": {
        borderColor: theme.palette.primary.main,
        outline: "2px solid orange",
      },
    },
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  cancelIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer",
  },
}));
