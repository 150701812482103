import requestv2 from "../../../../utils/authUtilv2";

export const getStudentAppCriteria = async (
    studentId: string,
    schoolId: string | null,
  ): Promise<any> => {
    const res = await requestv2({
      url: `student-application/student-app-criteria-details/?school_uuid=${schoolId}&student_app_uuid=${studentId}`,
      method: "get",
    });
    return res;
  };

export const postCriteriaInfo = async (
    studentId: string,
    schoolId: string | null,
    data: any
): Promise<any> => {
    const res = await requestv2({
        url: `student-application/student-app-criteria-details/?school_uuid=${schoolId}&student_app_uuid=${studentId}`,
        method: "put",
        data,
      });
    return res;
}

export const getInstruction = async (id: string | null): Promise<any> => {
  const res = await requestv2({
    url: `student-application/school-criteria-instruction/?school_uuid=${id}`,
    method: "get",
  });
  return res;
};