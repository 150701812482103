import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useStyle } from "./education-status.style";
import { Input } from "../../../../components/common/input/index";
import { InputDatePicker } from "../../../../components/common/date-picker";
import Icon from "@material-ui/core/Icon";
import { DeleteBox } from "../../../../components/common/delete-dialogbox/index";

type EducationItemProps = {
  data: any;
  index: number;
  onChange?: any;
  errorFrom?: any;
  errorTo?: any;
  errorSchool?: any;
  errorRoll_no?: any;
  onDelete?: any;
  id?: any;
  DateValueCheck?: any;
  disabled?: any;
  showRollNumber?: boolean;
  isRollNumberMandatory?: boolean;
};

const EducationItem: React.FC<EducationItemProps> = ({
  data,
  index,
  onChange,
  errorFrom,
  errorTo,
  errorSchool,
  errorRoll_no,
  id,
  onDelete,
  DateValueCheck,
  showRollNumber = true,
  isRollNumberMandatory,
  disabled,
}) => {
  const styles = useStyle();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <DeleteBox
        index={index}
        id={id}
        onClick={onDelete}
        open={open}
        setOpen={setOpen}
      />
      <Input
        label={
          index === 0
            ? "Name and Address of current primary school attending"
            : "Name of primary school attended"
        }
        onChange={(e: any) => onChange(e.target.value, index, "schoolname")}
        value={data.primarySchool}
        error={errorSchool}
        disabled={disabled}
      />
      {showRollNumber && (
        <Input
          label={
            isRollNumberMandatory
              ? "School Roll Number *"
              : "School Roll Number"
          }
          onChange={(e: any) =>
            onChange(e.target.value, index, "school_roll_number")
          }
          value={data.school_roll_number}
          error={errorRoll_no}
          disabled={disabled}
        />
      )}
      {/* Range Picker Start*/}
      <Box className={styles.dateFlex}>
        <Box className={styles.dateWidth}>
          <InputDatePicker
            label="From"
            onChange={(e: any) => {
              onChange(e, index, "from");
              DateValueCheck(index);
            }}
            value={data.fromDate}
            error={errorFrom}
            disabled={disabled}
          />
        </Box>
        <Box className={styles.dateWidth}>
          <InputDatePicker
            label="To"
            onChange={(e: any) => {
              onChange(e, index, "to");
            }}
            value={data.toDate}
            minDate={data.fromDate !== null && data.fromDate}
            error={errorTo}
            disabled={disabled}
          />
        </Box>
      </Box>
      {index !== 0 && !disabled ? (
        <Icon
          style={{ cursor: "pointer", color: "#757575" }}
          onClick={() => setOpen(true)}
        >
          delete
        </Icon>
      ) : null}
    </div>
  );
};

export default EducationItem;
