import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "./general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";

type Props = {
  applicationID?: string;
  error?: any;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const PreRequisites: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [error, setError] = useState<Number[]>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [idPre, setId] = useState<string>("");
  const [indexPre, setIndex] = useState<number>(0);
  const default_state = [{ preRequisite_uuid: "", preRequisite: "" }];
  const [list, setList] = useState<any>(default_state);

  useEffect(() => {
    setLoader(true);
    request({
      url: `school-settings/pre-requisites-details/?application_uuid=${applicationID}&pre_requisite_for=APPLICATION`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            response.data.results.map((pre_requisite: any) => {
              temp.push({
                preRequisite_uuid: pre_requisite.pre_requisite_uuid || "",
                preRequisite: pre_requisite.pre_requisite,
              });
            });
            setList(temp);
          } else {
            setList(default_state);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  }, [applicationID, selectedGlobalDate]);

  const addPreReqHandler = () => {
    setList([...list, { preRequisite_uuid: "", preRequisite: "" }]);
  };

  const onChange = (value: any, ind: any) => {
    switchReady(false);
    const xyz = [...list];
    const temp = [...error];
    xyz[ind].preRequisite = value;
    setList(xyz);
    if (value.length === 0) {
      if (!temp.includes(ind)) {
        temp.push(ind);
      }
    } else if (temp.includes(ind)) {
      const val = temp.indexOf(ind);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  const onSaveClick = () => {
    const temp = [...error];
    try {
      list.map((item: any, index: any) => {
        if (item.preRequisite.length == 0) {
          if (!temp.includes(index)) {
            temp.push(index);
          }
        } else if (temp.includes(index)) {
          const val = temp.indexOf(index);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        const data = list.map((item: any) => {
          return {
            pre_requisite_uuid: item.preRequisite_uuid,
            pre_requisite: item.preRequisite,
          };
        });
        request({
          url: `school-settings/pre-requisites-details/?application_uuid=${applicationID}&pre_requisite_for=APPLICATION`,
          method: "put",
          data: data,
        }).then((res) => {
          const temp: any = [];
          setLoader(false);
          res.data.results.map((pre_requisite: any) => {
            temp.push({
              preRequisite_uuid: pre_requisite.pre_requisite_uuid || "",
              preRequisite: pre_requisite.pre_requisite,
            });
          });
          setList(temp);
          switchReady(true);
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deletePreRequisite = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/pre-requisites-details/?pre_requisite_for=APPLICATION`,
        method: "delete",
        data: {
          pre_requisite_uuid: id,
        },
      }).then(() => {
        const temp = [...list];
        temp.splice(index, 1);
        setList(temp);
        setError([]);
        setLoader(false);
      });
    } else {
      const temp = [...list];
      temp.splice(index, 1);
      setList(temp);
      setError([]);
    }
  };

  const deleteHandler = (id: string, index: number) => {
    setOpen(true);
    setId(id);
    setIndex(index);
  };

  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <div className={classes.preReqContainer}>
          {list.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                key={index}
              >
                <Box width="90%">
                  <Input
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    label={`Pre-Requisite ${index + 1}`}
                    type="text"
                    value={item.preRequisite}
                    onChange={(e: { target: { value: any } }) =>
                      onChange(e.target.value, index)
                    }
                    error={error.includes(index)}
                  />
                </Box>
                {selectedGlobalDate === currentGlobalDate && (
                  <Icon
                    style={{ cursor: "pointer", color: "#757575", marginTop: "24px" }}
                    onClick={() => deleteHandler(item.preRequisite_uuid, index)}
                  >
                    delete
                  </Icon>
                )}
              </Box>
            );
          })}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <>
            <div style={{ width: "250px", marginTop: "20px" }}>
              <AdminButton
                label="Add further Pre-requisites"
                endIcon="add"
                light
                onClick={addPreReqHandler}
              />
            </div>

            <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
              <AdminButton label="Save" onClick={onSaveClick} />
            </div>
            <DeleteBox
              index={indexPre}
              id={idPre}
              onClick={() => {
                deletePreRequisite(idPre, indexPre);
              }}
              open={open}
              setOpen={setOpen}
            />
          </>
        )}
      </Grid>
    </div>
  );
};

export default PreRequisites;
