import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import cn from "classnames";
import { useStyle } from "./general-tab.style";
import request from "../../../utils/authUtil";
import AppSetting from "./appSetting";
import RelevantDates from "./relevantDates";
import Intake from "./intake";
import UrlLinks from "./urlLinks";
import PreRequisites from "./pre-requisites";
import PreRequisitesEnroll from "./pre-requisites-enroll";
import { ConfirmBox } from "../../../components/common/confirm-box";

type Props = {
  schoolID?: string|undefined;
  applicationID: string;
  minDate?: any;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const GeneralTab: React.FC<Props> = ({
  schoolID,
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [show, setShow] = useState("appSetting");
  const [active, setActive] = useState("appSetting");
  const [saved, setSaved] = useState<boolean>(true);
  const [helperFlag, setHelperFlag] = useState(false);
  const [schoolName, setSchoolName] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [usCode, setUsCode] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [nextTab, setNextTab] = useState<string>("");
  const [error, setError] = useState({});

  useEffect(() => {
    request({
      url: `/school/school-details/?school_uuid=${schoolID}`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          if (Object.keys(response.data.results).length === 0) {
            setHelperFlag(false);
          } else {
            setHelperFlag(true);
            setSchoolName(response.data.results.name);
            setWebsite(response.data.results.website);
            setUsCode(response.data.results.uscode);
          }
        } else {
          setError(response.status_message);
        }
      })
      .catch();
  }, [schoolID]);

  const handleTabChange = (value: string) => {
    setNextTab(value);
    if (saved) {
      setShow(value);
      setActive(value);
    } else {
      setOpenConfirm(true);
    }
  };

  const changeTab = () => {
    setShow(nextTab);
    setActive(nextTab);
    setSaved(true);
    ready(true);
  };

  const readyToSwitch = (val: boolean) => {
    setSaved(val);
    ready(val);
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div>
        <div style={{ width: "45vw" }}>
          {show === "appSetting" ? (
            <AppSetting
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
          {show === "dates" ? (
            <RelevantDates
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
          {show === "intake" ? (
            <Intake
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
          {show === "prereq" ? (
            <PreRequisites
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
          {show === "enrollprereq" ? (
            <PreRequisitesEnroll
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
          {show === "urls" ? (
            <UrlLinks
              applicationID={applicationID}
              switchReady={readyToSwitch}
              selectedGlobalDate={selectedGlobalDate}
              currentGlobalDate={currentGlobalDate}
            />
          ) : null}
        </div>
      </div>
      <div>
        <div className={classes.tab}>
          <button
            className={cn(
              classes.tabButton,
              active === "appSetting" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("appSetting")}
          >
            Headings
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "dates" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("dates")}
          >
            Relevant Dates
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "intake" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("intake")}
          >
            Intake
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "prereq" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("prereq")}
          >
            Application <br />
            Pre-requisites
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "enrollprereq" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("enrollprereq")}
          >
            Enrolment <br />
            Pre-requisites
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "urls" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("urls")}
          >
            URL Links
          </button>
        </div>
      </div>
      <ConfirmBox
        message={`You have unsaved data. Do you want to continue without saving?`}
        onClick={changeTab}
        open={openConfirm}
        setOpen={setOpenConfirm}
      />
    </Grid>
  );
};

export default GeneralTab;
