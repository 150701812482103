import { makeStyles } from "@material-ui/core";
import { reverse } from "dns";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  card: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "27px 38px",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  textOne: {
    margin: 2,
    fontSize: "15px",
    fontWeight: 600,
    color: "#191919",
  },
  textTwo: {
    margin: 2,
    fontSize: "15px",
    lineHeight: "18px",
  },
  footerContainer: {
    marginTop: "60px",
    [theme.breakpoints.up("md")]: {
      marginTop: "60px",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  buttonStyle: {
    width: "298px",
  },
  backButton: {
    textDecoration: "none",
    width: "298px",
  },
  margin: {
    marin: "20px 0px 40px 0px ",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 40px 0px ",
    },
  },
  alignment: {
    [theme.breakpoints.up("md")]: {
      marginRight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "170px 170px",
    marginBottom: "30px",
    marginTop: "15px",
  },
}));
