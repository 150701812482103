/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useStyle } from "./education-status.style";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { InputDatePicker } from "../../../../components/common/date-picker";
import request, { ParentUser } from "../../../../utils/authUtil";
import { InputRadio } from "../../../../components/common/input-radio/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Loader } from "../../../../components/common/loader/index";
import { Type } from "../../../../utils/document-types";
import Image from "../../image-checkbox/index";
import moment from "moment";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const PrevSchoolConsent: React.FC<Props> = ({
  nextPage,
  prevPage,
  stdAppId,
}) => {
  const styles = useStyle();
  const parentID = ParentUser.uuid;
  const schoolID = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [consent, setConsent] = useState<boolean>(false);
  const [consentText, setConsentText] = useState<string>("");
  const [signCheck, setSignCheck] = useState(false);
  const [consentDate, setConsentDate] = useState(new Date());
  const [error, setError] = useState({
    signCheckError: false,
    consentError: false,
  });
  const [localData, setLocalData] = useState({
    signImgUrl: "",
    document_uuid: "",
  });
  const handleChangeRadio = () => {
    setConsent(!consent);
    consent
      ? setError({ ...error, consentError: true })
      : setError({ ...error, consentError: false });
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setConsentText(res.data.results.contact_previous_school_notice);
    })
    request({
      url: `/auth/parent-documents-upload-credentials?user_uuid=${parentID}&document_type=${Type.signature}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setLocalData({
        ...localData,
        signImgUrl: res.data.results.document_url,
        document_uuid: res.data.results.document_uuid,
      });
    });
    setLoader(true);
    request({
      url: `auth/application-server-date/`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      setConsentDate(new Date(res.data.results));
    });
  }, []);

  const validate = () => {
    return {
      signCheckError: signCheck === false,
      consentError: consent === false,
    };
  };

  const onSubmitHandler = () => {
    const data = {
      parent_document_uuid: localData.document_uuid,
      document_page: "previous_consent",
      document_date: moment(consentDate).format("YYYY-MM-DD"),
    };
    request({
      url: `student-application/student-application-parent-documents/?student_app_uuid=${stdAppId}&document_uuid=${localData.document_uuid}`,
      method: "put",
      data: data,
    }).then((res) => {
      if (res.data.status_code === 1) {
        setLoader(false);
        nextPage();
      } else {
        setLoader(false);
      }
    });
  };

  const nextButton = () => {
    const err = validate();
    setError(err);
    if (err.signCheckError === false && err.consentError === false) {
      onSubmitHandler();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        {/* part3 */}
        <Loader open={loader} />
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={4} />
            </Box>
            <Welcome title="Education status" description="Next: School info" />
            <Grid container direction="column">
              <Box>
                <p>
                  <b>Consent to Contact Current Primary School</b>
                </p>
                <p className={styles.textOne}>
                  {consentText}
                </p>
                <Box>
                  {localData.signImgUrl ? (
                    <Image
                      src={localData.signImgUrl}
                      check={signCheck}
                      change={() => {
                        setSignCheck(!signCheck);
                        signCheck
                          ? setError({ ...error, signCheckError: true })
                          : setError({ ...error, signCheckError: false });
                      }}
                      label="Your signature"
                      error={error.signCheckError}
                      errorMessage="Signature confirmation is required"
                    />
                  ) : null}
                </Box>
                <Box>
                  <InputDatePicker
                    label="Date signed"
                    disabled
                    value={consentDate}
                  />
                </Box>
                <Box>
                  <InputRadio
                    radioHolderOne="I consent"
                    onChange={handleChangeRadio}
                    check={consent}
                    error={error.consentError}
                    errorMessage="Your consent is required"
                  />
                </Box>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={nextButton}
                    />
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default PrevSchoolConsent;
