import { useStyle } from "./dropdown.style";
import { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { clearStorage } from "../../../../utils/authUtil";

export const DropDown = () => {
  const classes = useStyle();
  const ref: any = useRef();
  const history = useHistory();

  let infoItems = [
    {
      title: "Profile",
      icon: "person_identity",
      link: `/parent/profile`,
    },
    {
      title: "Change password",
      icon: "settings_icon",
      link: `/parent/change-password`,
    },
    {
      title: "Forget me",
      icon: "person_add_disabled_icon",
      link: `#`,
    },
  ];

  const [drop, setDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setDrop(false);
    });
  }, []);
  const isDropdown = () => {
    setDrop(!drop);
  };

  const logoutHandler = () => {
    clearStorage();
    history.push(`/`);
  };

  const onNavElementClick = () => {
    localStorage.removeItem("studentEnrollmentStatus");
  };

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" fontWeight={600} mr={2}>
        <Icon className={classes.iconlabel}>developer_board</Icon>
        <Link to="/parent/dashboard" className={classes.textOne}>
          Dashboard
        </Link>
      </Box>
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          fontWeight={600}
          className={classes.container}
          onClick={isDropdown}
        >
          <Icon className={classes.iconlabel}>person_identity</Icon>
          <p className={classes.textOne}>Account</p>
          <Icon className={classes.arrowIcon}>
            {drop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
          </Icon>
        </Box>
        <Box
          position="absolute"
          right={10}
          className={drop ? classes.dropContainer : classes.displayNone}
        >
          {infoItems.map((items, key) => (
            <div key={key}>
              <Link to={items.link} style={{ textDecoration: "none" }} onClick={onNavElementClick}>
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.itemBorder}
                  fontWeight={600}
                >
                  <Icon className={classes.icon}>{items.icon}</Icon>
                  <p className={classes.textOne}>{items.title}</p>
                </Box>
              </Link>
            </div>
          ))}
          <Box
            display="flex"
            alignItems="center"
            fontWeight={600}
            onClick={logoutHandler}
          >
            <Icon className={classes.icon}>exit_to_app_icon</Icon>
            <p className={classes.textOne}>Logout</p>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
