export default {
  common: {
    white: "#FFFFFF",
    black: "#000",
  },
  background: {
    default: "#F9F8F7",
  },
  text: {
    primary: "#191919",
  },
  // yellow
  primary: {
    main: "#FBAD16",
    light: "rgba(251, 173, 22, 0.3)",
  },
  //secondary
  secondary: {
    main: "rgba(222, 64, 64, 0.13)",
  },

  // gray
  grey: {
    200: "#E6E4E3", //form border
    300: "#757575", //icons
  },
  divider: "rgba(0, 0, 0, 0.2)",
  // green
  success: {
    main: "#0B8A00",
  },
  // red
  error: {
    main: "#EB5757",
    light: "rgba(222, 64, 64, 0.13)",
  },
};
