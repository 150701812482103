import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    fontFamily: `"LFT Etica", sans-serif`,
    backgroundColor: theme.palette.common.white,
    "&:focus": {
      backgroundColor: theme.palette.common.white,
      outline: "none",
      fontFamily: `"LFT Etica", sans-serif`,
    },
  },

  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  labelError: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  iconCustom: {
    color: "rgba(0, 0, 0, 0.54)",
    right: "15px",
    position: "absolute",
    pointerEvents: "none",
  },
}));
