import { makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import GeneralTab from "./general-tab/index";
import ConsentsTab from "./consents-tab/index";
import CustomSettings from "./custom-settings/index";
import CriteriaTab from "./criteria-tab/index";
import EducationalQuestions from "./educational-questions/index";
import MedicalTab from "./medical-ques-tab";
import { useState, useEffect } from "react";
import request from "../../utils/authUtil";
import ApplicationSetup from "./application-setup/index";
import { useQuery, UseQueryResult } from "react-query";
import Withdrawals from "./withdrawal";
import BookGrant from "./book-grant";
import { ConfirmBox } from "../../components/common/confirm-box";
import { useParams } from "react-router-dom";
import DocumentTab from "./my-document-tab";
import ASDDocumentTab from "./asd-document-tab";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "0px",
    display: "flex",
    flexDirection: "column",
  },
  tabMain: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    fontWeight: "bold",
  },
  tab: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontWeight: "bold",
    "&.Mui-selected": {
      background: "#F7F8FC",
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabs: {
    maxWidth: "100%",
    marginLeft: "-40px",
  },
  heading: {
    fontWeight: "bold",
    fontSize: theme.typography.h5.fontSize,
    paddingBottom: theme.spacing(1),
  },
  container: { paddingTop: 40, paddingRight: 30 },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const styles = useStyle();

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box className={styles.container}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Settings() {
  const styles = useStyle();
  const { tabValue } = useParams<{ tabValue: string }>();
  const [value, setValue] = useState<number>();
  const [flag, setFlag] = useState<boolean>(false);
  const [appId, setAppId] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [nextTab, setNextTab] = useState<number | undefined>();
  const [saved, setSaved] = useState<boolean>(true);
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  useEffect(() => {
    setValue(Number(tabValue));
  }, []);
  const getApplication = async (): Promise<any> => {
    const schoolId = localStorage.getItem("school-id");
    const res = await request({
      url: `/school-settings/school-application/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    });
    return res;
  };
  const {
    isLoading,
    isError,
    isSuccess,
    error,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["applicationId", { selectedGlobalDate }],
    () => getApplication(),
    {
      select: (app) => app.data,
    }
  );

  useEffect(() => {
    if (data) {
      if (data?.results.length === 0) {
        setFlag(false);
      } else {
        setFlag(true);
        setAppId(data?.results[0].application_uuid);
      }
    }
  }, [data]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setNextTab(newValue);
    if (saved) {
      setValue(newValue);
    } else {
      setOpenConfirm(true);
    }
  };

  const changeTab = () => {
    setValue(nextTab);
    setSaved(true);
  };

  const readyToSwitch = (val: boolean) => {
    setSaved(val);
  };

  return (
    <>
      {isLoading ? (
        <div>
          <p>Loading.....</p>
        </div>
      ) : null}
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
        </div>
      ) : null}
      {isSuccess ? (
        <>
          {flag ? (
            <Box className={styles.root}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
                maxWidth="96%"
              >
                <Box className={styles.heading}>General Settings</Box>
              </Box>
              <div className={styles.tabMain}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  className={styles.tabs}
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    className={styles.tab}
                    label="General"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Consents"
                    {...a11yProps(1)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Custom Settings"
                    {...a11yProps(2)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Criteria"
                    {...a11yProps(3)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Educational"
                    {...a11yProps(4)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Medical Questions"
                    {...a11yProps(5)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Withdrawls"
                    {...a11yProps(6)}
                  />
                  <Tab
                    className={styles.tab}
                    label="Document"
                    {...a11yProps(7)}
                  />

                  <Tab
                    className={styles.tab}
                    label="Book Grant"
                    {...a11yProps(8)}
                  />

                  <Tab
                    className={styles.tab}
                    label="ASD Documents"
                    {...a11yProps(9)}
                  />
                </Tabs>

                <TabPanel value={value} index={0}>
                  <GeneralTab
                    schoolID={localStorage.getItem("school-id") || ""}
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ConsentsTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <CustomSettings
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <CriteriaTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <EducationalQuestions
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <MedicalTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <Withdrawals
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>

                <TabPanel value={value} index={7}>
                  <DocumentTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>

                <TabPanel value={value} index={8}>
                  <BookGrant
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                <TabPanel value={value} index={9}>
                  <ASDDocumentTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel>
                {/* <TabPanel value={value} index={8}>
                  <DocumentTab
                    applicationID={appId}
                    ready={readyToSwitch}
                    selectedGlobalDate={selectedGlobalDate}
                    currentGlobalDate={currentGlobalDate}
                  />
                </TabPanel> */}
              </div>
              <ConfirmBox
                message={`You have unsaved data. Do you want to continue without saving?`}
                onClick={changeTab}
                open={openConfirm}
                setOpen={setOpenConfirm}
              />
            </Box>
          ) : (
            <ApplicationSetup />
          )}
        </>
      ) : null}
    </>
  );
}
