/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { useStyle } from "./student-profile.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { Upload } from "../../../../components/common/upload/index";
import { TextArea } from "../../../../components/common/text-area-component/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Type } from "../../../../utils/document-types";
import axios from "axios";
import { Loader } from "../../../../components/common/loader";
import { DownLoad } from "../../../../components/common/download";
import InputLabel from "@material-ui/core/InputLabel";
type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const StudentProfile = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const schoolID = localStorage.getItem("school-id");
  const parentID = ParentUser.uuid;
  const [textInput, setTextInput] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [isDoc, setIsDoc] = useState<boolean>(false);
  const [docDownload, setDocDownload] = useState([
    {
      fileName: "",
      document_uuid: "",
      document_presingne_url: "",
    },
  ]);
  const [docUpload, setdocUpload] = useState([
    { isFile: false, fileName: "", disabled: false, document_uuid: "" },
  ]);
  const [uploadArrayId, setUploadArrId] = useState<any>([]);
  const [error, setError] = useState({
    profile: false,
  });

  const getStudProfDocuments = () => {
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=OTHERS&document_for=APPLICATION`,
      method: "get",
    }).then((res) => {
      const temp: any = [];
      if (Object.keys(res?.data.results).length !== 0) {
        setIsDoc(true);
        res.data.results.map((item: any) => {
          temp.push({
            fileName: item.document_name,
            document_uuid: item.document_uuid,
            document_presingne_url: item.document_presingne_url,
          });
        });
        setDocDownload(temp);
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-application/student-app-profile/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setTextInput(res.data.results.profile);
      }
      setLoader(false);
    });
    getStudProfDocuments();
  }, []);

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any
  ) => {
    setLoader(true);
    const res = await request({
      url: "/auth/parent-documents-upload-credentials",
      method: "post",
      data: {
        user_uuid: userId,
        school_uuid: schoolId,
        document_type: documentType,
        file_name: fileName,
      },
    });
    return res;
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const studentProfileFirst = async (stdAppId: any, fileUrl: any) => {
    setLoader(true);
    const res = await request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}`,
      method: "post",
      data: {
        document_type: Type.other,
        document_url: fileUrl,
        document_for: "APPLICATION",
      },
    });
    return res;
  };

  const studentProfileFinal = async (data: any, stdAppId: any) => {
    setLoader(true);
    const res = await request({
      url: `student-application/student-app-profile/?student_app_uuid=${stdAppId}`,
      method: "put",
      data: data,
    });
    return res;
  };

  const change = (event: any, index: number) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.other,
        event.target.files[0].name
      ).then((response) => {
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      const temp = [...docUpload];
      const tempTwo = [...uploadArrayId];
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          studentProfileFirst(
            stdAppId,
            `${response.url}${response.fields.key}`
          ).then((response) => {
            if (response.data.status_code === 1) {
              temp.splice(index, 1);
              setdocUpload(temp);
              tempTwo.push(response.data.results.document_uuid);
              setUploadArrId(tempTwo);
              getStudProfDocuments();
            }
            setLoader(false);
          });
        }
      );
    });
  };

  const addDocumentsHandler = () => {
    if (docUpload.length < 5) {
      setdocUpload([
        ...docUpload,
        {
          isFile: false,
          fileName: "",
          disabled: false,
          document_uuid: "",
        },
      ]);
    }
  };

  const validate = () => {
    return {
      profile: textInput.length === 0 || textInput == null,
    };
  };

  const setProfile = (e: any) => {
    setTextInput(e.target.value);
    setError({ profile: e.target.value.length === 0 });
  };

  const onSubmitHandler = async () => {
    const err = validate();
    setError(err);
    if (err.profile === false) {
      const data = {
        profile: textInput,
        application_enrollment: "APPLICATION",
        allow_supporting: true,
        is_superadmin: false,
        document_uuid: [],
      };
      studentProfileFinal(data, stdAppId).then((r) => {
        if (r.data.status_code === 1) {
          setLoader(false);
          nextPage();
        }
        if (r.data.status_code === -1) {
          setLoader(false);
        }
      });
    }
  };

  const deleteDocument = (id: string, index: number) => {
    const temp: any = [];
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_uuid=${docDownload[index].document_uuid}`,
      method: "delete",
    }).then((res) => {
      if (res.data.status_code === 1) {
        request({
          url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=OTHERS&document_for=APPLICATION`,
          method: "get",
        }).then((res) => {
          if (Object.keys(res?.data.results).length !== 0) {
            setIsDoc(true);
            res.data.results.map((item: any) => {
              temp.push({
                fileName: item.document_name,
                document_uuid: item.document_uuid,
                document_presingne_url: item.document_presingne_url,
              });
            });
            setDocDownload(temp);
          } else if (Object.keys(res?.data.results).length === 0) {
            setIsDoc(false);
          }
          setLoader(false);
        });
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Loader open={loader} />
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={5} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome title="Student/Child Profile" />
            </div>
            <Grid container direction="column">
              <Box mt={3}>
                <TextArea
                  label="Please tell us a little about the applicant, their favourite
                subjects, hobbies and interests etc."
                  value={textInput}
                  onChange={(e: any) => setProfile(e)}
                  error={error.profile}
                />
              </Box>
              <Box mt={5}>
                {docUpload.map((item: any, key: any) => (
                  <Upload
                    id={`student-prof-signature${key}`}
                    label="Upload supporting document"
                    placeholder="Upload document"
                    onChange={(e: any) => change(e, key)}
                    isFile={item.isFile}
                    file={item.fileName}
                    disabled={item.disabled}
                    showDeleteIcon={false}
                  />
                ))}
              </Box>
              <Box mb={6}>
                <button
                  className={styles.extraButton}
                  onClick={addDocumentsHandler}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    Add more documents
                    <Icon style={{ fontSize: "18px", marginLeft: "15px" }}>
                      add_icon
                    </Icon>
                  </Box>
                </button>
              </Box>
              <Box mt={5}>
                {isDoc === true ? (
                  <>
                    <InputLabel className={styles.label}>
                      List of documents uploaded succesfully
                    </InputLabel>
                    {docDownload.map((item: any, key: any) => (
                      <DownLoad
                        id={`student_others_download${key}`}
                        file={item.fileName}
                        key={key}
                        downloadLink={item.document_presingne_url}
                        onDelete={deleteDocument}
                        index={key}
                      />
                    ))}
                  </>
                ) : null}
              </Box>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button label="Next" onClick={onSubmitHandler} />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    </div>
  );
};
export default StudentProfile;
