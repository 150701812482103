import { useStyle } from "./dropdown.style";
import { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { clearStorage } from "../../../utils/authUtil";
import { useHistory } from "react-router-dom";

export const DropDown = () => {
  const classes = useStyle();
  const ref: any = useRef();
  const history = useHistory();

  const [drop, setDrop] = useState<boolean>(false);
  useEffect(() => {
    document.body.addEventListener("click", (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setDrop(false);
    });
  }, []);
  const isDropdown = () => {
    setDrop(!drop);
  };

  const logoutHandler = () => {
    clearStorage();
    history.push(`/`);
  };

  return (
    <Box display="flex">
      <div ref={ref}>
        <Box
          display="flex"
          alignItems="center"
          fontWeight={600}
          className={classes.container}
          onClick={isDropdown}
        >
          <Icon className={classes.iconlabel}>person_identity</Icon>
          <Icon className={classes.arrowIcon}>
            {drop ? "keyboard_arrow_up_icon" : "keyboard_arrow_down_icon"}
          </Icon>
        </Box>
        <Box
          position="absolute"
          right={10}
          className={drop ? classes.dropContainer : classes.displayNone}
        >
          <Box
            display="flex"
            alignItems="center"
            fontWeight={600}
            onClick={logoutHandler}
          >
            <Icon className={classes.icon}>exit_to_app_icon</Icon>
            <p className={classes.textOne}>Logout</p>
          </Box>
        </Box>
      </div>
    </Box>
  );
};
