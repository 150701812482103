import { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Text } from "../../../components/common/text/index";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./login.style";
import { Footer } from "../../../components/common/footer/index";
import { Input } from "../../../components/common/input/index";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { CheckNetwork } from "../../../utils/helper";
import request, {
  setParent,
  setRefreshToken,
  setToken,
} from "../../../utils/authUtil";
import Logo from "../../../components/common/logo/index";
import { Loader } from "../../../components/common/loader";

type Props = {};

const ParentLogin = (props: Props) => {
  const classes = useStyle();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [validError, setValidError] = useState({
    emailEmpError: false,
    emailAcceptError: false,
    passwordError: false,
  });

  const validate = () => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    return {
      emailEmpError: email.length === 0,
      emailAcceptError: !emailRegex.test(email),
      passwordError: password.length === 0,
    };
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "email":
        setEmail(event.target.value);
        setValidError({
          ...validError,
          emailEmpError: event.target.value.length === 0,
        });
        break;
      case "password":
        setPassword(event.target.value);
        setValidError({
          ...validError,
          passwordError: event.target.value.length === 0,
        });
    }
  };
  const onSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const err = validate();
    setValidError(err);
    if (
      err.emailEmpError === false &&
      err.emailAcceptError === false &&
      err.passwordError === false
    ) {
      setLoader(true);
      request({
        url: "/auth/school-parent-login/",
        method: "post",
        data: {
          email,
          password,
          device_type: "web",
        },
      }).then((response) => {
        setLoader(false);
        if (response.data.status_code === 1) {
          if (response.data.results.user_details) {
            setParent(response.data.results.user_details);
            localStorage.setItem("fullName", response.data.results.user_details.user.first_name.trim() + ' ' + response.data.results.user_details.user.last_name.trim());
            localStorage.setItem(
              "isSignature",
              response.data.results.is_document_uploaded
            );
            localStorage.setItem(
              "type",
              response.data.results.user_details.user_type
            );
          }
          if (response.data.results.access_token) {
            setToken(response.data.results.access_token);
            setRefreshToken(response.data.results.refresh_token);
            window.location.reload();
          }
          setError("");
        } else if (response.data.status_code === -1) {
          setLoader(false);
          setError(response.data.status_message);
        }
      });
    }
  };
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      justifyContent="space-between"
    >
      <CheckNetwork />
      <Loader open={loader} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <Logo />
          <CheckNetwork />
        </div>
        <div style={{ width: "130px" }}>
          <Link to="/registration" style={{ textDecoration: "none" }}>
            <Button label="Register" light />
          </Link>
        </div>
      </Grid>
      <Grid container justifyContent="center">
        <div className={classes.card}>
          <div style={{ marginBottom: 34 }}>
            <Text heading>Login</Text>
          </div>
          {/* error msg */}
          <p
            style={{
              margin: 10,
              color: "#EB5757",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {error}
          </p>
          <form>
            <Grid container direction="column">
              <Input
                label="Your email *"
                value={email}
                onChange={(e: any) => onValueChange(e, "email")}
                error={validError.emailAcceptError || validError.emailEmpError}
                errorMessage={
                  validError.emailEmpError
                    ? "Required"
                    : "Please enter valid email ID"
                }
              />
              <Input
                label="Password *"
                type="password"
                value={password}
                onChange={(e: any) => onValueChange(e, "password")}
                error={validError.passwordError}
              />

              <Box display="flex" alignItems="center" mt="-25px" mb="50px">
                <Link
                  to="/parent/forgot-password"
                  style={{ textDecoration: "none" }}
                  className={classes.btnLink}
                >
                  <u>Forgot Password</u>
                  <Icon style={{ fontSize: "15px" }}>call_made_icon</Icon>
                </Link>
              </Box>
            </Grid>
            <Box display="flex" justifyContent="center">
              <Box mr="30px" width="177px">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Button
                    label="Back"
                    light
                    startIcon="arrow_back_ios"
                    iconSize="13px"
                  />
                </Link>
              </Box>
              <Box width="177px">
                <Link to="/parent/dashboard" style={{ textDecoration: "none" }}>
                  <Button type="submit" label="Login" onClick={onSave} />
                </Link>
              </Box>
            </Box>
          </form>
        </div>
      </Grid>
      <Grid container>
        <div style={{ width: "100vw" }}>
          <Footer center />
        </div>
      </Grid>
    </Grid>
  );
};

export default ParentLogin;
