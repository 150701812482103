import { useState } from "react";
import Box from "@material-ui/core/Box";
import { useStyle } from "./setup.style";
import Grid from "@material-ui/core/Grid";
import { Input } from "../../../components/common/input/index";
import { CustomtextField } from "../custom-settings/text-field/index";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "../../../components/common/button/index";
import request from "../../../utils/authUtil";

type Props = {};
const ApplicationSetup: React.FC<Props> = () => {
  const classes = useStyle();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [assignWait, setAssignWait] = useState(false);
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const onSaveClick = () => {
    request({
      url: "/school-settings/application-details/",
      method: "post",
      data: {
        school_uuid: localStorage.getItem("school-id"),
        year_uuid: selectedGlobalDate,
        title: title,
        description: description,
        email_reciepient: email,
        assign_waiting_list: assignWait,
      },
    })
      .then((response) => {
        if (response.data) {
          if (response.data.results) {
            window.location.reload();
          }
        }
      })
      .catch((e) => {});
  };
  return (
    <Grid container className={classes.root}>
      <Box mb={10}>
        <Grid item xs={12}>
          <Box mb={3}>
            <h2 className={classes.header}>Application Settings</h2>
          </Box>
        </Grid>
        <Box width="500px">
          <Input
            label="Application Title"
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setTitle(e.target.value)}
          />
        </Box>
        <Box width="476px" mb={4}>
          <CustomtextField
            label="Application Description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder="Type Something"
            hide={false}
          />
        </Box>
        <Box width="500px" mb={0}>
          <Input
            label="Email Recipent"
            onChange={(e: {
              target: { value: React.SetStateAction<string> };
            }) => setEmail(e.target.value)}
          />
        </Box>
        <Box width="300px" mb={8}>
          <Box display="flex" alignItems="center">
            <Checkbox
              color="default"
              checked={assignWait}
              onChange={() => setAssignWait(!assignWait)}
            />
            <p className={classes.labelCheckBox}>Assign waiting list numbers</p>
          </Box>
        </Box>
        <Box width="120px">
          <Button label="Submit" onClick={onSaveClick} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ApplicationSetup;
