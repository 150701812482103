import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f9f8f7",
    minHeight: "82vh",
  },
  bodyContainer: {
    maxWidth: "650px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  adminLogin: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  textOne: {
    fontSize: "16px",
    Weight: 400,
    lineHeight: "27px",
  },
  textBold:{
    fontSize:'15px',
    Weight: 600,
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "-20px",
    },
  },
  buttonWidth: {
    margin: "29px 0px auto",
    width:'355px',
    [theme.breakpoints.down("sm")]: {
      width: "355px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "355px",
    },
  },
}));
