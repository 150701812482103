import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "../start-selection.style";
import { AdminButton } from "../../../../../components/common/admin-button/index";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import request from "../../../../../utils/authUtil";
import { Loader } from "../../../../../components/common/loader";

const LotteryNumber = () => {
  const classes = useStyle();
  const history = useHistory();
  const schoolId = localStorage.getItem("school-id");
  const { criteriaId } = useParams<{ criteriaId: string }>();
  const [lotteryNumbers, setLotteryNumbers] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    setLoader(true);
    request({
      url: `/school-settings/offers-lottery-numbers-details/?school_uuid=${schoolId}&criteria_uuid=${criteriaId}`,
      method: "get",
    }).then((res) => {
      setLotteryNumbers(res.data.results);
      setLoader(false);
    });
  }, []);

  const downloadFile = () => {
    setLoader(true);
    request({
      url: `/school-settings/offers-lottery-numbers-csv/?school_uuid=${schoolId}&criteria_uuid=${criteriaId}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      window.open(res.data.results, "_parent");
    });
  };

  return (
    <Grid container direction="row" justifyContent="space-between">
      <div className={classes.subContainer}>
        <Box marginBottom="40px">
          <Box textAlign="center">
            <p className={classes.textOne}>Lottery Screen</p>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box width="200px">
            <AdminButton
              label="Export CSV"
              startIcon="download_icon"
              light
              onClick={downloadFile}
            />
          </Box>
          <Box width="250px">
            <AdminButton
              label="Go to main screen"
              onClick={() => history.push("/admin/offers")}
            />
          </Box>
        </Box>
        <Box className={classes.lotteryBox}>
          <Box
            className={classes.border}
            padding="5px 25px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <p className={classes.textTwo}>Lottery Numbers</p>
            <p className={classes.textTwo}>Application Number</p>
          </Box>
          <Box
            padding="5px 25px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box padding="5px 25px">
              {lotteryNumbers &&
                lotteryNumbers.map((item: any) => (
                  <p key={item.lottery_number}>{item.lottery_number}</p>
                ))}
            </Box>
            <Box padding="5px 125px">
              {lotteryNumbers &&
                lotteryNumbers.map((item: any) => (
                  <p key={item.ID}>{item.ID}</p>
                ))}
            </Box>
          </Box>
        </Box>
      </div>
      <Loader open={loader} />
    </Grid>
  );
};

export default LotteryNumber;
