import { useEffect, useState } from "react";
import { Input } from "../../../../components/common/input";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { useStyle } from "./student-details.style";
import cn from "classnames";
import Grid from "@material-ui/core/Grid";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import { TextArea } from "../../../../components/common/text-area-component/index";
import { AdminButton } from "../../../../components/common/admin-button/index";
import moment from "moment";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import { InputDatePicker } from "../../../../components/common/date-picker/index";
import theme from "../../../../theme";
import { county } from "../../../parent/application/county";
import { ConfirmBox } from "../../../../components/common/confirm-box/index";
import { dropDownHelper } from "../../../../utils/helper";
import { updatePdf } from "./single-application.api";
import requestv2 from "../../../../utils/authUtilv2";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StudentSiblingsDetails from "./student-sibling-details/student-siblings-details";

type Props = {
  applicationID?: any;
  type?: string;
  UIType?: string;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const StudentDetails: React.FC<Props> = ({
  applicationID,
  type,
  UIType,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const school_uuid = localStorage.getItem("school-id");
  const [studentData, setStudentData] = useState<any>({});
  const [prevData, setPrevData] = useState([]);
  const [show, setShow] = useState("studentDetails");
  const [active, setActive] = useState("studentDetails");
  const [formDisable, setFormDisable] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [gender, setGender] = useState<string>("");
  const [errorEdit, setErrorEdit] = useState("");
  const [selectedMotherTongue, setSelectedMotherTongue] = useState("");
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [open, setOpen] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState({
    contact_name: "",
    contact_number: "",
  });
  const [country, setCountry] = useState([
    { name: "-- Select Country --", value: "" },
  ]);
  const [religion, setReligion] = useState([
    { name: "-- Select Religion --", value: "" },
  ]);
  const [nationality, setNationality] = useState([
    { name: "-- Select Nationality --", value: "" },
  ]);
  const [ethnicity, setEthnicity] = useState([
    { name: "-- Select Ethnicity --", value: "" },
  ]);
  const [legacyApplication, setLegacyApplication] = useState({
    isActive: false,
    date: "",
  });
  const classes = useStyle();

  const getReligions = () => {
    request({
      url: `student-application/student-application-religions-list/`,
      method: "get",
    }).then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "religion_name",
        value: "religion_name",
      });
      setReligion([...religion, ...result]);
    });
  };

  const getStudentData = async (): Promise<any> => {
    setLoader(true);
    await request({
      url: `school-settings/student-application/?school_uuid=${school_uuid}&student_app_uuid=${applicationID}`,
      method: "get",
    }).then((res) => {
      setLoader(false);
      if (res.data.status_code === 1) {
        setStudentData(res.data.results.Student_Details);
        setPrevData(res.data.results.Student_Details);
        if (res.data.results.Student_Details.legacy_application_date) {
          setLegacyApplication({
            isActive: true,
            date: res.data.results.Student_Details.legacy_application_date,
          });
        }
      } else if (res.data.status_code === -1) {
        setLoader(false);
      }
    });
  };

  const getGenderData = () => {
    if (type === "enroll") {
      setLoader(true);
      request({
        url: `student-enrolment/student-enrol-application-details/?student_app_uuid=${applicationID}`,
        method: "get",
      }).then((res) => {
        if (res.data) {
          setGender(res.data.results.identify_gender);
        }
        setLoader(false);
      });
    }
  };

  const getCountry = async () => {
    await request({
      url: `student-application/country-details/`,
      method: "get",
    }).then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "name",
        value: "value",
      });
      setCountry([...country, ...result]);
    });
  };

  const getNationality = async () => {
    return await request({
      url: `student-application/nationality-details`,
      method: "get",
    });
  };

  const getMotherTongueAndEthnicityList = async () => {
    const ethnicityList = await requestv2({
      url: `school-settings/ethnicity-cultural-background-list/`,
      method: "get",
    });
    return { ethnicityList };
  };

  const getMotherTongueAndEthnicity = async () => {
    const motherTongueAndEthnicityDetails = await requestv2({
      url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${applicationID}`,
      method: "get",
    });
    return motherTongueAndEthnicityDetails;
  };

  const getEmergencyContactNumber = async () => {
    setLoader(true);
    try {
      const data = await requestv2({
        url: `student-application/student-emergency-contact/?stu_app_uuid=${applicationID}`,
        method: "get",
      });
      if (data.data.status_code !== -1) {
        setEmergencyContact(data.data.results);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  
  useEffect(() => {
    getCountry();
    getStudentData();
    getGenderData();
    getReligions();
    getNationality().then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "nationality_adjective",
        value: "nationality_uuid",
      });
      setNationality([...nationality, ...result]);
    });
    getMotherTongueAndEthnicityList().then((res: any) => {
      const result2 = dropDownHelper({
        items: res?.ethnicityList?.data?.results,
        name: "key",
        value: "value",
      });
      setEthnicity([...ethnicity, ...result2]);
    });
    getMotherTongueAndEthnicity().then((res: any) => {
      setSelectedEthnicity(res?.data?.results?.ethnicity_cultural_background);
      setSelectedMotherTongue(res?.data?.results?.mother_tongue);
    });
    getEmergencyContactNumber();
    //eslint-disable-next-line
  }, []);

  const handleTabChange = (value: string) => {
    if (formDisable) {
      setShow(value);
      setActive(value);
      setFormDisable(true);
    } else {
      setErrorEdit(
        "Please save data before changing tab or click on cancel button"
      );
    }
  };

  const handleChangeRadio = (name: string, index: number) => {
    setStudentData({
      ...studentData,
      gender: name,
    });
  };

  const handleChangeMotherTongueRadio = (name: string) => {
    setSelectedMotherTongue(name);
  };


  const saveStudentInfo = async (): Promise<any> => {
    const data = {
      student_app_uuid: applicationID,
      first_name: studentData.first_name,
      last_name: studentData.last_name,
      legal_first_name: studentData?.legal_first_name || "",
      legal_last_name: studentData?.legal_last_name || "",
      date_of_birth: moment(studentData.date_of_birth).format("YYYY-MM-DD"),
      PPSNumber: studentData.PPSNumber,
      gender: studentData.gender,
      religion: studentData.religion,
      country_of_origin: studentData.country_of_origin,
      nationality: studentData.nationality,
      legacy_application_date: legacyApplication.isActive
        ? legacyApplication.date
        : null,
    };
    await request({
      url: `student-application/student-application-details/`,
      method: "put",
      data,
    }).then(() => {
      getStudentData();
    });
  };

  const saveStudentAddress = async (): Promise<any> => {
    const data = {
      address_line_one: studentData.address_line_one,
      address_line_two: studentData.address_line_two,
      town: studentData.town,
      city: studentData.city,
      country: studentData.country,
      eir_code: studentData.eir_code,
      is_current: true,
      is_permanent: true,
    };
    await request({
      url: `student-application/student-application-address/?student_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    }).then(() => {
      getStudentData();
    });
  };

  const saveStudentProfile = async () => {
    setLoader(true);
    const data = {
      profile: studentData.profile,
      application_enrollment: "APPLICATION",
      allow_supporting: true,
      is_superadmin: true,
      document_uuid: [],
    };
    await request({
      url: `student-application/student-app-profile/?student_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    }).then(() => {
      getStudentData();
    });
  };

  const saveMotherTongueAndEthnicity = async () => {
    setLoader(true);
    const data = {
      mother_tongue: selectedMotherTongue,
      ethnicity_cultural_background: selectedEthnicity,
    };
    await requestv2({
      url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${applicationID}`,
      method: "put",
      data: data,
    }).then(() => {
      getMotherTongueAndEthnicity();
    });
  };

  const saveEmergencyContact = async () => {
    try {
      const saveData = await requestv2({
        url: `student-application/student-emergency-contact/?stu_app_uuid=${applicationID}`,
        method: "put",
        data: emergencyContact,
      });
      if (saveData) {
        getEmergencyContactNumber();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const saveData = () => {
    if (show === "studentDetails") {
      saveStudentInfo();
    }
    if (show === "studentAddress") {
      saveStudentAddress();
    }
    if (show === "studentProfile") {
      saveStudentProfile();
    }
    if (show === "emergencyContact") {
      saveEmergencyContact();
    }
    updatePdf(applicationID);
    saveMotherTongueAndEthnicity();
    setErrorEdit("");
    setFormDisable(true);
  };

  const cancelEdit = () => {
    setFormDisable(true);
    setStudentData(prevData);
    setErrorEdit("");
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <div>
          <Loader open={loader} />
          {/* Student Info Section */}
          <Box width="600px">
            {show === "studentDetails" ? (
              <>
                <Box display="flex">
                  <Box marginRight={3} width="50%">
                    <Input
                      label="First name "
                      value={studentData.first_name}
                      disabled={formDisable}
                      onChange={(e) =>
                        setStudentData({
                          ...studentData,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <Input
                      label="Last name "
                      value={studentData.last_name}
                      disabled={formDisable}
                      onChange={(e) =>
                        setStudentData({
                          ...studentData,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <Box marginRight={3} width="50%">
                    <Input
                      label="Birth Certificate Forename"
                      value={studentData.legal_first_name}
                      disabled={formDisable}
                      onChange={(e) =>
                        setStudentData({
                          ...studentData,
                          legal_first_name: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box width="50%">
                    <Input
                      label="Birth Certificate Surname"
                      value={studentData.legal_last_name}
                      disabled={formDisable}
                      onChange={(e) =>
                        setStudentData({
                          ...studentData,
                          legal_last_name: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Box>

                <Box>
                  <InputDatePicker
                    label="Date of birth *"
                    value={
                      studentData.date_of_birth
                        ? new Date(studentData.date_of_birth)
                        : null
                    }
                    onChange={(e: any) => {
                      setStudentData({
                        ...studentData,
                        date_of_birth: e,
                      });
                    }}
                    disabled={formDisable}
                    maxDate={moment().subtract(11, "years").toDate()}
                  />
                </Box>
                <Input
                  label="PPS"
                  value={studentData.PPSNumber}
                  disabled={formDisable}
                  onChange={(e: any) =>
                    setStudentData({
                      ...studentData,
                      PPSNumber: e.target.value,
                    })
                  }
                />
                {studentData.religion ? (
                  <SelectDropDown
                    label="Religion"
                    item={religion}
                    value={studentData.religion}
                    disabled={formDisable}
                    onChange={(e: any) =>
                      setStudentData({
                        ...studentData,
                        religion: e.target.value,
                      })
                    }
                  />
                ) : null}

                <Box>
                  <InputRadioButton
                    label="Gender"
                    placeHolderOne="FEMALE"
                    placeHolderTwo="MALE"
                    answer={studentData.gender}
                    onChange={handleChangeRadio}
                    index={0}
                    valueOne="FEMALE"
                    valueTwo="MALE"
                    isCheckString
                    isDisabled={formDisable}
                  />
                </Box>
                <Box>
                  <SelectDropDown
                    label="Country of Origin *"
                    item={country}
                    value={studentData.country_of_origin}
                    onChange={(e: any) =>
                      setStudentData({
                        ...studentData,
                        country_of_origin: e.target.value,
                      })
                    }
                    disabled={formDisable}
                  />
                </Box>
                <Box>
                  <SelectDropDown
                    label="Nationality *"
                    item={nationality}
                    value={studentData.nationality}
                    onChange={(e: any) =>
                      setStudentData({
                        ...studentData,
                        nationality: e.target.value,
                      })
                    }
                    disabled={formDisable}
                  />
                </Box>
                <Box>
                  <InputRadioButton
                    label="Is English or Irish the Mother Tongue of the student? *"
                    placeHolderOne="YES"
                    placeHolderTwo="NO"
                    answer={selectedMotherTongue}
                    onChange={handleChangeMotherTongueRadio}
                    index={0}
                    valueOne={true}
                    valueTwo={false}
                    isCheckString
                    isDisabled={formDisable}
                  />
                </Box>
                <Box>
                  <SelectDropDown
                    label={
                      "To which ethnic or cultural background does the above-named student belong? *"
                    }
                    item={ethnicity}
                    value={selectedEthnicity}
                    onChange={(e: any) => {
                      if (e.target.value) {
                        setSelectedEthnicity(e.target.value);
                      }
                    }}
                    disabled={formDisable}
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={legacyApplication.isActive}
                        onChange={() =>
                          setLegacyApplication({
                            ...legacyApplication,
                            isActive: !legacyApplication.isActive,
                          })
                        }
                        name="checkedB"
                        color="primary"
                        disabled={formDisable}
                      />
                    }
                    label="Is Legacy Application"
                  />
                </Box>
                {legacyApplication.isActive && (
                  <Box>
                    <InputDatePicker
                      label="Legacy Application Date"
                      value={
                        legacyApplication.date
                          ? new Date(legacyApplication.date)
                          : null
                      }
                      onChange={(e: any) => {
                        setLegacyApplication({
                          ...legacyApplication,
                          date: e,
                        });
                      }}
                      disabled={formDisable}
                    />
                  </Box>
                )}

                {type === "enroll"
                  ? gender && (
                      <Input
                        label="Perspective gender"
                        value={gender}
                        disabled={formDisable}
                      />
                    )
                  : null}
              </>
            ) : null}
            {show === "siblingDetails" ? (
              <StudentSiblingsDetails
                setFormDisabled={(callback:boolean)=>setFormDisable(callback)}
                open={open}
                setOpen={setOpen}
                isDisabled={formDisable}
                stdAppId={applicationID}
                errorEdit={errorEdit}
                setErrorEdit={setErrorEdit}
                selectedGlobalDate={selectedGlobalDate}
                currentGlobalDate={currentGlobalDate}
              />
            ) : null}
            {show === "studentAddress" ? (
              <>
                <Input
                  label="Address line 1 "
                  value={studentData.address_line_one}
                  disabled={formDisable}
                  onChange={(e) =>
                    setStudentData({
                      ...studentData,
                      address_line_one: e.target.value,
                    })
                  }
                />
                {studentData.address_line_two ? (
                  <Input
                    value={studentData.address_line_two}
                    disabled={formDisable}
                    label="Address line 2"
                    onChange={(e) =>
                      setStudentData({
                        ...studentData,
                        address_line_two: e.target.value,
                      })
                    }
                  />
                ) : null}
                <Input
                  value={studentData.town}
                  disabled={formDisable}
                  label="Town/City *"
                  onChange={(e) => {
                    setStudentData({
                      ...studentData,
                      town: e.target.value,
                    });
                  }}
                />
                <SelectDropDown
                  label={studentData.country === "IE" ? "County *" : "County"}
                  item={county}
                  disabled={formDisable}
                  value={studentData.city}
                  onChange={(e: any) => {
                    setStudentData({
                      ...studentData,
                      city: e.target.value,
                    });
                  }}
                />
                <SelectDropDown
                  label="Country *"
                  item={country}
                  disabled={formDisable}
                  value={studentData.country}
                  onChange={(e: any) =>
                    setStudentData({
                      ...studentData,
                      country: e.target.value,
                    })
                  }
                />
                <Input
                  value={studentData.eir_code}
                  disabled={formDisable}
                  label="Eir code"
                  onChange={(e: any) =>
                    setStudentData({
                      ...studentData,
                      eir_code: e.target.value,
                    })
                  }
                />
              </>
            ) : null}
            {show === "studentProfile" ? (
              <>
                <Box>
                  <TextArea
                    label="Student Profile"
                    disabled={formDisable}
                    value={studentData.profile}
                    onChange={(e: any) =>
                      setStudentData({
                        ...studentData,
                        profile: e.target.value,
                      })
                    }
                  />
                </Box>
              </>
            ) : null}
            {show === "emergencyContact" && (
              <Box>
                <Input
                  value={emergencyContact.contact_name}
                  disabled={formDisable}
                  label="Contact name"
                  onChange={(e: any) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      contact_name: e.target.value,
                    })
                  }
                />
                <Input
                  value={emergencyContact.contact_number}
                  disabled={formDisable}
                  label="Contact number"
                  onChange={(e: any) =>
                    setEmergencyContact({
                      ...emergencyContact,
                      contact_number: e.target.value,
                    })
                  }
                />
              </Box>
            )}
          </Box>
        </div>
        <Box className={classes.subTab} height="190px">
          <button
            className={cn(
              classes.tabButton,
              active === "studentDetails" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("studentDetails")}
          >
            Student Details
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "siblingDetails" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("siblingDetails")}
          >
            Sibling Details
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "studentAddress" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("studentAddress")}
          >
            Student Address
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "studentProfile" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("studentProfile")}
          >
            Student Profile
          </button>
          <button
            className={cn(
              classes.tabButton,
              active === "emergencyContact" ? classes.tabActive : null
            )}
            onClick={() => handleTabChange("emergencyContact")}
          >
            Emergency Contact No.
          </button>
        </Box>
      </Grid>
      {!formDisable && (
        <Box mt="20px" mb="20px">
          <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
        </Box>
      )}
      {show !== "siblingDetails" && localStorage.getItem("employee_type") === "Super Admin" &&
      currentGlobalDate === selectedGlobalDate ? (
        <Box mt="20px" mb="30px" display="flex" alignItems="center">
          <Box width="150px">
            <AdminButton
              // disabled={isDisabled}
              label={!formDisable ? "Save" : "Edit Details"}
              onClick={
                !formDisable
                  ? () => {
                      setOpen(true);
                    }
                  : () => setFormDisable(false)
              }
            />
          </Box>
          {!formDisable && (
            <Box ml="10px" width="150px">
              <AdminButton label="Cancel" light onClick={cancelEdit} />
            </Box>
          )}
        </Box>
      ) : null}
      <ConfirmBox
        open={open && show !== "siblingDetails"}
        setOpen={setOpen}
        onClick={saveData}
        message="Are you sure you want to save ?"
        handleCancel={cancelEdit}
      />
    </>
  );
};

export default StudentDetails;
