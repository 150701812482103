import { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "300px",
    paddingLeft: "150px",
    paddingTop: "12px"
  },
}));

export const CheckNetwork = () => {
  const classes = useStyle();
  const [online, setOnline] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const [prev, setPrev] = useState<boolean>(true);
  const checkOnlineStatus = async () => {
      setOpen(true);
  };

  useEffect(() => {
    setInterval(async () => {
      setOnline(navigator.onLine);
    }, 3000);
  }, []);

  useEffect(() => {
      if (prev !== online) {
        checkOnlineStatus();
        setPrev(navigator.onLine);
      }
  }, [online]);

  const handleClose = () => {
    if (online === true) {
      setOpen(false);
    } 
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert
        onClose={() => handleClose}
        severity={online ? "success" : "error"}
        className={classes.root}
      >
        <AlertTitle>{online ? "Back Online" : "Network Error"}</AlertTitle>
      </Alert>
    </Snackbar>
  );
};

export const SIGNATURE_FONT = {
  fontFamily: 'AgreementSignature',
  fontSize: '50px'
}

export const dropDownHelper = ({ items, name, value }: any) => {
  return items.map((item: any) => {
    return {
      name: item[name],
      value: item[value],
    }
  });
}

export default CheckNetwork;
