import { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Text } from "../../../components/common/text/index";
import Grid from "@material-ui/core/Grid";
import { useStyle } from "./criteria-tab.style";
import { Input } from "../../../components/common/input/index";
import Checkbox from "@material-ui/core/Checkbox";
import { AdminButton } from "../../../components/common/admin-button/index";
import cn from "classnames";
import Cancel from "@material-ui/icons/Cancel";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import {
  getSingleCriteria,
  updateSingleCriteria,
  createDropdownOptions,
  getDropdownOptions,
} from "./criteria.api";
import { Loader } from "../../../components/common/loader/index";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

const CriteriaDetail = () => {
  const classes = useStyle();
  const history = useHistory();
  const { criteriaId } = useParams<{ criteriaId: string }>();
  const [loader, setLoader] = useState<boolean>(true);
  const [question, setQuestion] = useState<string>("");
  const [checkValue, setCheckValue] = useState<boolean>(false);
  const [furtherQuestion, setFurtherQuestion] = useState<string>("");
  const [secondFurtherQuestionRequired, setSecondFurtherQuestionRequired] = useState<boolean>(false);
  const [secondFurtherQuestion, setSecondFurtherQuestion] = useState<string>("");
  const [secondFurtherQuestionEmpty, setSecondFurtherQuestionEmpty] = useState<boolean>(false);
  const [optValues, setOptValues] = useState<string[]>([]);
  const [inpval, setInpval] = useState<string>("");
  const [answerType, setAnswerType] = useState<string>("text");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string | null>(null);
  const [editValueIndex, setEditValueIndex] = useState<number | null>(null);
  const [newDropDownValue, setNewDropDownValue] = useState<string | null>(null);
  const [isDropdownOptions, setIsDropdownOptions] = useState<boolean | null>(
    false
  );
  const [questionEmpty, setQuestionEmpty] = useState<boolean>(false);
  const [furtherQuestionEmpty, setFurtherQuestionEmpty] =
    useState<boolean>(false);
  const [dropError, setDropError] = useState<boolean>(false);

  const radioHandler = (type: string) => {
    setAnswerType(type);
    if (type === "drop") {
      setIsOpen(true);
    }
  };
  const addOptions = () => {
    if (inpval) {
      setOptValues([...optValues, inpval]);
      setInpval("");
    }
  };

  useEffect(() => {
    getSingleCriteria(criteriaId).then((response) => {
      setQuestion(response.data.results.question);
      setCheckValue(response.data.results.further_detail_required);
      setAnswerType(response.data.results.further_question_type || "text");
      setFurtherQuestion(response.data.results.follow_up_question);
      setSecondFurtherQuestionRequired(response.data.results.further_question_required);
      setSecondFurtherQuestion(response.data.results.further_question);
      setOptValues(response.data.results.followup_question_details || []);
      if (
        response.data.results.followup_question_details !== null &&
        response.data.results.followup_question_details.length
      ) {
        setIsDropdownOptions(true);
      }
      setLoader(false);
    });
  }, [criteriaId]);

  const ErrorCheck = () => {
    if (question === "") {
      setQuestionEmpty(true);
      return false;
    } else if (checkValue) {
      if (furtherQuestion === "") {
        setFurtherQuestionEmpty(true);
        return false;
      }
      if (answerType === "drop" && !isDropdownOptions) {
        setDropError(true);
        return false;
      }
    }
    if (secondFurtherQuestionRequired) {
      if (secondFurtherQuestion === "") {
        setSecondFurtherQuestionEmpty(true);
        return false;
      }
    }
    return true;
  };
  const updateCriteria = () => {
    setLoader(true);
    if (ErrorCheck()) {
      updateSingleCriteria({
        criteria_uuid: criteriaId,
        question: question,
        further_question_required: secondFurtherQuestionRequired,
        further_question: secondFurtherQuestion,
        further_detail_required: checkValue,
        further_question_type: answerType,
        follow_up_question: furtherQuestion,
        require_supporting_doc: false,
      }).then((response) => {
        if (response.data.results) {
          setLoader(false);
        }
      });
    } else {
      setLoader(false);
    }
  };

  const createDropdown = () => {
    setLoader(true);
    createDropdownOptions(criteriaId, optValues).then((res) => {
      if (res.data.results.details_list.length) {
        setIsDropdownOptions(true);
        setOptValues(res.data.results.details_list);
      }
    });
    setIsOpen(false);
    setLoader(false);
  };

  const removeOption = (val: string) => {
    const temp = optValues;
    const index = temp.indexOf(val);
    temp.splice(index, 1);
    setOptValues([...temp]);
  };

  const closePopUp = () => {
    setIsOpen(false);
    setLoader(false);
    getDropdownOptions(criteriaId).then((response) => {
      setOptValues(response.data.results.details_list);
    });
  };

  const questionChangeHandler = (value: string) => {
    if (value !== "") {
      setQuestionEmpty(false);
    }
    setQuestion(value);
  };

  const FQChangeHandler = (value: string) => {
    if (value !== "") {
      setFurtherQuestionEmpty(false);
    }
    setFurtherQuestion(value);
  };

  const SecondFQChangeHandler = (value: string) => {
    if (value !== "") {
      setSecondFurtherQuestionEmpty(false);
    }
    setSecondFurtherQuestion(value);
  };

  const handleUpdateDropDownValue = () => {
    setOptValues((values: any) => values.map((val: any, index: number) => {
      if (val === editValue && editValueIndex === index) {
        return newDropDownValue;
      }
      return val;
    }));
    setEditValue(null);
    setEditValueIndex(null);
  }

  return (
    <Box marginLeft="328px" marginRight="40px">
      <Icon
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/admin/settings/3");
        }}
      >
        arrow_back
      </Icon>
      <Text heading>Criteria Details</Text>
      <Text paragraph>Edit the details of the criteria</Text>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        style={{ marginBottom: 20, marginTop: 30, width: "60%" }}
      >
        <div className={classes.inputContainer}>
          <Input
            value={question}
            onChange={(e: any) => questionChangeHandler(e.target.value)}
            error={questionEmpty}
            //   disabled={selectedGlobalDate !== currentGlobalDate}
            maxlength="200"
          />
        </div>
        <Box display="flex" alignItems="center">
          <Checkbox
            color="default"
            onChange={() => setCheckValue(!checkValue)}
            checked={checkValue}
            style={{ marginLeft: "-13px" }}
            //   disabled={selectedGlobalDate !== currentGlobalDate}
          />
          <Text paragraph>Further details required?</Text>
        </Box>
        {checkValue && (
          <>
            <div className={classes.inputContainer}>
              <Input
                label="Further details question"
                value={furtherQuestion}
                onChange={(e: any) => FQChangeHandler(e.target.value)}
                error={furtherQuestionEmpty}
                // disabled={selectedGlobalDate !== currentGlobalDate}
              />
            </div>
            <Text subHeading>Please select one of the answer types ? </Text>
            <br />
            <Grid container direction="row">
              <button
                className={cn(
                  classes.typeBtn,
                  answerType == "text" && classes.greenButton
                )}
                style={{ width: 200 }}
                onClick={() => radioHandler("text")}
              >
                Text
              </button>
              <button
                className={cn(
                  classes.typeBtn,
                  answerType == "drop" &&
                    isDropdownOptions === false &&
                    classes.redButton,
                  answerType == "drop" &&
                    isDropdownOptions &&
                    classes.greenButton
                )}
                style={{ width: 200 }}
                onClick={() => radioHandler("drop")}
              >
                Drop-down
              </button>
            </Grid>
            <Box display="flex" alignItems="center" style={{ marginTop: '20px', marginBottom: '30px' }}>
              <Checkbox
                color="default"
                onChange={() => setSecondFurtherQuestionRequired(!secondFurtherQuestionRequired)}
                checked={secondFurtherQuestionRequired}
              //   disabled={selectedGlobalDate !== currentGlobalDate}
              />
              <Text paragraph>Further question required? (This will be asked after detail question)</Text>
            </Box>
            {secondFurtherQuestionRequired && (
              <>
                <div style={{ marginLeft: '13px', marginTop: '-10px' }}>
                  <Input
                    label="Further question"
                    value={secondFurtherQuestion}
                    onChange={(e: any) => SecondFQChangeHandler(e.target.value)}
                    error={secondFurtherQuestionEmpty}
                  // disabled={selectedGlobalDate !== currentGlobalDate}
                  />
                </div>
              </>)}
          </>
        )}
        {dropError && (
          <p style={{ color: "red" }}>Please add Dropdown options</p>
        )}
        <div style={{ width: 100, marginTop: 30 }}>
          <AdminButton
            label="Save"
            disabled={answerType === "drop" && isDropdownOptions === false}
            onClick={updateCriteria}
          />
        </div>
      </Grid>
      <Loader open={loader} />
      {/* POP UP */}
      <div
        className={cn(
          classes.popUpScreen,
          isOpen ? classes.openPopup : classes.closePopup
        )}
      >
        <div className={classes.formPopup}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            spacing={3}
            className={classes.popUpContainer}
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Text heading>Drop-down Values</Text>
              <div className={classes.closeBtn} onClick={closePopUp}>
                &times;
              </div>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={6}>
                <Input
                  label="Add Drop-down options here"
                  value={inpval}
                  onChange={(e: any) => setInpval(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} alignItems="center">
                <div style={{ cursor: "pointer" }} onClick={addOptions}>
                  <AddCircleRoundedIcon
                    fontSize="large"
                    style={{ color: "green" }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
              className={classes.optionsDiv}
            >
              {optValues.map((opt,index) => {
                return (
                  <Grid item xs={2} key={index}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      className={classes.options}
                    >
                      <Tooltip title={opt} interactive>
                        <div style={{ width: '800px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                          onClick={() => {
                            setEditValue(opt)
                            setNewDropDownValue(opt)
                            setEditValueIndex(index)
                          }}>
                          <Typography noWrap>{opt}</Typography>
                        </div>
                      </Tooltip>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => removeOption(opt)}
                      >
                        <Cancel
                          fontSize="small"
                          style={{
                            color: "black",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={3}>
                <AdminButton
                  label="Click to Save"
                  disabled={!optValues.length}
                  onClick={createDropdown}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {editValue ? <div
        className={cn(
          classes.popUpScreen,
          classes.openPopup
        )}
      >
        <div className={classes.formPopup}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            spacing={3}
            className={classes.popUpContainer}
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Text heading>Edit Value</Text>
              <div className={classes.closeBtn} 
              onClick={() => {
                setEditValue(null); setEditValueIndex(null)
              }}
              >
                &times;
              </div>
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={6} style={{ marginTop: '10px' }}>
                  <Input
                    label="Enter new value"
                    value={newDropDownValue}
                    onChange={(e: any) => setNewDropDownValue(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <AdminButton
                  label="Click to Save"
                  disabled={!optValues.length}
                  onClick={handleUpdateDropDownValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div> : null}
    </Box>
  );
};

export default CriteriaDetail;
