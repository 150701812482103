import { useStyle } from "./progressbar.style";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
type progressProps = {
  steps?: number;
  total?: number;
};

export const ProgressBar: React.FC<progressProps> = ({
  steps = 0,
  total = 8,
}) => {
  const classes = useStyle();
  const Progress = (100 / total) * steps;
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        size="80px"
        thickness={5}
        className={classes.progressTop}
      />
      <CircularProgress
        variant="determinate"
        value={Progress}
        className={classes.progressBottom}
        size="80px"
        thickness={5}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" className={classes.label}>
          {steps} of {total}
        </Typography>
      </Box>
    </Box>
  );
};
