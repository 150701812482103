import request3 from "../../../utils/authUtilv3"

interface documentData {
  asd_document_uuid: string;
  asd_document_description: string;
  further_details_required: boolean;
  follow_up_asd_document_question: string,
}
interface postDocumentData {
  id: string;
  document: documentData[];
}

export const postASDdocument = async ({
  id,
  document,
}: postDocumentData): Promise<any> => {
  const data = document.map((item: any) => {
    return {
      asd_document_uuid: item.asd_document_uuid || "",
      asd_document_description: item.asd_document_description,
      further_detail_required: item.further_details_required,
      follow_up_asd_document_question: item.further_details_required ? item.follow_up_asd_document_question : "",
    };
  });

  const res = await request3({
    url: `school-settings/school_asd_document_question/?application_uuid=${id}`,
    method: "put",
    data,
  });
  return res;
};

export const getASDDocument = async (id: string): Promise<any> => {
  const res = await request3({
    url: `school-settings/school_asd_document_question/?application_uuid=${id}`,
    method: "get",
  });
  return res;
}
