import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { useStyle } from "./file-upload.style";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../../components/common/button/index";
import { Upload } from "../../../../components/common/upload/index";
import { DownLoad } from "../../../../components/common/download";
import axios from "axios";
import { Type } from "../../../../utils/document-types";
type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const FileUpload: React.FC<Props> = ({ nextPage, prevPage, stdAppId }) => {
  const styles = useStyle();
  const [isEnrolmentForm, setIsEnrolmentForm] = useState<boolean>(false);

  useEffect(() => {
    setIsEnrolmentForm(
      window.location?.pathname?.split("/")[2] === "enrolment"
    );
  }, []);

  const parentID = ParentUser.uuid;
  const schoolID = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);

  const [prevDoc, setPrevDoc] = useState({
    doc_prev_name: "",
    document_uuid: "",
    isFile: false,
    isUploaded: false,
    document_presingne_url: "",
  });

  const [utilDoc, setUtilDoc] = useState({
    doc_util_name_one: "",
    document_uuid_one: "",
    isFileOne: false,
    isUploadedOne: false,
    document_presingne_url_one: "",
  });

  const [utilDocTwo, setUtilDocTwo] = useState({
    doc_util_name_two: "",
    document_uuid_two: "",
    isFileTwo: false,
    isUploadedTwo: false,
    document_presingne_url_two: "",
  });

  const [error, setError] = useState<any>({
    prev_doc_empty_err: false,
    prev_doc_accept_err: false,
    util_one_empty_err: false,
    util_one_accept_err: false,
    util_two_empty_err: false,
    util_two_accept_err: false,
  });

  const [utility, setUtility] = useState({
    one: false,
    two: false,
  });

  const getCustomSettings = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setUtility({
        one: res.data.results.request_utility_bill,
        two: res.data.results.request_utility_bill_second,
      });
      setLoader(false);
    });
  };

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    file: any
  ) => {
    if (fileName) {
      if (
        !fileName.toLowerCase().match(/\.(jpg|jpeg|pdf|png)$/) &&
        documentType === Type.previous_school_report
      ) {
        setPrevDoc({
          ...prevDoc,
          doc_prev_name: "",
          isFile: false,
        });
        setError({
          ...error,
          prev_doc_empty_err: false,
          prev_doc_accept_err: true,
        });
        return null;
      }
      if (
        (!fileName.toLowerCase().match(/\.(jpg|jpeg|pdf|png)$/)) &&
        documentType === Type.utility
      ) {
        setUtilDoc({
          ...utilDoc,
          doc_util_name_one: "",
          isFileOne: false,
        });
        setError({
          ...error,
          util_one_empty_err: false,
          util_one_accept_err: true,
        });
        return null;
      }
      if (
        (!fileName.toLowerCase().match(/\.(jpg|jpeg|pdf|png)$/)) &&
        documentType === Type.utilityTwo
      ) {
        setUtilDocTwo({
          ...utilDocTwo,
          doc_util_name_two: "",
          isFileTwo: false,
        });
        setError({
          ...error,
          util_two_empty_err: false,
          util_two_accept_err: true,
        });
        return null;
      } else {
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const getPreviousSchoolReport = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=${Type.previous_school_report}&document_for=ENROLMENT`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length > 0) {
        setPrevDoc({
          ...prevDoc,
          doc_prev_name: res.data?.results[0].document_name,
          document_uuid: res.data.results[0].document_uuid,
          isFile: true,
          isUploaded: true,
          document_presingne_url: res.data.results[0].document_presingne_url,
        });
      } else if (res.data.results.length === 0) {
        setPrevDoc({
          ...prevDoc,
          doc_prev_name: "",
          document_uuid: "",
          isFile: false,
          isUploaded: false,
          document_presingne_url: "",
        });
      }
      setLoader(false);
    });
  };

  const getUtilDocs = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=${Type.utility}&document_for=ENROLMENT`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length !== 0) {
        setUtilDoc({
          ...utilDoc,
          doc_util_name_one: res.data?.results[0].document_name,
          document_uuid_one: res.data.results[0].document_uuid,
          isFileOne: true,
          isUploadedOne: true,
          document_presingne_url_one:
            res.data.results[0].document_presingne_url,
        });
      } else {
        setUtilDoc({
          ...utilDoc,
          doc_util_name_one: "",
          document_uuid_one: "",
          isFileOne: false,
          isUploadedOne: false,
          document_presingne_url_one: "",
        });
      }
      setLoader(false);
    });
  };

  const getUtilTwo = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=${Type.utilityTwo}&document_for=ENROLMENT`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length !== 0) {
        setUtilDocTwo({
          ...utilDocTwo,
          doc_util_name_two: res.data?.results[0].document_name,
          document_uuid_two: res.data.results[0].document_uuid,
          isFileTwo: true,
          isUploadedTwo: true,
          document_presingne_url_two:
            res.data.results[0].document_presingne_url,
        });
      } else {
        setUtilDocTwo({
          ...utilDocTwo,
          doc_util_name_two: "",
          document_uuid_two: "",
          isFileTwo: false,
          isUploadedTwo: false,
          document_presingne_url_two: "",
        });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getCustomSettings();
    getPreviousSchoolReport();
    getUtilDocs();
    getUtilTwo();
  }, []);

  const setFileName = (type: string, name: string) => {
    if (type === Type.previous_school_report) {
      setPrevDoc({
        ...prevDoc,
        doc_prev_name: name,
        isFile: true,
      });
    }
    if (type === Type.utility) {
      setUtilDoc({
        ...utilDoc,
        doc_util_name_one: name,
        isFileOne: true,
      });
    }
    if (type === Type.utilityTwo) {
      setUtilDocTwo({
        ...utilDocTwo,
        doc_util_name_two: name,
        isFileTwo: true,
      });
    }
  };

  const validate = () => {
    return {
      // prev_doc_empty_err: prevDoc.doc_prev_name === "",
      util_one_empty_err:
        utility.one === true && utilDoc.doc_util_name_one === "",
      util_two_empty_err:
        utility.two === true && utilDocTwo.doc_util_name_two === "",
    };
  };

  const onSubmit = async (
    url: string,
    documentType: string,
    document_uuid: string
  ) => {
    await request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_for=APPLICATION&document_uuid=${document_uuid}`,
      method: "put",
      data: {
        document_type: documentType,
        document_url: url,
        document_for: "ENROLMENT",
      },
    }).then(() => {
      if (documentType === Type.previous_school_report) {
        getPreviousSchoolReport();
      }
      if (documentType === Type.utility) {
        getUtilDocs();
      }
      if (documentType === Type.utilityTwo) {
        getUtilTwo();
      }
    });
  };

  const onNext = () => {
    const err = validate();
    setError(err);
    if (
      // !err.prev_doc_empty_err &&
      !err.util_one_empty_err &&
      !err.util_two_empty_err
    ) {
      nextPage();
    }
  };

  const change = (event: any, documentType: string, document_uuid: string) => {
    setLoader(true);
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        documentType,
        event.target?.files[0]?.name,
        event.target.files
      ).then((response) => {
        if (response === null) {
          setLoader(false);
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setFileName(documentType, event.target.files[0].name);
          onSubmit(
            `${response.url}${response.fields.key}`,
            documentType,
            document_uuid
          );
          if (documentType === Type.previous_school_report) {
            setError({
              ...error,
              prev_doc_empty_err: false,
              prev_doc_accept_err: false,
            });
          }
          if (documentType === Type.utility) {
            setError({
              ...error,
              util_one_empty_err: false,
              util_one_accept_err: false,
            });
          }
          if (documentType === Type.utilityTwo) {
            setError({
              ...error,
              util_two_empty_err: false,
              util_two_accept_err: false,
            });
          }
        }
      );
    });
  };

  const deleteDocument = (id: string, type: string) => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_uuid=${id}`,
      method: "delete",
    }).then(() => {
      if (type === Type.previous_school_report) {
        getPreviousSchoolReport();
      }
      if (type === Type.utility) {
        getUtilDocs();
      }
      if (type === Type.utilityTwo) {
        getUtilTwo();
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Loader open={loader} />
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>{isEnrolmentForm ? "Enrolment" : "Application"}</div>
            </div>
            <ProgressBar total={7} steps={2} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome title="File upload" description="Next: Documents" />
          </div>
          {!prevDoc.isUploaded ? (
            <Upload
              id="prev_school_doc"
              placeholder="Upload report"
              label="Please upload a copy of the report from previous school"
              onChange={(e: any) =>
                change(e, Type.previous_school_report, prevDoc.document_uuid)
              }
              file={prevDoc.doc_prev_name}
              isFile={prevDoc.isFile}
              error={error.prev_doc_empty_err || error.prev_doc_accept_err}
              errorMessage={
                error.prev_doc_accept_err === true
                  ? "Only files .jpg .jpeg .png .pdf are allowed"
                  : "Required"
              }
              onDelete={() =>
                deleteDocument(utilDocTwo.document_uuid_two, Type.utilityTwo)
              }
              showDeleteIcon={false}
            />
          ) : (
            <DownLoad
              id="prev_school_doc"
              label="Download current school report *"
              file={prevDoc.doc_prev_name}
              downloadLink={prevDoc.document_presingne_url}
              onDelete={() =>
                deleteDocument(
                  prevDoc.document_uuid,
                  Type.previous_school_report
                )
              }
            />
          )}
          {utility.one &&
            (!utilDoc.isUploadedOne ? (
              <Upload
                id="util_one"
                placeholder="Upload utility bill"
                label="Please upload a copy of Utitlity Bill of home address"
                belowLabel="Electricity, Gas, Landline phone bill only accepted"
                onChange={(e: any) =>
                  change(e, Type.utility, utilDoc.document_uuid_one)
                }
                file={utilDoc.doc_util_name_one}
                isFile={utilDoc.isFileOne}
                error={error.util_one_empty_err || error.util_one_accept_err}
                errorMessage={
                  error.util_one_accept_err === true
                    ? "Only files jpg .jpeg .png .pdf are allowed"
                    : "Required"
                }
                showDeleteIcon={false}
              />
            ) : (
              <DownLoad
                id="util_one_doc"
                label="Download utility bill document *"
                file={utilDoc.doc_util_name_one}
                downloadLink={utilDoc.document_presingne_url_one}
                onDelete={() =>
                  deleteDocument(utilDoc.document_uuid_one, Type.utility)
                }
              />
            ))}
          {utility.two &&
            (!utilDocTwo.isUploadedTwo ? (
              <Upload
                id="util_two"
                placeholder="Upload utility bill"
                label="Please upload a second copy of Utitlity Bill of home address"
                belowLabel="Electricity, Gas, Landline phone bill only accepted"
                onChange={(e: any) =>
                  change(e, Type.utilityTwo, utilDocTwo.document_uuid_two)
                }
                file={utilDocTwo.doc_util_name_two}
                isFile={utilDocTwo.isFileTwo}
                error={error.util_two_empty_err || error.util_two_accept_err}
                errorMessage={
                  error.util_two_accept_err === true
                    ? "Only files jpg .jpeg .pdf are allowed"
                    : "Required"
                }
                showDeleteIcon={false}
              />
            ) : (
              <DownLoad
                id="util_two_doc"
                label="Download utility bill document *"
                file={utilDocTwo.doc_util_name_two}
                downloadLink={utilDocTwo.document_presingne_url_two}
                onDelete={() =>
                  deleteDocument(utilDocTwo.document_uuid_two, Type.utilityTwo)
                }
              />
            ))}
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onNext}
              />
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default FileUpload;
