import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: "14px 18px",
    minHeight: "100vh",
  },
  logoImage: {
    width: 50,
    height: 65,
  },
  card: {
    backgroundColor: theme.palette.common.white,
    // marginTop: 52,
    // marginBottom: 52,
    // width: "30%",
    width: "50%",
    marginTop: 82,
    marginBottom: 82,
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },

  btnLink: {
    padding: "0px",
    border: "none",
    fontSize: "13px",
    color: "#1A1A1A",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
}));
