import { Route, Switch } from "react-router-dom";
import AdminLogin from "../pages/common/admin-login";
import Communications from "../pages/admin/Communications";
import Dashboard from "../pages/admin/dashboard/index";
import Documents from "../pages/admin/document-tab/index";
import MySchool from "../pages/admin/my-school/index";
import LandingPage from "../pages/common/landing-page";
import Header from "./admin-header/Header";
import SideBar from "./SideBar";
import Settings from "../pages/admin/Settings";
import Applications from "../pages/admin/applications/index";
import Enrolments from "../pages/admin/enrollment/index";
import Offers from "../pages/admin/offers/index";
import StartSelection from "../pages/admin/offers/start-selection/index";
import LotteryNumber from "../pages/admin/offers/start-selection/lottery/lottery-number";
import LotterySelection from "../pages/admin/offers/start-selection/lottery/lottery-selection";
import StudentEditInfo from "../pages/admin/applications/single-application/index";
import WithDrawApplication from "../pages/admin/offers/withdrawal-application/index";
import ManualSelection from "../pages/admin/offers/manual-selection/index";
import WaitingList from "../pages/admin/offers/waiting-list/index";
import WaitingLottery from "../pages/admin/offers/waiting-list/waiting-lottery";
import StudentEnrolView from "../pages/admin/enrollment/single-enrollment/index";
import { CheckNetwork } from "../utils/helper";
import CriteriaDetail from "../pages/admin/criteria-tab/detail-criteria";
import request from "../utils/authUtil";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import Users from "../pages/admin/users-tab";

const useStyle = makeStyles((theme) => ({
  content: {
    marginLeft: "calc(100vw - 85vw) ",
    '@media (max-width: 1023px)': {
      marginLeft: "calc(100vw - 95vw) ",
    }
  },
  concoll: {
    '@media (max-width: 1023px)': {
      marginLeft: "220px !important ",
    }
  },
  ham: {
    cursor: "pointer",
    position: "absolute",
    marginTop: "39px",
    marginLeft: "calc(100vw - 95vw) ",
  }
}));

interface Props { }
interface globalYearI {
  is_archived: boolean;
  is_current: boolean;
  year: string;
  year_uuid: string;
}
const AdminRoutes = (props: Props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const styles = useStyle();
  const token = localStorage.getItem("token");
  const [globalYear, setGlobalYear] = useState<globalYearI[]>([
    {
      is_archived: false,
      is_current: false,
      year: "",
      year_uuid: "",
    },
  ]);
  const [selectedYear, setSelectedYear] = useState<any>("");
  const getGlobalYearDetails = () => {
    request({
      url: `school-settings/application-year-details/`,
      method: "get",
    }).then((response) => {
      if (response.data) {
        if (Object.keys(response?.data.results).length !== 0) {
          setGlobalYear(response.data.results);
          var currentYear = response.data.results.filter(
            (element: any) => element.is_current === true
          );
          if (
            localStorage.getItem("selectedGlobalDate") !== null ||
            undefined
          ) {
            setSelectedYear(localStorage.getItem("selectedGlobalDate"));
          } else {
            setSelectedYear(currentYear[0]?.year_uuid);
            localStorage.setItem(
              "selectedGlobalDate",
              currentYear[0]?.year_uuid
            );
          }
          localStorage.setItem("currentGlobalDate", currentYear[0].year_uuid);
        }
      }
    });
  };

  const handleChangeGlobalDate = (event: any) => {
    localStorage.setItem("selectedGlobalDate", event.target.value);
    window.location.reload();
  };
  useEffect(() => {
    getGlobalYearDetails();
  }, []);

  if (!token) {
    return <AdminLogin />;
  }

  return (
    <div>
      <CheckNetwork />
      <Switch>
        {/* The route which will not render the sidebar and navbar components */}
        <Route exact path="/admin/start-selection">
          <StartSelection />
        </Route>
        <Route exact path="/admin/lottery/:criteriaId">
          <LotteryNumber />
        </Route>
        <Route exact path="/admin/lottery-selection">
          <LotterySelection />
        </Route>
        <Route exact path="/admin/withdraw-application">
          <WithDrawApplication />
        </Route>
        <Route exact path="/admin/manual-selection">
          <ManualSelection />
        </Route>
        <Route exact path="/admin/waiting-list">
          <WaitingList />
        </Route>
        <Route exact path="/admin/waiting-list-lottery">
          <WaitingLottery />
        </Route>
        <Route>
          <div>
            <MenuIcon className={`${styles.ham} ${show ? styles.concoll : ""} `} onClick={handleShow} />
            <div className={`${styles.content} ${show ? styles.concoll : ""} `} >
              <Header
                globalDateItem={globalYear}
                selectedGlobalDate={selectedYear}
                handleChangeGlobaldate={handleChangeGlobalDate}

              />
            </div>
            <div >
              <SideBar show={show} />
            </div>
            <div className={`${styles.content} ${show ? styles.concoll : ""} `}>
              <Switch>
                <Route path="/admin/mySchool">
                  <MySchool />
                </Route>
                <Route exact path="/admin/applications">
                  <Applications />
                </Route>
                <Route exact path="/admin/applications/:applicationId">
                  <StudentEditInfo />
                </Route>
                <Route exact path="/admin/enrolment/:applicationId">
                  <StudentEnrolView />
                </Route>
                <Route path="/admin/enrolment">
                  <Enrolments />
                </Route>
                <Route path="/admin/documents">
                  <Documents />
                </Route>
                <Route path="/admin/communications">
                  <Communications />
                </Route>
                <Route exact path="/admin/users">
                  <Users />
                </Route>
                <Route exact path="/admin/settings/:tabValue">
                  <Settings />
                </Route>
                <Route exact path="/admin/settings/criteria/:criteriaId">
                  <CriteriaDetail />
                </Route>
                <Route exact path="/admin">
                  <Dashboard />
                </Route>
                <Route exact path="/admin/offers">
                  <Offers />
                </Route>
                <Route path="/">
                  <LandingPage />
                </Route>
              </Switch>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  );
};

export default AdminRoutes;