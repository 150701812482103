import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
    root: {
        marginLeft: "400px",
        display: "flex",
        flexDirection: "column",
    },
    header: {
        fontFamily:   `"LFT Etica", sans-serif`,
        fontWeight: "bold",
        fontSize: theme.typography.h5.fontSize,
        paddingBottom: theme.spacing(1),
    },
    text: {
        fontFamily:   `"LFT Etica", sans-serif`,
        fontSize: "14px",
        fontWeight: 400,
        color: "#2B2B33",
    },
    checkContainer: {
        border: "1px solid",
        borderColor: theme.palette.grey[200],
        paddingLeft:10
    },
    labelCheckBox: {
        fontFamily:   `"LFT Etica", sans-serif`,
        color: theme.palette.text.primary,
        fontSize: 13
    }
}));
