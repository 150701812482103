import request from "../../../utils/authUtil";
interface medicalFurtherDetails {
  medical_question_follow_up_uuid: string;
  follow_up_question: string;
  require_supporting_doc: boolean;
}
interface medicalData {
  medical_question_uuid: string;
  medicalQuestion: string;
  details: boolean;
  furtherDetails: medicalFurtherDetails[];
}

interface postMedicalData {
  id: string;
  medical: medicalData[];
}

export const getMedical = async (id: string): Promise<any> => {
  const res = await request({
    url: `school-settings/application-medical-question-details/?application_uuid=${id}`,
    method: "get",
  });
  return res;
};

export const postMedical = async ({
  id,
  medical,
}: postMedicalData): Promise<any> => {
  const data = medical.map((item: any) => {
    return {
      medical_question_uuid: item.medical_question_uuid || "",
      question: item.medicalQuestion,
      further_detail_required: item.details,
      follow_up: item.details ? item.furtherDetails : [],
    };
  });
  const res = await request({
    url: `school-settings/application-medical-question-details/?application_uuid=${id}`,
    method: "put",
    data,
  });
  return res;
};
