import request from "../../../../utils/authUtil";
import requestv2 from "../../../../utils/authUtilv2";

const schoolID = localStorage.getItem("school-id");

export const endLotteryProcess = async () => {
  return await request({
    url: `school-settings/update-waiting-lottery-process/?school_uuid=${schoolID}`,
    method: "get",
  });
};

export const getWaitingList = async () => {
  return await request({
    url: `school-settings/waiting-lottery-list/?school_uuid=${schoolID}`,
    method: `get`,
  });
};

export const saveWaitingList = async (data: any) => {
  return await request({
    url: `school-settings/waiting-lottery-list/?school_uuid=${schoolID}`,
    method: `put`,
    data,
  });
};

export const exportCsv = async () => {
  return await request({
    url: `school-settings/waiting-lottery-numbers-csv/?school_uuid=${schoolID}`,
    method: `get`,
  });
};

export const selectApplication = async (std_app_id: string) => {
  const res = await requestv2({
    url: `school-settings/select-student-waiting-list/?stu_app_uuid=${std_app_id}`,
    method: "put",
  });
  return res;
};

export const cancelApplication = async (std_app_id: string) => {
  const res = await requestv2({
    url: `school-settings/cancel-student-waiting-list/?stu_app_uuid=${std_app_id}`,
    method: "put",
  });
  return res;
};
