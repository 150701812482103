import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button/index";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "../general-tab/general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import { DeleteBox } from "../../../components/common/delete-dialogbox/index";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  applicationID?: string;
  error?: any;
  ready?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const BookGrant: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [error, setError] = useState<Number[]>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [idPre, setId] = useState<string>("");
  const [indexPre, setIndex] = useState<number>(0);
  const schoolId = localStorage.getItem("school-id");
  const default_state = [
    {
      book_grant_text: "",
      school_application: "",
      book_grant_uuid: "",
    },
  ];
  const [askBookGrant, setAskBookGrant] = useState<boolean>(false);
  const [bookGrant, setBookGrant] = useState<any>(default_state);

  const getBookGrantSettings = () => {
    request({
      url: `school-settings/school-custom-settings-book-grant/?application_uuid=${applicationID}`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          setAskBookGrant(response.data.results.book_grant_question);
        } else {
          setAskBookGrant(false);
        }
      })
      .catch();
  };

  const saveBookGrantSettings = async () => {
    const result = await request({
      url: `school-settings/school-custom-settings-book-grant/?application_uuid=${applicationID}`,
      method: "put",
      data: {
        book_grant_question: askBookGrant,
      },
    });
    return result;
  };

  const getBookGrant = () => {
    setLoader(true);
    request({
      url: `school-settings/school-book-grant/?application_uuid=${applicationID}`,
      method: "get",
    })
      .then((response) => {
        const temp: any = [];
        if (response.data) {
          setLoader(false);
          if (Object.keys(response.data.results).length !== 0) {
            response.data.results.map((item: any) => {
              temp.push({
                book_grant_text: item.book_grant_text,
                school_application: item.school_application,
                book_grant_uuid: item.book_grant_uuid,
              });
            });
            setBookGrant(temp);
          } else {
            setBookGrant(default_state);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  };

  const saveBookGrant = async () => {
    const data = bookGrant.map((item: any, index: number) => {
      return {
        book_grant_text: item.book_grant_text,
        book_grant_uuid: item.book_grant_uuid,
      };
    });
    const result = await request({
      url: `school-settings/school-book-grant/?application_uuid=${applicationID}`,
      method: "put",
      data: data,
    });
    return result;
  };

  useEffect(() => {
    getBookGrant();
    getBookGrantSettings();
  }, [applicationID, selectedGlobalDate]);

  const addBookGrant = () => {
    ready(false);
    setBookGrant([...bookGrant, { book_grant_text: "", book_grant_uuid: "" }]);
  };

  const onChange = (value: any, ind: any) => {
    ready(false);
    const xyz = [...bookGrant];
    const temp = [...error];
    xyz[ind].book_grant_text = value;
    setBookGrant(xyz);
    if (value.length === 0) {
      if (!temp.includes(ind)) {
        temp.push(ind);
      }
    } else if (temp.includes(ind)) {
      const val = temp.indexOf(ind);
      if (ind > -1) {
        temp.splice(val, 1);
      }
    }
    setError(temp);
  };

  const onSaveClick = async () => {
    const temp = [...error];
    try {
      bookGrant.map((item: any, index: any) => {
        if (item.book_grant_text.length === 0) {
          if (!temp.includes(index)) {
            temp.push(index);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        const res1 = await saveBookGrantSettings();
        const res2 = await saveBookGrant();
        if (res1.data.status_code && res2.data.status_code) {
          ready(true);
          setLoader(false);
        }
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteBookGrant = (id: string, index: number) => {
    if (id.length !== 0) {
      setLoader(true);
      request({
        url: `school-settings/school-book-grant/?application_uuid=${applicationID}`,
        method: "delete",
        data: {
          book_grant_uuid: id,
        },
      }).then(() => {
        const temp = [...bookGrant];
        temp.splice(index, 1);
        setBookGrant(temp);
        setError([]);
        getBookGrant();
      });
    } else {
      const temp = [...bookGrant];
      temp.splice(index, 1);
      setBookGrant(temp);
      setError([]);
    }
  };

  const deleteHandler = (id: string, index: number) => {
    setOpen(true);
    setId(id);
    setIndex(index);
  };

  return (
    <div>
      <Loader open={loader} />
      <Grid container direction="column" justifyContent="flex-start">
        <Box display="flex" alignItems="center" mb={1}>
          <Checkbox
            color="default"
            onChange={(e) => {
              setAskBookGrant(!askBookGrant);
            }}
            checked={askBookGrant}
            disabled={selectedGlobalDate !== currentGlobalDate}
          />
          <p>Ask Book Grant Question</p>
        </Box>
        <div className={classes.preReqContainer}>
          {bookGrant.map((item: any, index: number) => {
            return (
              <Box
                display="flex"
                justifyContent="space-between"
                key={index}
                width="80%"
              >
                <Box width="85%">
                  <Input
                    label={`Book Grant ${index + 1}`}
                    type="text"
                    value={item.book_grant_text}
                    onChange={(e: { target: { value: any } }) =>
                      onChange(e.target.value, index)
                    }
                    disabled={selectedGlobalDate !== currentGlobalDate}
                    error={error.includes(index)}
                  />
                </Box>
                {selectedGlobalDate === currentGlobalDate && (
                  <Icon
                    style={{ cursor: "pointer", color: "#757575", marginTop: "24px" }}
                    onClick={() => deleteHandler(item.book_grant_uuid, index)}
                  >
                    delete
                  </Icon>
                )}
              </Box>
            );
          })}
        </div>
        {selectedGlobalDate === currentGlobalDate && (
          <>
            <div style={{ width: "300px", marginTop: "20px" }}>
              <AdminButton
                label="Add Book Grant"
                endIcon="add"
                light
                onClick={addBookGrant}
              />
            </div>
            <div style={{ width: 100, marginTop: 40, marginBottom: 40 }}>
              <AdminButton label="Save" onClick={onSaveClick} />
            </div>
          </>
        )}
        <DeleteBox
          index={indexPre}
          id={idPre}
          onClick={() => {
            deleteBookGrant(idPre, indexPre);
          }}
          open={open}
          setOpen={setOpen}
        />
      </Grid>
    </div>
  );
};

export default BookGrant;
