import requestv3 from "../../../../utils/authUtilv3"

export const getASDApplicationDetails = async (
  studentAppId: string,
  schoolId: string | null
): Promise<any> => {
  const res = await requestv3({
    url: `student-application/student-asd-application/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}`,
    method: "get",
  });
  return res;
};

export const getASDUploadDocuments = async(
  studentAppId: string,
  schoolId: string | null): Promise<any> => {
  const res = await requestv3({
    url:`student-application/student-asd-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}`,
    method: "get",
  });
  return res;
}


export const postASDApplicationData = async (
  studentAppId: string,
  schoolId: string | null,
  data: any
): Promise<any> => {
  const res = await requestv3({
    url: `student-application/student-asd-application/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}`,
    method: "put",
    data: data,
  });
  return res;
};


export const postASDDocumentsData = async (
  studentAppId: string,
  schoolId: string | null,
  data: any
): Promise<any> => {
  const res = await requestv3({
    url: `student-application/student-asd-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}`,
    method: "put",
    data: data,
  });
  return res;
};


export const deleteASDDocument = async (
  studentAppId: string,
  schoolId: string | null,
  data: any
): Promise<any> => {
  const res = await requestv3({
    url: `student-application/student-asd-documents-upload-page/?school_uuid=${schoolId}&stu_app_uuid=${studentAppId}`,
    method: "delete",
    data: data,
  });
  return res;
};

