import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    width: "140px",
    height: "170px",
    color: theme.palette.text.primary,
    border: "1px solid",
    borderColor: theme.palette.grey[200],
  },
  label: {
    marginBottom: 6.5,
    fontFamily:   `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },

}));
