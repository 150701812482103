import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  criteriaContainer: {
    width: "100%", 
    marginTop: 30
  },
  inputContainer: {
    width: "100%", 
    marginTop: 20
  },
  tab: {
    marginRight: 50,
    borderLeft: "1px solid",
    borderColor: theme.palette.divider,
    width: 200
  },
  tabButton: {
    display: "block",
    backgroundColor: "inherit",
    color: theme.palette.common.black,
    padding: "12px 22px",
    width: "100%",
    border: "none",
    outline: "none",
    textAlign: "left",
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 14
  },
  langContainer: {
    width: "60%",
    marginTop: 30
  },
  text: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#2B2B33",
  },
}));
