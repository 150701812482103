import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { useStyle } from "./med-info.style";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { TextArea } from "../../../../components/common/text-area-component/index";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import { Loader } from "../../../../components/common/loader/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface medicalInfoData {
  medical_info_uuid: string;
  family_doctor_name: string;
  family_doctor_addres: string;
  family_doctor_phone: string;
  is_medical_card_holder: string | boolean;
  medical_card_number: string;
}

const MedInfoEnrollment: React.FC<Props> = ({
  nextPage,
  prevPage,
  stdAppId,
}) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [localData, setLocalData] = useState<medicalInfoData>({
    medical_info_uuid: "",
    family_doctor_name: "",
    family_doctor_addres: "",
    family_doctor_phone: "",
    is_medical_card_holder: "",
    medical_card_number: "",
  });

  const [error, setError] = useState({
    name_err: false,
    addres_err: false,
    phone_err: false,
    card_holder_err: false,
    card_number_err: false,
  });

  const handleRadioChange = (name: boolean, index: number) => {
    setError({
      ...error,
      card_holder_err: false,
    });
    if (name === false) {
      setLocalData({ ...localData, is_medical_card_holder: false });
    }
    if (name === true) {
      setLocalData({ ...localData, is_medical_card_holder: true });
    }
  };

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrolment-medical-info/?student_app_uuid=${stdAppId}`,
      method: "get",
    }).then((response) => {
      if (
        response.data.status_code === 1 &&
        response.data.results.length !== 0
      ) {
        setLocalData(response?.data?.results[0]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const validate = () => {
    return {
      name_err: localData.family_doctor_name.length === 0,
      addres_err: localData.family_doctor_addres.length === 0,
      phone_err: localData.family_doctor_phone.length === 0,
      card_holder_err: localData.is_medical_card_holder === "",
      card_number_err:
        localData.is_medical_card_holder === true &&
        localData.medical_card_number.length === 0,
    };
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "family_doctor_name":
        setLocalData({ ...localData, family_doctor_name: event.target.value });
        setError({
          ...error,
          name_err: event.target.value.length === 0,
        });
        break;
      case "family_doctor_addres":
        setLocalData({
          ...localData,
          family_doctor_addres: event.target.value,
        });
        setError({
          ...error,
          addres_err: event.target.value.length === 0,
        });
        break;
      case "family_doctor_phone":
        setLocalData({
          ...localData,
          family_doctor_phone: event.target.value,
        });
        setError({
          ...error,
          phone_err: event.target.value.length === 0,
        });
        break;
      case "medical_card_number":
        setLocalData({
          ...localData,
          medical_card_number: event.target.value,
        });
        setError({
          ...error,
          card_number_err: event.target.value.length === 0,
        });
        break;
    }
  };

  const onSubmit = () => {
    const err = validate();
    setError(err);
    if (
      err.addres_err === false &&
      err.card_holder_err === false &&
      err.card_number_err === false &&
      err.name_err === false &&
      err.phone_err === false
    ) {
      setLoader(true);
      const data = {
        family_doctor_name: localData.family_doctor_name,
        family_doctor_address: localData.family_doctor_addres,
        family_doctor_phone: localData.family_doctor_phone,
        is_medical_card_holder: localData.is_medical_card_holder,
        medical_card_number:
          localData.is_medical_card_holder === true
            ? localData.medical_card_number
            : "",
      };
      request({
        url: `student-enrolment/student-enrolment-medical-info/?student_app_uuid=${stdAppId}&medical_info_uuid=${localData.medical_info_uuid}`,
        method: "put",
        data: data,
      }).then((response) => {
        if (response.data.status_code === 1) {
          setLoader(false);
          nextPage();
        } else {
          setLoader(false);
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div className={styles.bodyContainer}>
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>Enrolment</div>
            </div>
            <ProgressBar total={7} steps={4} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome title="Medical Info" description="Next: Medical History" />
          </div>
          <Input
            label="Family Doctor name"
            value={localData.family_doctor_name}
            onChange={(e: any) => onValueChange(e, "family_doctor_name")}
            error={error.name_err}
          />
          <TextArea
            label="Family Doctor Address"
            value={localData.family_doctor_addres}
            onChange={(e: any) => onValueChange(e, "family_doctor_addres")}
            error={error.addres_err}
          />
          <Input
            label="Family Doctor phone"
            value={localData.family_doctor_phone}
            onChange={(e: any) => onValueChange(e, "family_doctor_phone")}
            error={error.phone_err}
          />
          <InputRadioButton
            label="Is your student/child a medical card holder"
            placeHolderOne="No"
            placeHolderTwo="Yes"
            valueTwo={true}
            valueOne={false}
            onChange={handleRadioChange}
            answer={localData.is_medical_card_holder}
            error={error.card_holder_err}
          />
          {localData.is_medical_card_holder ? (
            <Input
              label="Medical card number"
              value={localData.medical_card_number}
              onChange={(e: any) => onValueChange(e, "medical_card_number")}
              error={error.card_number_err}
            />
          ) : null}
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onSubmit}
              />
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default MedInfoEnrollment;
