import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    color: theme.palette.text.primary,
    padding: "0px 3%",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    fontSize: "16px",
    backgroundColor: theme.palette.common.white,
    fontFamily:   `"LFT Etica", sans-serif`,
  },
  borderTopBox: {
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
  },
  borderBottomBox: {
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  label: {
    marginBottom: 6.5,
    fontFamily:    `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  checked: {
    color: theme.palette.success.main,
    "&$checked": {
      color: theme.palette.success.main,
    },
    marginRight: "-24px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  checkedGrey: {
    color: theme.palette.grey[200],
    marginRight: "-24px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  borderGreen: {
    border: "1px solid",
    borderColor: theme.palette.success.main,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  labelError: {
    marginBottom: 6.5,
    fontFamily:    `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  placeholder: {
    fontFamily:    `"LFT Etica", sans-serif`,
    color: theme.palette.text.primary,
    fontWeight: 200,
  },
}));
