import { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Input } from "../../../components/common/input/index";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../components/common/button/index";
import { useStyle } from "./register.style";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

type Props = {
  openModal: any;
  closeModal: any;
  otpValue: string;
  otpValueChange: any;
  resendOtp: any;
  verifyOtp: any;
  type: string;
  otpError?: boolean;
  errorMessage?: string;
};

const OtpModal: React.FC<Props> = ({
  openModal,
  closeModal,
  otpValue,
  otpValueChange,
  resendOtp,
  verifyOtp,
  type,
  otpError,
  errorMessage,
}) => {
  const classes = useStyle();
  const [disable, setDisable] = useState<boolean>(true);
  const label = "Resend in ";

  const [[mins, secs], setTime] = useState([3, 0]);
  const tick = () => {
    if (mins === 0 && secs === 0) setDisable(false);
    else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  const resend = () => {
    resendOtp();
    setDisable(true);
    setTime([3, 0]);
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  return (
    <Modal open={openModal} onClose={closeModal}>
      <div className={classes.modalBox}>
        <Box mt={5}>
          <Box className={classes.cancelIcon} onClick={closeModal}>
            <Icon>cancel_icon</Icon>
          </Box>
          <Input
            label={type === "email" ? "Enter Email OTP" : "Enter Phone OTP"}
            value={otpValue}
            onChange={(e: any) => otpValueChange(e.target.value)}
            error={otpError}
            errorMessage={
              type === "email"
                ? "Please enter valid 6 digit OTP sent on your email"
                : "Please enter valid 6 digit OTP sent on your phone"
            }
          />
        </Box>
        {/* error msg */}
        <p
          style={{
            margin: 10,
            color: "#EB5757",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {errorMessage}
        </p>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-around"
        >
          <div className={classes.buttonWidth}>
            <Button label="Resend OTP" onClick={resend} disabled={disable} />
            {disable ? (
              <div
                style={{
                  fontStyle: "italic",
                  fontSize: "12px",
                  padding: "8px",
                }}
              >
                {label}
                {`${mins.toString().padStart(2, "0")}:${secs
                  .toString()
                  .padStart(2, "0")}`}
              </div>
            ) : null}
          </div>
          <div className={classes.buttonWidth}>
            <Button label="Verify OTP" onClick={verifyOtp} />
          </div>
        </Grid>
        {type === "email" ?  <div className={classes.otpStyle}> PS: If not received please check your spam and junk folder. </div> : ' '}

      </div>
    </Modal>
  );
};

export default OtpModal;
