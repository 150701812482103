import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    borderRadius: 4,
    color: theme.palette.text.primary,
    padding: "10px 10px",
    border: "1px solid",
    borderColor: theme.palette.grey[200],
    outline: "none",
    fontFamily: `"LFT Etica", sans-serif`,
    background: theme.palette.common.white,
    fontSize: "16px",
    width: "calc(100% - 20px)",
    margin: "0px",
    "& .MuiIconButton-root:hover": {
      backgroundColor: "transparent",
    },
  },
  focused: {
    border: "1px solid",
    borderColor: theme.palette.common.black,
  },
  error: {
    border: "1px solid",
    borderColor: theme.palette.error.main,
  },
  label: {
    marginBottom: 6.5,
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
  },
  labelError: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginTop: 7,
    fontSize: 11,
  },
  icon: {
    marginLeft: "10px",
  },
  inputStyle: {
    fontFamily: `"LFT Etica", sans-serif`,
  }
}));
