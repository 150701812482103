import { Box, Checkbox, InputLabel } from "@material-ui/core";
import { useEffect, useState } from "react";
import { AdminButton } from "../../../components/common/admin-button";
import { Input } from "../../../components/common/input";
import request from "../../../utils/authUtil";
import { useStyle } from "./general-tab.style";
import { Loader } from "../../../components/common/loader/index";
import { TextArea } from "../../../components/common/text-area-component/index";
type Props = {
  applicationID: string;
  error?: any;
  switchReady?: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

const AppSetting: React.FC<Props> = ({
  applicationID,
  switchReady,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const schoolID = localStorage.getItem("school-id");
  const classes = useStyle();
  const [error, setError] = useState("");
  const [appTitle, setAppTitle] = useState<string>("");
  const [appDescription, setAppDescription] = useState<string>("");
  const [emailRec, setEmailRec] = useState<string>("");
  const [assignWaitList, setAssignWaitList] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [validError, setValidError] = useState({
    appTitle_err: false,
    appDescription_err: false,
    emailRec_err: false,
  });

  useEffect(() => {
    setLoader(true);
    request({
      url: `school-settings/school-application/?school_uuid=${schoolID}&year_uuid=${selectedGlobalDate}`,
      method: "get",
    })
      .then((response) => {
        if (response.data) {
          if (response.data.results.length !== 0) {
            setLoader(false);
            setAppTitle(response?.data.results[0]?.title);
            setAppDescription(response?.data.results[0]?.description);
            setEmailRec(response?.data.results[0]?.email_reciepient);
            setAssignWaitList(response?.data.results[0]?.assign_waiting_list);
          } else {
            setLoader(false);
            setAppTitle("");
            setAppDescription("");
            setEmailRec("");
            setAssignWaitList(false);
          }
        } else {
          setError(response.status_message);
          setLoader(false);
        }
      })
      .catch();
  }, [schoolID, selectedGlobalDate]);

  const onValueChange = (e: any, inputType: string) => {
    switchReady(false);
    switch (inputType) {
      case "appTitle":
        setAppTitle(e.target.value);
        setValidError({
          ...validError,
          appTitle_err: e.target.value.length === 0,
        });
        break;
      case "appDescription":
        setAppDescription(e.target.value);
        setValidError({
          ...validError,
          appDescription_err: e.target.value.length === 0,
        });
        break;
      case "emailRec":
        let emailRegex =
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
        setEmailRec(e.target.value);
        setValidError({
          ...validError,
          emailRec_err:
            e.target.value.length === 0 || !emailRegex.test(emailRec),
        });
        break;
    }
  };

  const validate = () => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    return {
      appTitle_err: appTitle.length === 0,
      appDescription_err: appDescription.length === 0,
      emailRec_err: emailRec.length === 0 || !emailRegex.test(emailRec),
    };
  };

  const onSaveClick = () => {
    const err = validate();
    setValidError(err);
    if (
      err.appDescription_err === false &&
      err.appTitle_err === false &&
      err.emailRec_err === false
    ) {
      setLoader(true);
      request({
        url: "/school-settings/application-details/",
        method: "put",
        data: {
          school_uuid: localStorage.getItem("school-id"),
          year_uuid: currentGlobalDate,
          title: appTitle,
          description: appDescription,
          email_reciepient: emailRec,
          assign_waiting_list: assignWaitList,
          application_uuid: applicationID,
        },
      })
        .then((response) => {
          if (response.data) {
            setLoader(false);
            switchReady(true);
          }
        })
        .catch((e) => {});
    }
  };

  return (
    <div>
      {/* error msg */}
      <p
        style={{
          margin: 10,
          color: "#EB5757",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {error}
      </p>
      <Box>
        <Input
          label="Application Title"
          value={appTitle}
          type="text"
          onChange={(e) => onValueChange(e, "appTitle")}
          error={validError.appTitle_err}
          disabled={selectedGlobalDate !== currentGlobalDate}
        />
      </Box>
      <Box>
        <Box mb={1}>
          <TextArea
            value={appDescription}
            onChange={(e: any) => onValueChange(e, "appDescription")}
            error={validError.appDescription_err}
            label="Application Description"
            disabled={selectedGlobalDate !== currentGlobalDate}
          />
        </Box>
      </Box>
      <Box>
        <Input
          label="Email Recipent"
          value={emailRec}
          type="text"
          onChange={(e) => onValueChange(e, "emailRec")}
          error={validError.emailRec_err}
          disabled={selectedGlobalDate !== currentGlobalDate}
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          color="default"
          checked={assignWaitList}
          onChange={() => setAssignWaitList(!assignWaitList)}
          style={{ marginLeft: "-13px" }}
          disabled={selectedGlobalDate !== currentGlobalDate}
        />
        <InputLabel className={classes.label}>
          Manual Waiting Lottery
        </InputLabel>
      </Box>
      {selectedGlobalDate === currentGlobalDate && (
        <Box style={{ width: 100, marginTop: 40 }} mb="40px">
          <AdminButton label="Save" onClick={onSaveClick} />
        </Box>
      )}
      <Loader open={loader} />
    </div>
  );
};

export default AppSetting;
