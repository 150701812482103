import axios from "axios";

const getToken = () => localStorage.getItem("token");
const getRefreshToken = () => localStorage.getItem("refreshToken");

export const setToken = (token: string) => localStorage.setItem('token', token);
export const clearStorage = () => {
  localStorage.removeItem("parent");
  localStorage.removeItem("isSignature");
  localStorage.removeItem("type");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("selectedGlobalDate");
  localStorage.removeItem("currentGlobalDate");
  localStorage.removeItem("employee_type");
  localStorage.removeItem("currentStudentAppID");
  localStorage.removeItem("application_uuid");
  localStorage.removeItem("filterState");
} 
export const setRefreshToken = (token: string) => localStorage.setItem('refreshToken', token);
export const setParent = (parent: any) => localStorage.setItem('parent', JSON.stringify(parent))
export const setApplicationUUID = (application_uuid: string) => localStorage.setItem('application_uuid', application_uuid)

const parent = localStorage.getItem('parent') as string
export const ParentUser = JSON.parse(parent);

// optionaly add base url
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL as string,
});

const request = async ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
  client.defaults.headers.common.Content_Type = "application/json";
  //@ts-ignore
  const onSuccess = (response) => response;
  //@ts-ignore
  const onError = (error) => {
    // optionaly catch errors and add some additional logging here
    return error;
  };
  const schoolId = localStorage.getItem("school-id");
  const header = {'schoolname': schoolId};

  try {
    const response = await client({...options, headers: header });
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
};

client.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/admin/login" && err.response) {
      // Access Token was expired
      if (!originalConfig._retry) {
        if (err.response.status === 403 && err.response.data.status_type !== 'RESPONSE_STATUS_FORCE_LOGOUT') {
          originalConfig._retry = true;
          try {
            client.defaults.headers.common.Authorization = `Bearer ${getRefreshToken()}`;
            const schoolId = localStorage.getItem("school-id");
            const header = {'schoolname': schoolId};
            const rs = await client.post("auth/refresh-access-token/", {
              device_type: "web",
              device_token: ""
            }, {
              headers: header
            });

            const newAccessToken = rs.data.results.token;
            originalConfig.headers.Authorization = `Bearer ${newAccessToken}`;
            setToken(newAccessToken);
            return client(originalConfig);
          } catch (_error) {
            localStorage.clear();
            window.location.reload();
          }
        }

      }
    }

    return Promise.reject(err);
  }
);

export default request;
