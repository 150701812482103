import InputLabel from "@material-ui/core/InputLabel";
import { useStyle } from "./guardian-radio.style";
import Radio from "@material-ui/core/Radio";
import { Box } from "@material-ui/core";
import cn from "classnames";
type InputRadioProps = {
  label?: string;
  radioHolderOne?: string;
  radioHolderTwo?: string;
  radioHolderThree?: string;
  onChange?: any;
  check?: string;
  radioPlaceHolderOne?: string;
  radioPlaceHolderTwo?: string;
  radioPlaceHolderThree?: string;
  index?: number;
  twoGuardian?: boolean;
  disabled?: boolean;
};

export const GuardianRadio: React.FC<InputRadioProps> = ({
  label,
  radioHolderOne,
  radioHolderTwo,
  radioHolderThree,
  check,
  onChange,
  radioPlaceHolderOne,
  radioPlaceHolderTwo,
  radioPlaceHolderThree,
  index,
  twoGuardian,
  disabled = false,
}) => {
  const classes = useStyle();
  return (
    <div style={{ marginBottom: 34 }}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      {/* two radio */}
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={cn(
            classes.root,
            check === radioHolderOne ? classes.borderGreen : ""
          )}
        >
          <p>{radioPlaceHolderOne}</p>
          <Radio
            className={
              check === radioHolderOne ? classes.checked : classes.checkedGrey
            }
            disabled={disabled}
            checked={check === radioHolderOne}
            onClick={() => onChange(radioHolderOne, index)}
            color="default"
            disableFocusRipple
            disableRipple
          />
        </Box>
        {twoGuardian ? (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={cn(
                classes.root,
                check === radioHolderTwo ? classes.borderGreen : ""
              )}
            >
              <p>{radioPlaceHolderTwo}</p>
              <Radio
                className={
                  check === radioHolderTwo
                    ? classes.checked
                    : classes.checkedGrey
                }
                disabled={disabled}
                checked={check === radioHolderTwo}
                onClick={() => onChange(radioHolderTwo, index)}
                color="default"
                disableFocusRipple
                disableRipple
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className={cn(
                classes.root,
                check === radioHolderThree ? classes.borderGreen : ""
              )}
            >
              <p>{radioPlaceHolderThree}</p>
              <Radio
                className={
                  check === radioHolderThree
                    ? classes.checked
                    : classes.checkedGrey
                }
                disabled={disabled}
                checked={check === radioHolderThree}
                onClick={() => onChange(radioHolderThree, index)}
                color="default"
                disableFocusRipple
                disableRipple
              />
            </Box>
          </>
        ) : null}
      </>
    </div>
  );
};
