import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { useStyle } from "./med-history.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two/index";
import { Box } from "@material-ui/core";
import request from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface MedHistFollowUpData {
  follow_up_question: string;
  followup_answer: string;
  followup_answer_uuid: string;
  followup_medical_uuid: string;
}

interface MedHistData {
  medical_uuid: string;
  question: string;
  answer: boolean | string;
  answer_uuid: string;
  further_detail_required: boolean;
  followup_question_answer: MedHistFollowUpData[];
}

const MedicalHistory = (props: Props) => {
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>([]);
  const [medHistory, setMedHistory] = useState<MedHistData[]>([
    {
      medical_uuid: "",
      question: "",
      answer: "",
      answer_uuid: "",
      further_detail_required: false,
      followup_question_answer: [
        {
          follow_up_question: "",
          followup_answer: "",
          followup_answer_uuid: "",
          followup_medical_uuid: "",
        },
      ],
    },
  ]);
  const schoolID = localStorage.getItem("school-id");

  useEffect(() => {
    setLoader(true);
    request({
      url: `student-enrolment/student-enrolment-medical-details/?student_app_uuid=${stdAppId}&school_uuid=${schoolID}`,
      method: "get",
    }).then((response) => {
      if (response.data.status_code === 1) {
        if (Object.keys(response.data?.results).length !== 0) {
          const temp: any = [];
          response.data.results.map((ques: any, index: number) => {
            temp.push({
              medical_uuid: ques.medical_uuid,
              question: ques.question,
              answer: ques.answer,
              answer_uuid: ques.answer_uuid,
              further_detail_required: ques.further_detail_required,
              followup_question_answer: ques.followup_question_answer,
            });
          });
          setMedHistory(temp);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  }, []);

  const handleRadioChange = (name: boolean, index: number) => {
    const temp = [...medHistory];
    const tempErr = [...error];
    const val = tempErr.indexOf(`empty${index}`);
    if (val > -1) {
      tempErr.splice(val, 1);
    }
    setError(tempErr);
    if (name === false) {
      temp[index].answer = false;
      setMedHistory(temp);
    }
    if (name === true) {
      temp[index].answer = true;
      setMedHistory(temp);
    }
  };

  const onSubmit = () => {
    const temp = [...error];
    try {
      medHistory.map((item: any, index: any) => {
        if (item.answer === "") {
          if (!temp.includes(`empty${index}`)) {
            temp.push(`empty${index}`);
          }
        }
        if (
          item.answer === true &&
          item.further_detail_required === true &&
          item.followup_question_answer.length > 0
        ) {
          item.followup_question_answer.map((subItem: any, subIndex: any) => {
            if (subItem.followup_answer.length === 0) {
              if (!temp.includes(`${index}${subIndex}`)) {
                temp.push(`${index}${subIndex}`);
              }
            }
          });
        }
        if (item.answer === false) {
          item.followup_question_answer.map((subItem: any, subIndex: any) => {
            if (temp.includes(`${index}${subIndex}`)) {
              const val = temp.indexOf(`${index}${subIndex}`);
              if (index > -1) {
                temp.splice(val, 1);
              }
            }
          });
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setError([]);
        setLoader(true);
        const data = medHistory.map((item: any) => {
          let follow_up: any = [];
          let follow_up_id = item.answer_uuid || "";
          item.followup_question_answer.map((subItem: any) => {
            follow_up.push({
              followup_medical_uuid: subItem.followup_medical_uuid,
              followup_answer:
                item.answer === true ? subItem.followup_answer : "",
              followup_answer_uuid: subItem.followup_answer_uuid || "",
            });
          });
          return {
            medical_uuid: item.medical_uuid,
            answer: item.answer,
            followup_question_answer: item.further_detail_required
              ? follow_up
              : [],
            answer_uuid: follow_up_id,
          };
        });
        request({
          url: `student-enrolment/student-enrolment-medical-details/?student_app_uuid=${stdAppId}&school_uuid=${schoolID}`,
          method: "put",
          data: data,
        }).then((response) => {
          if (response.data.status_code === 1) {
            setLoader(false);
            nextPage();
          } else {
            setLoader(false);
          }
        });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const onChange = (value: string, index: number, followIndex: number) => {
    const temp = [...medHistory];
    const tempErr = [...error];
    temp[index].followup_question_answer[followIndex].followup_answer = value;
    setMedHistory(temp);
    if (value.length === 0) {
      tempErr.push(`${index}${followIndex}`);
    } else {
      if (tempErr.includes(`${index}${followIndex}`)) {
        const val = tempErr.indexOf(`${index}${followIndex}`);
        if (index > -1) {
          tempErr.splice(val, 1);
        }
      }
    }
    setError(tempErr)
  };

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Enrolment</div>
                </div>
                <ProgressBar total={7} steps={5} />
              </Box>
              <div style={{ marginBottom: 30 }}>
                <Welcome
                  title="Medical History"
                  description="Next: Educational needs"
                />
              </div>
              <Grid container direction="column">
                {medHistory.map((item, index) => (
                  <Box key={item.medical_uuid}>
                    <InputRadioButton
                      label={item.question}
                      placeHolderOne="No"
                      placeHolderTwo="Yes"
                      valueTwo={true}
                      valueOne={false}
                      index={index}
                      answer={item.answer}
                      onChange={handleRadioChange}
                      error={error.includes(`empty${index}`)}
                    />
                    {item.answer && item.further_detail_required
                      ? item.followup_question_answer.map(
                          (followItem, followIndex) => (
                            <Box key={followIndex}>
                              <Input
                                label={followItem.follow_up_question}
                                value={followItem.followup_answer}
                                onChange={(e: any) =>
                                  onChange(e.target.value, index, followIndex)
                                }
                                error={error.includes(`${index}${followIndex}`)}
                              />
                            </Box>
                          )
                        )
                      : null}
                  </Box>
                ))}
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={onSubmit}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default MedicalHistory;
