import { useEffect, useState } from "react";
import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { Button } from "../../../components/common/button/index";
import { Input } from "../../../components/common/input/index";
import Header from "../header/index";
import { useStyle } from "./profile.style";
import Grid from "@material-ui/core/Grid";
import { Link, useHistory } from "react-router-dom";
import { ParentUser } from "../../../utils/authUtil";
import { useQuery, UseQueryResult, useMutation, UseMutationResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import { CheckNetwork } from "../../../utils/helper";
import OtpModal from "../../common/register/otp-modal";
import { sendEmailOtp, verifyEmailOtp, saveProfileData, getProfileData } from "./profile.api";

const EditProfile = (props: any) => {
  const styles = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const id = ParentUser.uuid;
  const history = useHistory();
  const [error, setError] = useState<string>("");
  const [displayName, setDisplayName] = useState<string>("");
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    email_verified: true,
    email_verification_key: "",
    email_verified_date: ""
  });
  const [oldEmail, setOldEmail] = useState<string>('');
  const [validError, setValidError] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    phoneError: false
  });
  const [emailModel, setEmailModel] = useState<boolean>(false);
  const [emailOtp, setEmailOtp] = useState<string>("");
  const [otpVerifyError, setOtpVerifyError] = useState<string>("");
  const [otpError, setOtpError] = useState({
    otpErrorEmail: false,
  });
  const { data, isLoading }: UseQueryResult<any, Error> = useQuery<any, Error>(
    "profileData",
    () => getProfileData(id),
    {
      select: (profileData) => profileData.data,
    }
  );

  const sendEmail: UseMutationResult<any, Error, any> = useMutation(
    async ({ email, resend }) => sendEmailOtp({ email, resend }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_code === 1) {
          setLoader(false);
          setEmailModel(true);
        } else if (res.data.status_code === -1) {
          setError(res.data.status_message);
        }
      },
      onError: (e) => {
        // setError(e);
      },
    }
  );

  const verifyEmail: UseMutationResult<any, Error, any> = useMutation(
    async ({ email, otp }) => verifyEmailOtp({ email, otp }),
    {
      onSuccess: (res) => {
        setLoader(false);
        if (res.data.status_message === "OTP verified") {
          setOtpVerifyError("");
          setProfileData({
            ...profileData,
            email_verified: true,
            email_verification_key: res.data.results.verification_key,
            email_verified_date: new Date().toISOString(),
          });
          setOtpError({
            ...otpError,
            otpErrorEmail: false,
          });
          setEmailModel(false);
        } else {
          setOtpVerifyError(res.data.status_message);
          setLoader(false);
        }
      },
    }
  );
  useEffect(() => {
    if (data) {
      if (data.status_code === 1) {
        const pD = data.results;
        setProfileData({
          ...profileData,
          first_name: pD.first_name,
          last_name: pD.last_name,
          email: pD.email,
          phone: pD.mobile_number,
          email_verified: true,
        });
        setOldEmail(pD.email);
        setDisplayName(pD.first_name);
      } else if (data.status_code === -1) {
        setError(data.status_message);
      }
      setLoader(false);
    }
  }, [data]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  // Update Profile  Data Functionality
  const validate = () => {
    return {
      otpErrorEmail: emailOtp.length !== 6,
    };
  };
  const check_required = () => {
    return {
      firstNameError: profileData.first_name.length === 0,
      lastNameError: profileData.last_name.length === 0,
      emailError: profileData.email.length === 0,
      phoneError: profileData.phone.length === 0
    };
  }
  const onSubmit = () => {
    const err = check_required();
    setValidError(err);
    if (err.firstNameError === false && err.lastNameError === false && err.emailError === false && err.phoneError === false) {
      if (profileData.email_verified) {
        setLoader(true);
        saveProfileData(profileData, id)
          .then((response) => {
          setLoader(false);
          if (response.data.status_code === 1) {
            if (response.data.results) {
              setProfileData(response.data.results);
              setDisplayName(response.data.results.first_name);
              localStorage.setItem('fullName', response.data.results.first_name.trim() + ' ' + response.data.results.last_name.trim());
              localStorage.setItem("isSignature", response.data.results.is_document_uploaded);
              history.push("/parent/signature");
            }
          } else if (response.data.status_code === -1) {
            setLoader(false);
            setError(response.data.status_message);
          }
          setLoader(false);
        });
      } else {
        setError("Email should be Verified");
      }
    } else {
      setError("Please fill all the required fields");
    }
  };

  const resendOtp = (type: string) => {
    if (type === "email") {
      sendEmail.mutate({ email: profileData.email, resend: true });
    }
  };

  const verifyOtp = (type: string) => {
    const err = validate();
    setOtpError(err);
    if (type === "email" && err.otpErrorEmail === false) {
      verifyEmail.mutate({ email: profileData.email, otp: emailOtp });
    }
  };

  const handleOpen = (type: string) => {
    if (type === "email") {
      sendEmail.mutate({ email: profileData.email, resend: false });
    }
  };
  const handleClose = (type: string) => {
    if (type === "email") {
      setEmailModel(false);
      setOtpError({ ...otpError, otpErrorEmail: false });
      setOtpVerifyError("");
      setEmailOtp("");
    }
  };

  const validateEmail = (email: any) => {
    let emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9.\-]+\.[A-Za-z]+$/;
    if (emailRegex.test(email)) {
      setError("");
      setLoader(true);
      handleOpen("email");
    } else {
      setError("Email should be Valid");
    }
  };

  const onEmailChange = (value: string) => {
    if (value === oldEmail) {
      setProfileData({
        ...profileData,
        email: value,
        email_verified: true,
      });
    } else {
      setProfileData({ ...profileData, email: value, email_verified: false, })
    }
  }
  return (
    <div className={styles.container}>
      <CheckNetwork />
      <Header />
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title={`Hi ${displayName}`}
                description="Here you can edit your account info"
              />
            </div>
            {/* error msg */}
            <p
              style={{
                margin: 10,
                color: "#EB5757",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {error}
            </p>
            <Grid container direction="column">
              <Input
                label="Your first name"
                type="text"
                value={profileData.first_name}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, first_name: e.target.value })
                }
                error={validError.firstNameError}
              />
              <Input
                label="Your last name"
                type="text"
                value={profileData.last_name}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, last_name: e.target.value })
                }
                error={validError.lastNameError}
              />

{/* EDIT EMAIL */}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div style={{ width: "87%" }}>
                <Input
                  type="email"
                  label="Your email *"
                  value={profileData.email}
                  onChange={(e: any) => onEmailChange(e.target.value)}
                  // onBlur={(e: any) => {
                  //   e.target.value.length === 0
                  //     ? emptyFieldHandler("email")
                  //     : filledFieldHandler("email");
                  // }}
                //   error={requiredFields.includes("email")}
                />
              </div>
              {profileData.email_verified ? (
                <Icon className={styles.completeIcon}>check_circle_icon</Icon>
              ) : (
                <div
                  className={styles.checkButton}
                  onClick={() => validateEmail(profileData.email)}
                >
                <div style={{ height: 15}}>Verify</div>  
                </div>
              )}
            </Grid>

{/* END */}
              
              <Input
                label="Phone"
                type="text"
                disabled={true}
                value={profileData.phone}
                onChange={(e: any) =>
                  setProfileData({ ...profileData, phone: e.target.value })
                }
              />
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Link
                    to="/parent/profile"
                    style={{ textDecoration: "none" }}
                  >
                    <Button label="Cancel" light startIcon="navigate_before" />
                  </Link>
                </div>
                <div className={styles.buttonWidth}>
                  <Button label="Save" onClick={onSubmit} />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Box className={styles.footerContainer}>
          <Footer center />
        </Box>
        {emailModel && (
        <OtpModal
          openModal={emailModel}
          closeModal={() => handleClose("email")}
          otpValue={emailOtp}
          otpValueChange={setEmailOtp}
          resendOtp={() => resendOtp("email")}
          verifyOtp={() => verifyOtp("email")}
          type="email"
          otpError={otpError.otpErrorEmail}
          errorMessage={otpVerifyError}
        />
      )}
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default EditProfile;
