import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "650px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  
  card: {
    width: "80%",
    padding: "40px 40px",
    boxShadow: "0px 8px 14px 3px rgb(0 0 0 / 3%)",
    margin: "10px 0px 40px 0px",
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
  },

  backButton: {
    textDecoration: "none",
    width: "298px",
    marginBottom:'60px'
  },
  buttonWidth: {
    width: "298px",
    margin: "0px auto",
  },
  margin: {
    marin: "20px 0px 40px 0px ",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 40px 0px ",
      
    },
  },
}));
