import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loader:{
    color:theme.palette.primary.main,
  }
}));
