import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useCallback, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import request from "../../../utils/authUtil";
import { useStyle } from "./offers.style";
import { AdminButton } from "../../../components/common/admin-button";
import { Link, useHistory } from "react-router-dom";
import { ConfirmBox } from "../../../components/common/confirm-box/index";
import { MessageBox } from "../../../components/common/message-Box/index";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
} from "@material-ui/core";
import Filter from "./filter";
import moment from "moment";
interface OffersData {
  ID: any;
  student_app_uuid: string;
  Student_Name: string;
  application_status: string;
  criteria: string[];
  waiting_list_number: any;
  waiting_list_position: any;
  offered_date: string;
}
const Offers: React.FC = () => {
  const classes = useStyle();
  const schoolId = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const [selectionStatus, setSelectionStatus] = useState<string>();
  const [isLottery, setIsLottery] = useState<boolean>();
  const [startWithdrawal, setStartWithdrawal] = useState<boolean>();
  const [showOffers, setShowOffers] = useState<boolean>();
  const [error, setError] = useState<string>("");
  const [isSendOffers, setIsSendOffers] = useState<boolean>(false);
  const [sendReminderEmail, setSendReminderEmail] = useState<boolean>(false);
  const [offerSent, setOfferSent] = useState<boolean>(false);
  const [assignWaitingList, setAssignWaitingList] = useState<boolean>(false);
  const [waitingLotteryStatus, setWaitingLotteryStatus] = useState<string>();
  const [offers, setOffers] = useState<OffersData[]>([
    {
      ID: "",
      student_app_uuid: "",
      Student_Name: "",
      application_status: "",
      criteria: [],
      waiting_list_number: "",
      waiting_list_position: "",
      offered_date: "",
    },
  ]);
  const currentGlobalDate = localStorage.getItem("currentGlobalDate");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalApplicants, setTotalApplicants] = useState<number>(0);
  const [totalFilteredApplicants, setTotalFilteredApplicants] =
    useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [sortTag, setSortTag] = useState<string>("");
  const [isAsc, setIsAsc] = useState<boolean | string>(true);
  const [criterias, setCriterias] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [currentCriteriaId, setCurrentCriteriaId] = useState<string>("");
  const [currentStatusId, setCurrentStatusId] = useState<string>("");
  const [sendOfferDisabled, setSendOfferDisabled] = useState<boolean>(false);

  const getRelDate = useCallback(async (id: string) => {
    setLoader(true);
    const res = await request({
      url: `school-settings/application-dates-details/?application_uuid=${id}`,
      method: "get",
    });
    if (res.data.status_code === 1) {
      if (Object.keys(res.data.results).length > 0) {
        const date = new Date(res.data.results.notification_date);
        const mDate = moment(date).format("DD/MM/YYYY");
        const time = res.data.results.notification_date_time;
        //moment datetime obj
        const dateTime = moment(mDate + " " + time, "DD/MM/YYYY HH:mm");
        setSendOfferDisabled(moment().isBefore(dateTime));
        //
      }
      setLoader(false);
    }
  }, []);

  useEffect(() => {
    getRelDate("");
  }, [getRelDate]);

  const getCriterias = (): any => {
    setLoader(true);
    request({
      url: `/school-settings/offers-criteria-details/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setCriterias([
          {
            name: "All",
            value: "",
          },
          ...res.data.results?.criteria_list?.map((item: any) => {
            if (item.is_special) {
              return {
                name: `${item.criteria_name} *`,
                value: item.criteria_uuid,
              };
            } else {
              return {
                name: item.criteria_name,
                value: item.criteria_uuid,
              };
            }
          }),
        ]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const getOfferStatus = (): any => {
    setLoader(true);
    request({
      url: `/school-settings/application-status-list/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setStatus([
          {
            name: "All",
            value: "",
          },
          ...res.data?.results
            .filter((item: any) => item.value !== "DRAFT")
            .map((item: any) => {
              return {
                name: item.key,
                value: item.value,
              };
            }),
        ]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
  };

  const getOffers = async (): Promise<any> => {
    const res = await request({
      url: `/school-settings/offers-list/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}&criteria_uuid=${currentCriteriaId}&status=${currentStatusId}&page=${pageNumber}&search_text=${searchText}&sorting_var=${sortTag}&is_asc=${isAsc}`,
      method: "get",
    });
    return res;
  };

  const onCriteriaChange = (item: any) => {
    let filterString:any = localStorage?.getItem("filterState")
    let filter = JSON?.parse(filterString)
    localStorage.setItem("filterState", JSON.stringify({ ...filter, "offerCriteria": item.value }));
    setCurrentCriteriaId(item.value);
  };
  const onStatusChange = (item: any) => {
    let filterString:any = localStorage?.getItem("filterState")
    let filter = JSON?.parse(filterString)
    localStorage.setItem("filterState", JSON.stringify({ ...filter, "offerStatus": item.value }));
    setCurrentStatusId(item.value);
  };
  const {
    isLoading,
    isError,
    error: getTemplateError,
    data: tableData,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    [
      "offers",
      {
        pageNumber,
        searchText,
        isAsc,
        sortTag,
        currentCriteriaId,
        currentStatusId,
      },
    ],
    () => getOffers(),
    {
      select: (offers) => offers.data.results,
    }
  );

  useEffect(() => {
    if (tableData) {
      if (Object.keys(tableData).length !== 0) {
        setOffers(tableData?.applications_list);
        setTotalApplicants(tableData?.total_no_of_applicants);
        setTotalFilteredApplicants(tableData?.total_no_of_filters_applicants);
        setSelectionStatus(tableData.selection_process_status);
        setIsLottery(tableData.is_under_lottery);
        setStartWithdrawal(tableData.is_selection_process_completed);
        setShowOffers(tableData.is_show_button);
        setTotalPages(tableData.total_pages);
        setPageNumber(tableData.current_page);
        setAssignWaitingList(tableData.assign_waiting_list);
        setWaitingLotteryStatus(tableData.waiting_lottery_process_status);
        setError("");
      } else {
        setError("No data exist");
        setOffers([]);
        setTotalFilteredApplicants(0);
      }
    }
  }, [tableData]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    getCriterias();
    getOfferStatus();
    let filterString:any = localStorage?.getItem("filterState")
    let offerCriteriaFilter = JSON?.parse(filterString)?.offerCriteria
    let offerStatusFilter = JSON?.parse(filterString)?.offerStatus

    if(offerCriteriaFilter){
      setCurrentCriteriaId((prev)=>offerCriteriaFilter)
    }
    if(offerStatusFilter){
      setCurrentStatusId((prev)=>offerStatusFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    { id: "ID", label: "ID", minWidth: 100, isSort: true },
    { id: "Student_Name", label: "Student", minWidth: 100, isSort: true },
    {
      id: "Criteria",
      label: "Criteria",
      minWidth: 100,
    },
    { id: "Offer_Status", label: "Offer Status", minWidth: 100 },
    {
      id: "waiting_list_number",
      label: "Waiting List Number",
      minWidth: 100,
      isSort: true,
    },
    {
      id: "waiting_list_position",
      label: "Waiting List Position",
      minWidth: 100,
      isSort: true,
    },
    {
      id: "Offered_Date",
      label: "Offered Date",
      minWidth: 100,
      isSort: true,
    },
    {
      id: "Edit",
      label: " ",
      minWidth: 100,
      align: "right",
    },
  ];

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  const sendOffer = () => {
    setLoader(true);
    request({
      url: `/school-settings/update-offers-application-status/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      setOffers(res.data.results.applications_list);
      setSelectionStatus(res.data.results.selection_process_status);
      setIsLottery(res.data.results.is_under_lottery);
      setStartWithdrawal(res.data.results.is_selection_process_completed);
      setShowOffers(res.data.results.is_show_button);
      setSortTag("");
      setIsAsc(true);
      setLoader(false);
      setOfferSent(true);
    });
  };

  const exportCsv = async () => {
    setLoader(true);
    await request({
      url: `/school-settings/export-csv-offers-data/?year_uuid=${selectedGlobalDate}&status=${currentStatusId}&criteria_uuid=${currentCriteriaId}`,
      method: "get",
    }).then((res) => setLoader(false));
  };
  const sendReminder = async () => {
    setLoader(true);
    await request({
      url: `/school-settings/send-reminder-email/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => setLoader(false));
  };

  const firstLastPageHandler = (btnClicked: string) => {
    if (btnClicked === "first") {
      setPageNumber(1);
    } else {
      setPageNumber(totalPages);
    }
  };

  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Box mb="40px">
        <div>
          <Loader open={loader} />
        </div>
        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{getTemplateError}</p>
          </div>
        ) : null}
        <div style={{ margin: "-95px 0px 50px 0px" }}>
          <>
            <Box mt="90px">
              <Box className={classes.heading}>Offers</Box>
            </Box>
            <Box className={classes.boxStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="block">
                  <p>List of offers</p>
                  <p>
                    You have received <strong>{totalApplicants}</strong>
                    {`${" applications in total."}`}
                  </p>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  borderBottom="1px solid #000000"
                >
                  <input
                    onChange={(e: any) => searchTextChange(e.target.value)}
                    type="text"
                    placeholder="Find an applicant"
                    className={classes.inputStyle}
                  />
                  <div>
                    <Icon className={classes.iconStyle}>search</Icon>
                  </div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex">
                  <span style={{ marginTop: 5 }}>
                    <AdminButton
                      onClick={exportCsv}
                      label="Export Excel"
                      disabled={offers?.length === 0}
                    />
                  </span>
                </Box>
                {selectionStatus === "COMPLETED" &&
                  startWithdrawal &&
                  currentGlobalDate === selectedGlobalDate && (
                    <Box display="flex">
                      <span style={{ marginTop: 5 }}>
                        <AdminButton
                          onClick={() => setSendReminderEmail(true)}
                          label="Send Reminder Email"
                          disabled={offers?.length === 0}
                        />
                      </span>
                    </Box>
                  )}
              </Box>
              <Box
                marginTop="40px"
                display="flex"
                alignItems="center"
                gridGap={10}
              >
                {criterias && (
                  <Filter
                    options={criterias}
                    checkboxClick={onCriteriaChange}
                    label="Criteria"
                    defaultVal={currentCriteriaId}
                  />
                )}
                {status && (
                  <Filter
                    options={status}
                    checkboxClick={onStatusChange}
                    label="Offer Status"
                    defaultVal={currentStatusId}
                  />
                )}
                <span>
                  Total filtered applicants :{" "}
                  <strong>{`${totalFilteredApplicants}`}</strong>
                </span>
              </Box>
              <Box
                marginTop="40px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                {/* Start Button */}
                {selectionStatus === "NOT_STARTED" &&
                  currentGlobalDate === selectedGlobalDate && (
                    <Link
                      to="/admin/start-selection"
                      style={{ textDecoration: "none" }}
                    >
                      <Box width="220px" marginRight="15px">
                        <AdminButton label="Start selection process" />
                      </Box>
                    </Link>
                  )}
                {/* Continue Selection Button */}
                {selectionStatus === "IN_PROGRESS" &&
                  isLottery === false &&
                  currentGlobalDate === selectedGlobalDate && (
                    <Link
                      to="/admin/start-selection"
                      style={{ textDecoration: "none" }}
                    >
                      <Box width="220px" marginRight="15px">
                        <AdminButton label="Continue Selection process" />
                      </Box>
                    </Link>
                  )}
                {/* Lottery Button */}
                {selectionStatus === "IN_PROGRESS" &&
                  isLottery === true &&
                  currentGlobalDate === selectedGlobalDate && (
                    <Link
                      to="/admin/lottery-selection"
                      style={{ textDecoration: "none" }}
                    >
                      <Box width="220px" marginRight="15px">
                        <AdminButton label="Continue Selection process" />
                      </Box>
                    </Link>
                  )}
                {/* Send Offers and Edit Selection Button */}
                {showOffers === true &&
                  currentGlobalDate === selectedGlobalDate && (
                    <>
                      <Box width="250px" marginRight="15px">
                        <AdminButton
                          label="Send offer to selected candidates"
                          onClick={() => setIsSendOffers(true)}
                          disabled={sendOfferDisabled}
                        />
                      </Box>
                      <Box width="220px" marginRight="15px">
                        <AdminButton
                          label="Edit Selection"
                          onClick={() =>
                            history.push("/admin/manual-selection")
                          }
                        />
                      </Box>
                    </>
                  )}
                {/* Withdrawal Button */}
                {selectionStatus === "COMPLETED" &&
                  startWithdrawal &&
                  currentGlobalDate === selectedGlobalDate && (
                    <Box width="220px" marginRight="15px">
                      <AdminButton
                        label="Withdrawal"
                        onClick={() =>
                          history.push("/admin/withdraw-application")
                        }
                      />
                    </Box>
                  )}
                {/* Waiting List Button */}
                {selectionStatus === "COMPLETED" &&
                  currentGlobalDate === selectedGlobalDate &&
                  !assignWaitingList && (
                    <Box width="220px" marginRight="15px">
                      <AdminButton
                        label="Waiting list"
                        onClick={() => history.push("/admin/waiting-list")}
                      />
                    </Box>
                  )}
                {/* Waiting List lottery Buttons */}
                {selectionStatus === "COMPLETED" &&
                  currentGlobalDate === selectedGlobalDate &&
                  assignWaitingList &&
                  (waitingLotteryStatus !== "COMPLETED" ? (
                    <Box width="220px" marginRight="15px">
                      <AdminButton
                        label="Waiting list lottery"
                        onClick={() =>
                          history.push("/admin/waiting-list-lottery")
                        }
                      />
                    </Box>
                  ) : (
                    <Box width="220px" marginRight="15px">
                      <AdminButton
                        label="Waiting list"
                        onClick={() => history.push("/admin/waiting-list")}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
            <Paper
              style={{
                width: "94%",
                overflow: "hidden",
                marginTop: "20px",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          className={classes.root}
                          key={`${column.id}${index}`}
                          style={{ minWidth: column.minWidth }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                          >
                            {column.label}
                            {column.isSort && (
                              <>
                                <Box marginLeft="15px">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc(true);
                                    }}
                                  >
                                    arrow_upward_icon
                                  </Icon>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc("");
                                    }}
                                  >
                                    arrow_downward_icon
                                  </Icon>
                                </Box>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {offers &&
                      offers.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            key={`${item?.ID}${index}`}
                            role="checkbox"
                            tabIndex={-1}
                          >
                            <>
                              <TableCell className={classes.root}>
                                {item.ID}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Student_Name}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.criteria.toString()}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.application_status}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.waiting_list_number}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.waiting_list_position}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.offered_date}
                              </TableCell>
                              <TableCell className={classes.root}>
                                <span
                                  onClick={() =>
                                    window.open(
                                      `/admin/applications/${item.student_app_uuid}`
                                    )
                                  }
                                  style={{
                                    width: 80,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    style={{
                                      color: "#757575",
                                      marginRight: 10,
                                    }}
                                  >
                                    remove_red_eye_icon
                                  </Icon>
                                  VIEW
                                </span>
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {error === "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "20px 50px",
                  }}
                >
                  <div style={{ padding: "0px 12px" }}>{`${
                    pageNumber || 0
                  } of ${totalPages || 0}`}</div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("first")}
                  >
                    First
                  </button>
                  <div style={{ padding: "0px 12px" }}>
                    <button
                      onClick={() => setPageNumber(pageNumber - 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === 1 || pageNumber === 0}
                    >
                      <Icon>chevron_left_outlined</Icon>
                    </button>
                    <button
                      onClick={() => setPageNumber(pageNumber + 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === totalPages || pageNumber === 0}
                    >
                      <Icon>chevron_right_outlined</Icon>
                    </button>
                  </div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("last")}
                  >
                    Last
                  </button>
                </div>
              ) : null}
              {error !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  {error}
                </div>
              ) : null}
            </Paper>
          </>
        </div>
      </Box>
      <ConfirmBox
        message="Are you sure you want to send offer to the selected candidates ?"
        arg={false}
        open={isSendOffers}
        setOpen={setIsSendOffers}
        onClick={sendOffer}
      />
      <MessageBox
        message="The offer email has been sent to the respective candidates"
        open={offerSent}
        setOpen={setOfferSent}
      />
      <ConfirmBox
        message="Are you sure you want to send Reminder Email ?"
        arg={false}
        open={sendReminderEmail}
        setOpen={setSendReminderEmail}
        onClick={sendReminder}
      />
    </Grid>
  );
};

export default Offers;
