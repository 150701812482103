import { Box, Icon } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import request from "../../../utils/authUtil";
import { useStyle } from "../all-communications-tab/com.style";

import EditTemplate from "./edit-template";
import { useQuery, UseQueryResult } from "react-query";
import { Loader } from "../../../components/common/loader";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TablePagination,
  TableHead,
} from "@material-ui/core";
type Props = {
  currentGlobalDate?: any;
  selectedGlobalDate?: any;
};

interface templateData {
  uuid: string;
  template_code: string;
  template_code_text: string;
  email_subject: string;
  email_body: string;
  is_active: boolean;
  is_editable: boolean;
}

const Templates: React.FC<Props> = ({
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);
  const [show, setShow] = useState("list");
  const [templateID, setTemplateID] = useState("");
  const [templates, setTemplates] = useState<templateData[]>([
    {
      uuid: "",
      template_code: "",
      template_code_text: "",
      email_subject: "",
      email_body: "",
      is_active: true,
      is_editable: true,
    },
  ]);

  const getTemplates = async (): Promise<any> => {
    const res = await request({
      url: `school-communications/email-templates-details/`,
      method: "get",
    });
    return res;
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getTemplateError,
    data,
    refetch,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["templates", templateID],
    () => getTemplates(),
    {
      select: (template) => template.data,
    }
  );

  const handleTabChange = (value: string, templateID: string) => {
    setTemplateID(templateID);
    setShow(value);
  };

  const onClose = () => {
    setShow("list");
    refetch();
  };

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setTemplates(data.results);
    }
  }, [data]);

  const columns = [
    { id: "template_code_text", label: "Template Code Text", minWidth: 300 },
    { id: "email_subject", label: "Email Subject", minWidth: 300 },
    { id: "edit", label: " ", minWidth: 300 },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start">
      {/* post loader */}
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getTemplateError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <div style={{ width: "96%", marginTop: "20px" }}>
          <div>
            {show === "list" ? (
              <div>
                {/* *Table Code Start* */}

                <Paper
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    marginBottom: "40px",
                  }}
                >
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              className={classes.root}
                              key={column.id}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {templates
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            return (
                              <TableRow
                                key={item?.uuid}
                                hover
                                role="checkbox"
                                tabIndex={-1}
                              >
                                <>
                                  <TableCell className={classes.root}>
                                    {item.template_code_text}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    {item.email_subject}
                                  </TableCell>
                                  <TableCell className={classes.root}>
                                    <Box
                                      alignItems="center"
                                      display="flex"
                                      justifyContent="space-around"
                                    >
                                      EDIT TEMPLATE
                                      <Icon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleTabChange("edit", item.uuid)
                                        }
                                      >
                                        edit
                                      </Icon>
                                    </Box>
                                  </TableCell>
                                </>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={templates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Paper>
                {/* *Table Code End* */}

                {/* <div
                  style={{
                    width: "300px",
                    marginTop: "20px",
                    marginBottom: "40px",
                  }}
                >
                  <AdminButton
                    label="Add new template"
                    endIcon="add"
                    light
                    onClick={() => handleTabChange("add", "")}
                  />
                </div> */}
              </div>
            ) : null}
            {show === "edit" || show === "add" ? (
              <EditTemplate
                templateID={templateID}
                onClose={onClose}
                refetch={refetch}
                selectedGlobalDate={selectedGlobalDate}
                currentGlobalDate={currentGlobalDate}
              ></EditTemplate>
            ) : null}
          </div>
        </div>
      ) : null}
    </Grid>
  );
};

export default Templates;
