import { useStyle } from "./textfield.style";
import cn from "classnames";
import { Box } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

type TextfieldProps = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  errorMessage?: string | null;
  defaultValue?: string;
  value?: string;
  hide?: boolean;
  radioComponent?: any;
};

export const CustomtextField: React.FC<TextfieldProps> = ({
  label,
  placeholder,
  disabled,
  defaultValue,
  value,
  onChange,
  hide = true,
  error = false,
  errorMessage = "Required",
  radioComponent,

  //   checked,
}) => {
  const classes = useStyle();

  return (
    <Box>
      {!hide ? (
        <div style={{ width: "100%" }}>
          <InputLabel
            className={cn(classes.label, error ? classes.labelError : null)}
          >
            {label}
          </InputLabel>

          <TextareaAutosize
            disabled={disabled}
            value={value}
            onChange={onChange}
            maxRows={7}
            minRows={7}
            style={{ resize: "none", fontSize: "16px" }}
            className={cn(error ? classes.error : classes.inputRoot)}
          />

          <div>{radioComponent}</div>

          {error ? (
            <InputLabel className={classes.errorMessage}>
              {errorMessage}
            </InputLabel>
          ) : null}
        </div>
      ) : null}
    </Box>
  );
};
