import { useStyle } from "./welcome.style";
import cn from "classnames";
import { Box } from "@material-ui/core";
type WelcomeProps = {
  title?: string;
  subTitle?: string;
  description?: string;
  className?: string;
};

export const Welcome: React.FC<WelcomeProps> = ({
  title,
  subTitle,
  className,
  description,
}) => {
  const classes = useStyle();
  return (
    <Box className={cn(classes.root, className)}>
      <h2 className={classes.title}>{title}</h2>
      <p className={classes.subTitle}>{subTitle}</p>
      <p className={classes.description}>{description}</p>
    </Box>
  );
};
