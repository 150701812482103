import { makeStyles } from "@material-ui/core";
export const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
  },
  bodyContainer: {
    maxWidth: "750px",
    margin: "0 auto",
    paddingLeft: "23px",
    paddingRight: "23px",
  },
  logoImage: {
    width: "50.19px",
    height: "65.36px",
  },
  footerContainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "40px",
    },
  },
  iconlabel: {
    color: theme.palette.grey[300],
    marginRight: 2,
    fontSize: 18,
  },
  buttonWidth: {
    margin: "29px 0px auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "35%",
    },
  },

  icon: {
    color: theme.palette.grey[300],
    marginRight: "10px",
    fontSize: "18px",
  },
  arrowIcon: {
    fontSize: 20,
    color: theme.palette.grey[300],
  },
  textOne: {
    fontSize: "14px",
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  card: {
    marginTop: 52,
    marginBottom: 82,
    backgroundColor: theme.palette.common.white,
    width: "80%",
    borderRadius: 12,
    boxShadow: "0px 8px 14px 3px rgba(0, 0, 0, 0.03)",
    padding: "90px 49px",
    [theme.breakpoints.down("md")]: {
      marginTop: 52,
      marginBottom: 52,
      backgroundColor: "inherit",
      boxShadow: "none",
      padding: 0,
      width: "100%",
    },
  },
  imageError: {
    color: theme.palette.error.main,
  },
  text: {
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    color: theme.palette.text.primary,
    marginTop: "-10px",
    marginBottom: "30px",
    textAlign: "center",
  },
  disclamer: {
    marginBottom: "10px",
    fontFamily: `"LFT Etica", sans-serif`,
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));
