import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
    bgGray: {
        background:theme.palette.background.default
    },
    bgWhite:{
        background:theme.palette.common.white
        
    },
    header: {
        fontSize: "15px",
        fontWeight: 600,
    },
    text: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#2B2B33",
    },
    label: {
        marginBottom: 6.5,
        fontFamily:    `"LFT Etica", sans-serif`,
        fontSize: 13,
        color: theme.palette.text.primary,
    },
    labelError: {
        color: theme.palette.error.main,
    },
    inputRoot: {
        width: "100%",
        fontFamily:    `"LFT Etica", sans-serif`,
        textTransform: "none",
        borderRadius: 4,
        color: theme.palette.text.primary,
        padding: "15px 12px",
        border: "1px solid",
        borderColor: theme.palette.grey[200],
        overflow: "auto",
        outline: "none",
        "&:focus": {
            border: "1px solid",
            borderColor: theme.palette.common.black,
        },
    },
}));
