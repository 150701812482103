import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { useStyle } from "./photo-upload.style";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Loader } from "../../../../components/common/loader/index";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import Grid from "@material-ui/core/Grid";
import { Button } from "../../../../components/common/button/index";
import { Upload } from "../../../../components/common/upload/index";
import axios from "axios";
import { Type } from "../../../../utils/document-types";
import ProfileImage from "../../profile-image/index";
type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

const PhotoUpload: React.FC<Props> = ({ nextPage, prevPage, stdAppId }) => {
  const styles = useStyle();
  const [isEnrolmentForm, setIsEnrolmentForm] = useState<boolean>(false);

  useEffect(() => {
    setIsEnrolmentForm(
      window.location?.pathname?.split("/")[2] === "enrolment"
    );
  }, []);

  const parentID = ParentUser.uuid;
  const schoolID = localStorage.getItem("school-id");
  const [loader, setLoader] = useState<boolean>(false);
  const [passDoc, setPassDoc] = useState({
    doc_passport_name: "",
    document_uuid: "",
    isFile: false,
    document_url: "",
  });

  const [error, setError] = useState<any>({
    pas_doc_empty_err: false,
    pas_doc_accept_err: false,
  });

  const [isPhotoRequired, setIsPhotoRequired] = useState(false);

  const getCustomSettings = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setIsPhotoRequired(res.data.results.is_enrol_photo_optional);
      setLoader(false);
    });
  };

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    file: any
  ) => {
    if (fileName) {
      if (
        !fileName.toLowerCase().match(/\.(jpg|jpeg|png)$/) &&
        documentType === Type.passport
      ) {
        setPassDoc({
          ...passDoc,
          doc_passport_name: "",
          isFile: false,
        });
        setError({
          ...error,
          pas_doc_empty_err: false,
          pas_doc_accept_err: true,
        });
        return null;
      }
      else {
      const res = await request({
        url: "/auth/parent-documents-upload-credentials",
        method: "post",
        data: {
          user_uuid: userId,
          school_uuid: schoolId,
          document_type: documentType,
          file_name: fileName,
        },
      });
      return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  const getPassPortPhoto = () => {
    setLoader(true);
    request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_type=${Type.passport}&document_for=ENROLMENT`,
      method: "get",
    }).then((res) => {
      if (res.data.results.length > 0) {
        setPassDoc({
          ...passDoc,
          doc_passport_name: res.data.results[0].document_name,
          document_uuid: res.data.results[0].document_uuid,
          isFile: true,
          document_url: res.data.results[0].document_presingne_url,
        });
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    getCustomSettings();
    getPassPortPhoto();
  }, []);

  const setFileName = (type: string, name: string) => {
    if (type === Type.passport) {
      setPassDoc({
        ...passDoc,
        doc_passport_name: name,
        isFile: true,
      });
    }
  };

  const validate = () => {
    return {
      pas_doc_empty_err: isPhotoRequired && passDoc.doc_passport_name === "",
    };
  };

  const onSubmit = async (
    url: string,
    documentType: string,
    document_uuid: string
  ) => {
    await request({
      url: `student-application/student-application-documents/?student_app_uuid=${stdAppId}&document_for=APPLICATION&document_uuid=${document_uuid}`,
      method: "put",
      data: {
        document_type: documentType,
        document_url: url,
        document_for: "ENROLMENT",
      },
    }).then(() => {
      if (documentType === Type.passport) {
        getPassPortPhoto();
      }
    });
  };

  const onNext = () => {
    const err = validate();
    setError(err);
    if (
      !err.pas_doc_empty_err
    ) {
      nextPage();
    }
  };

  const change = (event: any, documentType: string, document_uuid: string) => {
    setLoader(true);
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        documentType,
        event.target?.files[0]?.name,
        event.target.files
      ).then((response) => {
        if (response === null) {
          setLoader(false);
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setFileName(documentType, event.target.files[0].name);
          onSubmit(
            `${response.url}${response.fields.key}`,
            documentType,
            document_uuid
          );
          if (documentType === Type.passport) {
            setError({
              ...error,
              pas_doc_empty_err: false,
              pas_doc_accept_err: false,
            });
          }
        }
      );
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Loader open={loader} />
        <Box mt="52px" className={styles.card} mb={6}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt="52px"
          >
            <div>
              <p>
                <Icon style={{ cursor: "pointer" }} onClick={prevPage}>
                  arrow_back
                </Icon>
              </p>
              <div>{isEnrolmentForm ? "Enrolment" : "Application"}</div>
            </div>
            <ProgressBar total={7} steps={2} />
          </Box>
          <div style={{ marginBottom: 30 }}>
            <Welcome title="Photo upload" description="Next: Documents" />
          </div>
          {isPhotoRequired && (
            <>
              <Box>
                {passDoc.document_url ? (
                  <ProfileImage src={passDoc.document_url} label="Your photo" />
                ) : null}
              </Box>
              <p className={styles.description}>
                Please upload a current headshot of your student/child facing forwards
                and looking straight at the camera with a plain expression, eyes
                open and mouth closed.
              </p>
              <Upload
                id="passport_photo"
                placeholder="Upload"
                belowLabel="Passport photos etc preferable"
                onChange={(e: any) =>
                  change(e, Type.passport, passDoc.document_uuid)
                }
                file={passDoc.doc_passport_name}
                isFile={passDoc.isFile}
                error={error.pas_doc_accept_err || error.pas_doc_empty_err}
                errorMessage={
                  error.pas_doc_accept_err === true
                    ? "Only  files .png .jpg .png .jpeg are allowed"
                    : "Required"
                }
                showDeleteIcon={false}
              />
            </>
          )}
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-around"
          >
            <div className={styles.buttonWidth}>
              <Button
                label="Next"
                light
                endIcon="navigate_next"
                onClick={onNext}
              />
            </div>
          </Grid>
        </Box>
      </div>
    </div>
  );
};
export default PhotoUpload;
